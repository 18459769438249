// @flow

export const GET_STORE_AVAILABILITY = "[STORE] GET_STORE_AVAILABILITY";
export const GET_STORE_AVAILABILITY_SUCCESS =
  "[STORE] GET_STORE_AVAILABILITY_SUCCESS";
export const GET_STORE_AVAILABILITY_ERROR = "[STORE] GET_STORE_AVAILABILITY_ERROR";

export const GET_STORE_COMMENTS = "[STORE] GET_STORE_COMMENTS";
export const GET_STORE_COMMENTS_SUCCESS = "[STORE] GET_STORE_COMMENTS_SUCCESS";
export const GET_STORE_COMMENTS_ERROR = "[STORE] GET_STORE_COMMENTS_ERROR";

export const SEND_STORE_COMMENTS = "[STORE] SEND_STORE_COMMENTS";
export const SEND_STORE_COMMENTS_SUCCESS = "[STORE] SEND_STORE_COMMENTS_SUCCESS";
export const SEND_STORE_COMMENTS_ERROR = "[STORE] SEND_STORE_COMMENTS_ERROR";

export const GET_STORE_DOUNGNUT_CHART = "[STORE] GET_STORE_DOUNGNUT_CHART";
export const GET_STORE_DOUNGNUT_CHART_SUCCESS =
  "[STORE] GET_STORE_DOUNGNUT_CHART_SUCCESS";
export const GET_STORE_DOUNGNUT_CHART_ERROR =
  "[STORE] GET_STORE_DOUNGNUT_CHART_ERROR";

export const GET_STORE_CALENDAR_CHART = "[STORE] GET_STORE_CALENDAR_CHART";
export const GET_STORE_CALENDAR_CHART_SUCCESS = "[STORE] GET_STORE_CALENDAR_CHART_SUCCESS";
export const GET_STORE_CALENDAR_CHART_ERROR = "[STORE] GET_STORE_CALENDAR_CHART_ERROR";

type GetStoreAvailability = {
  type: typeof GET_STORE_AVAILABILITY,
  payload: object,
};
type GetStoreAvailabilitySuccess = {
  type: typeof GET_STORE_AVAILABILITY_SUCCESS,
  payload: Array,
};
type GetStoreAvailabilityError = {
  type: typeof GET_STORE_AVAILABILITY_ERROR,
  payload: any,
};

type GetStoreComments = {
  type: typeof GET_STORE_COMMENTS,
  payload: object,
};
type GetStoreCommentsSuccess = {
  type: typeof GET_STORE_COMMENTS_SUCCESS,
  payload: Array,
};
type GetStoreCommentsError = {
  type: typeof GET_STORE_COMMENTS_ERROR,
  payload: any,
};

type SendStoreComments = {
  type: typeof SEND_STORE_COMMENTS,
  payload: object,
};
type SendStoreCommentsSuccess = {
  type: typeof SEND_STORE_COMMENTS_SUCCESS,
  payload: Array,
};
type SendStoreCommentsError = {
  type: typeof SEND_STORE_COMMENTS_ERROR,
  payload: any,
};

type GetStoreDoughnutChart = {
  type: typeof GET_STORE_DOUNGNUT_CHART,
  payload: object,
};
type GetStoreDoughnutChartSuccess = {
  type: typeof GET_STORE_DOUNGNUT_CHART_SUCCESS,
  payload: Array,
};
type GetStoreDoughnutChartError = {
  type: typeof GET_STORE_DOUNGNUT_CHART_ERROR,
  payload: any,
};
type GetStoreCalendarChart = {
  type: typeof GET_STORE_CALENDAR_CHART,
  payload: object,
};
type GetStoreCalendarChartSuccess = {
  type: typeof GET_STORE_CALENDAR_CHART_SUCCESS,
  payload: Array,
};
type GetStoreCalendarChartError = {
  type: typeof GET_STORE_CALENDAR_CHART_ERROR,
  payload: any,
};

export type StoreAction =
  | GetStoreAvailability
  | GetStoreAvailabilitySuccess
  | GetStoreAvailabilityError
  | GetStoreComments
  | GetStoreCommentsSuccess
  | GetStoreCommentsError
  | SendStoreComments
  | SendStoreCommentsSuccess
  | SendStoreCommentsError
  | GetStoreDoughnutChart
  | GetStoreDoughnutChartSuccess
  | GetStoreDoughnutChartError
  | GetStoreCalendarChart
  | GetStoreCalendarChartSuccess
  | GetStoreCalendarChartError;