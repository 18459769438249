// @flow

import * as fromTypes from "../types/kyc";

type State = {
  loading: boolean,
  forms: array,
  pie: array,
  line: array,
};

export const initialState = {
  loading: false,
  forms: [],
  comments: [],
  pie: [],
  line: [],
};

const setForms = (state, { payload }) => ({
  ...state,
  forms: payload,
  loading: false,
});

const setPie = (state, { payload }) => ({
  ...state,
  pie: payload,
  loading: false,
});

const setLine = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.KYCAction) => {
  switch (action.type) {
    case fromTypes.GET_KYC_AVAILABILITY:
    case fromTypes.ADD_KYC_ACTION:
    case fromTypes.GET_KYC_PIE_CHART:
    case fromTypes.GET_KYC_LINE_CHART:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_KYC_AVAILABILITY_SUCCESS:
      return setForms(state, action);
    case fromTypes.ADD_KYC_ACTION_SUCCESS:
      return setLoading(state, false);
    case fromTypes.GET_KYC_PIE_CHART_SUCCESS:
      return setPie(state, action);
    case fromTypes.GET_KYC_LINE_CHART_SUCCESS:
      return setLine(state, action);
    // Error
    case fromTypes.GET_KYC_AVAILABILITY_ERROR:
      return setLoading(state, false);
    case fromTypes.ADD_KYC_ACTION_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
