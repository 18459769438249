import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
//import Checkbox from "@mui/material/Checkbox";
//import TextField from "@mui/material/TextField";
//import Autocomplete from "@mui/material/Autocomplete";
import { black, darkGrey, white, inputSky } from "../theme/colors";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    left: "3%",
    top: "25%",
    background: white,
    color: darkGrey,
  },
  inputContainer: {
    width: "450px",
  },
  input: {
    fontFamily: "SantanderMicroText !important",
    boxSizing: "border-box",
    width: "450px",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focus-visible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-25%",
      fontSize: "0.9em",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "3%",
      top: "25%",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "3%",
    background: white,
    color: darkGrey,
    fontSize: "14px",
    lineHeight: "21px",
    top: "-12px",
  },
}));

const PersonField = ({
  input,
  filters,
  changedForm,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [id, setId] = useState("");
  const inputToFocus = useRef(null);

  useEffect(() => {
    setInputValue("");
    setId(0);
    inputToFocus.current.focus();
  }, [changedForm]);

  const changeValue = (e) => {
    setInputValue(e.target.value);
    const gaId = parseInt(e.target.value.substring(0, 6));
    if (!isNaN(gaId) && gaId >= 100000) {
      setId(gaId);
      inputToFocus.current.focus();
    } else {
      setId(0);
    }
  };

  const clearInput = (e) => {
    setInputValue("");
  };

  return (
    <div className={classes.root}>
      <input
        {...input}
        value={id}
        ref={inputToFocus}
        style={{ position: "absolute", opacity: "0", pointerEvents: "none", }}
      />
      <input
        list="gas"
        className={`${classes.input} dss-form-field__input`}
        value={inputValue}
        onChange={changeValue}
        onFocus={clearInput}
      />
      <span className={classes.provisionalPlaceholder}>
        Selecione um Perfil
      </span>
      <Icons icon="search" isInputIcon />
      {filters ? (
        <datalist id="gas">
          {filters.map((i, index) => {
            return (
              <option
                value={`${i.cd_Matricula} - ${i.nm_Func}`}
                key={i.cd_Matricula}
              />
            );
          })}
        </datalist>
      ) : null}
      {/* 
      <Autocomplete
        id="checkboxes-tags-demo"
        options={gaList}
        getOptionLabel={(option) => option.nm_Func}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            ({option.cd_Matricula}) {option.nm_Func}
          </li>
        )}
        renderInput={(params) => (
          <div className={classes.inputContainer} ref={params.InputProps.ref}>
            <input
              {...input}
              {...params.inputProps}
              className={`${classes.input} dss-form-field__input`}
              type="text"
            />
            <span
              className={`${
                isEmpty
                  ? "dss-form-field__label"
                  : "dss-form-field__label--active"
              }`}
            >
              Selecione um Perfil
            </span>
            <Icons icon="search" isInputIcon />
          </div>
        )}
      />
      */}
    </div>
  );
};

export default PersonField;
