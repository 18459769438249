// @flow

import * as fromTypes from "../types/manifestation";

export const getManifestationTable = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_TABLE,
  payload,
});
export const getManifestationTableSuccess = (
  payload: array
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_TABLE_SUCCESS,
  payload,
});
export const getManifestationTableError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_TABLE_ERROR,
  payload,
});

export const addManifestationComment = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_COMMENT,
  payload,
});
export const addManifestationCommentSuccess = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_COMMENT_SUCCESS,
  payload,
});
export const addManifestationCommentError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_COMMENT_ERROR,
  payload,
});

export const addManifestationAction = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_ACTION,
  payload,
});
export const addManifestationActionSuccess = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_ACTION_SUCCESS,
  payload,
});
export const addManifestationActionError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.ADD_MANIFESTATION_ACTION_ERROR,
  payload,
});

export const getManifestationDoughnutChart = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_DOUGHNUT,
  payload,
});
export const getManifestationDoughnutChartSuccess = (
  payload: array
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_DOUGHNUT_SUCCESS,
  payload,
});
export const getManifestationDoughnutChartError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_DOUGHNUT_ERROR,
  payload,
});

export const getManifestationComments = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_COMMENTS,
  payload,
});
export const getManifestationCommentsSuccess = (
  payload: array
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_COMMENTS_SUCCESS,
  payload,
});
export const getManifestationCommentsError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_COMMENTS_ERROR,
  payload,
});

export const getManifestationLineChart = (
  payload: object
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_LINE,
  payload,
});
export const getManifestationLineChartSuccess = (
  payload: array
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_LINE_SUCCESS,
  payload,
});
export const getManifestationLineChartError = (
  payload: any
): fromTypes.ManifestationAction => ({
  type: fromTypes.GET_MANIFESTATION_LINE_ERROR,
  payload,
});
