import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import {
  Loading,
  MainMenu,
  List,
  TextArea,
  SelectField,
  RegisterDateField,
  TextField,
  AlertAutocompleteFilter,
} from "../components";
import {
  lighterGrey,
  darkGrey,
  boston,
  santander,
  white,
  santander20,
  mediumGrey,
} from "../theme/colors";
import { getUserLvl } from "../store/services/auth";
import { Field, reduxForm, change, formValueSelector, reset } from "redux-form";
import { required } from "../utils/validators";
import { sendAlert, editAlert, deleteAlert } from "../store/actions/alerts";
import { alertToast } from "../components/Toast";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px 0px",
    minHeight: "100vh",
  },
  titleContainer: {
    padding: "0px 40px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    paddingBottom: "24px",
  },
  btnContainer: {
    marginTop: "120px",
  },
  alertForm: {
    width: "290px",
    paddingTop: "8px",
    marginLeft: "128px",
    marginTop: "72px",
  },
  btnHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  textArea: {
    width: "290px",
    height: "242px",
    marginBottom: "32px",
  },
  selectArea: {
    width: "290px",
    marginBottom: "18px",
  },
  hr: {
    width: "290px",
    border: `1px solid ${mediumGrey}`,
    opacity: "0.3",
    marginTop: "100px",
  },
}));

let defaultEndDate = new Date();
defaultEndDate.setDate(defaultEndDate.getDate() + 5);
const formatDefaultDate = (date) => {
  const addZero = (numero) => {
    if (numero <= 9) return "0" + numero;
    else return numero;
  };
  let dateVar =
    addZero(date.getDate().toString()) +
    "/" +
    addZero(date.getMonth() + 1).toString() +
    "/" +
    date.getFullYear();
  return dateVar;
};

const compare = (x, y) => {
  const srt = (obj) => JSON.stringify(obj)?.split("").sort().join("");
  return srt(x) === srt(y);
};

const Alerts = ({
  loading,
  handleSubmit,
  onSendAlert,
  onEditAlert,
  onDeleteAlert,
  change,
  reset,
}) => {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    id: "",
    type: "",
    date: [formatDefaultDate(new Date()), formatDefaultDate(defaultEndDate)],
    title: "",
    msg: "",
  });
  const [filterValues, setFilterValues] = useState({
    broadcast: 0,
    rede: 0,
    regional: 0,
    agencia: 0,
    ga: 0,
  });
  const [defaultDate, setDefaultDate] = useState([
    formatDefaultDate(new Date()),
    formatDefaultDate(defaultEndDate),
  ]);
  const [hasDefault, setHasDefault] = useState(false);
  const [filterDefaultValue, setFilterDefaultValue] = useState(0);
  const defaultFilters = {
    broadcast: 0,
    rede: 0,
    regional: 0,
    agencia: 0,
    ga: 0,
  };

  useEffect(() => {
    if (getUserLvl() === "1") window.location.href = "/home";
  }, []);

  useEffect(() => {
    change("msg", formValues.msg);
    change("date", formValues.date);
    change("type", formValues.type);
    change("title", formValues.title);
  }, [formValues.msg]);

  useEffect(() => {
    change("date", formValues.date);
  }, [formValues.date]);

  const openForm = (details) => {
    setHasDefault(false);
    setFilterDefaultValue(0);
    if (details !== "empty") {
      setFormValues({
        id: details.id,
        title: details.title,
        type: details.type,
        date: [details.dt_Vgc_Inc, details.dt_Vgc_Fnl],
        msg: details.msg,
      });
      setDefaultDate([details.dt_Vgc_Inc, details.dt_Vgc_Fnl]);
      const detailFilters = {
        broadcast: details.broadcast,
        rede: details.rede,
        regional: details.regional,
        agencia: details.agencia,
        ga: details.ga,
      };
      setFilterValues(detailFilters);
      setHasDefault(true);
      Object.keys(detailFilters).forEach((key, i) => {
        if (detailFilters[key] !== 0 && key !== "broadcast")
          setFilterDefaultValue(detailFilters[key]);
      });
    }
    if (!showForm) setShowForm(true);
    else {
      setFormValues({
        id: "",
        title: "",
        type: "",
        date: [
          formatDefaultDate(new Date()),
          formatDefaultDate(defaultEndDate),
        ],
        msg: "",
      });
      setDefaultDate([
        formatDefaultDate(new Date()),
        formatDefaultDate(defaultEndDate),
      ]);
      setFilterValues({
        broadcast: 0,
        rede: 0,
        regional: 0,
        agencia: 0,
        ga: 0,
      });
      reset("alertForm");
    }
  };

  const onSubmit = ({ msg, date, type, title }) => {
    let formatedDates = [];
    for (let index = 0; index < date.length; index++) {
      let day = date[index].substring(0, 2);
      let year = date[index].substring(6, 10);
      let month = date[index].substring(3, 5);

      formatedDates[index] = `${year}-${month}-${day}`;
    }
    if (!compare(filterValues, defaultFilters)) {
      if (formValues.id !== "") {
        onEditAlert({
          id: formValues.id,
          msg,
          date: formatedDates,
          type,
          title,
          filters: filterValues,
        });
        setShowForm(false);
      } else
        onSendAlert({
          msg,
          date: formatedDates,
          type,
          title,
          filters: filterValues,
        });
      setShowForm(false);
      setFormValues({ id: "", type: "", date: "", msg: "", title: "" });
      setFilterValues({
        broadcast: 0,
        rede: 0,
        regional: 0,
        agencia: 0,
        ga: 0,
      });
    } else alertToast("error", "Selecione um destinatário");
  };

  const alertDelete = () => {
    onDeleteAlert(formValues.id);
    setFormValues({ id: "", type: "", date: "", msg: "", title: "" });
    setShowForm(false);
  };

  const types = [
    { id: 3, typeName: "Informativo (Azul)" },
    { id: 2, typeName: "Sucesso (Verde)" },
    { id: 1, typeName: "Atenção (Amarelo)" },
    { id: 4, typeName: "Urgente (Vermelho)" },
  ];

  const handleChangeMethod = (fieldName, value) => {
    change(fieldName, value);
  };

  const handleChangeFilter = (type, value) => {
    switch (type) {
      case "CLEAR":
        setFilterValues({
          rede: 0,
          regional: 0,
          agencia: 0,
          ga: 0,
          broadcast: 1,
        });
        break;
      case "REDE":
        setFilterValues({
          broadcast: 0,
          regional: 0,
          agencia: 0,
          ga: 0,
          rede: value,
        });
        break;
      case "REGIONAL":
        setFilterValues({
          rede: 0,
          agencia: 0,
          ga: 0,
          broadcast: 0,
          regional: value,
        });
        break;
      case "AGÊNCIA":
        setFilterValues({
          broadcast: 0,
          rede: 0,
          regional: 0,
          ga: 0,
          agencia: value,
        });
        break;
      case "PERFIL":
        setFilterValues({
          broadcast: 0,
          rede: 0,
          regional: 0,
          agencia: 0,
          ga: value,
        });
        break;
      default:
    }
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Cadastro de Alertas
            </Typography>
          </div>

          <List type="alerts" alertEditMethod={openForm} />
        </Grid>
        <Grid item md={4}>
          {showForm ? null : (
            <div className={classes.btnContainer}>
              <>
                <Button
                  variant="contained"
                  onClick={() => openForm("empty")}
                  sx={{
                    height: "48px",
                    background: boston,
                    color: white,
                    fontFamily: "SantanderMicroText",
                    textTransform: "none",
                    textDecoration: "none",
                    display: "block",
                    margin: "auto",
                    "&:hover": {
                      background: boston,
                      color: white,
                    },
                  }}
                >
                  Cadastrar novo Alerta
                </Button>
                <hr className={classes.hr} />
              </>
            </div>
          )}

          {showForm ? (
            <div className={classes.alertForm}>
              {formValues.id === "" ? (
                <Typography className={classes.subtitle}>
                  Cadastrar novo alerta
                </Typography>
              ) : (
                <Typography className={classes.subtitle}>
                  Editar alerta
                </Typography>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.selectArea}>
                  <Field
                    type="text"
                    name="title"
                    icon=""
                    placeholder="Título*"
                    component={TextField}
                    validate={[required]}
                    maxLength="100"
                    defaultValue={formValues.title}
                  />
                </div>
                <div className={classes.selectArea}>
                  <AlertAutocompleteFilter
                    onChangeMethod={handleChangeFilter}
                    hasDefault={hasDefault}
                    defaultValue={filterDefaultValue}
                  />
                </div>
                <div className={classes.textArea}>
                  <Field
                    type="text"
                    name="msg"
                    placeholder="Mensagem*"
                    component={TextArea}
                    validate={[required]}
                    defaultValue={formValues.msg}
                  />
                </div>
                <div className={classes.selectArea}>
                  <Field
                    name="type"
                    placeholder="Severidade*"
                    component={SelectField}
                    dataList={types}
                    validate={[required]}
                    defaultValue={formValues.type}
                    {...{
                      changeMethod: handleChangeMethod,
                      fieldName: "type",
                    }}
                  />
                </div>

                <div className={classes.dateArea}>
                  <Field
                    type="text"
                    name="date"
                    placeholder="Data*"
                    component={() => (
                      <input
                        type="text"
                        value={formValues.date}
                        readOnly
                        style={{ display: "none" }}
                      />
                    )}
                    defaultValue={formValues.date}
                  />
                  <RegisterDateField
                    onChangeMethod={(value) =>
                      setFormValues({ ...formValues, date: value })
                    }
                    defaultValue={defaultDate}
                  />
                </div>
                <div className={classes.btnHolder}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      openForm("empty");
                      setShowForm(false);
                    }}
                    sx={{
                      height: "48px",
                      fontFamily: "SantanderMicroText ",
                      background: white,
                      textTransform: "none ",
                      color: `${santander} `,
                      border: `1px solid ${santander} `,
                      display: "block",
                      fontSize: "16px ",
                      "&:hover": {
                        background: santander20,
                        color: `${santander} `,
                      },
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      height: "48px",
                      background: boston,
                      color: white,
                      fontFamily: "SantanderMicroText",
                      textTransform: "none",
                      textDecoration: "none",
                      "&:hover": {
                        background: boston,
                        color: white,
                      },
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </div>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

const selector = formValueSelector("alertForm");

const mapDispatchToProps = (dispatch) => {
  return {
    onSendAlert: (payload) => dispatch(sendAlert(payload)),
    onEditAlert: (payload) => dispatch(editAlert(payload)),
    onDeleteAlert: (id) => dispatch(deleteAlert(id)),
    change,
    reset,
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.auth;
  const { filters } = state.bulletin;
  const { msg, date, type, title } = selector(
    state,
    "msg",
    "date",
    "type",
    "title"
  );
  return { loading, filters, msg, date, type, title };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "alertForm",
      enableReinitialize: true,
    })(Alerts)
  )
);
