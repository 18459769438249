import React from "react";
import { makeStyles } from "@material-ui/styles";
import { mediumGrey, white } from "../theme/colors";
import Row from "./Row";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: white,
    fontFamily: "SantanderHeadline",
    color: mediumGrey,
  },
  table: {
    minWidth: "100%",
    marginTop: "2.500em",
    tableLayout: "auto",
    "& th": {
      textAlign: "left",
    },
    "& tbody": {
      borderRadius: "2em",
    },
  },
  smallHeader: {
    fontSize: "0.75em",
    fontFamily: "SantanderMicroText",
    fontWeight: "400 !important",
    textAlign: "center",
  },
  titleHeader: {
    fontWeight: "bold !important",
    paddingLeft: "2em",
  },
}));

const Table = ({ tableName, rowData, currentPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <colgroup>
          <col width="20%" />
          <col width="4%" />
          <col width="4%" />
          <col width="14.25%" />
          <col width="14.25%" />
          <col width="14.25%" />
          <col width="14.25%" />
          <col width="14.25%" />
        </colgroup>
        <thead>
          <tr>
            <td className={classes.titleHeader}>{tableName}</td>
            <td className={classes.smallHeader}>Meta</td>
            {tableName === "Risk Pro Atendimento" ? (
              <td className={classes.smallHeader}>Pendências</td>
            ) : (
              <td className={classes.smallHeader}>Realizado</td>
            )}
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, i) => {
            return (
              <Row
                withTooltip={
                  tableName === "Risk Pro Atendimento" ? true : false
                }
                tooltip={false}
                data={row}
                key={i}
                isFirst={i === 0}
                isLast={i + 1 === rowData.length}
                currentPageInfo={currentPage}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
