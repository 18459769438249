import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles, styled } from "@material-ui/styles";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { addQueuesAction } from "../../store/actions/queues";
import csvIcon from "../../assets/icons/csv_icon.svg";
import { getUser } from "../../store/services/auth";
import { getLastSearchedUser } from "../../store/services/search";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  CommentDrawer,
  WarningExportModal,
  QueuesHeatmap,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  santander70,
  black,
  lightGrey,
  darkerGrey2,
  mediumGrey,
  yellow,
  mustard,
  turquoise20,
  beige,
  purple,
  yellow10,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";

import interno from "../../assets/img/interno.svg";
import { dataMock, mouthDays, dayHours } from "../../heatmapMock";
import { addIndicatorComment } from "../../store/actions/bulletin";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "350px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
    maxWidth: "360px",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "180px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "75%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "100px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
  circlesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // gap: "27px",
  },
  circleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row-reverse",
    paddingRight: "43px",
    width: "150px",
    // gap: "12px",
    display: "flex",
    alignItems: "center",
  },
  circle: {
    fontFamily: "SantanderHeadline",
    fontSize: "28px",
    color: white,
    width: "92px",
    height: "92px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  maxTimeText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
    textDecoration: "underline",
  },
  dayOverviewContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "12px 12px 20px 13px",
    width: "392px",
  },
  dayOverview: {
    display: "flex",
    alignItems: "center",
    gap: "30px",

    minHeight: "117px",
    height: "100%",
  },
  overviewTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  overviewText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  closeIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "16px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "16px",
    letterSpacing: "normal",
    lineHeight: "16px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "16pxx",
  },
  tooltipText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "14px !important",
    color: `${bulletinGrey} !important`,
    backgroundColor: `${white} !important`,
  },
}));

const convertToDate = (dateString) => {
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${white}`,
    boxShadow: "0px 3px 6px #00000029",
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginTop: "-10px !important",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      backgroundColor: `${white} !important`,
      boxShadow: "0px 3px 6px #00000029",
    },
  },
}));

const Queues = ({
  match,
  loading,
  onAddQueuesAction,
  table,
  gaInfo,
  doughnut,
}) => {
  const classes = useStyles();
  const [concluidoMesData, setConcluidoMesData] = useState([]);
  const [concluidoLegadoData, setConcluidoLegadoData] = useState([]);
  const [mesData, setMesData] = useState([]);
  const [legadoData, setLegadoData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [concluidoTotalData, setConcluidoTotalData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exoSortModel, setExoSortModel] = useState([
    {
      field: "aboveTarget",
      sort: "desc",
    },
    {
      field: "closeTarget",
      sort: "desc",
    },
  ]);
  const [showDayOverview, setShowDayOverview] = useState(false);

  useEffect(() => {
    let list = [];
    if (table[0] !== "forbidden") {
      table.map((i, index) => {
        list = [
          ...list,
          {
            id: index + 1,
            cdIndicador: i.cd_indicador,
            uniorg: i.cd_Uniorg,
            channel: i.nm_Canal,
            urgStatus: i.tmp_Fila_Maior,
            aboveTarget: i.qtde_Acima_Meta,
            closeTarget: i.qtde_Prox_Meta,
            onTarget: i.qtde_Dentro_Meta,
            totalQueues: i.qtde_Total_Fila,
            medQueues: i.tmp_Media_Fila,
            target: i.tmp_Meta_Atendimento,
            action: i.bl_Atuacao,
          },
        ];
      });
      setRows(list);
      setBackupRows(list);
    }
  }, [table, gaInfo]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut]);

  useEffect(() => {
    doughnut.perc_Meta < 100 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut]);

  const doughnutLabels = [`Acima do objetivo`, `Dentro do objetivo`];

  const doughnutChartCheckoutConfig = () => {
    if (doughnut.qtde_Acima_Meta_Cx > 0 || doughnut.qtde_Dentro_Meta_Cx > 0) {
      return [
        {
          data: [doughnut.qtde_Acima_Meta_Cx, doughnut.qtde_Dentro_Meta_Cx],
          backgroundColor: [santander, turquoise],
          cutout: 40,
        },
      ];
    } else {
      return [
        {
          data: [1],
          backgroundColor: [lighterGrey],
          cutout: 40,
        },
      ];
    }
  };

  const doughnutChartManagerConfig = () => {
    if (doughnut.qtde_Acima_Meta_Gr > 0 || doughnut.qtde_Dentro_Meta_Gr > 0) {
      return [
        {
          data: [doughnut.qtde_Acima_Meta_Gr, doughnut.qtde_Dentro_Meta_Gr],
          backgroundColor: [santander, turquoise],
          cutout: 40,
        },
      ];
    } else {
      return [
        {
          data: [1],
          backgroundColor: [lighterGrey],
          cutout: 40,
        },
      ];
    }
  };

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setInputValue(searchValue);
      setRows(backupRows);
    } else {
      setInputValue(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const getUrgencyColor = (type, aboveTarget, closeTarget) => {
    if (aboveTarget === 0 && closeTarget === 0) return "";
    else if (aboveTarget !== 0)
      return type === "aboveTarget" || type === "urgStatus" ? santander20 : "";
    else return type === "closeTarget" || type === "urgStatus" ? beige : "";
  };

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 140,
      headerClassName: "header",
    },
    {
      field: "channel",
      headerName: "Canal",
      width: 140,
      headerClassName: "header",
    },
    {
      field: "urgStatus",
      headerName: "Tempo de Espera",
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(
              "urgStatus",
              params.row.aboveTarget,
              params.row.closeTarget
            ),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.urgStatus}
        </div>
      ),
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Maior Tempo <br /> na Fila
        </div>
      ),
    },
    {
      field: "aboveTarget",
      headerName: "Tempo de Espera",
      width: 140,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(
              "aboveTarget",
              params.row.aboveTarget,
              params.row.closeTarget
            ),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.aboveTarget}
        </div>
      ),
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Acima <br /> da Meta
        </div>
      ),
    },
    {
      field: "onTarget",
      headerName: "Tempo de Espera",
      width: 140,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Dentro <br /> da Meta
        </div>
      ),
    },
    {
      field: "totalQueues",
      headerName: "Clientes Atendidos",
      width: 140,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Total <br /> de Fila
        </div>
      ),
    },
    {
      field: "medQueues",
      headerName: "Clientes Espera",
      width: 140,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Média <br /> de fila
        </div>
      ),
    },
    {
      field: "target",
      headerName: "Meta",
      width: 140,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                uniorg: params.row.uniorg,
                ind: params.row.cdIndicador,
                atuacao: params.row.action ? 1 : 0,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddQueuesAction({
                uniorg: params.row.uniorg,
                ind: params.row.cdIndicador,
                atuacao: !params.row.action ? 1 : 0,
              });
            }}
          >
            {!params.row.action ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const setDayOverviewDisplayMethod = () => {
    setShowDayOverview(true);
  };

  const setSelectedCategory = (category) => {
    console.log("category");
    console.log(category);
  };

  const getCheckoutGoal = () => {
    if (doughnut.qtde_Acima_Meta_Cx > 0) {
      return "qFora";
    } else if (doughnut.qtde_Dentro_Meta_Cx > 0) {
      return "qDentro";
    } else {
      return "qSem";
    }
  };

  const getManagerGoal = () => {
    if (doughnut.qtde_Acima_Meta_Gr > 0) {
      return "qFora";
    } else if (doughnut.qtde_Dentro_Meta_Gr > 0) {
      return "qDentro";
    } else {
      return "qSem";
    }
  };

  const setWithoutValue = (acima, dentro) => {
    if (acima > 0 || dentro > 0) {
      return false;
    } else {
      return true;
    }
  };

  const customCheckoutDoughnutConfigs = {
    withLegend: false,
    chartConfig: doughnutChartCheckoutConfig(),
    chartType: "doughnut",
    indicator: true,
    chartLabels: doughnutLabels,
    doughnutCenterLabel: doughnut.tmp_Fila_Maior_Cx,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    withTooltip: false,
    indicatorGoal: getCheckoutGoal(),
    isEXO: true,
    withoutValue: setWithoutValue(
      doughnut.qtde_Acima_Meta_Cx,
      doughnut.qtde_Dentro_Meta_Cx
    ),
    customPaddingBottom: "0px",
    customChartPaddingTop: -35,
    customHeight: 1.85,
    limitWidth: true,
    customMaxWidth: 150,
  };

  const customManagerDoughnutConfigs = {
    withLegend: false,
    chartConfig: doughnutChartManagerConfig(),
    chartType: "doughnut",
    indicator: true,
    chartLabels: doughnutLabels,
    doughnutCenterLabel: doughnut.tmp_Fila_Maior_Gr,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    withTooltip: false,
    indicatorGoal: getManagerGoal(),
    isEXO: true,
    withoutValue: setWithoutValue(
      doughnut.qtde_Acima_Meta_Gr,
      doughnut.qtde_Dentro_Meta_Gr
    ),
    customPaddingBottom: "0px",
    customChartPaddingTop: -35,
    customHeight: 1.85,
    limitWidth: true,
    customMaxWidth: 150,
  };

  const getIsResolveColor = () => {
    if (doughnut.qtde_Acima_Meta_Gr > 0 || doughnut.qtde_Acima_Meta_Cx > 0) {
      return {
        lineColor: santander,
        backColor: santander10,
      };
    } else if (
      doughnut.qtde_Dentro_Meta_Gr > 0 ||
      doughnut.qtde_Dentro_Meta_Cx > 0
    ) {
      return {
        lineColor: green,
        backColor: lighterGrey,
      };
    } else {
      return {
        lineColor: lighterGrey,
        backColor: lighterGrey,
      };
    }
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"FILAS"}
        modalType="interno"
      />
      <Header isQueues={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        // defaultExpanded={true}
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer && getPreviousLink() ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>Gestão de Filas</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {table.length > 0 ? (
              <>
                <Typography className={classes.uptime}>
                  Última atualização: {table[0].dt_Atualizacao}
                </Typography>
                <Tooltip placement="bottom" title={"Exportar"}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    sx={{
                      "&:hover": {
                        background: "none",
                        color: "none",
                      },
                    }}
                  >
                    <Icons icon="export-xls" />
                  </Button>
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>

        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: getIsResolveColor().backColor }}
            >
              <div className={classes.pieChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: getIsResolveColor().lineColor }}
                ></div>

                {showDayOverview ? (
                  <div className={classes.dayOverviewContainer}>
                    <div
                      onClick={() => setShowDayOverview(false)}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <Icons
                        icon="close-small"
                        customClass={classes.closeIcon}
                      />
                    </div>
                    <div
                      className={classes.dayOverview}
                      style={{
                        borderBottom: `1px solid ${lightGrey}`,
                      }}
                    >
                      <div className={classes.overviewTitle}>
                        <Typography
                          sx={{
                            fontFamily: "SantanderMicroText !important",
                            fontWeight: "bold !important",
                            fontSize: "12px !important",
                            color: `${darkGrey} !important`,
                            maxWidth: "83px",
                          }}
                        >
                          Informações da hora ({"11h"})
                        </Typography>
                      </div>
                      <div className={classes.overviewContainer}>
                        <Typography className={classes.overviewText}>
                          Tempo Médio Espera: {"00:05:23"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Tempo Médio Atendimento: {"00:07:23"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Total de clientes atendidos: {15}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Quantidade de clientes na fila: {3}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Terminais de Atendimento: {1}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classes.dayOverview}
                      style={{ paddingTop: "11px" }}
                    >
                      <div className={classes.overviewTitle}>
                        <Typography
                          sx={{
                            fontFamily: "SantanderMicroText !important",
                            fontWeight: "bold !important",
                            fontSize: "12px !important",
                            color: `${darkGrey} !important`,
                            maxWidth: "83px",
                          }}
                        >
                          Informações do Dia ({"27/06"})
                        </Typography>
                      </div>
                      <div className={classes.overviewContainer}>
                        <Typography className={classes.overviewText}>
                          Maior Tempo de Espera: {"01:20:24"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Tempo Médio Espera: {"00:35:23"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Tempo Médio Atendimento: {"00:12:23"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Horário de Pico: {"11h"}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Total de clientes atendidos: {130}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Quantidade de clientes na fila: {30}
                        </Typography>
                        <Typography className={classes.overviewText}>
                          Terminais de Atendimento: {4}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={classes.overviewDataContainer}>
                      <div>
                        <Typography
                          sx={{
                            fontFamily: "SantanderHeadline !important",
                            fontWeight: "bold !important",
                            fontSize: "18px !important",
                            color: `${darkGrey} !important`,
                            marginTop: "20px",
                          }}
                        >
                          Situação atual
                        </Typography>
                        <Typography className={classes.overviewDataChart}>
                          Média de fila: {doughnut.tmp_Medio_Fila_Geral}
                        </Typography>
                        <Typography className={classes.overviewDataChart}>
                          Total de fila: {doughnut.qtde_Clientes}
                        </Typography>
                        <Typography className={classes.overviewDataChart}>
                          Total de terminais: {doughnut.qtde_Atendente}
                        </Typography>
                      </div>
                      <div className={classes.legendContainer}>
                        <div className={classes.legendLine}>
                          <div
                            className={classes.colorLegendSquare}
                            style={{ backgroundColor: santander }}
                          ></div>
                          <Typography className={classes.legend}>
                            Acima da meta
                          </Typography>
                        </div>
                        <div className={classes.legendLine}>
                          <div
                            className={classes.colorLegendSquare}
                            style={{ backgroundColor: turquoise }}
                          ></div>
                          <Typography className={classes.legend}>
                            Dentro da meta
                          </Typography>
                        </div>
                        <div className={classes.legendLine}>
                          <div
                            className={classes.colorLegendSquare}
                            style={{ backgroundColor: lighterGrey }}
                          ></div>
                          <Typography className={classes.legend}>
                            Sem senha
                          </Typography>
                        </div>
                        <div className={classes.legendLine}>
                          <div
                            className={classes.colorLegendSquare}
                            style={{
                              backgroundColor: white,
                              border: `1px solid ${bulletinGrey}`,
                            }}
                          ></div>
                          <Typography className={classes.legend}>
                            Horário de pico
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.circlesContainer}>
                      <CustomTooltip
                        arrow
                        placement="bottom"
                        title={
                          <div>
                            <Typography className={classes.tooltipText}>
                              Caixa
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              Maior tempo de fila: {doughnut.tmp_Fila_Maior_Cx}
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              TME acima da meta: {doughnut.qtde_Acima_Meta_Cx}
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              TME dentro da meta: {doughnut.qtde_Dentro_Meta_Cx}
                            </Typography>
                          </div>
                        }
                      >
                        <div className={classes.circleContainer}>
                          <Typography
                            sx={{
                              fontFamily: "SantanderMicroText",
                              fontSize: "12px !important",
                              color: `${bulletinGrey} !important`,
                              marginLeft: "10px",
                            }}
                          >
                            Caixa
                          </Typography>
                          <ChartContainer
                            customConfigs={customCheckoutDoughnutConfigs}
                          />
                        </div>
                      </CustomTooltip>
                      <CustomTooltip
                        arrow
                        placement="bottom"
                        title={
                          <div>
                            <Typography className={classes.tooltipText}>
                              Gerência
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              Maior tempo de fila: {doughnut.tmp_Fila_Maior_Gr}
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              TME acima da meta: {doughnut.qtde_Acima_Meta_Gr}
                            </Typography>
                            <Typography className={classes.tooltipText}>
                              TME dentro da meta: {doughnut.qtde_Dentro_Meta_Gr}
                            </Typography>
                          </div>
                        }
                      >
                        <div
                          className={classes.circleContainer}
                          style={{ paddingLeft: "18px" }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "SantanderMicroText",
                              fontSize: "12px !important",
                              color: `${bulletinGrey} !important`,
                              marginLeft: "10px",
                            }}
                          >
                            Gerência
                          </Typography>
                          <ChartContainer
                            customConfigs={customManagerDoughnutConfigs}
                          />
                        </div>
                      </CustomTooltip>
                    </div>
                  </>
                )}
              </div>
              <div className={classes.lineChartsContainer}>
                <QueuesHeatmap
                  heatMapData={dataMock}
                  mouthDays={mouthDays}
                  dayHours={dayHours}
                  onChangeDayMethod={setDayOverviewDisplayMethod}
                  onChangeCategoryMethod={setSelectedCategory}
                />
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton customHeight={350} />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="queues"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />
      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={exoSortModel}
            changeSortModel={setExoSortModel}
            type="queues"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos de Filas possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Interno
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado publicado por meio de canais internos e disponíveis
            para todos os funcionários do banco, como comunicados de mudanças
            organizacionais, comunicados departamentais, comunicados exclusivos
            aos funcionários Santander e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddQueuesAction: (payload) => dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { gaInfo } = state.bulletin;
  const { doughnut, table, loading } = state.queues;

  return {
    match: ownProps?.match,
    loading,
    table,
    gaInfo,
    doughnut,
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Queues)
);
