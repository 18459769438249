import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/alerts";
import * as fromActions from "../actions/alerts";
import { reset } from "redux-form";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, getUser, logout } from "../services/auth";
import * as fromAlertsActions from "../actions/alerts";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getLastTenReports() {
  yield put(fromActions.getLastTenReportsSuccess());
}

export function* getAllReports() {
  yield put(fromActions.getLastTenReportsSuccess());
}

export function* getAlerts({ id }) {
  try {
    const response = yield axios.post(`${BASE_API}/Alerta/alertass/`, {
      matricula: id,
      situacao: 1,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getAlertsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getAlertsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getAlertsHistory({ id }) {
  try {
    const response = yield axios.post(`${BASE_API}/Alerta/alertass/`, {
      matricula: id,
      situacao: 2,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getAlertsHistorySuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getAlertsHistoryError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* sendAlert({ payload }) {
  if (
    payload.msg.toUpperCase().includes("<script>") ||
    payload.msg.toUpperCase().includes("</script>") ||
    payload.msg.toUpperCase().includes("<img>") ||
    payload.msg.toUpperCase().includes("</img>")
  ) {
    alertToast("error", "Texto inválido.");
    yield put(fromActions.sendAlertError(""));
  } else {
    try {
      const response = yield axios.post(`${BASE_API}/Alerta/addAlerta/`, {
        dt_Vgc_Inc: payload.date[0],
        dt_Vgc_Fnl: payload.date[1],
        texto: payload.msg,
        titulo: payload.title,
        tp_Info: parseInt(payload.type),
        broadcast: payload.filters.broadcast,
        Cd_Matricula_Alvo: payload.filters.ga,
        uniorg_Alvo: payload.filters.agencia,
        rede_Alvo: payload.filters.rede,
        regional_Alvo: payload.filters.regional,
        cd_Matricula_Autor: getUser(),
      });
      if (response.status === 200) {
        yield put(fromActions.sendAlertSuccess(response.data.data));
        alertToast("success", "Alerta cadastrado com sucesso!");
        yield put(reset("alertForm"));
        yield put(fromActions.getAlerts(getUser()));
        yield put(fromActions.getAlertsFullList(getUser()));
      }
    } catch (err) {
      console.log(err);
      alertToast("error", "Não foi possível cadastrar o alerta.");
      yield put(fromActions.sendAlertError(err));
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* editAlert({ payload }) {
  if (
    payload.msg.toUpperCase().includes("<script>") ||
    payload.msg.toUpperCase().includes("</script>") ||
    payload.msg.toUpperCase().includes("<img>") ||
    payload.msg.toUpperCase().includes("</img>")
  ) {
    alertToast("error", "Texto inválido.");
    yield put(fromActions.sendAlertError(""));
  } else {
    try {
      const response = yield axios.post(`${BASE_API}/Alerta/updateAlerta/`, {
        dt_Vgc_Inc: payload.date[0],
        dt_Vgc_Fnl: payload.date[1],
        texto: payload.msg,
        titulo: payload.title,
        tp_Info: parseInt(payload.type),
        broadcast: payload.filters.broadcast,
        cd_Matricula_Alvo: payload.filters.ga,
        uniorg_Alvo: payload.filters.agencia,
        rede_Alvo: payload.filters.rede,
        regional_Alvo: payload.filters.regional,
        cd_Matricula_Autor: getUser(),
        id: payload.id,
      });
      if (response.status === 200) {
        yield put(fromActions.editAlertSuccess(response.data.data));
        alertToast("success", "Alerta editado com sucesso!");
        yield put(reset("alertForm"));
        yield put(fromActions.getAlerts(getUser()));
        yield put(fromActions.getAlertsFullList(getUser()));
      }
    } catch (err) {
      console.log(err);
      alertToast("error", "Não foi possível editar o alerta.");
      yield put(fromActions.editAlertError(err));
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* deleteAlert({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Alerta/delAlerta/${id}`, {
      headers: headers,
    });
    if (response.status === 200) {
      alertToast("success", "Alerta excluído com sucesso.");
      yield put(fromActions.deleteAlertSuccess(response.data.data));
      yield put(reset("alertForm"));
      yield put(fromActions.getAlerts(getUser()));
      yield put(fromActions.getAlertsFullList(getUser()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível apagar o alerta.");
    yield put(fromActions.deleteAlertError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getAlertsFullList({ id }) {
  const payloadId = parseInt(id);
  try {
    const response = yield axios.get(
      `${BASE_API}/Alerta/alertasc/${payloadId}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getAlertsFullListSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getAlertsFullListError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* setAlertRead({ payload }) {
  try {
    const response = yield axios.post(`${BASE_API}/Alerta/alertaLido`, {
      headers: headers,
      id: payload.id,
      matricula: payload.ga,
    });
    if (response.status === 200) {
      yield put(fromActions.setAlertReadSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.setAlertReadError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchAlerts() {
  yield takeLatest(fromTypes.GET_LAST_TEN_REPORTS, getLastTenReports);
  yield takeLatest(fromTypes.GET_ALL_REPORTS, getAllReports);
  yield takeLatest(fromTypes.GET_ALERTS, getAlerts);
  yield takeLatest(fromTypes.GET_ALERTS_HISTORY, getAlertsHistory);
  yield takeLatest(fromTypes.SEND_ALERT, sendAlert);
  yield takeLatest(fromTypes.EDIT_ALERT, editAlert);
  yield takeLatest(fromTypes.DELETE_ALERT, deleteAlert);
  yield takeLatest(fromTypes.GET_ALERTS_FULL_LIST, getAlertsFullList);
  yield takeLatest(fromTypes.SET_ALERT_READ, setAlertRead);
}
