// @flow

export const GET_AGENCIES = "[AGENCY] GET_AGENCIES";
export const GET_AGENCIES_SUCCESS = "[AGENCY] GET_AGENCIES SUCCESS";
export const GET_AGENCIES_ERROR = "[AGENCY] GET_AGENCIES ERROR";

export const REGISTER_AGENCY = "[AGENCY] REGISTER AGENCY";
export const REGISTER_AGENCY_SUCCESS = "[AGENCY] REGISTER AGENCY SUCCESS";
export const REGISTER_AGENCY_ERROR = "[AGENCY] REGISTER AGENCY ERROR";

export const GET_AGENCY_DETAILS = "[AGENCY ] GET_AGENCY_DETAILS";
export const GET_AGENCY_DETAILS_SUCCESS = "[AGENCY] GET_AGENCY_DETAILS_SUCCESS";
export const GET_AGENCY_DETAILS_ERROR = "[AGENCY ] GET_AGENCY_DETAILS_ERROR";

export const UPDATE_AGENCY = "[AGENCY] UPDATE_AGENCY";
export const UPDATE_AGENCY_SUCCESS = "[AGENCY] UPDATE_AGENCY_SUCCESS";
export const UPDATE_AGENCY_ERROR = "[AGENCY] UPDATE_AGENCY_ERROR";

export const DELETE_AGENCY = "[AGENCY] DELETE_AGENCY";
export const DELETE_AGENCY_SUCCESS = "[AGENCY] DELETE_AGENCY_SUCCESS";
export const DELETE_AGENCY_ERROR = "[AGENCY] DELETE_AGENCY_ERROR";

export const GET_AGENCY_LIST = "[AGENCY ] GET_AGENCY_LIST";
export const GET_AGENCY_LIST_SUCCESS = "[AGENCY ] GET_AGENCY_LIST_SUCCESS";
export const GET_AGENCY_LIST_ERROR = "[AGENCY ] GET_AGENCY_LIST_ERROR";

export const GET_AGENCY_INFO = "[AGENCY] GET_AGENCY_INFO";
export const GET_AGENCY_INFO_SUCCESS = "[AGENCY] GET_AGENCY_INFO_SUCCESS";
export const GET_AGENCY_INFO_ERROR = "[AGENCY]  GET_AGENCY_INFO_ERROR";

export const GET_OPERATIONS = "[AGENCY] GET_OPERATIONS";
export const GET_OPERATIONS_SUCCESS = "[AGENCY] GET_OPERATIONS_SUCCESS";
export const GET_OPERATIONS_ERROR = "[AGENCY]  GET_OPERATIONS_ERROR";

type GetAgencies = {
  type: typeof GET_AGENCIES,
  id: Int,
};
type GetAgenciesSuccess = {
  type: typeof GET_AGENCIES_SUCCESS,
  payload: any,
};
type GetAgenciesError = {
  type: typeof GET_AGENCIES_ERROR,
  payload: any,
};

type RegisterAgency = {
  type: typeof REGISTER_AGENCY,
  payload: Object,
};
type RegisterAgencySuccess = {
  type: typeof GET_AGENCIES_SUCCESS,
  payload: any,
};
type RegisterAgencyError = {
  type: typeof GET_AGENCIES_ERROR,
  payload: any,
};

type GetAgencyDetails = {
  type: typeof GET_AGENCY_DETAILS,
  id: Int,
};
type GetAgencyDetailsSuccess = {
  type: typeof GET_AGENCY_DETAILS_SUCCESS,
  payload: any,
};
type GetAgencyDetailsError = {
  type: typeof GET_AGENCY_DETAILS_ERROR,
  payload: any,
};

type UpdateAgency = {
  type: typeof UPDATE_AGENCY,
  payload: Object,
};
type UpdateAgencySuccess = {
  type: typeof UPDATE_AGENCY_SUCCESS,
  payload: any,
};
type UpdateAgencyError = {
  type: typeof UPDATE_AGENCY_ERROR,
  payload: any,
};

type DeleteAgency = {
  type: typeof DELETE_AGENCY,
  id: Int,
};
type DeleteAgencySuccess = {
  type: typeof DELETE_AGENCY_SUCCESS,
  payload: any,
};
type DeleteAgencyError = {
  type: typeof DELETE_AGENCY_ERROR,
  payload: any,
};

type GetAgencyList = {
  type: typeof GET_AGENCY_LIST,
  id: int,
};
type GetAgencyListSuccess = {
  type: typeof GET_AGENCY_LIST_SUCCESS,
  payload: any,
};
type GetAgencyListError = {
  type: typeof GET_AGENCY_LIST_ERROR,
  payload: any,
};

type GetAgencyInfo = {
  type: typeof GET_Agency_INFO,
  id: int,
};
type GetAgencyInfoSuccess = {
  type: typeof GET_AGENCY_INFO_SUCCESS,
  payload: any,
};
type GetAgencyInfoError = {
  type: typeof GET_AGENCY_INFO_ERROR,
  payload: any,
};

type GetOperations = {
  type: typeof GET_OPERATIONS,
  id: int,
};
type GetOperationsSuccess = {
  type: typeof GET_OPERATIONS_SUCCESS,
  payload: any,
};
type GetOperationsError = {
  type: typeof GET_OPERATIONS_ERROR,
  payload: any,
};

export type AgencyAction =
  | GetAgencies
  | GetAgenciesSuccess
  | GetAgenciesError
  | RegisterAgency
  | RegisterAgencySuccess
  | RegisterAgencyError
  | GetAgencyDetails
  | GetAgencyDetailsSuccess
  | GetAgencyDetailsError
  | UpdateAgency
  | UpdateAgencySuccess
  | UpdateAgencyError
  | DeleteAgency
  | DeleteAgencySuccess
  | DeleteAgencyError
  | GetAgencyList
  | GetAgencyListSuccess
  | GetAgencyListError
  | GetAgencyInfo
  | GetAgencyInfoSuccess
  | GetAgencyInfoError
  | GetOperations
  | GetOperationsSuccess
  | GetOperationsError;
