import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Icons from "./Icons";
import { lightGrey, darkGrey, santander } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${lightGrey}`,
    borderRadius: "4px",
    padding: "0.5em 0.6em",
    boxSizing: "border-box",
    width: "225px",
    fontFamily: "SantanderMicroText",
    display: "flex",
    justifyContent: "space-around",
  },
  late: {
    border: `1px solid ${santander}`,
    borderRadius: "4px",
    padding: "0.5em 0.6em",
    boxSizing: "border-box",
    width: "225px",
    fontFamily: "SantanderMicroText",
    display: "flex",
    justifyContent: "space-around",
  },
  countdownNum: {
    color: darkGrey,
    "& span": {
      fontWeight: "bold",
    },
  },
  lateCountdownNum: {
    color: santander,
    "& span": {
      fontWeight: "bold",
    },
  },
}));

const Countdown = ({ finalHour, showClock }) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [countdown, setCountdown] = useState({
    hours: "  ",
    minutes: "  ",
    seconds: "  ",
  });
  const [isLate, setIslate] = useState(false);
  let countDownDate = new Date();

  var myToday = new Date(
    countDownDate.getFullYear(),
    countDownDate.getMonth(),
    countDownDate.getDate(),
    finalHour[0],
    finalHour[1],
    finalHour[2]
  ).getTime();

  useEffect(() => {
    setInterval(() => {
      let now = new Date().getTime();
      let distance = myToday - now;
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        setIslate(true);
        hours = hours + 1;
        minutes = minutes + 1;
        seconds = seconds + 1;
      }
      setCountdown({ hours, minutes, seconds });
    }, 1000);
  }, []);

  return (
    <div
      onClick={() => setShow(!!!show)}
      style={
        showClock ? {} : { opacity: "0", pointerEvents: "none", width: "1px" }
      }
    >
      {show && !isNaN(countdown.hours) ? (
        <div className={isLate ? classes.late : classes.root}>
          <div>
            <Icons icon="time" customColor={isLate ? santander : false} />
          </div>
          <div
            className={isLate ? classes.lateCountdownNum : classes.countdownNum}
          >
            <span>{countdown.hours}</span> hrs
          </div>
          <div
            className={isLate ? classes.lateCountdownNum : classes.countdownNum}
          >
            <span>{countdown.minutes}</span> min
          </div>
          <div
            className={isLate ? classes.lateCountdownNum : classes.countdownNum}
          >
            <span>{countdown.seconds}</span> seg
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <Icons icon="time" />
          </div>
          <div className={classes.countdownNum}>
            <span>--</span>
          </div>
          <div className={classes.countdownNum}>
            <span>--</span>
          </div>
          <div className={classes.countdownNum}>
            <span>--</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Countdown;
