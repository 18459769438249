// @flow

import * as fromTypes from "../types/alerts";

export const getLastTenReports = (): fromTypes.AlertAction => ({
  type: fromTypes.GET_LAST_TEN_REPORTS,
});
export const getLastTenReportsSuccess = (
  payload: array
): fromTypes.AlertAction => ({
  type: fromTypes.GET_LAST_TEN_REPORTS_SUCCESS,
  payload,
});
export const getLastTenReportsError = (
  payload: any
): fromTypes.AlertAction => ({
  type: fromTypes.GET_LAST_TEN_REPORTS_ERROR,
  payload,
});

export const getAllReports = (): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALL_REPORTS,
});
export const getAllReportsSuccess = (
  payload: array
): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALL_REPORTS_SUCCESS,
  payload,
});
export const getAllReportsError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALL_REPORTS_ERROR,
  payload,
});

export const getAlerts = (id: int): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS,
  id,
});
export const getAlertsSuccess = (payload: array): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_SUCCESS,
  payload,
});
export const getAlertsError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_ERROR,
  payload,
});

export const sendAlert = (payload: object): fromTypes.AlertAction => ({
  type: fromTypes.SEND_ALERT,
  payload,
});
export const sendAlertSuccess = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.SEND_ALERT_SUCCESS,
  payload,
});
export const sendAlertError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.SEND_ALERT_ERROR,
  payload,
});

export const editAlert = (payload: object): fromTypes.AlertAction => ({
  type: fromTypes.EDIT_ALERT,
  payload,
});
export const editAlertSuccess = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.EDIT_ALERT_SUCCESS,
  payload,
});
export const editAlertError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.EDIT_ALERT_ERROR,
  payload,
});

export const deleteAlert = (id: int): fromTypes.AlertAction => ({
  type: fromTypes.DELETE_ALERT,
  id,
});
export const deleteAlertSuccess = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.DELETE_ALERT_SUCCESS,
  payload,
});
export const deleteAlertError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.DELETE_ALERT_ERROR,
  payload,
});

export const realTimeAlerts = (id: int): fromTypes.AlertAction => ({
  type: fromTypes.REAL_TIME_ALERTS,
  id,
});
export const realTimeAlertsSuccess = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.REAL_TIME_ALERTS_SUCCESS,
  payload,
});
export const realTimeAlertsError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.REAL_TIME_ALERTS_ERROR,
  payload,
});

export const getAlertsFullList = (id: int): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_FULL_LIST,
  id,
});
export const getAlertsFullListSuccess = (payload: array): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_FULL_LIST_SUCCESS,
  payload,
});
export const getAlertsFullListError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_FULL_LIST_ERROR,
  payload,
});

export const setAlertRead = (payload: object): fromTypes.AlertAction => ({
  type: fromTypes.SET_ALERT_READ,
  payload,
});
export const setAlertReadSuccess = (payload: array): fromTypes.AlertAction => ({
  type: fromTypes.SET_ALERT_READ_SUCCESS,
  payload,
});
export const setAlertReadError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.SET_ALERT_READ_ERROR,
  payload,
});

export const getAlertsHistory = (id): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_HISTORY,
  id,
});
export const getAlertsHistorySuccess = (payload: array): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_HISTORY_SUCCESS,
  payload,
});
export const getAlertsHistoryError = (payload: any): fromTypes.AlertAction => ({
  type: fromTypes.GET_ALERTS_HISTORY_ERROR,
  payload,
});
