import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/auth";
import * as fromActions from "../actions/auth";
import { setToken, setUser, setUserLevel } from "../services/auth";
import { reset } from "redux-form";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken } from "../services/auth";
import * as fromProfileActions from "../actions/profile";

const BASE_API = getBaseAPI();

export function* signIn({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/account/Authenticate`,
      payload
    );
    if (response.status === 200) {
      yield put(fromActions.signInSuccess(response.data));
      const { UserName } = payload;
      const token = response.data.data.token;
      setToken(token);
      setUser(UserName);
      alertToast("success", "Login feito com sucesso!");
      yield put(reset("signInForm"));
      //yield put(push("/boletim"));
      try {
        const headers = {
          Authorization: `Bearer ${getToken()}`,
        };
        const response = yield axios.get(`${BASE_API}/Usuario/getPerfil`, {
          headers,
        });
        if (response.status === 200) {
          fromProfileActions.getProfileSuccess(response.data.data);
          setUserLevel(response.data.data);

          if (response.data.data === 4) {
            setTimeout(() => {
              window.location.href = "/compensacao";
            }, 1000);
          } else {
            setTimeout(() => {
              window.location.href = "/home";
            }, 1000);
          }
        }
      } catch (err) {
        fromProfileActions.getProfileError(err);
        alertToast(
          "error",
          "Não foi possível recuperar o perfil. Tente novamente mais tarde."
        );
      }
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.signInError(err));
    alertToast(
      "error",
      "Matrícula ou senha inválida. Verifique os dados digitados e tente novamente."
    );
  }
}

export function* sendPassRequest({ payload }) {
  let { UserName } = payload;
  try {
    let response = yield axios.post(`${BASE_API}/Account/ForgotPassword`, {
      UserName,
    });
    if (response.status === 200) {
      yield put(fromActions.SendPassRequestSuccess(response.data));
      setTimeout(() => {
        window.location.href = "/request-sent";
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.SendPassRequestError(err));

    alertToast(
      "error",
      "Matrícula inválida. Verifique os dados digitados e tente novamente"
    );
  }
}

export function* RegisterNewPassword({ payload }) {
  const { Username, Password, ConfirmPassword, Code } = payload;
  try {
    const responseFinal = yield axios.post(
      `${BASE_API}/Account/ResetPassword`,
      {
        Username,
        Password,
        ConfirmPassword,
        Code,
      }
    );
    if (responseFinal.status === 200) {
      yield put(reset("changePassForm"));
      //yield put(push("/boletim"));
      alertToast(
        "success",
        "Senha trocada com sucesso! Redirecionando para o login..."
      );
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  } catch (err) {
    alertToast(
      "error",
      "Não foi possível trocar de senha. Verifique se todos os requisitos foram cumpridos."
    );
    yield put(fromActions.SendPassRequestError(err));
  }
}

export function* watchAuth() {
  yield takeLatest(fromTypes.SIGN_IN, signIn);
  yield takeLatest(fromTypes.SEND_PASS_REQUEST, sendPassRequest);
  yield takeLatest(fromTypes.REGISTER_NEW_PASS, RegisterNewPassword);
}
