import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@mui/x-data-grid";
import {
  santander70,
  lighterGrey,
  darkGrey,
  listSelectedBg,
  white,
  boston,
  black,
  inputSky,
} from "../theme/colors";
import { Loading } from ".";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1200px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

const ATMList = ({
  loading,
  rows,
  cols,
  sortModel,
  changeSortModel,
  getStatusBGMethod,
  type,
  hidePagination = false,
}) => {
  const classes = useStyles();
  const [hoverBG, setHoverBG] = useState("");

  const handleMouseOver = (event) => {
    const id = event.currentTarget.parentElement.dataset.id;
    const row = rows.find((r) => r.id == id);
    if (type === "kyc") setHoverBG(getStatusBGMethod(row.action));
    else if (type === "atm" || type === "store")
      setHoverBG(getStatusBGMethod(row.urgStatus));
    else if (type === "exo")
      setHoverBG(getStatusBGMethod(row.legado, row.action));
    else if (type === "queues")
      setHoverBG(
        getStatusBGMethod("urgStatus", row.aboveTarget, row.closeTarget)
      );
    else if (type === "reft") setHoverBG(getStatusBGMethod(row.status));
    else if (type === "nps") setHoverBG(getStatusBGMethod(row.nota));
    else if (type === "nq") setHoverBG(getStatusBGMethod(row.prazo));
    else if (type === "cheque") setHoverBG("");
    else if (type === "manifestacao") setHoverBG(getStatusBGMethod(row.prazo));
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {cols ? (
            <DataGrid
              sx={{
                margin: "0 auto",
                maxWidth: "1280px",
                fontFamily: "SantanderMicroText",
                border: "none",
                textAlign: "center",
                "& .MuiCheckbox-root svg path": {
                  color: boston,
                },
                "& .header": {
                  backgroundColor: lighterGrey,
                  color: darkGrey,
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "SantanderMicroText",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  padding: "0px",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                  textAlign: "center",
                  width: "100%",
                },
                "& .MuiDataGrid-selectedRowCount": {
                  opacity: "0",
                },
                "& .MuiTablePagination-displayedRows": {
                  display: type == "cheque" ? "" : "none",
                },

                "& .MuiDataGrid-row ": {
                  background: `${white} !important`,
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  background: `${listSelectedBg} !important`,
                },
                "& .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
                "& .MuiTablePagination-selectLabel": {
                  visibility: "hidden",
                },
                "& .MuiTablePagination-select": { visibility: "hidden" },
                "& .MuiTablePagination-selectIcon": { visibility: "hidden" },
                "& .MuiDataGrid-row": {
                  "&:hover": {
                    background: `${hoverBG} !important`,
                  },
                },
                "& .MuiDataGrid-cell--textLeft": {
                  justifyContent: "center",
                },
                "& .MuiDataGrid-cell span": {
                  textAlign: "center",
                  width: "100%",
                },
                "& .MuiBadge-anchorOriginTopRightRectangular": {
                  visibility: "hidden !important",
                  opacity: "0",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
              rows={rows}
              columns={cols}
              sortModel={sortModel}
              onSortModelChange={(model) => changeSortModel(model)}
              pageSize={20}
              autoHeight
              disableColumnMenu
              componentsProps={{
                cell: {
                  onMouseEnter: handleMouseOver,
                },
              }}
              hideFooter={hidePagination}
              localeText={{
                MuiTablePagination: {
                  labelDisplayedRows: ({ from, to, count }) =>
                    `Você está vendo os cheques de ${from} a ${to} (total: ${count})`,
                },
              }}
            />
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  const { loading } = state.atm;
  return { loading };
};

export default compose(connect(mapStateToProps))(ATMList);
