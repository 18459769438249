import React, { useRef, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Icons from "./Icons";
import { getAlertsHistory } from "../store/actions/alerts";
import { getUser } from "../store/services/auth";
import { darkGrey, santander, black } from "../theme/colors";
import { Typography, Card, CardContent } from "@mui/material";
import { hasNewAlerts, setHasNewAlerts } from "../store/services/alerts";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "999999",
    position: "absolute",
    left: "65%",
    top: "0.5%",
    width: "400px",
    height: "424px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  bell: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginLeft: "8px",
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
    color: `${darkGrey} !important`,
  },
  alerts: {
    height: "360px",
    overflowY: "scroll",
  },
  alertTitle: {
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "12px !important",
  },
  alertText: {
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "16px !important",
    cursor: "pointer !important",
    maxWidth: "380px",
  },
  hr: {
    marginTop: "12px",
    marginBottom: "12px",
    border: `0.5px solid ${darkGrey}`,
    opacity: "0.2",
    width: "60%",
  },
  closeBtn: {
    cursor: "pointer",
  },
}));

const Notifications = ({
  onGetAlertsHistory,
  alertList,
  openNotifications,
  onClose,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);

  useEffect(() => {
    onGetAlertsHistory(getUser());
  }, [openNotifications]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const closeModal = () => {
    setHasNewAlerts("false");
    onClose();
  };

  const hasNewNotifications = () => {
    if (hasNewAlerts() === "true") return santander;
    else return black;
  };

  return (
    <>
      {openNotifications ? (
        <div ref={wrapperRef} className={classes.root} onBlur={closeModal}>
          <div className={classes.notificationCard}>
            <Card sx={{ minWidth: 400 }}>
              <CardContent>
                <div className={classes.titleContainer}>
                  <div className={classes.bell}>
                    <Icons icon="bell" customColor={hasNewNotifications()} />
                    <Typography className={classes.title}>
                      Notificações
                    </Typography>
                  </div>
                  <div className={classes.closeBtn} onClick={closeModal}>
                    <Icons icon="close-1px" />
                  </div>
                </div>
                <div className={classes.alerts}>
                  {alertList.map((i, index) => (
                    <div key={index}>
                      <Typography
                        onClick={() => (window.location.href = "/home")}
                        className={classes.alertTitle}
                      >
                        {i.titulo} - {i.dt_Alerta}
                      </Typography>
                      <Typography
                        onClick={() => (window.location.href = "/home")}
                        className={classes.alertText}
                        dangerouslySetInnerHTML={{ __html: i.texto }}
                      ></Typography>
                      <div className={classes.hr}></div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  const { alertList } = state.alerts;
  return { alertList };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAlertsHistory: (id) => dispatch(getAlertsHistory(id)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Notifications
);
