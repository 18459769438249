// @flow

export const GET_CHECKLIST = "[CHECKLIST] GET CHECKLIST";
export const GET_CHECKLIST_SUCCESS = "[CHECKLIST] GET CHECKLIST SUCCESS";
export const GET_CHECKLIST_ERROR = "[CHECKLIST] GET CHECKLIST ERROR";

export const CHANGE_CHECKLIST_ITEM = "[CHECKLIST] CHANGE CHECKLIST ITEM";
export const CHANGE_CHECKLIST_ITEM_SUCCESS =
  "[CHECKLIST] CHANGE CHECKLIST ITEM SUCCESS";
export const CHANGE_CHECKLIST_ITEM_ERROR =
  "[CHECKLIST] CHANGE CHECKLIST ITEM ERROR";

export const GET_TASK_COMMENTS = "[CHECKLIST] GET TASKS COMMENTS";
export const GET_TASK_COMMENTS_SUCCESS =
  "[CHECKLIST] GET TASKS COMMENTS SUCCESS";
export const GET_TASK_COMMENTS_ERROR = "[CHECKLIST] GET TASKS COMMENTS ERROR";

type GetChecklist = {
  type: typeof GET_CHECKLIST,
  payload: Object,
};
type GetChecklistSuccess = {
  type: typeof GET_CHECKLIST_SUCCESS,
  payload: any,
};
type GetChecklistError = {
  type: typeof GET_CHECKLIST_ERROR,
  payload: any,
};

type ChangeChecklistItem = {
  type: typeof CHANGE_CHECKLIST_ITEM,
  payload: Object,
};
type ChangeChecklistItemSuccess = {
  type: typeof CHANGE_CHECKLIST_ITEM_SUCCESS,
  payload: any,
};
type ChangeChecklistItemError = {
  type: typeof CHANGE_CHECKLIST_ITEM_ERROR,
  payload: any,
};

type GetTaskComments = {
  type: typeof GET_TASK_COMMENTS,
  id: int,
};
type GetTaskCommentsSuccess = {
  type: typeof GET_TASK_COMMENTS_SUCCESS,
  payload: any,
};
type GetTaskCommentsError = {
  type: typeof GET_TASK_COMMENTS_ERROR,
  payload: any,
};

export type ChecklistAction =
  | GetChecklist
  | GetChecklistSuccess
  | GetChecklistError
  | ChangeChecklistItem
  | ChangeChecklistItemSuccess
  | ChangeChecklistItemError
  | GetTaskComments
  | GetTaskCommentsSuccess
  | GetTaskCommentsError;
