import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Modal,
  Box,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { utils, writeFileXLSX } from "xlsx";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  white,
  santander,
  boston,
  darkGrey,
  santander20,
  lightGrey,
} from "../theme/colors";
import { getUser } from "../store/services/auth";

import { getUserInfo } from "../store/actions/profile";
import { sendLog } from "../store/actions/analytics";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {},

  btnContainer: {
    marginTop: "75px",
    display: "flex",
    justifyContent: "space-between",
  },

  returnBtn: {
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    textTransform: "none !important",
    padding: "0.25em 1em !important",
    fontSize: "1em !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  button: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  disabledButton: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${lightGrey} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
}));

const WarningExportModal = ({
  open,
  onClose,
  loading,
  data,
  userInfo,
  type,
  onGetUserInfo,
  modalType,
  onSendLog,
}) => {
  const classes = useStyles();

  const [columnsNames, setColumnsNames] = useState([]);
  const [checked, setChecked] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);

  useEffect(() => {
    setDataToExport(data);
  }, [data]);

  useEffect(() => {
    onGetUserInfo(getUser());
  }, []);

  useEffect(() => {
    switch (type) {
      case "EXO":
        dataToExport.map((obj) => {
          delete obj["nu_Doc_Cripto"];
          delete obj["nu_contrato_ag_conta_cripto"];
          delete obj["nu_proposta_cripto"];
          delete obj["cd_indicador"];
          delete obj["id_Exo"];
          obj.nu_contrato_ag_conta_mascara = obj.nu_contrato_ag_conta_mascara
            ? obj.nu_contrato_ag_conta_mascara
            : " ";
          obj.nu_proposta_mascara = obj.nu_proposta_mascara
            ? obj.nu_proposta_mascara
            : " ";
        });
        break;
      case "NPS":
        dataToExport.map((obj) => {
          delete obj["nu_doc_cripto"];
          delete obj["nm_cliente_cripto"];
          delete obj["resp_cripto"];
          delete obj["cd_indicador"];
          obj.resp_mascara = obj.resp_mascara ? obj.resp_mascara : " ";
        });
        break;
      case "NQ":
        dataToExport.map((obj) => {
          delete obj["nu_doc_cripto"];
          delete obj["nm_cliente_cripto"];
          delete obj["nu_matricula_carteira_cripto"];
          delete obj["cd_indicador"];
          obj.nm_segmento = obj.nm_segmento ? obj.nm_segmento : " ";
        });
        break;
      case "Manifestacao":
        dataToExport.map((obj) => {
          delete obj["nu_doc_cripto"];
          delete obj["nm_cliente_cripto"];
          delete obj["cd_indicador"];
          delete obj["nu_ordem"];
          delete obj["cd_indicador"];
          obj.nm_cliente_mascara = obj.nm_cliente_mascara
            ? obj.nm_cliente_mascara
            : " ";
          obj.nu_doc_mascara = obj.nu_doc_mascara ? obj.nu_doc_mascara : " ";
        });
        break;
      case "Cheque":
        dataToExport.map((obj) => {
          delete obj["cd_indicador"];
          delete obj["id_cheque"];
          delete obj["nm_gerente_cripto"];
          delete obj["st_origem"];
          delete obj["nu_ag_cripto"];
          delete obj["nu_conta_cripto"];
          delete obj["nm_cliente_cripto"];
          delete obj["nu_doc_cripto"];
          delete obj["bl_comentario"];
          delete obj["st_analise"];
        });
        break;
      case "KYC":
      case "ATM":
      case "LOJA":
      case "FILAS":
      case "REFT":
        dataToExport.map((obj) => {
          delete obj["cd_indicador"];
        });
        break;
    }
  }, [dataToExport]);

  useEffect(() => {
    switch (type) {
      case "KYC":
        setColumnsNames([
          "Pernumper",
          "Pessoa",
          "Uniorg",
          "Status",
          "Prazo",
          "Atuação (Binário)",
          "Última atualização",
        ]);
        break;
      case "EXO":
        setColumnsNames([
          "Uniorg",
          "CPF/CNPJ",
          "Matricula/Sigla",
          "Contrato/AG_Conta",
          "Produto Final",
          "Data",
          "Pernumper",
          "Status",
          "Tipo Cliente",
          "Última atualização",
          "Comentario (Binário)",
          "Atuação (Binário)",
          "Legado (Binário)",
        ]);
        break;
      case "CARTERIZACAO":
        setColumnsNames([
          "Uniorg",
          "Agência",
          "Regional",
          "Rede",
          "Matricula GA",
          "Nome GA",
          "Matricula GA (Interino)",
          "Nome GA (Interino)",
          "Data inicio (Interino)",
          "Data final (Interino)",
        ]);
        break;
      case "ATM":
        setColumnsNames([
          "Uniorg",
          "Agência",
          "Status (Atual)",
          "Equipamento",
          "Acumulo Dia (%)",
          "Atuação (Binário)",
          "Última atualização",
        ]);
        break;
      case "LOJA":
        setColumnsNames([
          "Uniorg",
          "Agência",
          "Status",
          "Data (Ocorrência)",
          "Problema",
          "Observações",
          "Responsável",
          "Atuação",
          "Última atualização",
        ]);
        break;
      case "NPS":
        setColumnsNames([
          "Uniorg",
          "Pernumper",
          "CPF/CNPJ",
          "Nome do Cliente",
          "Segmento",
          "Nota",
          "Data da avaliação",
          "Comentário do cliente",
          "Prazo (dias)",
          "Atuação (Binário)",
          "Comentario GA (Binário)",
          "Última atualização",
        ]);
        break;
      case "NQ":
        setColumnsNames([
          "Uniorg",
          "Identificador",
          "Documento",
          "Nome do cliente",
          "Dias em aberto",
          "Segmento",
          "Status",
          "Matricula",
          "Comentario GA (Binário)",
          "Atuação (Binário)",
          "Última atualização",
        ]);
        break;
      case "B2G":
        setColumnsNames([
          "Equipamento",
          "Uniorg",
          "Clientes Abordados",
          "Quantidade de vendas",
          "Conversão de vendas",
          "Meta",
          "% Atingido",
          "Abertura conta corrente",
          "Status",
          "Atuação",
        ]);
        break;
      case "FILAS":
        setColumnsNames([
          "Uniorg",
          "Canal",
          "Maior Tempo na Fila",
          "Acima da Meta",
          "Próximo da Meta",
          "Dentro da Meta",
          "Total de Fila",
          "Média de Fila",
          "Meta",
          "Data de atualização",
          "Atuação",
        ]);
        break;
      case "REFT":
        setColumnsNames([
          "Uniorg",
          "ID do Case (REFT)",
          "Data da Venda",
          "Data da Pendência",
          "Dias em aberto",
          "Estado",
          "Produto",
          "Comentario",
          "Atuação",
          "Data de Atualização",
        ]);
        break;
      case "Manifestacao":
        setColumnsNames([
          "Uniorg",
          "Número da Manifestação",
          "Tipo",
          "Origem",
          "Familia",
          "Sintoma Longo",
          "Prazo Tratamento",
          "Comentario",
          "Atuação",
          "Data de Atualização",
          "Nome cliente",
          "CPF",
        ]);
        break;
      // case "Cheque":
      //   setColumnsNames([
      //     "Uniorg",
      //     "Uniorg Contabil",
      //     "Carteira do gerente",
      //     "Gerente",
      //     "Nu.Cheque",
      //     "Valor",
      //     "Status imagem",
      //     "Motivo",
      //     "Descrição do motivo",
      //     "Agência",
      //     "Conta",
      //     "Cliente",
      //     "CPF/CNPJ",
      //     "Atuação",
      //     "Data de processamento",
      //     "MTR GA Responsavel",
      //     "Nome GA Responsavel",
      //   ]);
      //   break;
    }
  }, []);

  const getMessageContentToFile = () => {
    switch (modalType) {
      case "confidencial":
        return [
          {
            coluna: "Confidencial",
          },
          {
            coluna: "",
          },
          {
            coluna:
              "Esse arquivo contém dados classificados como sensíveis de acordo com os ",
          },
          {
            coluna:
              "regulamentos externos de proteção de dados e que, se comprometidos, ",
          },
          {
            coluna: "podem causar danos ou problemas significativos ao Banco. ",
          },
          {
            coluna:
              "Exemplos: dados pessoais de funcionários ou Clientes, dados de RH,",
          },
          {
            coluna:
              "dados não financeiros de clientes, atendimento ao cliente e interações.",
          },
          {
            coluna: "",
          },
          {
            coluna: `Nome: ${userInfo?.info?.nm_Func}`,
          },
          {
            coluna: `Matricula: T${getUser()}`,
          },
          {
            coluna: `Data: ${getCurrentDate()}`,
          },
          {
            coluna: `Hora: ${getCurrentTime()}`,
          },
        ];
        break;

      case "interno":
        return [
          {
            coluna: "Interno",
          },
          {
            coluna: "",
          },
          {
            coluna:
              "Informações publicadas em nossos canais internos e disponíveis ",
          },
          {
            coluna:
              "para todos os funcionários do Banco. São informações que, se ",
          },
          {
            coluna: "comprometidas, não causariam uma ruptura ou danos ",
          },
          {
            coluna:
              "significativos à reputação do Grupo, seus Clientes ou funcionários.",
          },
          {
            coluna:
              "Exemplo: comunicads internos, publicações no NOW, compromissos",
          },
          {
            coluna: "ou alterações organizacionais.",
          },
          {
            coluna: "",
          },
          {
            coluna: `Nome: ${userInfo?.info?.nm_Func}`,
          },
          {
            coluna: `Matricula: T${getUser()}`,
          },
          {
            coluna: `Data: ${getCurrentDate()}`,
          },
          {
            coluna: `Hora: ${getCurrentTime()}`,
          },
        ];
        break;
    }
  };

  const getCurrentDateToFile = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "_" + mm + "_" + yyyy;
    return today;
  };

  const getCurrentTime = () => {
    let today = new Date();
    today =
      today.getHours() + ":" + String(today.getMinutes()).padStart(2, "0");

    return today;
  };

  const getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  const getFileName = () => {
    switch (type) {
      case "KYC":
        return `FORMULARIOS_KYC_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "EXO":
        return `EXO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "CARTERIZACAO":
        return `CARTERIZACAO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "ATM":
        return `DISPONIBILIDADE_ATM_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "LOJA":
        return `DISPONIBILIDADE_LOJA_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "NPS":
        return `NPS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "NQ":
        return `NQ_ALERTAS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "B2G":
        return `BANK_TO_GO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "FILAS":
        return `GESTAO_DE_FILAS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "REFT":
        return `REFT_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "Manifestacao":
        return `MANIFESTACAO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
      case "Cheque":
        return `COMP_CHEQUE_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    }
  };

  const exportXLSX = useCallback(async () => {
    const json = dataToExport;
    const worksheet = utils.json_to_sheet(json);
    const workbook = utils.book_new();

    const warningSheet = getMessageContentToFile();
    const worksheet2 = utils.json_to_sheet(warningSheet);

    onSendLog({
      info: `"transação":"export de arquivo";"indicador":"${type}";"confidencialidade":"${modalType}"`,
    });

    utils.book_append_sheet(
      workbook,
      worksheet,
      `${type} (${modalType.charAt(0).toUpperCase() + modalType.slice(1)})`
    );
    utils.book_append_sheet(workbook, worksheet2, "ALERTA");

    utils.sheet_add_aoa(worksheet, [[...columnsNames]], {
      origin: "A1",
    });

    utils.sheet_add_aoa(worksheet2, [["Alerta de Sensibilidade de Arquivo"]], {
      origin: "A1",
    });

    const maxWidth = warningSheet.reduce(
      (w, r) => Math.max(w, r.coluna.length),
      10
    );
    worksheet2["!cols"] = [{ wch: maxWidth }];

    writeFileXLSX(workbook, getFileName());
    setChecked(false);
    onClose();
  });

  const getModalContent = () => {
    switch (modalType) {
      case "interno":
        return (
          <div className={classes.root}>
            <Typography
              sx={{
                fontFamily: "SantanderHeadline !important",
                fontWeight: "bold !important",
                fontSize: "24px !important",
                color: `${darkGrey} !important`,
              }}
            >
              Atenção!
            </Typography>
            <div className={classes.warningTextContainer}>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  marginTop: "12px",
                }}
              >
                Você está prestes a baixar um arquivo classificado como:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontWeight: "bold !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  marginTop: "8px",
                  marginLeft: "20px",
                }}
              >
                Interno
              </Typography>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  width: "514px",
                  marginLeft: "20px",
                }}
              >
                Informações publicadas em nossos canais internos e disponíveis
                para todos os funcionários do Banco. São informações que, se
                comprometidas, não causariam uma ruptura ou danos significativos
                à reputação do Grupo, seus Clientes ou funcionários. Exemplo:
                comunicads internos, publicações no NOW, compromissos ou
                alterações organizacionais.
              </Typography>
            </div>
            <div className={classes.btnContainer}>
              <Button
                variant="contained"
                onClick={() => {
                  setChecked(false);
                  onClose();
                }}
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  marginRight: "16px",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className={classes.button}
                disabled={loading}
                onClick={exportXLSX}
              >
                Download
              </Button>
            </div>
          </div>
        );
      case "confidencial":
        return (
          <div className={classes.root}>
            <Typography
              sx={{
                fontFamily: "SantanderHeadline !important",
                fontWeight: "bold !important",
                fontSize: "24px !important",
                color: `${darkGrey} !important`,
              }}
            >
              Atenção!
            </Typography>
            <div className={classes.warningTextContainer}>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  marginTop: "12px",
                }}
              >
                Você está prestes a baixar um arquivo classificado como:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontWeight: "bold !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  marginTop: "8px",
                  marginLeft: "20px",
                }}
              >
                Confidencial
              </Typography>
              <Typography
                sx={{
                  fontFamily: "SantanderMicroText !important",
                  fontSize: "16px !important",
                  color: `${darkGrey} !important`,
                  width: "514px",
                  marginLeft: "20px",
                }}
              >
                Dados classificados como sensíveis de acordo com os regulamentos
                externos de proteção de dados e que, se comprometidos, podem
                causar danos ou problemas significativos ao Banco. Exemplos:
                dados pessoais de funcionários ou Clientes, dados de RH, dados
                não financeiros de clientes, atendimento ao cliente e
                interações.
              </Typography>
            </div>
            <div className={classes.btnContainer}>
              <Button
                variant="contained"
                onClick={() => {
                  setChecked(false);
                  onClose();
                }}
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  marginRight: "16px",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 30,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checked}
                        onChange={() => {
                          setChecked(!!!checked);
                        }}
                      />
                    }
                    sx={{
                      "& .MuiTypography-root": {
                        fontFamily: "SantanderMicroText !important",
                        fontSize: "16px !important",
                        color: `${darkGrey} !important`,
                      },
                    }}
                    label="Estou ciente"
                  />
                </FormGroup>
                <Button
                  className={checked ? classes.button : classes.disabledButton}
                  disabled={!checked}
                  onClick={exportXLSX}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setChecked(false);
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "615px",
          height: "372px",
          bgcolor: white,
          p: 4,
          padding: "32px 40px 40px 40px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              padding: "0px !important",
              background: "none !important",
              minWidth: "16px !important",
            }}
            onClick={() => {
              setChecked(false);
              onClose();
            }}
          >
            <Icons icon="close-1px" />
          </Button>
        </div>
        {getModalContent()}
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onSendLog: (payload) => dispatch(sendLog(payload)),
  };
};

const mapStateToProps = (state) => {
  const { userInfo } = state.profile;
  return { userInfo };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WarningExportModal
);
