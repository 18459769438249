// @flow

export const GET_USER_TERMS = "[TERMS] GET_USER_TERMS";
export const GET_USER_TERMS_SUCCESS = "[TERMS] GET_USER_TERMS_SUCCESS";
export const GET_USER_TERMS_ERROR = "[TERMS] GET_USER_TERMS_ERROR";

export const AGREE_WITH_TERM = "[TERMS] AGREE_WITH_TERM";
export const AGREE_WITH_TERM_SUCCESS = "[TERMS] AGREE_WITH_TERM_SUCCESS";
export const AGREE_WITH_TERM_ERROR = "[TERMS] AGREE_WITH_TERM_ERROR";

type GetUserTerms = {
  type: typeof GET_USER_TERMS,
  id: Int,
};
type GetUserTermsSuccess = {
  type: typeof GET_USER_TERMS_SUCCESS,
  payload: Array,
};
type GetUserTermsError = {
  type: typeof GET_USER_TERMS_ERROR,
  payload: any,
};

type AgreeWithTerm = {
  type: typeof AGREE_WITH_TERM,
  payload: Object,
};
type AgreeWithTermSuccess = {
  type: typeof AGREE_WITH_TERM_SUCCESS,
  payload: any,
};
type AgreeWithTermError = {
  type: typeof AGREE_WITH_TERM_ERROR,
  payload: any,
};

export type TermsAction =
  | GetUserTerms
  | GetUserTermsSuccess
  | GetUserTermsError
  | AgreeWithTerm
  | AgreeWithTermSuccess
  | AgreeWithTermError;
