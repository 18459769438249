import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { TextField, SelectField, DeleteUserModal, ChangePassModal } from "./";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import {
  required,
  isEmail,
  onlyLetters,
  onlyNumbers,
  minEight,
  minTwo,
  onlyLettersNumbersAndSpecCharacters,
} from "../utils/validators";
import { santander, white, santander20, boston } from "../theme/colors";
import {
  getUserDetails,
  updateUser,
  getGenders,
  getTypes,
  registerUser,
  getRoles,
  getSupervisors,
} from "../store/actions/profile";
import { getUserLvl, getUser } from "../store/services/auth";
import { alertToast } from "./Toast";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    padding: "32px 0px 0px 0px",
  },
  idField: {
    marginBottom: "24px",
  },
  row: {
    display: "flex",
    width: "100%",
    marginBottom: "24px",
    justifyContent: "space-between",
  },
  select: {
    width: "153px",
  },
  selectType: {
    width: "190px",
  },
  halfTextInput: {
    width: "459px",
  },
  halfTextInputCargo: {
    width: "425px",
  },
  hr: {
    marginBottom: "24px",
  },
  btnHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  modalBtns: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const RegisterForm = ({
  onGetRoles,
  onGetSupervisors,
  onGetUserDetails,
  onUpdateUser,
  onGetGenders,
  onGetTypes,
  change,
  userFormDetails,
  onRegisterUser,
  formOptions,
  handleSubmit,
  edit,
  id,
}) => {
  const classes = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPassModal, setOpenPassModal] = useState(false);
  const previousPath = document.referrer;

  const onSubmit = ({
    Nm_Func,
    Cd_Tratamento,
    Nm_Exibicao,
    NomeExibicao,
    Cd_Matricula,
    Email,
    Ddd,
    Telefone,
    Cd_Perfil,
    Cd_Cargo,
    Cd_Gestor,
  }) => {
    let payload = {
      Nm_Func,
      Cd_Tratamento,
      Nm_Exibicao,
      NomeExibicao,
      Cd_Matricula,
      Email,
      Ddd,
      Telefone,
      Cd_Perfil,
      Cd_Cargo,
      Cd_Gestor,
    };

    validateEmptyPhoneFields(Ddd, Telefone)
      ? alertToast("error", "Por favor, preencha o DDD e o Telefone")
      : edit
      ? onUpdateUser(payload)
      : onRegisterUser(payload);
  };

  useEffect(() => {
    onGetGenders();
    onGetTypes();
    onGetRoles();
    onGetSupervisors();
  }, [
    onGetGenders,
    onGetTypes,
    onGetRoles,
    onGetSupervisors,
    onGetUserDetails,
  ]);

  const onClose = () => {
    setOpenDeleteModal(false);
    setOpenPassModal(false);
  };

  useEffect(() => {
    if (edit) onGetUserDetails(id);
  }, [onGetUserDetails, id]);

  useEffect(() => {
    change("Nm_Func", userFormDetails.nm_Func);
    change("Cd_Tratamento", userFormDetails.cd_Tratamento);
    change("Nm_Exibicao", userFormDetails.nm_Exibicao);
    change("Cd_Matricula", userFormDetails.cd_Matricula);
    change("Email", userFormDetails.email);
    change("Ddd", userFormDetails.ddd);
    change("Telefone", userFormDetails.telefone);
    change("Cd_Perfil", userFormDetails.cd_Perfil);
    change("Cd_Cargo", userFormDetails.cd_Cargo);
    change("Cd_Gestor", userFormDetails.cd_Gestor);
  }, [userFormDetails]);

  const validateEmptyPhoneFields = (ddd, telefone) => {
    if ((!ddd && !telefone) || (ddd && telefone)) {
      return false;
    } else if (!ddd || !telefone) {
      return true;
    }
  };

  const handleChangeMethod = (fieldName, value) => {
    change(fieldName, value);
  };

  return (
    <div className={classes.root}>
      <DeleteUserModal
        name={userFormDetails?.nm_Func}
        id={id}
        open={openDeleteModal}
        onClose={onClose}
      />
      <ChangePassModal id={id} open={openPassModal} onClose={onClose} />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.idField}>
          <Field
            type="text"
            name="Nm_Func"
            icon=""
            placeholder="Nome Completo*"
            component={TextField}
            validate={[required, onlyLettersNumbersAndSpecCharacters]}
            disabled={edit}
            maxLength="100"
            defaultValue={userFormDetails.nm_Func}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.select}>
            <Field
              name="Cd_Tratamento"
              icon=""
              placeholder="Tratamento*"
              component={SelectField}
              dataList={formOptions?.genders}
              validate={[required]}
              disabled={edit}
              defaultValue={userFormDetails.cd_Tratamento}
              {...{
                changeMethod: handleChangeMethod,
                fieldName: "Cd_Tratamento",
              }}
            />
          </div>
          <div className={classes.halfTextInput}>
            <Field
              type="text"
              name="Nm_Exibicao"
              icon=""
              placeholder="Nome de Exibição"
              component={TextField}
              validate={[onlyLettersNumbersAndSpecCharacters]}
              maxLength="100"
              defaultValue={userFormDetails.nm_Exibicao}
            />
          </div>
        </div>
        <div className={classes.idField}>
          <Field
            type="text"
            name="Cd_Matricula"
            icon=""
            placeholder="Matrícula*"
            component={TextField}
            validate={[required, onlyNumbers]}
            disabled={edit}
            maxLength="10"
            onlyNumbers
            defaultValue={userFormDetails.cd_Matricula}
          />
        </div>
        <hr className={classes.hr} />
        <div className={classes.idField}>
          <Field
            type="text"
            name="Email"
            maxLength="100"
            icon=""
            placeholder="E-mail*"
            component={TextField}
            validate={[required, isEmail]}
            disabled={edit}
            defaultValue={userFormDetails.email}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.select}>
            <Field
              type="text"
              name="Ddd"
              icon=""
              maxLength="2"
              placeholder="DDD"
              component={TextField}
              validate={[onlyNumbers, minTwo]}
              onlyNumbers
              defaultValue={userFormDetails.ddd}
            />
          </div>
          <div className={classes.halfTextInput}>
            <Field
              type="text"
              name="Telefone"
              icon=""
              placeholder="Telefone"
              component={TextField}
              validate={[onlyNumbers, minEight]}
              onlyNumbers
              maxLength="9"
              defaultValue={userFormDetails.telefone}
            />
          </div>
        </div>
        <hr className={classes.hr} />
        <div className={classes.row}>
          <div className={classes.selectType}>
            <Field
              name="Cd_Perfil"
              icon=""
              placeholder="Tipo de Usuário*"
              component={SelectField}
              dataList={formOptions?.types}
              validate={[required]}
              maxLength="10"
              onlyNumbers
              disabled={getUserLvl() === "1" || getUserLvl() === "4"}
              defaultValue={userFormDetails.cd_Perfil}
              {...{
                changeMethod: handleChangeMethod,
                fieldName: "Cd_Perfil",
              }}
            />
          </div>
          <div className={classes.halfTextInputCargo}>
            <Field
              type="text"
              name="Cd_Cargo"
              icon=""
              placeholder="Cargo*"
              component={SelectField}
              validate={[required]}
              dataList={formOptions?.roles}
              disabled={getUserLvl() === "1" || getUserLvl() === "4"}
              defaultValue={userFormDetails.cd_Cargo}
              {...{
                changeMethod: handleChangeMethod,
                fieldName: "Cd_Cargo",
              }}
            />
          </div>
        </div>
        <div className={classes.idField}>
          <Field
            type="text"
            name="Cd_Gestor"
            icon=""
            placeholder="Gestor*"
            component={SelectField}
            dataList={formOptions?.supervisors}
            validate={[required]}
            disabled={getUserLvl() === "1" || getUserLvl() === "4"}
            defaultValue={userFormDetails.cd_Gestor}
            {...{
              changeMethod: handleChangeMethod,
              fieldName: "Cd_Gestor",
            }}
          />
        </div>
        <div className={classes.btnHolder}>
          <div className={classes.modalBtns}>
            <Button
              variant="contained"
              onClick={
                previousPath.includes("cadastro")
                  ? () => window.history.go(-2)
                  : () => window.history.back()
              }
              sx={{
                height: "48px",
                fontFamily: "SantanderMicroText ",
                background: white,
                textTransform: "none ",
                color: `${santander} `,
                border: `1px solid ${santander} `,
                display: "block",
                fontSize: "16px ",
                marginRight: "16px",
                "&:hover": {
                  background: santander20,
                  color: `${santander} `,
                },
              }}
            >
              Voltar
            </Button>
            {edit ? (
              <>
                {getUser() === id ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenPassModal(true)}
                    sx={{
                      height: "48px",
                      fontFamily: "SantanderMicroText ",
                      background: white,
                      textTransform: "none ",
                      color: `${santander} `,
                      border: `1px solid ${santander} `,
                      display: "block",
                      fontSize: "16px ",
                      marginRight: "16px",
                      "&:hover": {
                        background: santander20,
                        color: `${santander} `,
                      },
                    }}
                  >
                    Alterar senha
                  </Button>
                ) : null}
                {(getUserLvl() === "2" ||
                  getUserLvl() === "3" ||
                  getUserLvl() === "5") &&
                getUser() !== id ? (
                  <Button
                    variant="contained"
                    onClick={() => setOpenDeleteModal(true)}
                    sx={{
                      height: "48px",
                      fontFamily: "SantanderMicroText ",
                      background: white,
                      textTransform: "none ",
                      color: `${santander} `,
                      border: `1px solid ${santander} `,
                      display: "block",
                      fontSize: "16px ",
                      "&:hover": {
                        background: santander20,
                        color: `${santander} `,
                      },
                    }}
                  >
                    Exclusão do Usuário
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "48px",
              background: boston,
              color: white,
              fontFamily: "SantanderMicroText",
              textTransform: "none",
              textDecoration: "none",
              "&:hover": {
                background: boston,
                color: white,
              },
            }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserDetails: (id) => dispatch(getUserDetails(id)),
    onUpdateUser: (payload) => dispatch(updateUser(payload)),
    onGetGenders: () => dispatch(getGenders()),
    onGetTypes: () => dispatch(getTypes()),
    onRegisterUser: (payload) => dispatch(registerUser(payload)),
    onGetRoles: () => dispatch(getRoles()),
    onGetSupervisors: () => dispatch(getSupervisors()),
    change,
  };
};

const selector = formValueSelector("registerForm");

const mapStateToProps = (state) => {
  const { loading, userFormDetails, formOptions } = state.profile;
  const {
    Nm_Func,
    Cd_Tratamento,
    Nm_Exibicao,
    NomeExibicao,
    Cd_Matricula,
    Email,
    Ddd,
    Telefone,
    Cd_Perfil,
    Cd_Cargo,
    Cd_Gestor,
  } = selector(
    state,
    "Nm_Func",
    "Cd_Tratamento",
    "Nm_Exibicao",
    "NomeExibicao",
    "Cd_Matricula",
    "Email",
    "Ddd",
    "Telefone",
    "Cd_Perfil",
    "Cd_Cargo",
    "Cd_Gestor"
  );
  return {
    loading,
    userFormDetails,
    formOptions,
    Nm_Func,
    Cd_Tratamento,
    Nm_Exibicao,
    NomeExibicao,
    Cd_Matricula,
    Email,
    Ddd,
    Telefone,
    Cd_Perfil,
    Cd_Cargo,
    Cd_Gestor,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "registerForm",
    enableReinitialize: true,
  })(RegisterForm)
);
