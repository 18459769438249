// @flow

import * as fromTypes from "../types/nps";

export const getNpsTable = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_TABLE,
  payload,
});
export const getNpsTableSuccess = (payload: array): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_TABLE_SUCCESS,
  payload,
});
export const getNpsTableError = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_TABLE_ERROR,
  payload,
});

export const addNpsComment = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_COMMENT,
  payload,
});
export const addNpsCommentSuccess = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_COMMENT_SUCCESS,
  payload,
});
export const addNpsCommentError = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_COMMENT_ERROR,
  payload,
});

export const addNpsAction = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_ACTION,
  payload,
});
export const addNpsActionSuccess = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_ACTION_SUCCESS,
  payload,
});
export const addNpsActionError = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.ADD_NPS_ACTION_ERROR,
  payload,
});

export const getNPSDoughnutChart = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_DOUGHNUT,
  payload,
});
export const getNPSDoughnutChartSuccess = (
  payload: array
): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_DOUGHNUT_SUCCESS,
  payload,
});
export const getNPSDoughnutChartError = (
  payload: any
): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_DOUGHNUT_ERROR,
  payload,
});

export const getNpsComments = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_COMMENTS,
  payload,
});
export const getNpsCommentsSuccess = (payload: array): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_COMMENTS_SUCCESS,
  payload,
});
export const getNpsCommentsError = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_COMMENTS_ERROR,
  payload,
});

export const getNPSLineChart = (payload: object): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_LINE,
  payload,
});
export const getNPSLineChartSuccess = (
  payload: array
): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_LINE_SUCCESS,
  payload,
});
export const getNPSLineChartError = (payload: any): fromTypes.NPSAction => ({
  type: fromTypes.GET_NPS_LINE_ERROR,
  payload,
});
