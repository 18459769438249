// Primary colors
export const santander = "#EC0000";
export const santander70 = "#F46666";
export const santander40 = "#FAB2B2";
export const santander20 = "#FEE5E5";
export const santander10 = "#FFF2F2";
export const boston = "#CC0000";
export const ruby = "#990000";
export const sky = "#DEEDF2";
export const mediumSky = "#CEDEE7";
export const darkSky = "#9BC3D3";
export const white = "#FFFFFF";
export const lighterGrey = "#F0F0F0";
export const lightGrey = "#CCCCCC";
export const mediumGrey = "#767676";
export const darkGrey = "#444444";
export const darkerGrey = "#121212";
export const darkerGrey2 = "#999";
export const black = "#000000";
export const inputSky = "#5ab8ca";

// Secondary colors
export const yellow = "#FFCC33";
export const yellow10 = "#FFFDF5";
export const yellow20 = "#FFFAEB";
export const darkerYellow = "#946F00";
export const green = "#63BA68";
export const green10 = "#F8FCF8";
export const green20 = "#F0F8F0";
export const darkerGreen = "#008437";
export const turquoise = "#1BB3BC";
export const turquoise20 = "#E8F8F9";
export const darkerTurquoise = "#137E84";
export const blue = "#3366FF";
export const blue10 = "#90C4D6";
export const blue20 = "#EBF0FF";
export const darkerBlue = "#0032E6";
export const lighterSky = "#F5F9FB";
export const purple = "#9E3667";
export const darkerPurple = "#732645";
export const borderGrey = "#DFDFDF";
export const bulletinGrey = "#707070";
export const wine = "#732745";
export const mustard = "#956F01";
export const mustard10 = "#EFE9D9";
export const oceanBlue = "#0131E6";
export const oceanGreen = "#167E83";
export const beige = "#FFF5D7";
export const black2 = "#222222";

// Acessible colors
export const darkerSky = "#257FA4";

// Neutrals
export const neutral10 = "#F6F6F6";
export const neutral20 = "#ECECEC";
export const neutral30 = "#DADADA";
export const neutral40 = "#C7C7C7";
export const neutral50 = "#B5B5B5";
export const neutral60 = "#A2A2A2";
export const neutral70 = "#8F8F8F";
export const neutral80 = "#7C7C7C";
export const neutral90 = "#6A6A6A";
export const neutral100 = "#575757";

// Link colors
export const defaultLink = "#EC0000";
export const hoverLink = "#990000";
export const activeLink = "#CC0000";
export const visitedLink = "#9E3667";

// Functional support colors
export const darkerError = "#990000";
export const error = "#CC0000";
export const lighterError = "#FEE5E5";
export const darkerSuccess = "#3A8340";
export const success = "#63BA68";
export const lighterSuccess = "#F0F8F0";
export const darkerWarning = "#946F00";
export const warking = "#FFCC33";
export const lighterWarning = "#FFFAEB";
export const darkerInfo = "#0032E6";
export const info = "#3366FF";
export const lighterInfo = "#EBF0FF";
export const toastSuccess = "#739e41";
export const toastWarn = "#f2a51a";
export const toastError = "#f14c4c";
export const toastInfo = "#5ab8ca";

export const taskSubBg = "#F4F9FB";
export const listSelectedBg = "#EEF6F8";

export const exoRed = "#CB0000";
export const exoYellow = "#FECB32";
