import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/checklist";
import * as fromActions from "../actions/checklist";
import { getToken, getUser, logout } from "../services/auth";
import { getAgencia, setAgencia } from "../services/checklist";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";

const BASE_API = getBaseAPI();

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

export function* getChecklist({ payload }) {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  let { agencia } = payload;
  try {
    if (agencia === 0) {
      setAgencia(0);
      yield put(fromActions.getChecklistSuccess({}));
    } else {
      const response = yield axios.post(`${BASE_API}/Checklist`, {
        agencia,
        headers: headers,
      });
      if (response.status === 200) {
        setAgencia(agencia);
        yield put(fromActions.getChecklistSuccess(response.data.data));
      }
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getChecklistError(err));
    alertToast("error", "Houve um problema ao carregar o checklist.");
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* changeChecklistItem({ payload }) {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  let { id, estagio, qte, n_ok, ok } = payload;
  let { comentario } = payload;
  let data = today;
  let ga = parseInt(getUser());
  let agencia = parseInt(getAgencia());
  try {
    const response = yield axios.post(`${BASE_API}/Checklist/item`, {
      Id: id,
      Estagio: estagio,
      Agencia: agencia,
      Qte: qte,
      N_Ok: n_ok,
      Ok: ok,
      GA: ga,
      Comentario: comentario,
      headers: headers,
    });
    if (response.status === 200) {
      try {
        const response = yield axios.post(`${BASE_API}/Checklist`, {
          agencia,
          headers: headers,
        });
        if (response.status === 200) {
          yield put(fromActions.changeChecklistItemSuccess(response.data.data));
        }
      } catch (err) {
        console.log(err);
        yield put(fromActions.changeChecklistItemError(err));
        alertToast("error", "Houve um problema ao atualizar o checklist.");
        if (err.response.status === 401) {
          logout();
          window.location.href = "/";
        }
      }
    }
  } catch (err) {
    yield put(fromActions.changeChecklistItemError(err));
    alertToast("error", "Houve um problema ao atualizar o checklist.");
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
    try {
      const response = yield axios.post(`${BASE_API}/Checklist`, {
        data,
        agencia,
        headers: headers,
      });
      if (response.status === 200) {
        yield put(fromActions.changeChecklistItemSuccess(response.data.data));
      }
    } catch (err) {
      console.log(err);
      yield put(fromActions.changeChecklistItemError(err));
      alertToast("error", "Houve um problema ao atualizar o checklist.");
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* getTaskComments({ id }) {}

export function* watchChecklist() {
  yield takeLatest(fromTypes.GET_CHECKLIST, getChecklist);
  yield takeLatest(fromTypes.CHANGE_CHECKLIST_ITEM, changeChecklistItem);
  yield takeLatest(fromTypes.GET_TASK_COMMENTS, getTaskComments);
}
