// @flow

export const setAgencia = async (agencia: int) =>
  sessionStorage.setItem("agencia", agencia);

export const getAgencia = (): int => {
  const agencia = sessionStorage.getItem("agencia");
  if (agencia && agencia === "undefined") {
    sessionStorage.removeItem("agencia");
    return "";
  }

  return agencia || "";
};
