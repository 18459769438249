import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  black,
  darkGrey,
  white,
  inputSky,
  boston,
  mediumGrey,
  lightGrey,
  santander,
} from "../theme/colors";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {},
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  inputContainer: {
    width: "100%",
  },
  input: {
    fontFamily: "SantanderMicroText !important",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focus-visible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:disabled": {
      background: "transparent",
    },
  },
  error: {
    fontFamily: "SantanderMicroText !important",
    fontSize: "0.750em",
    color: boston,
    textAlign: "left",
    margin: "0em",
    marginTop: "0.5em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
    opacity: "0.9",
  },
  select: {
    fontFamily: "SantanderMicroText !important",
    height: "48px !important",
    "& fieldset": {
      borderColor: `${black} !important`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: `${lightGrey} !important`,
    },
  },

  selectLabel: {
    fontFamily: "SantanderMicroText !important",
    lineHeight: "1em !important",
    marginLeft: "5px !important",
    "&.Mui-focused": {
      color: `${darkGrey} !important`,
      fontSize: "18px !important",
    },
    "&.Mui-focused": {
      fontSize: "18px !important",
      color: `${darkGrey} !important`,
    },
  },
}));

const SelectField = ({
  input,
  meta: { touched, error },
  dataList,
  placeholder,
  disabled,
  defaultValue,
  onGetInputValueMethod = () => {},
  changeMethod = () => {},
  fieldName,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (defaultValue || defaultValue === "") {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const getInputValue = (value) => {
    onGetInputValueMethod(value);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    getInputValue(e.target.value);
    changeMethod(fieldName, e.target.value);
  };

  return (
    <div className={classes.root}>
      <FormControl
        sx={{
          minWidth: "100%",
        }}
      >
        <InputLabel className={classes.selectLabel}>{placeholder}</InputLabel>
        <Select
          value={inputValue}
          onChange={handleChange}
          label={placeholder}
          disabled={disabled}
          className={classes.select}
          sx={{
            backgroundColor: disabled ? null : white,
            fontFamily: "SantanderMicroText !important",
          }}
        >
          {dataList.map((i) => {
            return (
              <MenuItem
                sx={{ fontFamily: "SantanderMicroText !important" }}
                key={i.id || i.cd_Matricula || i.cd_operacao}
                value={i.id || i.cd_Matricula || i.cd_operacao}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontFamily: "SantanderMicroText !important",
                  }}
                >
                  {i.nm_Tratamento ||
                    i.nm_Perfil ||
                    i.nm_Cargo ||
                    i.nm_Func ||
                    i.typeName ||
                    `${i.nm_operacao} - ${i.cd_operacao}`}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {touched && !!error ? <p className={classes.error}>{error}</p> : null}
    </div>
  );
};

export default SelectField;
