import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/atm";
import * as fromActions from "../actions/atm";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser } from "../services/auth";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";
import { setLastSearchedUser } from "../services/search";
import * as fromAlertsActions from "../actions/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getAtms({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmDisponibilidades/`,
      {
        ga,
        rede,
        regional,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getATMAvailabilitySuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    if (err.response.data.errors) {
      yield put(fromActions.getATMAvailabilitySuccess(["forbidden"]));
    } else {
      yield put(fromActions.getATMAvailabilityError(err));
      alertToast("error", "Não foi possível recuperar a lista de ATMs.");
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* getComments({ payload }) {
  const { uniorg, ind, aux } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmComentarios/`,
      {
        headers: headers,
        ind: ind,
        uniorg: uniorg,
        info_aux: aux,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getATMCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getATMCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* sendComment({ payload }) {
  const { uniorg, ind, action, comentario, aux } = payload;
  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmComentarios/add`,
      {
        headers: headers,
        uniorg: uniorg,
        mtr: parseInt(getUser()),
        ind: ind,
        atuacao: action,
        aux: aux,
        com: comentario,
      }
    );
    if (response.status === 200) {
      alertToast("success", "Comentário enviado com sucesso.");
      yield put(fromActions.sendATMCommentsSuccess(response.data.data));
      yield put(
        fromActions.getATMComments({
          ind: ind,
          uniorg: uniorg,
          aux: aux,
        })
      );
      yield put(fromActions.getATMAvailability(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível enviar o comentário");
    yield put(fromActions.sendATMCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getAtmsDoungnutChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/graficoRosca`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getATMDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getATMDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getAtmsLineChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/graficoEvolutivo`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getATMLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getATMLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchATM() {
  yield takeLatest(fromTypes.GET_ATM_AVAILABILITY, getAtms);
  yield takeLatest(fromTypes.GET_ATM_COMMENTS, getComments);
  yield takeLatest(fromTypes.SEND_ATM_COMMENTS, sendComment);
  yield takeLatest(fromTypes.GET_ATM_DOUNGNUT_CHART, getAtmsDoungnutChart);
  yield takeLatest(fromTypes.GET_ATM_LINE_CHART, getAtmsLineChart);
}
