import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { addExoAction, addExoComment } from "../../store/actions/exo";
import csvIcon from "../../assets/icons/csv_icon.svg";
import { getUser } from "../../store/services/auth";
import { getLastSearchedUser } from "../../store/services/search";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  CommentDrawer,
  WarningExportModal,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  santander70,
  black,
  lightGrey,
  darkerGrey2,
  mediumGrey,
  yellow,
  mustard,
  turquoise20,
  beige,
  purple,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";

import interno from "../../assets/img/confidencial_restrito.svg";

const mockConversao = [50, 57, 55, 45, 50, 60];
const mockAtigMeta = [45, 40, 40, 50, 47, 56];
const mockClientesAbord = [20, 35, 30, 25, 25, 30];
const mockVendas = [10, 20, 20, 15, 10, 20];
const mockDataLineLabel = [
  "Nov/21",
  "Dez/21",
  "Jan/22",
  "Fev/22",
  "Mar/22",
  "Abr/22",
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "180px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "75%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "85px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const convertToDate = (dateString) => {
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

const B2G = ({
  match,
  line,
  loading,
  onAddExoAction,
  onAddExoComment,
  table,
  atms,
  gaInfo,
  doughnut,
}) => {
  const classes = useStyles();
  const [concluidoMesData, setConcluidoMesData] = useState([]);
  const [concluidoLegadoData, setConcluidoLegadoData] = useState([]);
  const [mesData, setMesData] = useState([]);
  const [legadoData, setLegadoData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [concluidoTotalData, setConcluidoTotalData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exoSortModel, setExoSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    let list = [];
    atms.map((i, index) => {
      list = [
        ...list,
        {
          id: index + 1,
          cpf: i.nu_Documento,
          uniorg: i.cd_Uniorg,
          urgStatus: i.chamado_Aberto,
          action: i.bl_Atuacao,
          finalProduct: i.ds_Pendencia,
          tpCliente: i.tp_Cliente,
          legado: i.bl_Legado,
        },
      ];
    });
    setRows(list);
    setBackupRows(list);
  }, [atms, gaInfo]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, line.length]);

  useEffect(() => {
    let concluidoMesData = [];
    let concluidoLegadoData = [];
    let mesData = [];
    let legadoData = [];
    let totalData = [];
    let concluidoTotalData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        concluidoMesData.push(i.qtde_Mes_Concluido);
        concluidoLegadoData.push(i.qtde_Leg_Concluido);
        mesData.push(i.qtde_Mes_Aberto);
        legadoData.push(i.qtde_Leg_Aberto);
        totalData.push(i.qtde_Total);
        concluidoTotalData.push(i.qtde_Concluido);
        periodoData.push(i.dt_Abrv);
      });

    setConcluidoMesData(concluidoMesData);
    setConcluidoLegadoData(concluidoMesData);
    setMesData(mesData);
    setLegadoData(legadoData);
    setTotalData(totalData);
    setConcluidoTotalData(concluidoTotalData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.perc_Meta < 100 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut]);

  const doughnutLabels = [
    `Desligados (${34}%)`,
    `Ligados (${57}%)`,
    `Trabalhados (${1}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [4, 10, 1],
      backgroundColor: [santander, turquoise, lightGrey],
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Conversão`,
      data: mockConversao,
      borderColor: purple,
      backgroundColor: purple,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Atig. Meta`,
      data: mockAtigMeta,
      borderColor: darkerGrey2,
      backgroundColor: darkerGrey2,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },

    {
      type: "bar",
      label: `Clientes Abord.`,
      data: mockClientesAbord,
      borderColor: turquoise,
      backgroundColor: turquoise,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
    {
      type: "bar",
      label: `Vendas`,
      data: mockVendas,
      borderColor: santander,
      backgroundColor: santander,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setInputValue(searchValue);
      setRows(backupRows);
    } else {
      setInputValue(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const getUrgencyColor = (legado, action) => {
    if (action) return lightGrey;
    else if (legado) return santander20;
    else return beige;
  };

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const cols = [
    {
      field: "equip",
      headerName: "Equipamento",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 125,
      headerClassName: "header",
    },
    {
      field: "clients",
      headerName: "Clientes abordados",
      width: 125,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          Clientes <br /> abordados
        </div>
      ),
    },
    {
      field: "sales",
      headerName: "Quantidade de vendas",
      width: 125,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          Quantidade <br /> de Vendas
        </div>
      ),
    },
    {
      field: "conversions",
      headerName: "Conversão de Vendas",
      width: 125,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          Conversão <br /> de Vendas
        </div>
      ),
    },
    {
      field: "target",
      headerName: "Meta",
      width: 120,
      headerClassName: "header",
    },
    {
      field: "percent",
      headerName: "% Atingido",
      width: 120,
      headerClassName: "header",
    },
    {
      field: "accounts_opened",
      headerName: "Abertura Conta Corrente",
      width: 140,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
          }}
        >
          Abertura <br /> Conta Corrente
        </div>
      ),
    },
    {
      field: "urgStatus",
      headerName: "Status",
      width: 125,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.legado, params.row.action),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.urgStatus}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 125,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                nuDoc: params.row.cpf,
                tpPerson: params.row.tpCliente,
                uniorg: params.row.uniorg,
                finalProduct: params.row.finalProduct,
                date: params.row.date,
                urgStatus: params.row.urgStatus,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddExoAction({
                nuDoc: params.row.cpf,
                tpPerson: params.row.tpCliente,
                uniorg: params.row.uniorg,
                finalProduct: params.row.finalProduct,
                date: params.row.date,
                urgStatus: params.row.urgStatus,
                action: !params.row.action ? 1 : 0,
              });
            }}
          >
            {!params.row.action ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    }
  };

  const customPieConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "pie",
    indicator: true,
    chartLabels: doughnutLabels,
    doughnutCenterLabel: doughnut.perc_Meta,
    withLegend: false,
    withDoughnutCenterLabelPercentage: true,
    withLines: false,
    withStacked: false,
    indicatorGoal: 100,
    isEXO: true,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado mensal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: mockDataLineLabel,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={atms}
        type={"B2G"}
        modalType="confidencial"
      />
      <Header isATM={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>Bank 2 Go</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {atms.length > 0 ? (
              <>
                <Typography className={classes.uptime}>
                  Última atualização: {atms[0].dt_Processamento}
                </Typography>
                <Tooltip placement="bottom" title={"Exportar"}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    sx={{
                      "&:hover": {
                        background: "none",
                        color: "none",
                      },
                    }}
                  >
                    <Icons icon="export-xls" />
                  </Button>
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>

        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: isResolve ? lighterGrey : santander10 }}
            >
              <div className={classes.pieChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {10}
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Tablet: {14}
                    </Typography>
                  </div>
                  <div className={classes.legendContainer}>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: turquoise }}
                      ></div>
                      <Typography className={classes.legend}>
                        Ligados: {10} ({57}%)
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: lightGrey }}
                      ></div>
                      <Typography className={classes.legend}>
                        Trabalhados: {1} ({9}%)
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: santander }}
                      ></div>
                      <Typography className={classes.legend}>
                        Desligados: {4} ({34}%)
                      </Typography>
                    </div>
                    <Typography
                      className={classes.legend}
                      style={{ width: "215px", marginLeft: "14px" }}
                    >
                      tablet sem comunicação a mais de 6 horas.
                    </Typography>
                  </div>
                </div>

                <ChartContainer customConfigs={customPieConfigs} />
              </div>
              <div className={classes.lineChartsContainer}>
                <div className={classes.lineChartContainer}>
                  <ChartContainer customConfigs={customLineConfigs} />
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="exo"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />
      {gaInfo.length >= 1 && getUser() === getLastSearchedUser() ? (
        <IndicatorList
          rows={rows}
          cols={cols}
          getStatusBGMethod={getUrgencyColor}
          sortModel={exoSortModel}
          changeSortModel={setExoSortModel}
          type="exo"
        />
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do B2G possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddExoAction: (payload) => dispatch(addExoAction(payload)),
    onAddExoComment: (payload) => dispatch(addExoComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { gaInfo } = state.bulletin;
  const { table, doughnut, loading, line } = state.exo;
  const { atms } = state.atm;

  return {
    match: ownProps?.match,
    line,
    loading,
    table,
    gaInfo,
    doughnut,
    atms,
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(B2G)
);
