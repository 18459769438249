import { Typography } from "@mui/material";
import { mediumGrey } from "../theme/colors";

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getFilters } from "../store/actions/bulletin";
import { getChecklist } from "../store/actions/checklist";
import { makeStyles } from "@material-ui/styles";
import { black, darkGrey, white, inputSky } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    left: "3%",
    top: "25%",
    background: white,
    color: darkGrey,
  },
  inputContainer: {
    width: "705px",
  },
  input: {
    fontFamily: "SantanderMicroText !important",
    boxSizing: "border-box",
    width: "705px",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1.7em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-25%",
      fontSize: "0.9em",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "3%",
      top: "25%",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "3%",
    background: white,
    color: darkGrey,
    fontSize: "14px",
    lineHeight: "21px",
    top: "-12px",
  },
  inputCategory: {
    fontFamily: "SantanderMicroText",
  },
  inputCategoryTitleContainer: {
    display: "flex",
    fontWeight: "bold",
    padding: "10px 16px",
    alignItems: "center",
  },
  inputCategoryTitle: {
    marginLeft: "12px",
  },
  divider: {
    width: "95%",
  },
}));

const ChartIndex = ({
  onGetFilters,
  filters,
  changedForm,
  onChangeMethod,
  isBulletin,
}) => {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [filterType, setFilterType] = useState("");
  const [totalFilters, setTotalFilters] = useState([]);
  const [autoInputValue, setAutoInputValue] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  let autoIndex = -1;

  function adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    else return numero;
  }

  useEffect(() => {
    let data = new Date();
    let dataAtualFormatada =
      adicionaZero(data.getDate().toString()) +
      "-" +
      adicionaZero(data.getMonth() + 1).toString() +
      "-" +
      data.getFullYear();
    setCurrentDate(dataAtualFormatada);
  }, []);

  useEffect(() => {
    if (currentDate !== "" && currentDate !== 0) {
      onChangeMethod("DATA", currentDate);
    }
  }, [currentDate]);

  useEffect(() => {
    setInterval(() => {
      autoIndex++;
      if (autoIndex > 179) autoIndex = 0;
      if (totalFilters[autoIndex]) {
        setAutoInputValue(
          totalFilters[autoIndex].label ||
            totalFilters[autoIndex].ds_Rede ||
            totalFilters[autoIndex].ds_Regional
        );
        changeValue(totalFilters[autoIndex]);
      }
    }, 45000);
  }, [totalFilters]);

  useEffect(() => {
    onGetFilters();
  }, [onGetFilters]);

  useEffect(() => {
    //TO-DO: refatorar isso
    let totalFiltersArray = [];
    if (filters.redes && filters.regionais && filters.agencias && filters.gAs) {
      if (isBulletin) {
        totalFiltersArray = [
          ...totalFiltersArray,
          { type: "CLEAR", label: "Consolidado de agências" },
        ];
        for (let index = 0; index < filters.redes.length; index++) {
          totalFiltersArray = [
            ...totalFiltersArray,
            {
              ...filters.redes[index],
              label: `${filters.redes[index].cd_Rede} - ${filters.redes[index].ds_Rede}`,
              type: "REDE",
            },
          ];
        }
        for (let index = 0; index < filters.regionais.length; index++) {
          totalFiltersArray = [
            ...totalFiltersArray,
            {
              ...filters.regionais[index],
              label: `${filters.regionais[index].cd_Regional} - ${filters.regionais[index].ds_Regional}`,
              type: "REGIONAL",
            },
          ];
        }
      }
      for (let index = 0; index < filters.agencias.length; index++) {
        totalFiltersArray = [
          ...totalFiltersArray,
          {
            ...filters.agencias[index],
            label: `${filters.agencias[index].cd_Uniorg} - ${filters.agencias[index].nm_Agencia}`,
            type: "AGÊNCIA",
          },
        ];
      }
      for (let index = 0; index < filters.gAs.length; index++) {
        totalFiltersArray = [
          ...totalFiltersArray,
          {
            ...filters.gAs[index],
            label: `${filters.gAs[index].cd_Matricula} - ${filters.gAs[index].nm_Func}`,
            type: "PERFIL",
          },
        ];
      }
    }
    setTotalFilters([...totalFilters, ...totalFiltersArray]);
  }, [filters, isBulletin]);

  useEffect(() => {
    setId(0);
    //inputToFocus.current.focus();
  }, [changedForm]);

  useEffect(() => {
    if (filterType !== "" && id !== "") {
      onChangeMethod(filterType, id);
    }
  }, [id, filterType]);

  const changeValue = (value) => {
    if (value !== null) {
      setFilterType(value.type);
      const gaId = parseInt(value.label.split(" ")[0]);
      if (!isNaN(gaId) && gaId >= 10) {
        setId(gaId);
      } else {
        setId(0);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontFamily: "SantanderMicroText",
          color: mediumGrey,
          fontWeight: "bold",
          verticalAlign: "middle",
          margin: "12px auto",
          fontSize: "20px",
        }}
      >
        {autoInputValue}
      </Typography>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFilters: () => dispatch(getFilters()),
    onGetChecklist: (agencia) => dispatch(getChecklist(agencia)),
  };
};

const mapStateToProps = (state) => {
  const { filters } = state.bulletin;
  return { filters };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ChartIndex
);
