// @flow

import * as fromTypes from "../types/terms";

export const getUserTerms = (id: int): fromTypes.TermsAction => ({
  type: fromTypes.GET_USER_TERMS,
  id,
});
export const getUserTermsSuccess = (payload: array): fromTypes.TermsAction => ({
  type: fromTypes.GET_USER_TERMS_SUCCESS,
  payload,
});
export const getUserTermsError = (payload: any): fromTypes.TermsAction => ({
  type: fromTypes.GET_USER_TERMS_ERROR,
  payload,
});

export const agreeWithTerm = (payload: object): fromTypes.TermsAction => ({
  type: fromTypes.AGREE_WITH_TERM,
  payload,
});
export const agreeWithTermSuccess = (payload: array): fromTypes.TermsAction => ({
  type: fromTypes.AGREE_WITH_TERM_SUCCESS,
  payload,
});
export const agreeWithTermError = (payload: any): fromTypes.TermsAction => ({
  type: fromTypes.AGREE_WITH_TERM_ERROR,
  payload,
});