import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAlerts, setAlertRead } from "../store/actions/alerts";
import { getUser } from "../store/services/auth";
import { setHasNewAlerts } from "../store/services/alerts";
import { isEmpty, isEqual, xorWith } from "lodash";
import { alertToast } from "./Toast";

const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const getToastType = (type) => {
  switch (type) {
    case 1:
      return "alert-warn";
    case 2:
      return "alert-success";
    case 4:
      return "alert-error";
    default:
      break;
  }
};

const RealTimeAlerts = ({ onGetAlerts, onSetAlertRead, alerts }) => {
  useEffect(() => {
    if (getUser() !== "undefined") {
      onGetAlerts(getUser());
      setInterval(() => {
        onGetAlerts(getUser());
      }, 300000);
    }
  }, []);

  useEffect(() => {
    for (let index = 0; index < alerts.length; index++) {
      alertToast(
        getToastType(alerts[index].tp_Info),
        alerts[index].texto,
        alerts[index].titulo,
        alerts[index].dt_Alerta
      );
      setHasNewAlerts("true");
      ////////
      onSetAlertRead({ id: alerts[index].id, ga: getUser() });
    }
  }, [alerts]);

  return <></>;
};

const mapStateToProps = (state) => {
  const { alerts } = state.alerts;
  return { alerts };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAlerts: (id) => dispatch(getAlerts(id)),
    onSetAlertRead: (payload) => dispatch(setAlertRead(payload)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RealTimeAlerts
);
