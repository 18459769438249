// @flow

import * as fromTypes from "../types/atm";

type State = {
  loading: boolean,
  atms: array,
  comments: array,
  doughnut: array,
  line: array,
};

export const initialState = {
  loading: false,
  atms: [],
  atmComments: [],
  doughnut: [],
  line: [],
};

const setAtms = (state, { payload }) => ({
  ...state,
  atms: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  atmComments: payload,
  loading: false,
});

const setDoungnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setLine = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.ATMAction) => {
  switch (action.type) {
    case fromTypes.GET_ATM_AVAILABILITY:
    case fromTypes.GET_ATM_DOUNGNUT_CHART:
    case fromTypes.GET_ATM_LINE_CHART:
    case fromTypes.GET_ATM_COMMENTS:
    case fromTypes.SEND_ATM_COMMENTS:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_ATM_AVAILABILITY_SUCCESS:
      return setAtms(state, action);
    case fromTypes.GET_ATM_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_ATM_DOUNGNUT_CHART_SUCCESS:
      return setDoungnut(state, action);
    case fromTypes.GET_ATM_LINE_CHART_SUCCESS:
      return setLine(state, action);
    case fromTypes.SEND_ATM_COMMENTS_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.GET_ATM_AVAILABILITY_ERROR:
    case fromTypes.GET_ATM_COMMENTS_ERROR:
    case fromTypes.SEND_ATM_COMMENTS_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
