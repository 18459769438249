// @flow

import * as fromTypes from "../types/manifestation";

type State = {
  loading: boolean,
  doughnut: object,
  line: array,
  table: array,
  manifestationComments: array,
};

export const initialState = {
  loading: false,
  table: [],
  doughnut: {},
  line: [],
  manifestationComments: [],
};

const setLoading = (state, loading) => ({ ...state, loading });

const setTable = (state, { payload }) => ({
  ...state,
  table: payload,
  loading: false,
});

const setDoughnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  manifestationComments: payload,
  loading: false,
});

const setLine = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.ManifestationAction
) => {
  switch (action.type) {
    case fromTypes.GET_MANIFESTATION_TABLE:
    case fromTypes.ADD_MANIFESTATION_COMMENT:
    case fromTypes.ADD_MANIFESTATION_ACTION:
    case fromTypes.GET_MANIFESTATION_DOUGHNUT:
    case fromTypes.GET_MANIFESTATION_COMMENTS:
    case fromTypes.GET_MANIFESTATION_LINE:
      return setLoading(state, true);
    // success
    case fromTypes.ADD_MANIFESTATION_COMMENT:
    case fromTypes.ADD_MANIFESTATION_ACTION:
      return setLoading(state, true);
    case fromTypes.GET_MANIFESTATION_TABLE_SUCCESS:
      return setTable(state, action);
    case fromTypes.GET_MANIFESTATION_DOUGHNUT_SUCCESS:
      return setDoughnut(state, action);
    case fromTypes.GET_MANIFESTATION_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_MANIFESTATION_LINE_SUCCESS:
      return setLine(state, action);
    // error
    case fromTypes.GET_MANIFESTATION_TABLE_ERROR:
    case fromTypes.ADD_MANIFESTATION_COMMENT_ERROR:
    case fromTypes.ADD_MANIFESTATION_ACTION_ERROR:
    case fromTypes.GET_MANIFESTATION_DOUGHNUT_ERROR:
    case fromTypes.GET_MANIFESTATION_COMMENTS_ERROR:
    case fromTypes.GET_MANIFESTATION_LINE_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
