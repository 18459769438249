// @flow

export const setOperation = async (operation: string) =>
  localStorage.setItem("operation", operation);

export const getOperation = (): string => {
  const operation = localStorage.getItem("operation");
  if (operation && operation === "undefined") {
    localStorage.removeItem("operation");
    return "default";
  }

  return operation || "default";
};
