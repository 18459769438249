export const setLastSearchedUser = async (user: string) =>
  sessionStorage.setItem("exoSearchedUser", user);

export const getLastSearchedUser = (): string => {
  const user = sessionStorage.getItem("exoSearchedUser");
  if (user && user === "undefined") {
    sessionStorage.removeItem("exoSearchedUser");
    return "";
  }

  return user || "teste";
};
