// @flow

export const GET_QUEUES_TABLE = "[QUEUES] GET_QUEUES_TABLE";
export const GET_QUEUES_TABLE_SUCCESS = "[QUEUES] GET_QUEUES_TABLE_SUCCESS";
export const GET_QUEUES_TABLE_ERROR = "[QUEUES] GET_QUEUES_TABLE_ERROR";

export const ADD_QUEUES_COMMENT = "[QUEUES] ADD_QUEUES_COMMENT";
export const ADD_QUEUES_COMMENT_SUCCESS = "[QUEUES] ADD_QUEUES_COMMENT_SUCCESS";
export const ADD_QUEUES_COMMENT_ERROR = "[QUEUES] ADD_QUEUES_COMMENT_ERROR";

export const ADD_QUEUES_ACTION = "[QUEUES] ADD_QUEUES_ACTION";
export const ADD_QUEUES_ACTION_SUCCESS = "[QUEUES] ADD_QUEUES_ACTION_SUCCESS";
export const ADD_QUEUES_ACTION_ERROR = "[QUEUES] ADD_QUEUES_ACTION_ERROR";

export const GET_QUEUES_DOUGHNUT = "[QUEUES] GET_QUEUES_DOUGHNUT";
export const GET_QUEUES_DOUGHNUT_SUCCESS =
  "[QUEUES] GET_QUEUES_DOUGHNUT_SUCCESS";
export const GET_QUEUES_DOUGHNUT_ERROR = "[QUEUES] GET_QUEUES_DOUGHNUT_ERROR";

export const GET_QUEUES_COMMENTS = "[QUEUES] GET_QUEUES_COMMENTS";
export const GET_QUEUES_COMMENTS_SUCCESS =
  "[QUEUES] GET_QUEUES_COMMENTS_SUCCESS";
export const GET_QUEUES_COMMENTS_ERROR = "[QUEUES] GET_QUEUES_COMMENTS_ERROR";

export const GET_QUEUES_HEATMAP = "[QUEUES] GET_QUEUES_HEATMAP";
export const GET_QUEUES_HEATMAP_SUCCESS = "[QUEUES] GET_QUEUES_HEATMAP_SUCCESS";
export const GET_QUEUES_HEATMAP_ERROR = "[QUEUES] GET_QUEUES_HEATMAP_ERROR";

type GetQueuesTable = {
  type: typeof GET_QUEUES_TABLE,
  payload: object,
};
type GetQueuesTableSuccess = {
  type: typeof GET_QUEUES_TABLE_SUCCESS,
  payload: Array,
};
type GetQueuesTableError = {
  type: typeof GET_QUEUES_TABLE_ERROR,
  payload: any,
};

type AddQueuesComment = {
  type: typeof ADD_QUEUES_COMMENT,
  payload: object,
};
type AddQueuesCommentSuccess = {
  type: typeof ADD_QUEUES_COMMENT_SUCCESS,
  payload: Array,
};
type AddQueuesCommentError = {
  type: typeof ADD_QUEUES_COMMENT_ERROR,
  payload: any,
};

type AddQueuesAction = {
  type: typeof ADD_QUEUES_ACTION,
  payload: object,
};
type AddQueuesActionSuccess = {
  type: typeof ADD_QUEUES_ACTION_SUCCESS,
  payload: Array,
};
type AddQueuesActionError = {
  type: typeof ADD_QUEUES_ACTION_ERROR,
  payload: any,
};

type GetQueuesDoughnut = {
  type: typeof GET_QUEUES_DOUGHNUT,
  payload: object,
};
type GetQueuesDoughnutSuccess = {
  type: typeof GET_QUEUES_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetQueuesDoughnutError = {
  type: typeof GET_QUEUES_DOUGHNUT_ERROR,
  payload: any,
};

type GetQueuesComments = {
  type: typeof GET_QUEUES_COMMENTS,
  payload: object,
};
type GetQueuesCommentsSuccess = {
  type: typeof GET_QUEUES_COMMENTS_SUCCESS,
  payload: Array,
};
type GetQueuesCommentsError = {
  type: typeof GET_QUEUES_COMMENTS_ERROR,
  payload: any,
};

type GetQueuesHeatmap = {
  type: typeof GET_QUEUES_HEATMAP,
  payload: object,
};
type GetQueuesHeatmapSuccess = {
  type: typeof GET_QUEUES_HEATMAP_SUCCESS,
  payload: Array,
};
type GetQueuesHeatmapError = {
  type: typeof GET_QUEUES_HEATMAP_ERROR,
  payload: any,
};

export type QueuesAction =
  | GetQueuesTable
  | GetQueuesTableSuccess
  | GetQueuesTableError
  | AddQueuesComment
  | AddQueuesCommentSuccess
  | AddQueuesCommentError
  | AddQueuesAction
  | AddQueuesActionSuccess
  | AddQueuesActionError
  | GetQueuesDoughnut
  | GetQueuesDoughnutSuccess
  | GetQueuesDoughnutError
  | GetQueuesComments
  | GetQueuesCommentsSuccess
  | GetQueuesCommentsError
  | GetQueuesHeatmap
  | GetQueuesHeatmapSuccess
  | GetQueuesHeatmapError;
