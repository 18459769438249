import { getUser } from "../store/services/auth";
import { utils, writeFileXLSX } from "xlsx";

const deleterOverColumns = (dataToExport, type) => {
  switch (type) {
    case "EXO":
      dataToExport.map((obj) => {
        delete obj["nu_Doc_Cripto"];
        delete obj["nu_contrato_ag_conta_cripto"];
        delete obj["nu_proposta_cripto"];
        delete obj["cd_indicador"];
        delete obj["id_Exo"];
        obj.nu_contrato_ag_conta_mascara = obj.nu_contrato_ag_conta_mascara
          ? obj.nu_contrato_ag_conta_mascara
          : " ";
        obj.nu_proposta_mascara = obj.nu_proposta_mascara
          ? obj.nu_proposta_mascara
          : " ";
      });
      return dataToExport;

    case "NPS":
      dataToExport.map((obj) => {
        delete obj["nu_doc_cripto"];
        delete obj["nm_cliente_cripto"];
        delete obj["resp_cripto"];
        delete obj["cd_indicador"];
        obj.resp_mascara = obj.resp_mascara ? obj.resp_mascara : " ";
      });
      return dataToExport;

    case "NQ":
      dataToExport.map((obj) => {
        delete obj["nu_doc_cripto"];
        delete obj["nm_cliente_cripto"];
        delete obj["nu_matricula_carteira_cripto"];
        delete obj["cd_indicador"];
        obj.nm_segmento = obj.nm_segmento ? obj.nm_segmento : " ";
      });
      return dataToExport;

    case "Manifestacao":
      dataToExport.map((obj) => {
        delete obj["nu_doc_cripto"];
        delete obj["nm_cliente_cripto"];
        delete obj["cd_indicador"];
        delete obj["nu_ordem"];
        delete obj["cd_indicador"];
        obj.nm_cliente_mascara = obj.nm_cliente_mascara
          ? obj.nm_cliente_mascara
          : " ";
        obj.nu_doc_mascara = obj.nu_doc_mascara ? obj.nu_doc_mascara : " ";
      });
      return dataToExport;

    case "Cheque":
      dataToExport.map((obj) => {
        delete obj["cd_indicador"];
        delete obj["id_cheque"];
        delete obj["nm_gerente_cripto"];
        delete obj["st_origem"];
        delete obj["nu_ag_cripto"];
        delete obj["nu_conta_cripto"];
        delete obj["nm_cliente_cripto"];
        delete obj["nu_doc_cripto"];
        delete obj["bl_comentario"];
        delete obj["st_analise"];
      });
      return dataToExport;

    case "KYC":
    case "ATM":
    case "LOJA":
    case "FILAS":
    case "REFT":
      dataToExport.map((obj) => {
        delete obj["cd_indicador"];
      });
      return dataToExport;
  }
};

const getMessageContentToFile = (modalType, userInfo) => {
  switch (modalType) {
    case "confidencial":
      return [
        {
          coluna: "Confidencial",
        },
        {
          coluna: "",
        },
        {
          coluna:
            "Esse arquivo contém dados classificados como sensíveis de acordo com os ",
        },
        {
          coluna:
            "regulamentos externos de proteção de dados e que, se comprometidos, ",
        },
        {
          coluna: "podem causar danos ou problemas significativos ao Banco. ",
        },
        {
          coluna:
            "Exemplos: dados pessoais de funcionários ou Clientes, dados de RH,",
        },
        {
          coluna:
            "dados não financeiros de clientes, atendimento ao cliente e interações.",
        },
        {
          coluna: "",
        },
        {
          coluna: `Nome: ${userInfo?.info?.nm_Func}`,
        },
        {
          coluna: `Matricula: T${getUser()}`,
        },
        {
          coluna: `Data: ${getCurrentDate()}`,
        },
        {
          coluna: `Hora: ${getCurrentTime()}`,
        },
      ];
      break;

    case "interno":
      return [
        {
          coluna: "Interno",
        },
        {
          coluna: "",
        },
        {
          coluna:
            "Informações publicadas em nossos canais internos e disponíveis ",
        },
        {
          coluna:
            "para todos os funcionários do Banco. São informações que, se ",
        },
        {
          coluna: "comprometidas, não causariam uma ruptura ou danos ",
        },
        {
          coluna:
            "significativos à reputação do Grupo, seus Clientes ou funcionários.",
        },
        {
          coluna:
            "Exemplo: comunicads internos, publicações no NOW, compromissos",
        },
        {
          coluna: "ou alterações organizacionais.",
        },
        {
          coluna: "",
        },
        {
          coluna: `Nome: ${userInfo?.info?.nm_Func}`,
        },
        {
          coluna: `Matricula: T${getUser()}`,
        },
        {
          coluna: `Data: ${getCurrentDate()}`,
        },
        {
          coluna: `Hora: ${getCurrentTime()}`,
        },
      ];
      break;
  }
};

const getColumnNames = (type) => {
  switch (type) {
    case "KYC":
      return [
        "Pernumper",
        "Pessoa",
        "Uniorg",
        "Agência",
        "Status",
        "Prazo",
        "Atuação (Binário)",
        "Última atualização",
      ];
    case "EXO":
      return [
        "CPF/CNPJ",
        "Tipo Cliente",
        "Uniorg",
        "Produto final",
        "Data",
        "Status",
        "Atuação (Binário)",
        "Última atualização",
      ];
    case "CARTERIZACAO":
      return [
        "Uniorg",
        "Matricula GA",
        "Nome GA",
        "Matricula GA (Interino)",
        "Agência",
        "Regional",
        "Nome GA (Interino)",
        "Data inicio (Interino)",
        "Data final (Interino)",
      ];
    case "ATM":
      return [
        "Uniorg",
        "Agência",
        "Status (Atual)",
        "Equipamento",
        "Acumulo Dia (%)",
        "Atuação (Binário)",
        "Última atualização",
      ];
    case "LOJA":
      return [
        "Uniorg",
        "Agência",
        "Status",
        "Data (Ocorrência)",
        "Problema",
        "Observações",
        "Responsável",
        "Atuação",
        "Última atualização",
      ];
    case "NPS":
      return [
        "CPF/CNPJ",
        "Pessoa",
        "Segmento",
        "Uniorg",
        "Data",
        "Prazo",
        "Nota",
        "Resposta",
        "Atuação (Binário)",
        "Última atualização",
      ];
    case "NQ":
      return [
        "Uniorg",
        "Identificador",
        "Pernumper",
        "Matricula GR",
        "Status",
        "Atuação (Binário)",
        "Última atualização",
      ];
    case "B2G":
      return [
        "Equipamento",
        "Uniorg",
        "Clientes Abordados",
        "Quantidade de vendas",
        "Conversão de vendas",
        "Meta",
        "% Atingido",
        "Abertura conta corrente",
        "Status",
        "Atuação",
      ];
    case "FILAS":
      return [
        "Uniorg",
        "Canal",
        "Maior Tempo na Fila",
        "Acima da Meta",
        "Próximo da Meta",
        "Dentro da Meta",
        "Total de Fila",
        "Média de Fila",
        "Meta",
        "Data de atualização",
        "Atuação",
      ];
    case "REFT":
      return [
        "Uniorg",
        "ID do Case (REFT)",
        "Data da Venda",
        "Data da Pendência",
        "Dias em aberto",
        "Estado",
        "Produto",
        "Atuação",
      ];
  }
};

const getCurrentDateToFile = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "_" + mm + "_" + yyyy;
  return today;
};

const getCurrentTime = () => {
  let today = new Date();
  today = today.getHours() + ":" + String(today.getMinutes()).padStart(2, "0");

  return today;
};

const getCurrentDate = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;
  return today;
};

const getFileName = (type) => {
  switch (type) {
    case "KYC":
      return `FORMULARIOS_KYC_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "EXO":
      return `EXO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "CARTERIZACAO":
      return `CARTERIZACAO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "ATM":
      return `DISPONIBILIDADE_ATM_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "LOJA":
      return `DISPONIBILIDADE_LOJA_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "NPS":
      return `NPS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "NQ":
      return `NQ_ALERTAS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "B2G":
      return `BANK_TO_GO_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "FILAS":
      return `GESTAO_DE_FILAS_${getUser()}_${getCurrentDateToFile()}.xlsx`;
    case "REFT":
      return `REFT_${getUser()}_${getCurrentDateToFile()}.xlsx`;
  }
};

const exportXLSX = (dataToExport, modalType, type, userInfo) => {
  const json = deleterOverColumns(dataToExport, type);
  const worksheet = utils.json_to_sheet(json);
  const workbook = utils.book_new();

  const warningSheet = getMessageContentToFile(modalType, userInfo);
  const worksheet2 = utils.json_to_sheet(warningSheet);

  utils.book_append_sheet(
    workbook,
    worksheet,
    `${type} (${modalType.charAt(0).toUpperCase() + modalType.slice(1)})`
  );
  utils.book_append_sheet(workbook, worksheet2, "ALERTA");

  utils.sheet_add_aoa(worksheet, [[...getColumnNames(type)]], {
    origin: "A1",
  });

  utils.sheet_add_aoa(worksheet2, [["Alerta de Sensibilidade de Arquivo"]], {
    origin: "A1",
  });

  const maxWidth = warningSheet.reduce(
    (w, r) => Math.max(w, r.coluna.length),
    10
  );
  worksheet2["!cols"] = [{ wch: maxWidth }];

  writeFileXLSX(workbook, getFileName(type));
};

export default exportXLSX;
