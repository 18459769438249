// Funções para manipular no storage o tipo (REDE, REGIONAL, AGÊNCIA, PERFIL e DATA) e o id
export const setIndicatorType = async (type) =>
  sessionStorage.setItem("indicatorType", JSON.stringify(type));

export const getIndicatorType = () => {
  const indicatorType = sessionStorage.getItem("indicatorType");
  if (indicatorType && indicatorType === "undefined") {
    sessionStorage.removeItem("indicatorType");
    return "";
  }

  return JSON.parse(indicatorType);
};
export const removeIdicatorType = () => {
  sessionStorage.removeItem("indicatorType");
};

// Funções para manipular a uniorg selecionada no storage
export const setSelectedUniorg = async (uniorg) =>
  sessionStorage.setItem("selectedUniorg", JSON.stringify(uniorg));

export const getSelectedUniorg = () => {
  const selectedUniorg = sessionStorage.getItem("selectedUniorg");
  if (selectedUniorg && selectedUniorg === "undefined") {
    sessionStorage.removeItem("selectedUniorg");
    return "";
  }

  return JSON.parse(selectedUniorg);
};
export const removeSelectedUniorg = () => {
  sessionStorage.removeItem("selectedUniorg");
};
