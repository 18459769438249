import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Header, ChartContainer, TabPanel, Loading } from "../components";

import { mediumGrey, white, santander } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    maxWidth: "500px",
  },
  totalData: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const typographyStyle = {
  fontFamily: "SantanderMicroText",
  color: mediumGrey,
  fontWeight: "bold",
};

const DataCharts = ({ bulletin, loading }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const replacedSpan = (value) => {
    value = value.replace("<span>", "");
    value = value.replace("</span>", "");

    return value;
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Header isBulletin={true} isPanel={true} />
      <div>
        <div>
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { background: santander },
              }}
              textColor="inherit"
            >
              {bulletin?.gruposIndicadores?.length > 0 &&
                bulletin.gruposIndicadores.map((item, index) => {
                  return (
                    <Tab
                      label={item.name}
                      {...a11yProps(index)}
                      value={index}
                      sx={{ color: mediumGrey }}
                    />
                  );
                })}
            </Tabs>
          </div>
          <></>
          {bulletin?.gruposIndicadores?.length > 0 ? (
            <>
              {bulletin.gruposIndicadores.map((item, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <Typography
                      sx={{
                        fontFamily: "SantanderMicroText",
                        color: mediumGrey,
                        fontWeight: "bold",
                        margin: "12px 0",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      {item.rows.map((data, index) => {
                        return (
                          <div className={classes.chartContainer}>
                            <ChartContainer
                              key={index}
                              title={data.title}
                              propChartData={data.cols}
                              chartType="bar"
                              indicator={false}
                              withLegend={false}
                              withLines={true}
                              withStacked={false}
                              withPercentage={true}
                              withTooltip={false}
                            />
                            <div className={classes.totalData}>
                              <div>
                                <Typography sx={typographyStyle}>
                                  Meta
                                </Typography>
                                <span className={classes.typography}>
                                  {data.cols[0]?.data?.num.includes("<span>")
                                    ? replacedSpan(data.cols[0]?.data?.num)
                                    : data.cols[0]?.data?.num}
                                </span>
                              </div>
                              <div>
                                <Typography sx={typographyStyle}>
                                  Realizado
                                </Typography>
                                <span className={classes.typography}>
                                  {data.cols[1]?.data?.num.includes("<span>")
                                    ? replacedSpan(data.cols[1]?.data?.num)
                                    : data.cols[1]?.data?.num}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { bulletin, loading } = state.bulletin;
  return { bulletin, loading };
};

export default withRouter(compose(connect(mapStateToProps, null))(DataCharts));
