// @flow

import * as fromTypes from "../types/bulletin";

export const getBulletin = (): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN,
});
export const getBulletinSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_SUCCESS,
  payload,
});
export const getBulletinError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_ERROR,
  payload,
});

export const getBulletinUrgency = (): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_URGENCY,
});
export const getBulletinUrgencySuccess = (
  payload: any
): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_URGENCY_SUCCESS,
  payload,
});
export const getBulletinUrgencyError = (
  payload: any
): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_URGENCY_ERROR,
  payload,
});

export const getBulletinSearch = (payload: object): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_SEARCH,
  payload
});
export const getBulletinSearchSuccess = (
  payload: any
): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_SEARCH_SUCCESS,
  payload,
});
export const getBulletinSearchError = (
  payload: any
): fromTypes.BulletinAction => ({
  type: fromTypes.GET_BULLETIN_SEARCH_ERROR,
  payload,
});

export const getFilters = (): fromTypes.BulletinAction => ({
  type: fromTypes.GET_FILTERS,
});
export const getFiltersSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_FILTERS_SUCCESS,
  payload,
});
export const getFiltersError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_FILTERS_ERROR,
  payload,
});

export const getPersonInfo = (gaId: string): fromTypes.BulletinAction => ({
  type: fromTypes.GET_PERSON_INFO,
  gaId,
});
export const getPersonInfoSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_PERSON_INFO_SUCCESS,
  payload,
});
export const getPersonInfoError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_PERSON_INFO_ERROR,
  payload,
});

export const setExportChecked = (payload: object): fromTypes.BulletinAction => ({
  type: fromTypes.SET_EXPORT_CHECKED,
  payload
});

export const getActionCategories = (ind: int): fromTypes.BulletinAction => ({
  type: fromTypes.GET_ACTION_CATEGORIES,
  ind
});
export const getActionCategoriesSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_ACTION_CATEGORIES_SUCCESS,
  payload
});
export const getActionCategoriesError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_ACTION_CATEGORIES_SUCCESS,
  payload
});

export const getIndicatorComments = (payload: object): fromTypes.BulletinAction => ({
  type: fromTypes.GET_INDICATOR_COMMENTS,
  payload
});
export const getIndicatorCommentsSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_INDICATOR_COMMENTS_SUCCESS,
  payload
});
export const getIndicatorCommentsError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.GET_INDICATOR_COMMENTS_ERROR,
  payload
});

export const addIndicatorComment = (payload: object): fromTypes.BulletinAction => ({
  type: fromTypes.ADD_INDICATOR_COMMENT,
  payload
});
export const addIndicatorCommentSuccess = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.ADD_INDICATOR_COMMENT_SUCCESS,
  payload
});
export const addIndicatorCommentError = (payload: any): fromTypes.BulletinAction => ({
  type: fromTypes.ADD_INDICATOR_COMMENT_ERROR,
  payload
});

