import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Box, Button, Typography } from "@material-ui/core";
import {
  white,
  green,
  mediumGrey,
  santander,
  boston,
  darkGrey,
  santander20,
} from "../theme/colors";
import { newPassValidators } from "../utils/validators";
import { alertToast } from "../components/Toast";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { changeProfilePass } from "../store/actions/profile";
import { TextField, Icons } from "./";
import { required } from "../utils/validators";

const useStyles = makeStyles((theme) => ({
  root: {},
  login: {
    marginTop: "2em",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    ["@media (max-height: 645px)"]: {
      display: "block",
      position: "relative",
      margin: "auto",
      transform: "none",
      top: "auto",
      left: "auto",
      paddingBottom: "2em",
    },
  },
  whiteFloater: {
    backgroundColor: "white",
    textAlign: "center",
    margin: "0 auto",
    padding: "1.5em",
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      width: "80vw",
      maxWidth: "1080px",
      padding: "1em, 3.750em",
    },
  },
  logoTelemetriaContainer: {
    marginBottom: "1em",
  },
  containerForm: {
    display: "flex",
    width: "100%",
  },
  rules: {
    flexBasis: "45%",
    padding: "1em",
    "& li": {
      fontFamily: "SantanderHeadline",
      color: mediumGrey,
      textAlign: "left",
    },
  },
  rulesTitle: {
    fontFamily: "SantanderHeadline !important",
    color: `${darkGrey} !important`,
    fontWeight: "bold !important",
    textAlign: "left",
    fontSize: "1.1em !important",
  },
  form: {
    flexBasis: "55%",
    padding: "24px 16px",
  },
  idField: {
    marginBottom: "1.1em",
  },
  passField: {
    marginBottom: "2.250em",
  },
  forgotPass: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    paddingLeft: "0 !important",
  },
  returnBtn: {
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    textTransform: "none !important",
    padding: "0.25em 1em !important",
    fontSize: "1em !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  submitBtn: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  list: {
    listStyleType: "none",
    padding: "0px",
    "& li": {
      display: "flex",
      alignItems: "center",
    },
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
  oldPassField: {
    marginBottom: "32px",
  },
  warnChangePass: {
    fontFamily: "SantanderHeadline !important",
    color: `${santander} !important`,
    textAlign: "left",
    fontSize: "1.1em !important",
  },
}));

const ChangePassModal = ({
  open,
  onClose,
  loading,
  handleSubmit,
  id,
  onChangePassword,
}) => {
  const classes = useStyles();
  const [validations, setValidations] = useState({
    validLength: false,
    upperCase: false,
    lowerCase: false,
    numbers: false,
    specialCharacters: false,
  });
  const {
    isValidLength,
    hasUpperCase,
    hasLowerCase,
    hasNumbers,
    hasSpecialCharacters,
  } = newPassValidators;

  const onSubmit = ({ oldPass, newPass, newPassConfirm }) => {
    if (newPass === newPassConfirm) {
      onChangePassword({
        UserName: id,
        Password: newPass,
        ConfirmPassword: newPassConfirm,
      });
    } else {
      alertToast("error", "Senhas não correspondem!");
    }
  };

  const checkNewPass = (newPass) => {
    setValidations({
      validLength: isValidLength(newPass),
      upperCase: hasUpperCase(newPass),
      lowerCase: hasLowerCase(newPass),
      numbers: hasNumbers(newPass),
      specialCharacters: hasSpecialCharacters(newPass),
    });
  };

  const getCheckmark = (validator) =>
    validator ? (
      <Icons customColor={green} icon="checkmark" />
    ) : (
      <div style={{ padding: "0px 8px" }}>•</div>
    );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "755px",
          bgcolor: white,
          p: 4,
        }}
      >
        <div className={classes.containerForm}>
          <div className={classes.rules}>
            <Typography className={classes.rulesTitle}>
              Alterar senha
            </Typography>
            <Typography className={classes.subtitle}>
              Requisitos necessários:
            </Typography>
            <ul className={classes.list}>
              <li>
                {getCheckmark(validations.validLength)}
                <span
                  style={
                    validations.validLength ? { fontWeight: "bold" } : null
                  }
                >
                  Entre 8 e 14 caracteres
                </span>
              </li>
              <li>
                {getCheckmark(validations.upperCase)}
                <span
                  style={validations.upperCase ? { fontWeight: "bold" } : null}
                >
                  Letras maiúsculas
                </span>
              </li>
              <li>
                {getCheckmark(validations.lowerCase)}
                <span
                  style={validations.lowerCase ? { fontWeight: "bold" } : null}
                >
                  Letras minúsculas
                </span>
              </li>
              <li>
                {getCheckmark(validations.numbers)}
                <span
                  style={validations.numbers ? { fontWeight: "bold" } : null}
                >
                  Números
                </span>
              </li>
              <li>
                {getCheckmark(validations.specialCharacters)}
                <span
                  style={
                    validations.specialCharacters
                      ? { fontWeight: "bold" }
                      : null
                  }
                >
                  Caracteres especiais
                </span>
              </li>
            </ul>
            <Typography className={classes.warnChangePass}>
              <strong>Atenção:</strong> ao alterar a senha sua sessão <br /> vai
              expirar e será necessário efetuar <br />
              login novamente!
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <div className={classes.oldPassField}>
              <Field
                type="password"
                name="oldPass"
                icon="security-close"
                placeholder="Digite a senha atual"
                component={TextField}
                validate={[required]}
              />
            </div>
            <div className={classes.idField}>
              <Field
                type="password"
                name="newPass"
                icon="security-close"
                placeholder="Defina uma senha"
                parentValueCheck={checkNewPass}
                component={TextField}
                validate={[required]}
              />
            </div>
            <div className={classes.idField}>
              <Field
                name="newPassConfirm"
                type="password"
                icon="security-close"
                placeholder="Confirme a nova senha"
                validate={[required]}
                component={TextField}
              />
            </div>

            <div className={classes.btnContainer}>
              <Button
                variant="contained"
                onClick={onClose}
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  marginRight: "16px",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                className={classes.submitBtn}
                disabled={loading}
              >
                Alterar
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: (payload) => dispatch(changeProfilePass(payload)),
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.auth;
  return { loading };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "authChangePassForm",
    enableReinitialize: true,
  })
)(ChangePassModal);
