import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";

import Icons from "./Icons";

import { logout, getUser, getUserLvl } from "../store/services/auth";
import { mediumGrey } from "../theme/colors";

import logoSmall from "../assets/img/logo_small.svg";
import PWBIModal from "./PWBIModal";

const useStyles = makeStyles((theme) => ({
  root: {},
  logoOpen: {
    cursor: "pointer",
  },
  list: {
    padding: "1em !important",
  },
  drawerLogo: {
    margin: "2em 1em",
  },
  listItem: {
    padding: "1em 0.6em !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    fontFamily: "SantanderHeadline",
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    width: "100%",
    paddingLeft: "16px",
  },
  listSubItem: {
    display: "flex !important",
    justifyContent: "space-between !important",
    fontFamily: "SantanderHeadline",
    color: `${mediumGrey} !important`,
    fontSize: "14px",
  },
}));

const MenuItems = () => {
  const [listItemManagementOpen, setListManagementItemOpen] = useState(false);
  const [listItemAnalysisOpen, setListItemAnalysisOpen] = useState(false);
  const [listItemSupportOpen, setListItemSupportOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();

  const signOut = () => {
    logout();
    window.location.href = "/";
  };

  return (
    <div className={classes.root}>
      <PWBIModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        reportUrl={
          "https://app.powerbi.com/reportEmbed?reportId=ce587fb7-fed7-40ad-a6ec-2aae0924bb90&autoAuth=true&ctid=35595a02-4d6d-44ac-99e1-f9ab4cd872db"
        }
      />
      <List className={classes.list}>
        <img
          src={logoSmall}
          alt=""
          className={classes.drawerLogo}
          style={{
            marginTop:
              process.env.REACT_APP_BASE_API === "development" ? "67px" : null,
          }}
        />
        <Divider />

        <ListItem
          className={classes.listItem}
          button
          onClick={() => (window.location.href = "/home")}
        >
          <Icons icon="home" />
          <div className={classes.text}>Home</div>
          <Icons icon="chevron-right-1px" />
        </ListItem>
        <Divider />

        {getUserLvl() === "4" || getUserLvl() === "5" ? (
          <>
            <ListItem
              className={classes.listItem}
              button
              onClick={() => (window.location.href = "/compensacao")}
            >
              <Icons icon="checkbook" />
              <div className={classes.text}>Compesação</div>
              <Icons icon="chevron-right-1px" />
            </ListItem>
            <Divider />
          </>
        ) : (
          <>
            <ListItem
              className={classes.listItem}
              button
              onClick={() => (window.location.href = "/boletim")}
            >
              <Icons icon="communication-message" />
              <div className={classes.text}>Boletim Diário</div>
              <Icons icon="chevron-right-1px" />
            </ListItem>
            <Divider />

            <ListItem
              className={classes.listItem}
              button
              onClick={() => (window.location.href = "/checklist")}
            >
              <Icons icon="checklist" />
              <div className={classes.text}>Checklist</div>
              <Icons icon="chevron-right-1px" />
            </ListItem>
            <Divider />
          </>
        )}

        <ListItem
          className={classes.listItem}
          button
          onClick={() => (window.location.href = `/perfil/${getUser()}`)}
        >
          <Icons icon="account-user" />
          <div className={classes.text}>Meu perfil</div>
          <Icons icon="chevron-right-1px" />
        </ListItem>
        <Divider />

        {getUserLvl() === "2" ||
        getUserLvl() === "3" ||
        getUserLvl() === "5" ? (
          <>
            <ListItem
              className={`${classes.listItem} collapse-trigger`}
              button
              onClick={() => {
                setListManagementItemOpen(!listItemManagementOpen);
              }}
            >
              <Icons icon="team-interaction" />
              <div className={`${classes.text} collapse-trigger`}>Gestão</div>

              {listItemManagementOpen ? (
                <Icons icon="chevron-up-1px" />
              ) : (
                <Icons icon="chevron-down-1px" />
              )}
            </ListItem>
            <Collapse
              in={listItemManagementOpen}
              timeout="auto"
              unmountOnExit
              sx={{ padding: "0 1.7em" }}
            >
              <ListItem
                className={classes.listSubItem}
                button
                onClick={() => (window.location.href = "/usuarios")}
              >
                <Icons icon="chevron-right-1px" />
                <div className={classes.text}>Usuários</div>
              </ListItem>

              <ListItem
                className={classes.listSubItem}
                button
                onClick={() => (window.location.href = "/agencias")}
              >
                <Icons icon="chevron-right-1px" />
                <div className={classes.text}>Agências</div>
              </ListItem>
              <ListItem
                className={classes.listSubItem}
                button
                onClick={() => (window.location.href = "/carterizacao")}
              >
                <Icons icon="chevron-right-1px" />
                <div className={classes.text}>Carterização</div>
              </ListItem>

              <ListItem
                className={classes.listSubItem}
                button
                onClick={() => (window.location.href = "/alertas")}
              >
                <Icons icon="chevron-right-1px" />
                <div className={classes.text}>Alertas</div>
              </ListItem>
            </Collapse>
            {/* 
            <Divider />
            <ListItem
              className={classes.listItem}
              button
              onClick={() => (window.location.href = "/painel-boletim")}
            >
              <Icons icon="burndowncharct" />
              <div className={classes.text}>Painéis</div>

              <Icons icon="chevron-right-1px" />
            </ListItem> */}
          </>
        ) : null}

        <Divider />
        <ListItem
          className={classes.listItem}
          button
          onClick={() => setOpenModal(true)}
        >
          <Icons icon="idea" />
          <div className={classes.text}>Quem resolve?</div>
          <Icons icon="chevron-right-1px" />
        </ListItem>
        <Divider />
        <ListItem
          className={`${classes.listItem} collapse-trigger`}
          button
          onClick={() => {
            setListItemSupportOpen(!listItemSupportOpen);
          }}
        >
          <Icons icon="phone-support" />
          <div className={`${classes.text} collapse-trigger`}>Suporte</div>

          {listItemSupportOpen ? (
            <Icons icon="chevron-up-1px" />
          ) : (
            <Icons icon="chevron-down-1px" />
          )}
        </ListItem>
        <Collapse
          in={listItemSupportOpen}
          timeout="auto"
          unmountOnExit
          sx={{ padding: "0 1.7em" }}
        >
          <ListItem
            className={classes.listSubItem}
            button
            onClick={() =>
              (window.location.href = "mailto:teleindicadores@santander.com.br")
            }
          >
            <Icons icon="chevron-right-1px" />
            <div className={classes.text}>E-mail</div>
          </ListItem>
          <ListItem
            className={classes.listSubItem}
            button
            onClick={() =>
              window.open(
                "https://confluence.santanderbr.corp/display/GTTGA/Perguntas+Frequentes",
                "_blank"
              )
            }
          >
            <Icons icon="chevron-right-1px" />
            <div className={classes.text}>Perguntas e Respostas</div>
          </ListItem>
          <ListItem
            className={classes.listSubItem}
            button
            onClick={() =>
              window.open(
                "https://teams.microsoft.com/l/channel/19%3aKOENhd7z06YbRT0548n6G8SvSGdCIiN2kgyBhxz2NUw1%40thread.tacv2/Geral?groupId=90732f4a-2c17-4347-9f18-deb5b4658edb&tenantId=35595a02-4d6d-44ac-99e1-f9ab4cd872db",
                "_blank"
              )
            }
          >
            <Icons icon="chevron-right-1px" />
            <div className={classes.text}>Grupo Teams</div>
          </ListItem>
        </Collapse>

        <Divider />

        <ListItem className={classes.listItem} button onClick={signOut}>
          <Icons icon="sign-off" />
          <div className={classes.text}>Sair</div>
          <Icons icon="chevron-right-1px" />
        </ListItem>
        <Divider />
      </List>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  const { loading } = state.profile;
  return { loading };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MenuItems);
