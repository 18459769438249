import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip } from "@mui/material";

import {
  darkGrey,
  turquoise,
  santander,
  white,
  yellow,
  lightGrey,
  black,
  mediumGrey,
  lighterGrey,
  bulletinGrey,
} from "../theme/colors";

const currentDate = new Date().getDate();
const currentMouth = new Date().toLocaleString("pt-br", { month: "long" });
const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 30px",
  },
  mouthContainer: {
    marginTop: "30px",
  },
  daysContainer: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
  },
  dayContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    fontFamily: "SantanderHeadline ",
    fontSize: "12px ",
    border: "2px solid",
  },
  legendContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  legendSquare: {
    width: "10px",
    height: "10px",
  },
  legendText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
}));

const CustomCalendar = ({ heatMapData }) => {
  const classes = useStyles();

  const getBackgroundColor = (colorCode) => {
    switch (colorCode) {
      case 1:
        return lightGrey;
      case 2:
        return yellow;
      case 3:
        return santander;
      case 4:
        return lighterGrey;
      default:
        break;
    }
  };

  const getBorderColor = (colorCode) => {
    switch (colorCode) {
      case 1:
        return turquoise;
      case 2:
        return yellow;
      case 3:
        return santander;
      case 4:
        return lighterGrey;
      default:
        break;
    }
  };

  const getTextColor = (colorCode) => {
    switch (colorCode) {
      case 2:
      case 3:
        return white;
      default:
        return darkGrey;
    }
  };

  const getTooltipText = (colorCode, status, problem) => {
    switch (colorCode) {
      case 1:
        return `Status: ${status}`;
      case 2:
      case 3:
        return `Status: ${status} - Problema: ${problem}`;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        sx={{
          fontFamily: "SantanderHeadline !important",
          fontWeight: "bold !important",
          fontSize: "18px !important",
          color: `${darkGrey} !important`,
        }}
      >
        Resultado Mensal
      </Typography>

      <div className={classes.mouthContainer}>
        <Typography
          sx={{
            marginBottom: "12px",
            fontFamily: "SantanderHeadline !important",
            fontWeight: "bold !important",
            fontSize: "14px !important",
            color: `${mediumGrey} !important`,
          }}
        >
          {currentMouth.charAt(0).toUpperCase() + currentMouth.slice(1)} /{" "}
          {currentYear}
        </Typography>
        <div className={classes.daysContainer}>
          {heatMapData.map((item) =>
            item.colorCode === 4 ? (
              <div
                className={classes.dayContainer}
                style={{
                  backgroundColor: getBackgroundColor(item.colorCode),
                  borderColor: white,
                  color: getTextColor(item.colorCode),
                }}
              >
                <span style={{ cursor: "default" }}>{item.day}</span>
              </div>
            ) : (
              <Tooltip
                placement="top"
                title={
                  currentDate == item.day
                    ? `${getTooltipText(
                        item.colorCode,
                        item.status,
                        item.problem
                      )} (Hoje)`
                    : getTooltipText(item.colorCode, item.status, item.problem)
                }
              >
                <div
                  className={classes.dayContainer}
                  style={{
                    backgroundColor: getBackgroundColor(item.colorCode),
                    borderColor:
                      currentDate == item.day ? `${darkGrey}` : `${white}`,

                    color: getTextColor(item.colorCode),
                  }}
                >
                  <span style={{ cursor: "default" }}>{item.day}</span>
                </div>
              </Tooltip>
            )
          )}
        </div>
      </div>

      <div className={classes.legendContainer}>
        <div
          className={classes.legendSquare}
          style={{ backgroundColor: lightGrey }}
        ></div>
        <Typography className={classes.legendText}>Loja Aberta</Typography>
        <div
          className={classes.legendSquare}
          style={{ backgroundColor: yellow }}
        ></div>
        <Typography className={classes.legendText}>
          Loja Aberta parcial
        </Typography>
        <div
          className={classes.legendSquare}
          style={{ backgroundColor: santander }}
        ></div>
        <Typography className={classes.legendText}>Loja Fechada</Typography>
      </div>
    </div>
  );
};

export default CustomCalendar;
