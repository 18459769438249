import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getAgencia } from "../store/services/checklist";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import {
  borderGrey,
  darkGrey,
  santander20,
  white,
  bulletinGrey,
  lighterGrey,
  green,
  yellow,
  santander,
  mediumGrey,
  black2,
} from "../theme/colors";
import { getUser } from "../store/services/auth";
import { getLastSearch } from "../store/services/atm";
import { setExportChecked } from "../store/actions/bulletin";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "64px",
    outline: `1px solid ${lighterGrey}`,
    "& td": {
      textAlign: "center",
    },
    "&:hover": {
      boxShadow: "0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.12)",
    },
  },
  bar: {
    width: "4px",
    height: "64px",
  },
  titleContainer: {
    textAlign: "left !important",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    padding: "0em",
  },
  titleHolder: {
    paddingLeft: "1.125em",
  },
  title: {
    color: darkGrey,
    fontSize: "1.125em !important",
    fontFamily: "SantanderMicroText !important",
    fontWeight: "bold !important",
  },
  subtitle: {
    fontFamily: "SantanderMicroText !important",
    fontSize: "0.700em !important",
    color: mediumGrey,
  },
  commonNum: {
    fontFamily: "SantanderMicroText !important",
    fontWeight: "bold !important",
    fontSize: "1.125em !important",
    color: darkGrey,
    "& span": {
      fontSize: "0.6em !important",
      verticalAlign: "top",
    },
  },
  withColorHolder: {
    verticalAlign: "bottom",
    padding: "0em 2em",
    maxHeight: "64px",
  },
  withColorNum: {
    fontFamily: "SantanderMicroText !important",
    fontWeight: "bold !important",
    fontSize: "1.250em !important",
    color: darkGrey,
    paddingBottom: "0.2em",
    paddingTop: "0.8em",
  },
  withColorSub: {
    color: bulletinGrey,
    fontFamily: "SantanderMicroText !important",
    fontSize: "0.750em !important",
    height: "100%",
  },
  tripleCol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignContent: "center",
    alignItems: "center",
  },
  tripleColOverdue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
  },
  tripleVerticalAlign: {
    verticalAlign: "bottom",
  },
  tripleNum: {
    fontFamily: "SantanderMicroText !important",
    fontWeight: "bold !important",
    color: darkGrey,
    paddingBottom: "0.2em",
  },
  tripleSub: {
    color: bulletinGrey,
    fontFamily: "SantanderMicroText !important",
    fontSize: "0.750em !important",
  },
  tripleNumBigText: {
    fontFamily: "SantanderMicroText !important",
    fontWeight: "bold !important",
    color: darkGrey,
    paddingBottom: "0em",
  },
  tripleSubBigText: {
    color: bulletinGrey,
    fontFamily: "SantanderMicroText !important",
    fontSize: "0.60em !important",
  },
  emptyCol: {
    width: "10em",
  },
  uniorgAlertCircle: {
    position: "absolute",
    borderRadius: "50%",
    left: "-8%",
    top: "25%",
    background: santander,
    height: "8px",
    width: "8px",
    border: `2px solid ${white}`,
  },
}));

const Row = ({
  tooltip,
  data,
  isFirst,
  isLast,
  alerts,
  currentPageInfo,
  gaInfo,
  exportChecked,
  onSetExportChecked,
  withTooltip,
}) => {
  const classes = useStyles();
  const [isMouseOn, setIsMouseOn] = useState(false);
  const [shouldEnableReftLink, setShouldEnableReftLink] = useState("");
  const [shouldEnableExoLink, setShouldEnableExoLink] = useState("");

  useEffect(() => {
    if (getLastSearch().ga !== 0 || getLastSearch().agencia !== 0) {
      setShouldEnableReftLink("REFT - Devolvidas");
      setShouldEnableExoLink("EXO");
    } else {
      setShouldEnableReftLink("");
      setShouldEnableExoLink("");
    }
  }, [data]);

  const indicatorsWithLink = [
    "Disponibilidade - ATM",
    "KYC - Formulários",
    "Disponibilidade - Loja",
    "Fila Caixa (D-1)",
    "Fila Gerência (D-1)",
    "EXO",
    shouldEnableReftLink,
    "NQ - Alertas",
    "NPS - % trabalhado",
    "Manifestações",
    //"Compensação de Cheques",
  ];

  const getRemainingCols = () => {
    switch (data.cols.length) {
      case 4:
        return (
          <>
            <td className={classes.emptyCol}></td>
            <td className={classes.emptyCol}></td>
            <td className={classes.emptyCol}></td>
          </>
        );
      case 5:
        return (
          <>
            <td className={classes.emptyCol}></td>
            <td className={classes.emptyCol}></td>
          </>
        );
      case 6:
        return <td></td>;
      default:
        break;
    }
  };

  const getColor = (colorCode) => {
    switch (colorCode) {
      case 9:
        return lighterGrey;
      case 1:
        return green;
      case 2:
        return yellow;
      case 3:
        return santander;
      default:
        break;
    }
  };

  const getBorderRadius = () => {
    if (isFirst) return "4px 4px 0px 0px";
    else if (isLast) return "0px 0px 4px 4px";
    else return "none";
  };

  const checkIfRowHasAlerts = () => {
    for (let i = 0; i < alerts.length; i++) {
      if (
        data.title === "Disponibilidade - ATM" ||
        data.title === "KYC - Formulários" ||
        data.title === "Disponibilidade - Loja" ||
        data.title === "Fila Caixa (D-1)" ||
        data.title === "Fila Gerência (D-1)" ||
        data.title === "REFT - Devolvidas"
      ) {
        if (
          alerts[i].cd_Uniorg === parseInt(getAgencia()) &&
          alerts[i].titulo === data.title &&
          parseInt(getAgencia()) !== 0
        ) {
          return true;
        } else if (
          parseInt(getAgencia()) === 0 &&
          alerts[i].titulo === data.title &&
          alerts[i].cd_Uniorg !== 0
        )
          return true;
      }
    }
  };

  const getURL = () => {
    switch (currentPageInfo.type) {
      case "selectedAgency":
        return `agencia/${currentPageInfo.id}`;
      case "DATA":
        if (gaInfo.length > 0) {
          return `perfil/${getUser()}`;
        } else {
          return "clear/0";
        }
      case "AGÊNCIA" || "agência":
        return `agencia/${currentPageInfo.id}`;
      default:
        return `${currentPageInfo.type.toLowerCase()}/${currentPageInfo.id}`;
    }
  };

  const getURLType = () => {
    switch (data.title) {
      case "Disponibilidade - ATM":
        return "atm";
      case "Disponibilidade - Loja":
        return "loja";
      case "KYC - Formulários":
        return "kyc";
      case "Fila Caixa (D-1)":
      case "Fila Gerência (D-1)":
        return "filas";
      case "REFT - Devolvidas":
        return "reft";
      case "NQ - Alertas":
        return "nq-alertas";
      case "EXO":
        return "exo";
      case "NPS - % trabalhado":
        return "nps";
      case "Manifestações":
        return "manifestacoes";
      //case "Compensação de Cheques":
        //return "cheque";
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const indicatorsTypes = [
    "KYC - Formulários",
    "NQ - Alertas",
    "EXO",
    "REFT - Devolvidas",
  ];

  return (
    <tr
      className={classes.root}
      style={{
        backgroundColor: tooltip ? santander20 : white,
        borderRadius: getBorderRadius(),
      }}
      onMouseOver={() => {
        setIsMouseOn(true);
      }}
      onMouseOut={() => {
        setIsMouseOn(false);
      }}
    >
      <td className={classes.titleContainer}>
        <div
          className={classes.bar}
          style={{ backgroundColor: getColor(data.color) }}
        ></div>
        <div className={classes.titleHolder}>
          {
            // O ideal é no futuro essa informação vir da API, por enquanto criei um array com o nome dos indicadores como solução temporária
            indicatorsWithLink.includes(data.title) ? (
              <Typography
                className={classes.title}
                style={{
                  color: isMouseOn
                    ? getColor(data.color) === lighterGrey
                      ? darkGrey
                      : getColor(data.color)
                    : darkGrey,
                  position: "relative",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  window.location.href = `/${getURLType()}/${getURL()}`;
                }}
              >
                {checkIfRowHasAlerts() ? (
                  <div className={classes.uniorgAlertCircle}></div>
                ) : null}
                {data.title}
              </Typography>
            ) : (
              <Typography
                className={classes.title}
                style={{
                  color: isMouseOn
                    ? getColor(data.color) === lighterGrey
                      ? darkGrey
                      : getColor(data.color)
                    : darkGrey,
                  position: "relative",
                }}
              >
                {checkIfRowHasAlerts() ? (
                  <div className={classes.uniorgAlertCircle}></div>
                ) : null}
                {data.title}
              </Typography>
            )
          }

          {data.subtitle ? (
            <Typography className={classes.subtitle}>
              Dados referentes a {data.subtitle}*
            </Typography>
          ) : null}
        </div>
        {tooltip ? <div>{/*TODO: TOOLTIP*/}</div> : null}
      </td>
      {data.cols.map((col, i) => {
        switch (col.type) {
          case "common":
            return indicatorsTypes.some((item) => data.title === item) ? (
              <LightTooltip
                title={`Sua meta é resolver ${data.cols[1].data.num} pendências`}
              >
                <td
                  key={i}
                  className={classes.commonNum}
                  dangerouslySetInnerHTML={{ __html: col.data.num }}
                ></td>
              </LightTooltip>
            ) : (
              <td
                key={i}
                className={classes.commonNum}
                dangerouslySetInnerHTML={{ __html: col.data.num }}
              ></td>
            );
          case "triple":
            return (
              <td key={i} className={classes.tripleVerticalAlign}>
                <div className={classes.tripleColOverdue}>
                  <div>
                    <div
                      className={classes.tripleNum}
                      dangerouslySetInnerHTML={{ __html: col.data.sub_1 }}
                    ></div>
                    <div
                      className={classes.tripleSub}
                      dangerouslySetInnerHTML={{
                        __html: col.data.sub_1 ? col.data.ds_Sub_1 : "",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={classes.tripleNum}
                      dangerouslySetInnerHTML={{ __html: col.data.sub_2 }}
                    ></div>
                    <div
                      className={classes.tripleSub}
                      dangerouslySetInnerHTML={{
                        __html: col.data.sub_2 ? col.data.ds_Sub_2 : "",
                      }}
                    ></div>
                  </div>
                  <div>
                    <div
                      className={classes.tripleNum}
                      dangerouslySetInnerHTML={{ __html: col.data.sub_3 }}
                    ></div>
                    <div
                      className={classes.tripleSub}
                      dangerouslySetInnerHTML={{ __html: col.data.ds_Sub_3 }}
                    ></div>
                  </div>
                </div>
              </td>
            );
          case "withColor":
            return (
              <td key={i} className={classes.withColorHolder}>
                <div
                  style={{
                    borderBottom: `0.125em solid ${col.data.color}`,
                    backgroundColor: isMouseOn ? col.data.color : white,
                    height: "64px",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    className={classes.withColorNum}
                    style={{
                      color: isMouseOn
                        ? col.data.color === "#FFCC33" ||
                          col.data.color === "#f0f0f0"
                          ? darkGrey
                          : white
                        : darkGrey,
                    }}
                  >
                    {col.data.num}
                  </div>
                  <div
                    className={classes.withColorSub}
                    style={{
                      color: isMouseOn
                        ? col.data.color === "#FFCC33" ||
                          col.data.color === "#f0f0f0"
                          ? darkGrey
                          : black2
                        : bulletinGrey,
                    }}
                  >
                    {col.data.detail}
                  </div>
                </div>
              </td>
            );
          case "empty":
            return <td key={i}></td>;
          default:
            break;
        }
        return "";
      })}
      {getRemainingCols()}
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetExportChecked: (payload) => dispatch(setExportChecked(payload)),
  };
};

const mapStateToProps = (state) => {
  const { alerts } = state.alerts;
  const { bulletin, gaInfo, exportChecked } = state.bulletin;
  return { alerts, bulletin, gaInfo, exportChecked };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Row);
