import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  ChecklistDrawer,
  Header,
  Loading,
  BackToTopButton,
} from "../components";
import { reduxForm, reset } from "redux-form";
import { lightGrey, white } from "../theme/colors";
import { getChecklist } from "../store/actions/checklist";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  cols: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 30px",
  },
  column: {
    flexBasis: "100%",
    [theme.breakpoints.up("md")]: {
      flexBasis: "26.25%",
      width: "300px",
      "&:nth-child(2)": {
        flexBasis: "47.5%",
        width: "542px",
      },
    },
  },
  colName: {
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    fontWeight: "bold",
    color: lightGrey,
    paddingLeft: "30px",
    width: "300px",
    "&:nth-child(2)": {
      width: "542px",
    },
  },
  colNameDivider: {
    width: "95%",
    borderTop: `2px solid ${lightGrey}`,
  },
}));

const Checklist = ({ loading, checklist }) => {
  const classes = useStyles();
  const [currentPageInfo, setCurrentPageInfo] = useState({});

  const getFinalHour = (period) => {
    switch (period) {
      case "Até 9h30":
        return [9, 30, 0];
      case "Até 11h30":
        return [11, 30, 0];
      default:
        return false;
    }
  };

  const getCurrentPageInfo = (type, id) => {
    setCurrentPageInfo({ type, id });
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Header
        isChecklist={true}
        isBulletin={false}
        getCurrentPageInfoMethod={getCurrentPageInfo}
      />
      <div className={classes.cols}>
        <div className={classes.column}>
          {checklist.stages ? (
            <>
              <div className={classes.colName}>PARA FAZER</div>
              <hr className={classes.colNameDivider} />
              {checklist.stages[0].periods.map((i, index) => {
                if (i.itens.length >= 1) {
                  return (
                    <ChecklistDrawer
                      key={index}
                      title={i.period}
                      showClock={false}
                      finalHour={getFinalHour(i.period)}
                      taskList={i.itens}
                      width="300px"
                      currentPage={currentPageInfo}
                    />
                  );
                } else return null;
              })}
            </>
          ) : null}
        </div>
        <div className={classes.column}>
          {checklist.stages ? (
            <>
              <div className={classes.colName}>FAZENDO</div>
              <hr className={classes.colNameDivider} />
              {checklist.stages[1]?.periods.map((i, index) => {
                if (i.itens.length >= 1) {
                  return (
                    <ChecklistDrawer
                      key={index}
                      title={i.period}
                      showClock={
                        i.period === "Até 9h30" || i.period === "Até 11h30"
                      }
                      finalHour={getFinalHour(i.period)}
                      taskList={i.itens}
                      width="542px"
                      currentPage={currentPageInfo}
                    />
                  );
                } else return null;
              })}
            </>
          ) : null}
        </div>
        <div className={classes.column}>
          {checklist.stages ? (
            <>
              <div className={classes.colName}>FEITO</div>
              <hr className={classes.colNameDivider} />
              {checklist.stages[2].periods.map((i, index) => {
                if (i.itens.length >= 1) {
                  return (
                    <ChecklistDrawer
                      key={index}
                      title={i.period}
                      showClock={false}
                      finalHour={false}
                      taskList={i.itens}
                      width="300px"
                      currentPage={currentPageInfo}
                    />
                  );
                } else return null;
              })}
            </>
          ) : null}
        </div>
      </div>
      <BackToTopButton />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetForm: () => dispatch(reset("checklistFilterForm")),
    onGetChecklist: (payload) => dispatch(getChecklist(payload)),
  };
};

const mapStateToProps = (state) => {
  const { loading, checklist } = state.checklist;
  return { loading, checklist };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: "checklistFilterForm",
      enableReinitialize: true,
    })
  )(Checklist)
);
