import React from "react";

const Logo = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 750.2 361"
      style={{ enableBackground: "new 0 0 750.2 361" }}
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#EC0000"
          d="M86.15,136.84H55.28v100.1H32.85v-100.1H0.1c0.23-6.48,0.92-12.94,2.07-19.32h85.92
		C87.82,123.99,87.17,130.44,86.15,136.84z"
        />
        <path
          fill="#EC0000"
          d="M165.67,198.76h-55.89c1.55,20.33,11.21,27.94,28.11,27.94c9.06,0.06,17.95-2.39,25.7-7.07
		c-0.38,4.54-1.13,9.04-2.25,13.45c-6.9,4.14-15.87,5.87-25.19,5.87c-28.11,0-41.74-17.07-41.74-45.54
		c0-24.67,11.56-44.16,37.44-44.16c23.8,0,34.66,15.71,34.66,38.3C166.55,191.3,166.26,195.05,165.67,198.76z M109.95,186.68h41.4
		c-0.17-15.01-5.69-25.19-20-25.19C118.76,161.5,111.34,170.63,109.95,186.68z"
        />
        <path
          fill="#EC0000"
          d="M184.83,117.51c4.77-1.57,9.76-2.33,14.78-2.25v121.66h-14.78V117.51z"
        />
        <path
          fill="#EC0000"
          d="M287.44,198.76h-55.89c1.55,20.33,11.22,27.94,28.12,27.94c9.05,0.06,17.95-2.39,25.69-7.07
		c-0.38,4.54-1.13,9.04-2.24,13.45c-6.9,4.14-15.87,5.87-25.19,5.87c-28.11,0-41.74-17.07-41.74-45.54
		c0-24.67,11.55-44.16,37.43-44.16c23.81,0,34.68,15.71,34.68,38.3C288.32,191.3,288.03,195.05,287.44,198.76z M231.72,186.68h41.4
		c-0.17-15.01-5.69-25.19-20.01-25.19c-12.57,0-19.99,9.14-21.37,25.19H231.72z"
        />
        <path
          fill="#EC0000"
          d="M426.32,178.92v58h-14.83v-55.43c0-13.8-5.87-20.01-20.19-20.01c-6.8,0.05-13.49,1.76-19.48,5
		c1.44,4.22,2.14,8.65,2.07,13.11v57.28h-14.84v-55.38c0-13.8-5.35-20.19-20.71-20.19c-5.7,0.06-11.38,0.81-16.9,2.25v73.38h-14.84
		v-82.51c11.04-3.27,21.39-5.17,32.09-5.17c13.11,0,21.91,2.94,27.42,8.32c8.31-6.04,17.94-8.32,28.64-8.32
		C415.97,149.25,426.32,160.46,426.32,178.92z"
        />
        <path
          fill="#EC0000"
          d="M513.09,198.76h-55.9c1.55,20.33,11.22,27.94,28.13,27.94c9.05,0.06,17.95-2.39,25.7-7.07
		c-0.39,4.54-1.14,9.04-2.24,13.45c-6.9,4.14-15.87,5.87-25.19,5.87c-28.11,0-41.74-17.07-41.74-45.54
		c0-24.67,11.56-44.16,37.43-44.16c23.81,0,34.67,15.71,34.67,38.3C513.97,191.3,513.68,195.05,513.09,198.76z M457.37,186.68h41.4
		c-0.17-15.01-5.69-25.19-20.01-25.19C466.17,161.5,458.75,170.63,457.37,186.68z"
        />
        <path
          fill="#EC0000"
          d="M559.49,226.36c4.4-0.07,8.72-1.19,12.59-3.28c-0.36,4.28-0.93,8.54-1.73,12.77
		c-4.39,2.19-9.24,3.25-14.15,3.1c-15,0-24.83-7.76-24.83-26.4v-84.65c4.78-1.6,9.8-2.36,14.84-2.24v25.66h26.22
		c-0.35,4.49-0.69,8.32-1.38,12.43h-24.84v47.26C546.21,222.05,550.69,226.36,559.49,226.36z"
        />
        <path
          fill="#EC0000"
          d="M634.19,151.15c0,4.31-0.52,9.83-1.39,12.76c-5.21-1.15-10.53-1.67-15.86-1.55c-4.52,0.04-9.03,0.5-13.46,1.39
		v73.18h-14.85v-82.67c9.08-2.97,18.56-4.53,28.11-4.62C622.59,149.53,628.44,150.04,634.19,151.15z"
        />
        <path
          fill="#EC0000"
          d="M645.74,123.72c0-5.87,3.98-9.66,9.49-9.66c5.35,0,9.49,3.8,9.49,9.66c0.05,1.27-0.16,2.53-0.62,3.72
		c-0.46,1.18-1.15,2.26-2.04,3.17c-0.89,0.91-1.95,1.62-3.13,2.1c-1.17,0.48-2.44,0.71-3.7,0.68
		C649.71,133.39,645.74,129.59,645.74,123.72z M647.8,151.32h14.84v85.61H647.8V151.32z"
        />
        <path
          fill="#EC0000"
          d="M750.1,153.91v83.02h-13.65l-0.86-11.38c-4.62,8.32-11.73,13.45-22.42,13.45c-20.01,0-33.26-13.8-33.26-44.5
		c0-27.95,12.42-45.2,41.06-45.2C733.03,149.25,741.82,151.01,750.1,153.91z M735.26,218.58v-55.35c-4.8-1.19-9.72-1.77-14.66-1.73
		c-19.14,0-25.53,13.29-25.53,32.6c0,22.6,8.11,32.6,21.91,32.6c3.45,0.05,6.87-0.65,10.03-2.05c3.15-1.4,5.97-3.46,8.25-6.05
		V218.58z"
        />
        <path
          fill="#222222"
          d="M187.01,251.71c0.5,4.57-0.18,9.2-1.95,13.45c-1.78,4.25-4.6,7.97-8.21,10.83l-92.49,52.35
		c0,0-27.27,15.17-27.86-10.63l0.42-65.6H30.59l-0.17,86.06c0,0,8.32,32.61,37.95,19.73l110.29-67.52c0,0,20.05-15.86,18.38-40.2
		L187.01,251.71z"
        />
        <path
          fill="#222222"
          d="M175.69,53.14L79.6,4.59c0,0-22.53-10.62-37.35,0.77c0,0-11.27,5.3-11.27,25.79l-0.13,67.84h27.02l0.39-61.77
		c0,0,1.78-17.44,25.5-9.1l89.53,39.44c0,0,12.53,4.55,12.53,19.7l0.37,11.78l12.14-0.41C198.31,98.63,204.14,67.56,175.69,53.14z"
        />
        <g>
          <text
            transform="matrix(1 0 0 1 587.7552 312.0616)"
            style={{
              fontFamily: "SantanderHeadline",
              fontWeight: "bold",
              fontStyle: "italic",
              fontSize: "75.3595px",
              letterSpacing: "-1",
            }}
          >
            REDE
          </text>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
