import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";
import auth from "./auth";
import bulletin from "./bulletin";
import checklist from "./checklist";
import profile from "./profile";
import agency from "./agency";
import alerts from "./alerts";
import atm from "./atm";
import indicator from "./indicator";
import kyc from "./kyc";
import storeAvailability from "./storeAvailability";
import nq from "./nq";
import exo from "./exo";
import analytics from "./analytics";
import queues from "./queues";
import reft from "./reft";
import nps from "./nps";
import terms from "./terms";
import manifestation from "./manifestation";
import check from "./check";
import comp from "./comp";

const appReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  auth,
  bulletin,
  checklist,
  profile,
  agency,
  alerts,
  atm,
  indicator,
  kyc,
  storeAvailability,
  nq,
  exo,
  analytics,
  queues,
  reft,
  nps,
  terms,
  manifestation,
  check,
  comp,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
