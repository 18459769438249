import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import {
  Loading,
  Table,
  BulletinSkeleton,
  BackToTopButton,
  Modal,
  Icons,
} from "../../components";
import { HeaderComp } from "../../components/compensacao";
import {
  bulletinGrey,
  santander,
  black,
  white,
  darkGrey,
  mediumGrey,
  lighterGrey,
  black2,
  darkerGrey2,
  turquoise,
  mustard,
} from "../../theme/colors";
import { alertToast } from "../../components/Toast";

import { sendLog } from "../../store/actions/analytics";

import { decrypt } from "../../store/services/crypto";

//import publico from "../assets/img/publico.svg";
import imagemChequeVazio from "../../assets/img/cheque_vazio.png";
import { getBucketInfo } from "../../store/sagas/comp";
import { addIndicatorComment } from "../../store/actions/bulletin";
import ConfirmCheckModal from "./ConfirmCheckModal";
//import confidencial from "../assets/img/confidencial.svg";
//import confidencialRestrito from "../assets/img/confidencial_restrito.svg";
//import secreto from "../assets/img/secreto.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },

  checkInfosContainer: {
    padding: "15px 0 20px 0",
    height: "73px",
    width: "100%",
    backgroundColor: lighterGrey,
    borderTop: `1px solid ${mediumGrey}`,
    borderBottom: `1px solid ${mediumGrey}`,
  },

  infosTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  infoContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    border: `1px solid ${black}`,
    borderRadius: "4px",
    fontFamily: "SantanderMicroText",
    color: darkGrey,
  },

  infoTitle: {
    fontFamily: "SantanderMicroText!important",
    fontSize: "16px !important",
  },
  checkContainer: {
    margin: "15px 0 60px 0",
    display: "flex",
  },

  checkImgsContentContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    gap: "45px",
  },
  checkImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > span": {
      fontFamily: "SantanderMicroText !important",
      color: darkerGrey2,
      fontSize: "16px",
      fontWeight: "bold",
      transform: "rotate(-90deg)",
      whiteSpace: "nowrap",
      paddingTop: "100px",
    },
  },

  checkImgInfos: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "78px 0 0 21px",
    "&  span": {
      fontFamily: "SantanderMicroText !important",
      color: black2,
      fontSize: "16px",
      fontWeight: "bold",
    },
  },

  infoToCopy: {
    fontFamily: "SantanderMicroText !important",
    fontSize: "16px",
    color: black2,
    textDecoration: "underline",
    cursor: "pointer",
  },

  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },

  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
}));

const Act = ({
  gaInfo,
  loading,
  onSendLog,
  bucketInfo,
  sheetPaperInfo,
  onAddAction,
  status,
}) => {
  const classes = useStyles();
  const [showDocText, setShowDocText] = useState(false);
  const ref = useRef(null);
  const [isPanel, setIsPanel] = useState(false);
  const [imagemFrente, setImagemFrente] = useState("");
  const [imagemVerso, setImagemVerso] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalInfos, setModalInfos] = useState({});
  const [actionSuccessStatus, setActionSuccessStatus] = useState();

  useEffect(() => {
    if (status === "[BULLETIN] ADD_INDICATOR_COMMENT_SUCCESS") {
      setOpenModal(true);
    } else if (status === "[BULLETIN] ADD_INDICATOR_COMMENT_ERROR") {
      setOpenModal(false);
    }
  }, [status]);

  const getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };

  useEffect(() => {
    if (bucketInfo) {
      getDecryptImage(bucketInfo?.img_frente_cripto).then((valor) => {
        setImagemFrente(valor);
      });
      getDecryptImage(bucketInfo?.img_verso_cripto).then((valor) => {
        setImagemVerso(valor);
      });
    }
  }, [bucketInfo]);

  const copyValue = async (value, isCrypt = true) => {
    isCrypt
      ? navigator.clipboard.writeText(await decrypt(value))
      : navigator.clipboard.writeText(value);
  };

  // const showValue = async (id, type, cryptoType) => {
  //   let newDocObj = {};
  //   newDocObj[type] = await decrypt(
  //     bucketInfo?.find((x) => x.id === id)[cryptoType]
  //   );

  //   return bucketInfo?.id === id
  //     ? { ...bucketInfo, ...newDocObj }
  //     : bucketInfo;
  // };

  // const hideValue = (id, type) => {
  //   bucketInfo?.id === id
  //     ? { ...bucketInfo, [type]: "" }
  //     : bucketInfo;
  // };

  const getDecryptImage = async (image) => {
    const retorno = await decrypt(image);
    return retorno;
  };

  return (
    <div className={classes.root}>
      <ConfirmCheckModal
        data={modalInfos}
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
      />
      {loading ? <Loading /> : null}
      <div ref={ref} style={{ background: white }}>
        <div className={classes.checkInfosContainer}>
          <div className={classes.infosTitleContainer}>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>UNIORG</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.cd_uniorg_contabil, false);
                  }}
                >
                  {bucketInfo ? bucketInfo?.cd_uniorg_contabil : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>Gerente</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.nm_gerente_cripto);
                  }}
                >
                  {bucketInfo ? bucketInfo?.nm_gerente_mascara : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>Agência</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.nu_ag_cripto);
                  }}
                >
                  {bucketInfo ? bucketInfo?.nu_ag_mascara : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>Conta</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.nu_conta_cripto);
                  }}
                >
                  {bucketInfo ? bucketInfo?.nu_conta_mascara : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>Cliente</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.nm_cliente_cripto);
                  }}
                >
                  {bucketInfo ? bucketInfo?.nm_cliente_mascara : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>CPF/CNPJ</Typography>
              <div
                style={{
                  height: "46px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  className={classes.infoToCopy}
                  onMouseDown={() => {
                    copyValue(bucketInfo?.nu_doc_cripto);
                  }}
                >
                  {bucketInfo ? bucketInfo?.nu_doc_mascara : "--"}
                </Typography>
              </div>
            </div>
            <div className={classes.infoContentContainer}>
              <Typography className={classes.infoTitle}>Atuação</Typography>
              <div className={classes.actionBtns}>
                <Button className={classes.editBtn}>
                  <Icons icon="sign-up" />
                </Button>
                <Tooltip placement="bottom" title={` Pago`}>
                  <Button
                    disabled={Object.keys(bucketInfo).length === 0}
                    className={classes.editBtn}
                    onClick={() => {
                      onAddAction({
                        ind: bucketInfo?.cd_indicador,
                        uniorg: bucketInfo?.cd_uniorg_contabil,
                        aux: bucketInfo?.id_cheque,
                        atuacao: 1,
                      });

                      setModalInfos({
                        nu_cheque: bucketInfo?.nu_cheque,
                        ds_tratamento: "pago",
                        color: turquoise,
                      });
                    }}
                  >
                    <span
                      className={classes.actionButton}
                      style={{
                        padding: "5px 10px",
                        // background:
                        //   bucketInfo?.actionPG === 1 ? turquoise : "none",
                        // color: bucketInfo?.actionPG === 1 ? white : darkGrey,
                      }}
                    >
                      PG
                    </span>
                  </Button>
                </Tooltip>
                <Tooltip placement="bottom" title={` Devolvido`}>
                  <Button
                    disabled={Object.keys(bucketInfo).length === 0}
                    className={classes.editBtn}
                    onClick={() => {
                      onAddAction({
                        ind: bucketInfo?.cd_indicador,
                        uniorg: bucketInfo?.cd_uniorg_contabil,
                        aux: bucketInfo?.id_cheque,
                        atuacao: 2,
                      });

                      setModalInfos({
                        nu_cheque: bucketInfo?.nu_cheque,
                        ds_tratamento: "devolvido",
                        color: santander,
                      });
                    }}
                  >
                    <span
                      className={classes.actionButton}
                      style={{
                        padding: "5px 10px",
                        // background:
                        //   bucketInfo?.actionDEV === 2 ? santander : "none",
                        // color: bucketInfo?.actionDEV === 2 ? white : darkGrey,
                      }}
                    >
                      DEV
                    </span>
                  </Button>
                </Tooltip>

                <Tooltip placement="bottom" title={"Fora do escopo"}>
                  <Button
                    disabled={Object.keys(bucketInfo).length === 0}
                    className={classes.editBtn}
                    onClick={() => {
                      onAddAction({
                        ind: bucketInfo?.cd_indicador,
                        uniorg: bucketInfo?.cd_uniorg_contabil,
                        aux: bucketInfo?.id_cheque,
                        atuacao: 3,
                      });

                      setModalInfos({
                        nu_cheque: bucketInfo?.nu_cheque,
                        ds_tratamento: "fora do escopo",
                        color: mustard,
                      });
                    }}
                  >
                    <div
                      className={classes.actionButton}
                      style={
                        {
                          // background: bucketInfo?.actionX === 3 ? yellow : "none",
                          // color: bucketInfo?.row.actionX === 3 ? white : darkGrey,
                        }
                      }
                    >
                      <Icons icon="close-1px" />
                    </div>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.checkContainer}>
          <div className={classes.checkImgInfos}>
            <div>
              <span>Cheque</span>
              <Typography
                className={classes.infoToCopy}
                onMouseDown={() => {
                  copyValue(bucketInfo?.nu_cheque, false);
                }}
              >
                {bucketInfo?.nu_cheque}
              </Typography>
            </div>
            <div>
              <span>Valor</span>
              <Typography
                className={classes.infoToCopy}
                onMouseDown={() => {
                  copyValue(bucketInfo?.vl_cheque, false);
                }}
              >
                {bucketInfo?.vl_cheque}
              </Typography>
            </div>
            <div>
              <span>Motivo</span>
              <Typography
                className={classes.infoToCopy}
                onMouseDown={() => {
                  copyValue(bucketInfo?.nu_motivo, false);
                }}
              >
                {bucketInfo?.nu_motivo}
              </Typography>
            </div>
            <div>
              <span>Tipo</span>
              <Typography
                className={classes.infoToCopy}
                onMouseDown={() => {
                  copyValue(bucketInfo?.ds_motivo, false);
                }}
              >
                {bucketInfo?.ds_motivo}
              </Typography>
            </div>
          </div>
          <div className={classes.checkImgsContentContainer}>
            <div className={classes.checkImgContainer}>
              <span>Frente do cheque</span>
              {bucketInfo?.img_frente_cripto ? (
                <img
                  src={`data:image/png;base64,${imagemFrente}`}
                  width={800}
                  height={345}
                />
              ) : (
                <img src={imagemChequeVazio} width={800} height={345} />
              )}
            </div>
            <div className={classes.checkImgContainer}>
              <span>Verso do cheque</span>

              {bucketInfo?.img_verso_cripto ? (
                <img
                  src={`data:image/png;base64,${imagemVerso}`}
                  width={800}
                  height={345}
                />
              ) : (
                <img src={imagemChequeVazio} width={800} height={345} />
              )}
            </div>
          </div>
        </div>
      </div>

      <BackToTopButton />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendLog: (payload) => dispatch(sendLog(payload)),
    onAddAction: (payload) => dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { bucketInfo, sheetPaperInfo } = state.comp;
  const { status } = state.bulletin;
  return { bucketInfo, sheetPaperInfo, status };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Act)
);
