import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles, styled } from "@material-ui/styles";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { decrypt } from "../../store/services/crypto";
import { addManifestationAction } from "../../store/actions/manifestation";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  WarningExportModal,
  CommentDrawer,
  QueuesHeatmap,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  darkerGrey2,
  mediumGrey,
  yellow,
  black,
  mustard,
  yellow20,
  mustard10,
  green20,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";
import interno from "../../assets/img/confidencial_restrito.svg";
import CheckModal from "../../components/CheckModal";
import { addIndicatorComment } from "../../store/actions/bulletin";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "5px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "60px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    whiteSpace: "nowrap",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  wordCloud: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    borderRadius: "4px",
    marginBottom: "36px",
    padding: "16px 24px",
  },
  wordCloudContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: white,
    gap: "30px",
    height: "88px",
    boxShadow: "0px 3px 6px #00000029",
  },
  wordCloudTitle: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "13px",
  },
  wordsContainer: {
    fontFamily: "SantanderHeadline !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  placeholderIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px !important",
    marginLeft: "10px",
    position: "absolute",
    right: "2%",
    top: "30%",
    background: white,
    zIndex: "99",
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "SantanderMicroText, sans-serif",
    color: mediumGrey,
    fontWeight: "bold",
    fontSize: "16px",
    paddingTop: "5px",
  },

  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    border: `1px solid ${black}`,
    borderRadius: "4px",
    fontFamily: "SantanderMicroText",
    color: darkGrey,
  },
}));

const convertToDate = (dateString) => {
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

const Manifestations = ({
  match,
  line,
  loading,
  onAddManifestationAction,
  table,
  doughnut,
}) => {
  const classes = useStyles();
  const [dentroPrazosData, setDentroPrazosData] = useState([]);
  const [foraPrazosData, setForaPrazosData] = useState([]);
  const [totaisData, setTotaisData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [manifestationSortModel, setManifestationSortModel] = useState([
    {
      field: "action",
      sort: "asc",
    },
  ]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [origemInputValue, setOrigemInputValue] = useState("");
  const [manifestInputValue, setManifestInputValue] = useState("");
  const [showDocText, setShowDocText] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    let list = [];
    table.map((i, index) => {
      list = [
        ...list,
        {
          id: index,
          uniorg: i.cd_uniorg,
          manifestacao: i.id_manifestacao,
          tipo: i.tipo,
          origem: i.ds_origem,
          nomeCliente: i.nm_cliente_mascara,
          documento: i.nu_doc_mascara,
          familia: i.ds_familia,
          sintoma: i.ds_sintoma_longo,
          prazo: convertToDate(i.dt_prazo_tratamento),
          docTool: "",
          nomeTool: "",
          action: i.bl_atuacao,
          cd_indicador: i.cd_indicador,
          nomeClienteCripto: i.nm_cliente_cripto,
          docCripto: i.nu_doc_cripto,
        },
      ];
    });

    setRows(list);
    setBackupRows(list);
  }, [table]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, line.length]);

  useEffect(() => {
    let dentroPrazosData = [];
    let foraPrazosData = [];
    let totaisData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        dentroPrazosData.push(i.qtde_dentro_prazo);
        foraPrazosData.push(i.qtde_fora_prazo);
        totaisData.push(i.qtde_total);
        periodoData.push(i.dt_status);
      });

    setDentroPrazosData(dentroPrazosData);
    setForaPrazosData(foraPrazosData);
    setTotaisData(totaisData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.qtde_total > 0 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut]);

  const mockDataPieLabel = [
    `Trabalhado (${14}%)`,
    `Neutro (${20}%)`,
    `Detrator (${11}%)`,
    `Promotor (${25}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [
        doughnut.qtde_f_prazo,
        doughnut.qtde_venc_hoje,
        doughnut.qtde_d_prazo,
      ],
      backgroundColor: [santander, yellow, turquoise],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Fora do Prazo`,
      data: foraPrazosData,
      fill: "true",
      borderColor: santander,
      backgroundColor: santander,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Dentro do Prazo`,
      data: dentroPrazosData,
      fill: "true",
      borderColor: turquoise,
      backgroundColor: turquoise,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "bar",
      label: `Total`,
      data: totaisData,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue, inputField) => {
    if (searchValue === "") {
      switch (inputField) {
        case "manifestacao":
          setManifestInputValue(searchValue);
          break;
        case "origem":
          setOrigemInputValue(searchValue);
          break;
      }
      setRows(backupRows);
    } else {
      switch (inputField) {
        case "manifestacao":
          setManifestInputValue(searchValue);
          break;
        case "origem":
          setOrigemInputValue(searchValue);
          break;
      }

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[inputField]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const getUrgencyColor = (prazo) => {
    let currenDate = new Date();

    if (convertDateToBR(currenDate) == convertDateToBR(prazo)) {
      return yellow20;
    } else if (prazo < currenDate) {
      return santander20;
    } else {
      return green20;
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 90,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar a UNIORG</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.uniorg, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "manifestacao",
      headerName: "Manifestação",
      width: 150,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={manifestInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(
                e.target.value.replace(/[^\d/]/, ""),
                "manifestacao"
              )
            }
            placeholder="Manifestação"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>
                    Clique e segure para copiar o número da manifestação
                  </span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.manifestacao, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 130,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.tipo || ""}>
          <Typography
            noWrap
            sx={{ fontFamily: "SantanderMicroText !important" }}
          >
            {params.row.tipo}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "origem",
      headerName: "Origem",
      width: 150,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={origemInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(
                e.target.value.replace(
                  /[^A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/,
                  ""
                ),
                "origem"
              )
            }
            placeholder="Origem"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar a origem</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.origem, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "nomeCliente",
      headerName: "Cliente",
      width: 100,
      headerClassName: "header",
      sortable: false,

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o Nome</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nomeClienteCripto);
                showValue(params.id, "nomeTool", "nomeClienteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "documento",
      headerName: "CPF",
      width: 120,
      headerClassName: "header",
      sortable: false,

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.docTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o CPF</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.docTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.docCripto);
                showValue(params.id, "docTool", "docCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "docTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "docTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "familia",
      headerName: "Família",
      width: 130,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.familia || ""}>
          <Typography
            noWrap
            sx={{ fontFamily: "SantanderMicroText !important" }}
          >
            {params.row.familia}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "sintoma",
      headerName: "Sintoma Longo",
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.sintoma || ""}>
          <Typography
            noWrap
            sx={{ fontFamily: "SantanderMicroText !important" }}
          >
            {params.row.sintoma}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "prazo",
      headerName: "Prazo Tratam.",
      width: 140,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: getUrgencyColor(params.row.prazo),
            padding: "13px",
          }}
        >
          <span>{convertDateToBR(params.row.prazo)}</span>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 120,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                ind: params.row.cd_indicador,
                uniorg: Number(params.row.uniorg),
                aux: params.row.manifestacao,
                atuacao: params.row.action === "1" ? 1 : 0,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddManifestationAction({
                ind: params.row.cd_indicador,
                uniorg: params.row.uniorg,
                aux: params.row.manifestacao,
                atuacao: params.row.action === "0" ? 1 : 0,
              });
            }}
          >
            {params.row.action === "0" ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];

  const copyValue = async (value, isCrypt = true) => {
    isCrypt
      ? navigator.clipboard.writeText(await decrypt(value))
      : navigator.clipboard.writeText(value);
  };

  const showValue = async (id, type, cryptoType) => {
    let newDocObj = {};
    newDocObj[type] = await decrypt(rows.find((x) => x.id === id)[cryptoType]);

    setRows((prevRows) =>
      prevRows.map((row) => {
        return row.id === id ? { ...row, ...newDocObj } : row;
      })
    );
  };

  const hideValue = (id, type) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [type]: "" } : row))
    );
  };

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const customDoughnuteConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataPieLabel,
    doughnutCenterLabel: doughnut.qtde_total,
    withLegend: false,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    withTooltip: false,
    isEXO: true,
    indicatorGoal: 0,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado mensal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: periodoData,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
    customChartHeight: 300,
    customChartPaddingTop: 0,
  };

  return (
    <div className={classes.root}>
      <CheckModal
        open={openCheckModal}
        onClose={() => setOpenCheckModal(!!!openCheckModal)}
      />
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"Manifestacao"}
        modalType="confidencial"
      />
      <Header
        isManifestation={true}
        url={match}
        accordionIsExpanded={isExpanded}
      />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer && getPreviousLink() ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>Manifestações</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {table.length > 0 ? (
              <Typography className={classes.uptime}>
                Última atualização: {table[0].dt_processamento}
              </Typography>
            ) : null}
            <Tooltip placement="bottom" title={"Exportar"}>
              <Button
                onClick={() => setOpenModal(true)}
                sx={{
                  "&:hover": {
                    background: "none",
                    color: "none",
                  },
                }}
              >
                <Icons icon="export-xls" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              style={{
                backgroundColor: isResolve ? lighterGrey : santander10,
              }}
            >
              <div className={classes.chartsContainer}>
                <div className={classes.pieChartContainer}>
                  <div
                    className={classes.bar}
                    style={{ backgroundColor: isResolve ? green : santander }}
                  ></div>
                  <div className={classes.overviewDataContainer}>
                    <div>
                      <Typography
                        sx={{
                          fontFamily: "SantanderHeadline !important",
                          fontWeight: "bold !important",
                          fontSize: "24px !important",
                          color: `${darkGrey} !important`,
                        }}
                      >
                        Situação atual
                      </Typography>

                      <Typography className={classes.overviewDataChart}>
                        Analista: {1}
                      </Typography>
                    </div>
                    <div className={classes.legendContainer}>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: turquoise }}
                        ></div>
                        <Typography className={classes.legend}>
                          Dentro do prazo: {doughnut.qtde_d_prazo} (
                          {doughnut.perc_d_prazo})
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: yellow }}
                        ></div>
                        <Typography className={classes.legend}>
                          Vence Hoje: {doughnut.qtde_venc_hoje} (
                          {doughnut.perc_venc_hoje})
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: santander }}
                        ></div>
                        <Typography className={classes.legend}>
                          Fora do Prazo: {doughnut.qtde_f_prazo} (
                          {doughnut.perc_f_prazo})
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <ChartContainer customConfigs={customDoughnuteConfigs} />
                </div>
                <div className={classes.lineChartsContainer}>
                  <div className={classes.lineChartContainer}>
                    <ChartContainer customConfigs={customLineConfigs} />
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>

      <CommentDrawer
        type="manifestacao"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />

      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={manifestationSortModel}
            changeSortModel={setManifestationSortModel}
            type="manifestacao"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do EXO possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddManifestationAction: (payload) =>
      dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { line, doughnut, table, loading } = state.manifestation;
  const { gaInfo } = state.bulletin;

  return { match: ownProps?.match, line, doughnut, loading, gaInfo, table };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Manifestations)
);
