import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/bulletin";
import * as fromActions from "../actions/bulletin";
import { getToken, logout, getUser } from "../services/auth";
import { setAgencia, getAgencia } from "../services/checklist";
import { getBaseAPI } from "../services/api";
import { setLastSearch, getLastSearch } from "../services/atm";
import { alertToast } from "../../components/Toast";
import { setLastSearchedUser } from "../services/search";

//indicator actions to re-render
import * as fromNPSActions from "../actions/nps";
import * as fromQueuesActions from "../actions/queues";
import * as fromManifestationActions from "../actions/manifestation";
import * as fromKYCActions from "../actions/kyc";
import * as fromNQActions from "../actions/nq";
import * as fromEXOActions from "../actions/exo";
import * as fromReftActions from "../actions/reft";
import * as fromCompActions from "../actions/comp";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getBulletin() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = yield axios.post(`${BASE_API}/boletim`, {
      agencia: 0,
      data: today,
      ga: 0,
      rede: 0,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getBulletinSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Houve um problema ao carregar o boletim.");
    yield put(fromActions.getBulletinError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getBulletinByUrgency() {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = yield axios.post(`${BASE_API}/boletim`, {
      headers: headers,
    });
    if (response.status === 200) {
      let dataByUrgency = [
        {
          name: "ATUAR nestes indicadores: ",
          rows: [],
        },
        {
          name: "ATENÇÃO a estes indicadores",
          rows: [],
        },
        {
          name: "Indicadores normalizados",
          rows: [],
        },
      ];

      for (let i = 0; i < response.data.data.length; i++) {
        for (let j = 0; j < response.data.data[i].rows.length; j++) {
          switch (response.data.data[i].rows[j].color) {
            case 3:
              dataByUrgency[0].rows.push(response.data.data[i].rows[j]);
              break;
            case 2:
              dataByUrgency[1].rows.push(response.data.data[i].rows[j]);
              break;
            case 1:
              dataByUrgency[2].rows.push(response.data.data[i].rows[j]);
              break;
            default:
              break;
          }
        }
      }
      yield put(fromActions.getBulletinUrgencySuccess(dataByUrgency));
    }
  } catch (err) {
    yield put(fromActions.getBulletinUrgencyError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getBulletinBySearch({ payload }) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  let { data, ga, rede, regional, agencia } = payload;
  const oldData = payload.data;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);
  if (!data) {
    data = today;
  } else {
    let day = data.substring(0, 2);
    let year = data.substring(6, 10);
    let month = data.substring(3, 5);

    data = `${year}/${month}/${day}`;
  }

  // if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
  //   agencia = parseInt(getAgencia());
  // }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/boletim`, {
      data,
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setAgencia(agencia);
      setLastSearch({ data: oldData, ga, rede, regional, agencia });
      yield put(fromActions.getBulletinSearchSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    if (err.response.data.errors) {
      yield put(fromActions.getBulletinSearchSuccess(["forbidden"]));
    } else {
      yield put(fromActions.getBulletinSearchError(err));
      alertToast("error", "Houve um problema ao carregar a busca do boletim.");
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* getFilters() {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = yield axios.get(`${BASE_API}/boletim/filtros/`, {
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getFiltersSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Houve um problema ao carregar o boletim.");
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getPersonInfo({ gaId }) {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = yield axios.get(
      `${BASE_API}/Usuario/carteiraAgencias/${gaId}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getPersonInfoSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getPersonInfoError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addIndicatorComment({ payload }) {
  const payloadToSend = {
    headers: headers,
    ind: payload.ind,
    uniorg: payload.uniorg,
    aux: payload.aux || "",
    mtr: parseInt(getUser()),
    com: payload.comment || null,
    cd_opc: payload.category || 0,
    atuacao: payload.atuacao || 0,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/boletim/comentarios/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Atuação registrada com sucesso.");
      yield put(fromActions.addIndicatorCommentSuccess(response.data.data));
      yield put(
        fromActions.getIndicatorComments({
          uniorg: payload.uniorg,
          ind: payload.ind,
          info_aux: payload.aux || "",
        })
      );
      switch (payload.ind) {
        case 1:
          yield put(fromNPSActions.getNpsTable(getLastSearch()));
          yield put(fromNPSActions.getNPSDoughnutChart(getLastSearch()));
          break;
        case 2:
          yield put(
            fromManifestationActions.getManifestationTable(getLastSearch())
          );
          yield put(
            fromManifestationActions.getManifestationDoughnutChart(
              getLastSearch()
            )
          );
          break;
        case 4:
          yield put(fromKYCActions.getKYCAvailability(getLastSearch()));
          yield put(fromKYCActions.getKYCPieChart(getLastSearch()));
          yield put(fromKYCActions.getKYCsLineChart(getLastSearch()));
          break;
        case 5:
          yield put(fromNQActions.getNqTable(getLastSearch()));
          yield put(fromNQActions.getNQDoughnutChart(getLastSearch()));
          break;
        case 6:
          yield put(fromEXOActions.getExoTable(getLastSearch()));
          yield put(fromEXOActions.getEXODoughnutChart(getLastSearch()));
          break;
        case 21:
          yield put(fromReftActions.getReftTable(getLastSearch()));
          yield put(fromReftActions.getReftDoughnut(getLastSearch()));
          break;
        case 19:
        case 20:
          yield put(fromQueuesActions.getQueuesTable(getLastSearch()));
          yield put(fromQueuesActions.getQueuesDoughnutChart(getLastSearch()));
          break;
        case 22:
          yield put(fromCompActions.getTotalTrab());
          break;
        default:
          break;
      }
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addIndicatorCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getIndicatorComments({ payload }) {
  const { ind, uniorg, info_aux } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/boletim/comentarios/`, {
      headers: headers,
      ind,
      uniorg,
      info_aux: info_aux || "",
    });
    if (response.status === 200) {
      yield put(fromActions.getIndicatorCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getIndicatorCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getActionCategories({ ind }) {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  try {
    const response = yield axios.get(
      `${BASE_API}/boletim/motivosAtuacao/${ind}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getActionCategoriesSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getActionCategoriesError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchBulletin() {
  yield takeLatest(fromTypes.GET_BULLETIN, getBulletin);
  yield takeLatest(fromTypes.GET_BULLETIN_SEARCH, getBulletinBySearch);
  yield takeLatest(fromTypes.GET_FILTERS, getFilters);
  yield takeLatest(fromTypes.GET_PERSON_INFO, getPersonInfo);
  yield takeLatest(fromTypes.ADD_INDICATOR_COMMENT, addIndicatorComment);
  yield takeLatest(fromTypes.GET_INDICATOR_COMMENTS, getIndicatorComments);
  yield takeLatest(fromTypes.GET_ACTION_CATEGORIES, getActionCategories);
}
