// @flow

import * as fromTypes from "../types/exo";

export const getExoTable = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_TABLE,
  payload,
});
export const getExoTableSuccess = (payload: array): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_TABLE_SUCCESS,
  payload,
});
export const getExoTableError = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_TABLE_ERROR,
  payload,
});

export const addExoComment = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_COMMENT,
  payload,
});
export const addExoCommentSuccess = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_COMMENT_SUCCESS,
  payload,
});
export const addExoCommentError = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_COMMENT_ERROR,
  payload,
});

export const addExoAction = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_ACTION,
  payload,
});
export const addExoActionSuccess = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_ACTION_SUCCESS,
  payload,
});
export const addExoActionError = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.ADD_EXO_ACTION_ERROR,
  payload,
});

export const getEXODoughnutChart = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_DOUGHNUT,
  payload,
});
export const getEXODoughnutChartSuccess = (
  payload: array
): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_DOUGHNUT_SUCCESS,
  payload,
});
export const getEXODoughnutChartError = (
  payload: any
): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_DOUGHNUT_ERROR,
  payload,
});

export const getExoComments = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_COMMENTS,
  payload,
});
export const getExoCommentsSuccess = (payload: array): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_COMMENTS_SUCCESS,
  payload,
});
export const getExoCommentsError = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_COMMENTS_ERROR,
  payload,
});

export const getEXOLineChart = (payload: object): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_LINE,
  payload,
});
export const getEXOLineChartSuccess = (
  payload: array
): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_LINE_SUCCESS,
  payload,
});
export const getEXOLineChartError = (payload: any): fromTypes.EXOAction => ({
  type: fromTypes.GET_EXO_LINE_ERROR,
  payload,
});
