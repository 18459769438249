import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button, Tooltip } from "@mui/material";

import {
  Loading,
  MainMenu,
  List,
  WarningExportModal,
  Icons,
} from "../components";
import { lighterGrey, darkGrey, boston, white } from "../theme/colors";
import csvIcon from "../assets/icons/csv_icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px 0px",
    minHeight: "100vh",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 40px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "14px !important",
    color: `${darkGrey} !important`,
  },
  btnContainer: {
    marginTop: "120px",
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 0px 0px 0px",
  },
}));

const GAList = ({ loading, match, uniorgList }) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const getType = () => {
    return match.path === "/carterizacao/" ? "selectionList" : "userlist";
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={uniorgList}
        modalType={"confidencial"}
        type={"CARTERIZACAO"}
      />
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              {getType() === "userlist" ? "Gestão de Usuários" : "Carterização"}
            </Typography>
            {getType() === "selectionList" ? (
              <Tooltip placement="bottom" title={"Exportar"}>
                <Button
                  onClick={() => setOpenModal(true)}
                  sx={{
                    "&:hover": {
                      background: "none",
                      color: "none",
                    },
                  }}
                >
                  <Icons icon="export-xls" />
                </Button>
              </Tooltip>
            ) : null}
          </div>

          <List type={getType()} />
        </Grid>
        <Grid item md={4}>
          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              onClick={() => (window.location.href = "/cadastro")}
              sx={{
                height: "48px",
                background: boston,
                color: white,
                fontFamily: "SantanderMicroText",
                textTransform: "none",
                textDecoration: "none",
                display: "block",
                margin: "auto",
                "&:hover": {
                  background: boston,
                  color: white,
                },
              }}
            >
              Cadastrar novo Usuário
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading } = state.auth;
  const { uniorgList } = state.profile;
  return { loading, match: ownProps?.match, uniorgList };
};

export default withRouter(compose(connect(mapStateToProps))(GAList));
