import { makeStyles } from "@material-ui/styles";
import { Button } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { mediumGrey, santander, white } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    heigth: "42px",
    padding: "0 24px",
    gap: "8px",
    "& button": {
      backgroundColor: white,
      color: santander,
      border: `1px solid ${santander}`,
      borderRadius: "21px",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: santander,
        color: white,
      },
    },
    "& > span": {
      height: "24px",
      border: `1px solid ${mediumGrey}`,
      opacity: "0.5",
    },
  },
}));

const NPSFilter = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  const handleSelected = (event, teste) => {
    if (selected) {
      setSelected(false);
      event.target.style.backgroundColor = white;
      event.target.style.color = santander;
    } else {
      event.target.style.backgroundColor = santander;
      event.target.style.color = white;
      setSelected(true);
      console.log("event");
      console.log(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Button value="Geral" onClick={handleSelected} variant="contained">
        Geral
      </Button>
      <Button value="PF" onClick={handleSelected} variant="contained">
        PF
      </Button>
      <Button value="PJ" onClick={handleSelected} variant="contained">
        PJ
      </Button>
      <span></span>
      <Button value="Clássico" onClick={handleSelected} variant="contained">
        Clássico
      </Button>
      <Button value="E1" onClick={handleSelected} variant="contained">
        E1
      </Button>
      <Button value="E1 Digital" onClick={handleSelected} variant="contained">
        E1 Digital
      </Button>
      <Button value="E2" onClick={handleSelected} variant="contained">
        E2
      </Button>
      <Button value="E3" onClick={handleSelected} variant="contained">
        E3
      </Button>
      <Button value="Especial" onClick={handleSelected} variant="contained">
        Especial
      </Button>
      <Button value="Mei" onClick={handleSelected} variant="contained">
        Mei
      </Button>
      <Button value="Select" onClick={handleSelected} variant="contained">
        Select
      </Button>
      <Button value="Van Gogh" onClick={handleSelected} variant="contained">
        Van Gogh
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    // onResetForm: () => dispatch(reset("checklistFilterForm")),
    // onGetChecklist: (payload) => dispatch(getChecklist(payload)),
    // onGetBulletinBySearch: (payload) => dispatch(getBulletinSearch(payload)),
    // onGetPersonInfo: (gaId) => dispatch(getPersonInfo(gaId)),
    // onGetATMAvailability: (payload) => dispatch(getATMAvailability(payload)),
    // onGetATMDoughnutChart: (payload) => dispatch(getATMDoughnutChart(payload)),
    // onGetATMLineChart: (payload) => dispatch(getATMLineChart(payload)),
    // onGetKYCPieChart: (payload) => dispatch(getKYCPieChart(payload)),
    // onGetKYCLineChart: (payload) => dispatch(getKYCsLineChart(payload)),
    // onGetKYCAvailability: (payload) => dispatch(getKYCAvailability(payload)),
    // onGetStoreAvailability: (payload) =>
    //   dispatch(getStoreAvailability(payload)),
    // onGetStoreDoughnutChart: (payload) =>
    //   dispatch(getStoreDoughnutChart(payload)),
    // onGetStoreCalendar: (payload) => dispatch(getStoreCalendarChart(payload)),
  };
};

const mapStateToProps = (state) => {
  // const { loading } = state.checklist;
  // const { gaInfo, filters } = state.bulletin;
  // const { alerts } = state.alerts;
  // const loadingATM = state.atm.loading;
  // return { loading, loadingATM, gaInfo, filters, alerts };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NPSFilter);
