import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/nps";
import * as fromActions from "../actions/nps";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser, getUserLvl } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/Nps/Nps/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getNpsTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Nps.");
    yield put(fromActions.getNpsTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNpsDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Nps/graficoSituacao`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getNPSDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getNPSDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNPSLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Nps/graficoMensal`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getNPSLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getNPSLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addNPSAction({ payload }) {
  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  const payloadToSend = {
    uniorg: payload.uniorg,
    ind: payload.ind,
    mtr: parseInt(getUser()),
    atuacao: payload.action,
    aux: payload.pernumper,
    com: payload.com,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Nps/NpsAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addNpsActionSuccess(response.data.data));
      yield put(fromActions.getNpsTable(getLastSearch()));
      yield put(fromActions.getNPSDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addNpsActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addNPSComment({ payload }) {
  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  const payloadToSend = {
    uniorg: payload.uniorg,
    ind: payload.ind,
    mtr: parseInt(getUser()),
    atuacao: payload.action,
    aux: payload.pernumper,
    com: payload.comentario,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Nps/NpsAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addNpsCommentSuccess(response.data.data));
      yield put(fromActions.getNpsTable(getLastSearch()));
      yield put(
        fromActions.getNpsComments({
          uniorg: payload.uniorg,
          pernumper: payload.pernumper,
          ind: payload.ind,
        })
      );
      yield put(fromActions.getNPSDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addNpsCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNpsComments({ payload }) {
  const { uniorg, pernumper, ind } = payload;

  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  try {
    const response = yield axios.post(`${BASE_API}/Nps/NpsComentarios/`, {
      headers: headers,
      ind: ind,
      uniorg: Number(uniorg),
      info_aux: pernumper,
    });
    if (response.status === 200) {
      yield put(fromActions.getNpsCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getNpsCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchNPS() {
  yield takeLatest(fromTypes.GET_NPS_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_NPS_ACTION, addNPSAction);
  yield takeLatest(fromTypes.ADD_NPS_COMMENT, addNPSComment);
  yield takeLatest(fromTypes.GET_NPS_DOUGHNUT, getNpsDoughnut);
  yield takeLatest(fromTypes.GET_NPS_COMMENTS, getNpsComments);
  yield takeLatest(fromTypes.GET_NPS_LINE, getNPSLine);
}
