// @flow

export const GET_NQ_TABLE = "[NQ] GET_NQ_TABLE";
export const GET_NQ_TABLE_SUCCESS = "[NQ] GET_NQ_TABLE_SUCCESS";
export const GET_NQ_TABLE_ERROR = "[NQ] GET_NQ_TABLE_ERROR";

export const ADD_NQ_COMMENT = "[NQ] ADD_NQ_COMMENT";
export const ADD_NQ_COMMENT_SUCCESS = "[NQ] ADD_NQ_COMMENT_SUCCESS";
export const ADD_NQ_COMMENT_ERROR = "[NQ] ADD_NQ_COMMENT_ERROR";

export const ADD_NQ_ACTION = "[NQ] ADD_NQ_ACTION";
export const ADD_NQ_ACTION_SUCCESS = "[NQ] ADD_NQ_ACTION_SUCCESS";
export const ADD_NQ_ACTION_ERROR = "[NQ] ADD_NQ_ACTION_ERROR";

export const GET_NQ_DOUGHNUT = "[NQ] GET_NQ_DOUGHNUT";
export const GET_NQ_DOUGHNUT_SUCCESS = "[NQ] GET_NQ_DOUGHNUT_SUCCESS";
export const GET_NQ_DOUGHNUT_ERROR = "[NQ] GET_NQ_DOUGHNUT_ERROR";

export const GET_NQ_COMMENTS = "[NQ] GET_NQ_COMMENTS";
export const GET_NQ_COMMENTS_SUCCESS = "[NQ] GET_NQ_COMMENTS_SUCCESS";
export const GET_NQ_COMMENTS_ERROR = "[NQ] GET_NQ_COMMENTS_ERROR";

export const GET_NQ_LINE = "[NQ] GET_NQ_LINE";
export const GET_NQ_LINE_SUCCESS = "[NQ] GET_NQ_LINE_SUCCESS";
export const GET_NQ_LINE_ERROR = "[NQ] GET_NQ_LINE_ERROR";

type GetNqTable = {
  type: typeof GET_NQ_TABLE,
  payload: object,
};
type GetNqTableSuccess = {
  type: typeof GET_NQ_TABLE_SUCCESS,
  payload: Array,
};
type GetNqTableError = {
  type: typeof GET_NQ_TABLE_ERROR,
  payload: any,
};

type AddNqComment = {
  type: typeof ADD_NQ_COMMENT,
  payload: object,
};
type AddNqCommentSuccess = {
  type: typeof ADD_NQ_COMMENT_SUCCESS,
  payload: Array,
};
type AddNqCommentError = {
  type: typeof ADD_NQ_COMMENT_ERROR,
  payload: any,
};

type AddNqAction = {
  type: typeof ADD_NQ_ACTION,
  payload: object,
};
type AddNqActionSuccess = {
  type: typeof ADD_NQ_ACTION_SUCCESS,
  payload: Array,
};
type AddNqActionError = {
  type: typeof ADD_NQ_ACTION_ERROR,
  payload: any,
};

type GetNqDoughnut = {
  type: typeof GET_NQ_DOUGHNUT,
  payload: object,
};
type GetNqDoughnutSuccess = {
  type: typeof GET_NQ_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetNqDoughnutError = {
  type: typeof GET_NQ_DOUGHNUT_ERROR,
  payload: any,
};

type GetNqComments = {
  type: typeof GET_NQ_COMMENTS,
  payload: object,
};
type GetNqCommentsSuccess = {
  type: typeof GET_NQ_COMMENTS_SUCCESS,
  payload: Array,
};
type GetNqCommentsError = {
  type: typeof GET_NQ_COMMENTS_ERROR,
  payload: any,
};

type GetNQLineChart = {
  type: typeof GET_NQ_LINE_CHART,
  payload: object,
};
type GetNQLineChartSuccess = {
  type: typeof GET_NQ_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetNQLineChartError = {
  type: typeof GET_NQ_LINE_CHART_ERROR,
  payload: any,
};

export type NQAction =
  | GetNqTable
  | GetNqTableSuccess
  | GetNqTableError
  | AddNqComment
  | AddNqCommentSuccess
  | AddNqCommentError
  | AddNqAction
  | AddNqActionSuccess
  | AddNqActionError
  | GetNqDoughnut
  | GetNqDoughnutSuccess
  | GetNqDoughnutError
  | GetNqComments
  | GetNqCommentsSuccess
  | GetNqCommentsError
  | GetNQLineChart
  | GetNQLineChartSuccess
  | GetNQLineChartError;
