import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  Loading,
  Table,
  Header,
  BulletinSkeleton,
  BackToTopButton,
  Modal,
} from "../components";
import {
  bulletinGrey,
  santander,
  black,
  white,
  darkGrey,
  mediumGrey,
} from "../theme/colors";
import { toBlob } from "html-to-image";
import { alertToast } from "../components/Toast";

import { sendLog } from "../store/actions/analytics";

import exportXLSX from "../utils/getExportXLS";

//import publico from "../assets/img/publico.svg";
import interno from "../assets/img/interno.svg";
//import confidencial from "../assets/img/confidencial.svg";
//import confidencialRestrito from "../assets/img/confidencial_restrito.svg";
//import secreto from "../assets/img/secreto.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  btnsHolder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignContent: "center",
    alignItems: "center",
    paddingLeft: "1em",
  },
  btnRaio: {
    borderRadius: "50% !important",
    width: "3em !important",
    height: "3em !important",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    background: white,
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  submitBtn: {
    height: "48px !important",
    width: "85px !important",
    background: `${santander} !important`,
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    fontWeight: "400 !important",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  provisionalPlaceInputs: {
    backgroundColor: white,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "0em 1em 0em 1em",
    marginBottom: "2.500em",
  },
  tooltip: {
    padding: "1em",
    fontFamily: "SantanderMicroText",
  },
  tooltipTitle: {
    color: santander,
    fontWeight: "bold",

    fontSize: "18px",
  },
  tooltipHr: {
    border: `1px solid ${santander}`,
  },
  tooltipInfo: {
    padding: "1em 0em",
    color: darkGrey,
    lineHeight: "1.4em",
    "& span": {
      fontWeight: "bold",
    },
  },
  tooltipShops: {
    color: darkGrey,
    "& span": {
      fontWeight: "bold",
    },
  },
  footerInfoContainer: {
    marginTop: "1.5em",
    marginBottom: "1em",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const mailBody =
  "Bom dia,%0D%0A%0D%0ACompartilho o BOLETIM DIÁRIO da sua loja contendo indicadores de EXPERIÊNCIA DO CLIENTE e QUALIDADE para apoio na sua gestão.%0D%0A%0D%0AOs indicadores aqui apresentados são um diagnóstico de sua loja com a fotografia de ontem e hoje.%0D%0A%0D%0AVamos JUNTOS atuar em todos estes indicadores de forma que tenhamos nossa loja referência em Satisfação para os nossos clientes, Qualidade e Resultados Sustentáveis!%0D%0A%0D%0ADúvidas estou à disposição.%0D%0A%0D%0A";

const mailBodyReft =
  "Atenção! Por favor note que anexo ao email segue arquivo com as novas ocorrências de REFT - Devolvidas.";

const Bulletin = ({ bulletin, gaInfo, loading, onSendLog, reftTable }) => {
  const classes = useStyles();
  const [showDocText, setShowDocText] = useState(false);
  const ref = useRef(null);
  const [isPanel, setIsPanel] = useState(false);
  const [currentPageInfo, setCurrentPageInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [exportFormFields, setExportFormFields] = useState({});
  const [reftNum, setReftNum] = useState(0);

  useEffect(() => {
    return bulletin.gruposIndicadores
      ? setReftNum(bulletin.gruposIndicadores[1].rows[3].cols[2].data.num)
      : null;
  }, [bulletin]);

  const openExportModal = (formFields) => {
    setExportFormFields(formFields);
    if (
      formFields.agencia &&
      bulletin.gruposIndicadores[1].rows[3].cols[2].data.num > 0
    ) {
      setOpenModal(true);
    } else getPageScreenshot(false, formFields);
  };

  const getPageScreenshot = (shouldExportXls, formFields) => {
    setOpenModal(false);
    if (ref.current === null) {
      return;
    }
    if (shouldExportXls) exportXLSX(reftTable, "interno", "REFT", gaInfo);
    toBlob(ref.current, { cacheBust: true, pixelRatio: 0.75 })
      .then((dataUrl) => {
        copyImage(dataUrl, shouldExportXls, formFields);
      })
      .catch((err) => {
        onSendLog({
          info: "ERRO: Erro ao tirar screenshot do boletim para o export.",
        });
        console.log(err);
      });
  };

  const getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };

  async function copyImage(blob, shouldExportXls, formFields) {
    try {
      if (window.document.hasFocus()) {
        const item = new window.ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);

        let subject = `Boletim Diário - ${
          formFields.agencia ? `Agência ${formFields.agencia} -` : ""
        } ${formFields.data ? formFields.data : getCurrentDate()} `;
        const emailLink = document.createElement("a");
        emailLink.href = `mailto:?subject=${subject}&body=${
          shouldExportXls ? mailBody + mailBodyReft : mailBody
        }`;
        emailLink.click();
        alertToast(
          "info",
          "Boletim copiado para a Área de transferência. Cole no email."
        );
        onSendLog({
          info: `"transação":"export de boletim";"indicador":"Boletim";"confidencialidade":"Interno"`,
        });
      } else {
        alertToast(
          "error",
          "Não foi possível copiar o boletim. Mantenha a aba aberta e tente novamente."
        );
        onSendLog({
          info: "ERRO: Erro ao tirar screenshot do boletim para o export (usuário tirou o foco da página).",
        });
      }
    } catch (error) {
      console.log(error);
      onSendLog({
        info: "ERRO: Erro ao tirar screenshot do boletim para o export.",
      });
      alertToast(
        "error",
        "Não foi possível copiar o boletim. Tente novamente."
      );
    }
  }

  const documentTexts = {
    public:
      "Qualquer dado e informação que possam ser obtidos através de outros canais externos ou que tenham sido oficialmente publicados e distribuídos ao público em geral pelo banco, como material de marketing, informações sobre produtos, informações sobre o site Santander, informações coletadas de fontes públicas, informações publicadas pelo santander e etc.",
    internal:
      "Qualquer dado publicado por meio de canais internos e disponíveis para todos os funcionários do banco, como comunicados de mudanças organizacionais, comunicados departamentais, comunicados exclusivos aos funcionários Santander e etc.",
    confidential:
      "Qualquer dado classificado como regulamentos externos de proceção de dados, como dados pessoais, dados de RH, dados não financeiros de Clientes, atendimento, interações com Clientes e etc.",
    confidentialRestrict:
      "Qualquer dados que reflita conhecimento do Santander, obtido como resultado de atividades comerciais, de análise ou pesquisa, que pressupõem uma vantagem competitiva para o banco, como dados de transações financeiras de Clientes, posições e pontuação de crédito, dados confidenciais de private banking / SCIB, relacionamentos comerciais com Clientes e etc.",
    secret:
      "Qualquer dado que descreva a estratégia do Santander, como aquisição / fusão, documentação sensível do conselho, resultados financeiros não publicados, questões jurídicas altamente sensíveis etc, ou que possam gerar uma ameaça à segurança das atividades e operações do banco, como os planos de contingência e detalhes de infraestrutura do Santander e etc.",
  };

  const getCurrentPageInfo = (type, id) => {
    setCurrentPageInfo({ type, id });
  };

  const ExportModalSubtitle = () => (
    <>
      A{" "}
      <span>
        UNIORG {exportFormFields?.agencia} possui {reftNum} novas ocorrências
      </span>{" "}
      de REFT-Devolvidas. Faça download do{" "}
      <a onClick={() => getPageScreenshot(true)}>arquivo XLS</a>, em seguida{" "}
      <span>envie como anexo no e-mail</span> do Boletim Diário.
      <Typography
        sx={{
          fontFamily: "SantanderMicroText !important",
          fontSize: "16px !important",
          color: `${darkGrey} !important`,
          marginTop: "12px",
        }}
      >
        Obs.: você está prestes a baixar um arquivo classificado como:
      </Typography>
      <Typography
        sx={{
          fontFamily: "SantanderMicroText !important",
          fontWeight: "bold !important",
          fontSize: "16px !important",
          color: `${darkGrey} !important`,
          marginTop: "8px",
          marginLeft: "20px",
        }}
      >
        Interno
      </Typography>
      <Typography
        sx={{
          fontFamily: "SantanderMicroText !important",
          fontSize: "16px !important",
          color: `${darkGrey} !important`,
          width: "514px",
          marginLeft: "20px",
        }}
      >
        Informações publicadas em nossos canais internos e disponíveis para
        todos os funcionários do Banco. São informações que, se comprometidas,
        não causariam uma ruptura ou danos significativos à reputação do Grupo,
        seus Clientes ou funcionários. Exemplo: comunicados internos,
        publicações no NOW, compromissos ou alterações organizacionais.
      </Typography>
    </>
  );

  const exportModalBtns = [
    {
      text: "Download XLS",
      type: "main",
      function: () => getPageScreenshot(true, exportFormFields),
    },
    {
      text: "Enviar boletim sem REFT",
      type: "secondary",
      function: () => getPageScreenshot(false, exportFormFields),
    },
  ];

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="Atenção!"
        Subtitle={ExportModalSubtitle}
        buttonList={exportModalBtns}
      />
      <div ref={ref} style={{ background: white }}>
        <Header
          isBulletin={true}
          isChecklist={false}
          isPanel={isPanel}
          getPageScreenshot={openExportModal}
          getCurrentPageInfoMethod={getCurrentPageInfo}
        />
        <div className={classes.tableContainer}>
          {loading ? (
            <BulletinSkeleton />
          ) : (
            <>
              {bulletin[0] === "forbidden" ? (
                <Typography className={classes.confidentialWarn}>
                  A tabela com dados analíticos do boletim possui dados
                  sensíveis que são mostrados apenas à quem tem a(s) agência(s)
                  na carteira.
                </Typography>
              ) : (
                <>
                  {bulletin.gruposIndicadores
                    ? bulletin.gruposIndicadores.map((table, i) => {
                        return (
                          <Table
                            key={i}
                            tableName={table.name}
                            rowData={table.rows}
                            currentPage={currentPageInfo}
                          />
                        );
                      })
                    : null}
                </>
              )}
            </>
          )}
          {isPanel ? null : (
            <div className={classes.footerInfoContainer}>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: mediumGrey,
                }}
              >
                <strong>* Frequência de atualização dos indicadores:</strong>{" "}
                NPS (D -1), Manifestações (D -1), Retorno ao cliente (D -1), KYC
                - Formularios (S -1), NQ - Alertas (D -1), Exo (D -1), REFT –
                Devolvidas (D-1), Disponibilidade - Loja (D -1), Disponibilidade
                - ATM (D -1), Fila Caixa (D-1), Fila Gerência (D-1), Indicadores
                CheckList (D -1), Contratos (D -1), KYC - Q.A. (D -1), Renda x
                Faturamento (D -1), Expurgo de talões (D -1), Net Curso (D -1),
                Book Alertas (D -1) e Auditoria (D -1).
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: mediumGrey,
                  marginTop: "12px",
                }}
              >
                <strong>Legenda:</strong> D (Frequência diária); S (Frequência
                semanal); M (Frequência mensal).
              </Typography>
            </div>
          )}
        </div>
      </div>
      {isPanel ? null : (
        <div
          className={classes.fixedBottom}
          onClick={() => setShowDocText(!!!showDocText)}
        >
          <div>
            <img src={interno} alt="" />
            Documento Interno
          </div>
          {showDocText ? (
            <p className={classes.docText}>{documentTexts.internal}</p>
          ) : null}
        </div>
      )}
      <BackToTopButton />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendLog: (payload) => dispatch(sendLog(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { loading, bulletin, filters, gaInfo } = state.bulletin;
  const reftTable = state.reft.table;
  return {
    loading,
    bulletin,
    filters,
    gaInfo,
    reftTable,
    match: ownProps?.match,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: "filterForm",
      enableReinitialize: true,
    })
  )(Bulletin)
);
