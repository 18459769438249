import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/exo";
import * as fromActions from "../actions/exo";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser, getUserLvl } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/Exo/exo/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getExoTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Exo.");
    yield put(fromActions.getExoTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getExoDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Exo/graficoSituacao`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getEXODoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getEXODoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getEXOLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Exo/graficoMensal`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getEXOLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getEXOLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addEXOAction({ payload }) {
  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  const payloadToSend = {
    nu_Documento: payload.nuDoc,
    nu_Prop: payload.nu_Prop,
    nu_Cont_Ag: payload.nu_Cont_Ag,
    Tp_Cliente: payload.tpPerson,
    Cd_Uniorg: payload.uniorg,
    Cd_Ga: parseInt(getUser()),
    Ds_Pendencia: payload.finalProduct,
    Dt_Pendencia: formatDate,
    St_Pendencia: payload.urgStatus,
    Comentario: null,
    Bl_Atuacao: payload.action,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Exo/exoAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addExoActionSuccess(response.data.data));
      yield put(fromActions.getExoTable(getLastSearch()));
      yield put(fromActions.getEXODoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addExoActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addEXOComment({ payload }) {
  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  const payloadToSend = {
    nu_Documento: payload.nuDoc,
    nu_Prop: payload.nu_Prop,
    nu_Cont_Ag: payload.nu_Cont_Ag,
    tp_Cliente: payload.tpPerson,
    Cd_Uniorg: payload.uniorg,
    Cd_Ga: parseInt(getUser()),
    Ds_Pendencia: payload.finalProduct,
    Dt_Pendencia: formatDate,
    St_Pendencia: payload.urgStatus,
    Comentario: payload.comentario,
    Bl_Atuacao: 2,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Exo/exoAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addExoCommentSuccess(response.data.data));
      yield put(fromActions.getExoTable(getLastSearch()));
      yield put(
        fromActions.getExoComments({
          uniorg: payload.uniorg,
          nuDoc: payload.nuDoc,
          finalProduct: payload.finalProduct,
          date: formatDate,
        })
      );
      yield put(fromActions.getEXODoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addExoCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getExoComments({ payload }) {
  const { uniorg, nuDoc, finalProduct } = payload;

  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  try {
    const response = yield axios.post(`${BASE_API}/Exo/exoComentarios/`, {
      headers: headers,
      Cd_Uniorg: uniorg,
      Nu_Documento: nuDoc,
      ds_Pendencia: finalProduct,
      Dt_Pendencia: formatDate,
    });
    if (response.status === 200) {
      yield put(fromActions.getExoCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getExoCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchEXO() {
  yield takeLatest(fromTypes.GET_EXO_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_EXO_ACTION, addEXOAction);
  yield takeLatest(fromTypes.ADD_EXO_COMMENT, addEXOComment);
  yield takeLatest(fromTypes.GET_EXO_DOUGHNUT, getExoDoughnut);
  yield takeLatest(fromTypes.GET_EXO_COMMENTS, getExoComments);
  yield takeLatest(fromTypes.GET_EXO_LINE, getEXOLine);
}
