import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/indicator";
import * as fromActions from "../actions/indicator";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";

const BASE_API = `${getBaseAPI()}`;

export function* getIndicatorInfo({ payload }) {
  let { type, id } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmDisponibilidades`,
      { type, id }
    );
    if (response.status === 200) {
      yield put(fromActions.getIndicatorInfoSuccess(response.data.data[0]));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getIndicatorInfoError(err));
  }
}

export function* getCommentsList({ payload }) {
  let { uniorg, numEquip } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmComentarios`,
      {
        uniorg,
        Nu_Equip: numEquip,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getCommentsError(err));
  }
}

export function* registerComment({ payload }) {
  let { uniorg, numEquip, matricula, evento, comentario } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/AtmDisponibilidade/atmComentarios/add`,
      {
        uniorg,
        Nu_Equip: numEquip,
        Cd_Ga: matricula,
        Ds_Evento: evento,
        Comentario: comentario,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.registerCommentSuccess(response.data.data));
      alertToast("success", "Comentário registrado com sucesso!");
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível registrar o comentário");
    yield put(fromActions.registerCommentError(err));
  }
}

export function* watchIndicator() {
  yield takeLatest(fromTypes.REGISTER_COMMENT, registerComment);
  yield takeLatest(fromTypes.GET_COMMENTS_LIST, getCommentsList);
  yield takeLatest(fromTypes.GET_INDICATOR_INFO, getIndicatorInfo);
}
