// @flow

import * as fromTypes from "../types/auth";

export const signIn = (payload: object): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN,
  payload,
});
export const signInSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN_SUCCESS,
  payload,
});
export const signInError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN_ERROR,
  payload,
});

export const SendPassRequest = (payload: object): fromTypes.AuthAction => ({
  type: fromTypes.SEND_PASS_REQUEST,
  payload,
});
export const SendPassRequestSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SEND_PASS_SUCCESS,
  payload,
});
export const SendPassRequestError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SEND_PASS_ERROR,
  payload,
});

export const RegisterNewPassword = (payload: object): fromTypes.AuthAction => ({
  type: fromTypes.REGISTER_NEW_PASS,
  payload,
});
export const RegisterNewPasswordSuccess = (
  payload: any
): fromTypes.AuthAction => ({
  type: fromTypes.REGISTER_NEW_PASS_SUCCESS,
  payload,
});
export const RegisterNewPasswordError = (
  payload: any
): fromTypes.AuthAction => ({
  type: fromTypes.REGISTER_NEW_PASS_ERROR,
  payload,
});
