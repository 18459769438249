// @flow

import * as fromTypes from "../types/check";

type State = {
  loading: boolean,
  doughnut: object,
  line: array,
  table: array,
  checkComments: array,
  images: object,
};

export const initialState = {
  loading: false,
  table: [],
  doughnut: {},
  line: [],
  checkComments: [],
  images: {},
};

const setLoading = (state, loading) => ({ ...state, loading });

const setTable = (state, { payload }) => ({
  ...state,
  table: payload,
  loading: false,
});

const setDoughnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  checkComments: payload,
  loading: false,
});

const setLine = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const setImages = (state, { payload }) => ({
  ...state,
  images: payload,
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.CheckAction
) => {
  switch (action.type) {
    case fromTypes.GET_CHECK_TABLE:
    case fromTypes.ADD_CHECK_COMMENT:
    case fromTypes.ADD_CHECK_ACTION:
    case fromTypes.GET_CHECK_DOUGHNUT:
    case fromTypes.GET_CHECK_COMMENTS:
    case fromTypes.GET_CHECK_LINE_CHART:
    case fromTypes.GET_CHECK_IMAGES:
      return setLoading(state, true);
    // success
    case fromTypes.ADD_CHECK_COMMENT:
    case fromTypes.ADD_CHECK_ACTION:
      return setLoading(state, true);
    case fromTypes.GET_CHECK_TABLE_SUCCESS:
      return setTable(state, action);
    case fromTypes.GET_CHECK_DOUGHNUT_SUCCESS:
      return setDoughnut(state, action);
    case fromTypes.GET_CHECK_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_CHECK_LINE_CHART_SUCCESS:
      return setLine(state, action);
    case fromTypes.GET_CHECK_IMAGES_SUCCESS:
      return setImages(state, action);
    // error
    case fromTypes.GET_CHECK_TABLE_ERROR:
    case fromTypes.ADD_CHECK_COMMENT_ERROR:
    case fromTypes.ADD_CHECK_ACTION_ERROR:
    case fromTypes.GET_CHECK_DOUGHNUT_ERROR:
    case fromTypes.GET_CHECK_COMMENTS_ERROR:
    case fromTypes.GET_CHECK_LINE_CHART_ERROR:
    case fromTypes.GET_CHECK_IMAGES_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
