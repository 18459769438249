import React from "react";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme) => ({
  tableSkeleton: {
    marginBottom: "40px",
  },
  titleSkeleton: {
    display: "flex",
  },
  titleSkeletonPadding: {
    marginRight: "2em",
  },
  skeletonRect: {
    marginBottom: "5px",
  },
}));

const BulletinSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.tableSkeleton}>
        <div className={classes.titleSkeleton}>
          <Skeleton
            variant="text"
            width={200}
            className={classes.titleSkeletonPadding}
          />
          <Skeleton variant="text" width={100} />
        </div>
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
      </div>
      <div className={classes.tableSkeleton}>
        <div className={classes.titleSkeleton}>
          <Skeleton
            variant="text"
            width={200}
            className={classes.titleSkeletonPadding}
          />
          <Skeleton variant="text" width={100} />
        </div>
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
      </div>
      <div className={classes.tableSkeleton}>
        <div className={classes.titleSkeleton}>
          <Skeleton
            variant="text"
            width={200}
            className={classes.titleSkeletonPadding}
          />
          <Skeleton variant="text" width={100} />
        </div>
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
        <Skeleton
          variant="rect"
          width={1280}
          height={64}
          className={classes.skeletonRect}
        />
      </div>
    </>
  );
};

export default BulletinSkeleton;
