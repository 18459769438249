// @flow

import * as fromTypes from "../types/reft";

export const getReftTable = (payload: object): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_TABLE,
  payload,
});
export const getReftTableSuccess = (
  payload: array
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_TABLE_SUCCESS,
  payload,
});
export const getReftTableError = (payload: any): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_TABLE_ERROR,
  payload,
});

export const addReftAction = (payload: object): fromTypes.ReftAction => ({
  type: fromTypes.ADD_REFT_ACTION,
  payload,
});
export const addReftActionSuccess = (
  payload: any
): fromTypes.ReftAction => ({
  type: fromTypes.ADD_REFT_ACTION_SUCCESS,
  payload,
});
export const addReftActionError = (payload: any): fromTypes.ReftAction => ({
  type: fromTypes.ADD_REFT_ACTION_ERROR,
  payload,
});

export const addReftComment = (payload: object): fromTypes.ReftAction => ({
    type: fromTypes.ADD_REFT_COMMENT,
    payload,
  });
  export const addReftCommentSuccess = (
    payload: any
  ): fromTypes.ReftAction => ({
    type: fromTypes.ADD_REFT_COMMENT_SUCCESS,
    payload,
  });
  export const addReftCommentError = (
    payload: any
  ): fromTypes.ReftAction => ({
    type: fromTypes.ADD_REFT_COMMENT_ERROR,
    payload,
  });

export const getReftDoughnut = (
  payload: object
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_DOUGHNUT,
  payload,
});
export const getReftDoughnutSuccess = (
  payload: array
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_DOUGHNUT_SUCCESS,
  payload,
});
export const getReftDoughnutError = (
  payload: any
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_DOUGHNUT_ERROR,
  payload,
});

export const getReftComments = (payload: object): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_COMMENTS,
  payload,
});
export const getReftCommentsSuccess = (
  payload: array
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_COMMENTS_SUCCESS,
  payload,
});
export const getReftCommentsError = (
  payload: any
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_COMMENTS_ERROR,
  payload,
});

export const getReftLine = (
  payload: object
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_LINE,
  payload,
});
export const getReftLineSuccess = (
  payload: array
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_LINE_SUCCESS,
  payload,
});
export const getReftLineError = (
  payload: any
): fromTypes.ReftAction => ({
  type: fromTypes.GET_REFT_LINE_ERROR,
  payload,
});
