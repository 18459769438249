// @flow
import * as fromTypes from "../types/comp";

type State = {
  loading: boolean,
  bucketInfo: Object,
  sheetPaperInfo: Object,
  workedOutTable: Array,
  doughnut: Object,
  line: Array,
  trab: Object,
};

export const initialState = {
  loading: false,
  bucketInfo: {},
  sheetPaperInfo: {},
  workedOutTable: [],
  doughnut: {},
  line: [],
  trab: {},
};

const getBucketInfo = (state, { payload }) => ({
  ...state,
  bucketInfo: payload,
  loading: false,
});

const getSheetPaperInfo = (state, { payload }) => ({
  ...state,
  sheetPaperInfo: payload,
  loading: false,
});

const getWorkedOutTable = (state, { payload }) => ({
  ...state,
  workedOutTable: payload,
  loading: false,
});

const getDoughnutChart = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const getLineChart = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const getTotalTrab = (state, { payload }) => ({
  ...state,
  trab: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.CompAction) => {
  switch (action.type) {
    case fromTypes.GET_BUCKET_INFO:
    case fromTypes.GET_SHEET_PAPER:
    case fromTypes.GET_WORKED_OUT_TABLE:
    case fromTypes.GET_DOUGHNUT_CHART:
    case fromTypes.GET_LINE_CHART:
    case fromTypes.GET_TOTAL_TRAB:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_BUCKET_INFO_SUCCESS:
      return getBucketInfo(state, action);
    case fromTypes.GET_SHEET_PAPER_SUCCESS:
      return getSheetPaperInfo(state, action);
    case fromTypes.GET_WORKED_OUT_TABLE_SUCCESS:
      return getWorkedOutTable(state, action);
    case fromTypes.GET_DOUGHNUT_CHART_SUCCESS:
      return getDoughnutChart(state, action);
    case fromTypes.GET_LINE_CHART_SUCCESS:
      return getLineChart(state, action);
    case fromTypes.REMOVE_GA_SUCCESS:
      return setLoading(state, true);
    case fromTypes.GET_TOTAL_TRAB_SUCCESS:
      return getTotalTrab(state, action);
    // Error
    case fromTypes.GET_BUCKET_INFO_ERROR:
    case fromTypes.GET_SHEET_PAPER_ERROR:
    case fromTypes.GET_WORKED_OUT_TABLE_ERROR:
    case fromTypes.GET_DOUGHNUT_CHART_ERROR:
    case fromTypes.GET_LINE_CHART_ERROR:
    case fromTypes.REMOVE_GA_ERROR:
    case fromTypes.GET_TOTAL_TRAB_ERROR:
      return setLoading(state, false);

    default:
      return state;
  }
};

export default reducer;
