// @flow

import * as fromTypes from "../types/check";

export const getCheckTable = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_TABLE,
  payload,
});
export const getCheckTableSuccess = (
  payload: array
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_TABLE_SUCCESS,
  payload,
});
export const getCheckTableError = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_TABLE_ERROR,
  payload,
});

export const addCheckComment = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_COMMENT,
  payload,
});
export const addCheckCommentSuccess = (
  payload: any
): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_COMMENT_SUCCESS,
  payload,
});
export const addCheckCommentError = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_COMMENT_ERROR,
  payload,
});

export const addCheckAction = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_ACTION,
  payload,
});
export const addCheckActionSuccess = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_ACTION_SUCCESS,
  payload,
});
export const addCheckActionError = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.ADD_CHECK_ACTION_ERROR,
  payload,
});

export const getCheckDoughnutChart = (
  payload: object
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_DOUGHNUT,
  payload,
});
export const getCheckDoughnutChartSuccess = (
  payload: array
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_DOUGHNUT_SUCCESS,
  payload,
});
export const getCheckDoughnutChartError = (
  payload: any
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_DOUGHNUT_ERROR,
  payload,
});

export const getCheckComments = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_COMMENTS,
  payload,
});
export const getCheckCommentsSuccess = (
  payload: array
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_COMMENTS_SUCCESS,
  payload,
});
export const getCheckCommentsError = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_COMMENTS_ERROR,
  payload,
});

export const getCheckLineChart = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_LINE_CHART,
  payload,
});
export const getCheckLineChartSuccess = (
  payload: array
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_LINE_CHART_SUCCESS,
  payload,
});
export const getCheckLineChartError = (
  payload: any
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_LINE_CHART_ERROR,
  payload,
});

export const getCheckImages = (payload: object): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_IMAGES,
  payload,
});

export const getCheckImagesSuccess = (
  payload: object
): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_IMAGES_SUCCESS,
  payload,
});

export const getCheckImagesError = (payload: any): fromTypes.CheckAction => ({
  type: fromTypes.GET_CHECK_IMAGES_ERROR,
  payload,
});
