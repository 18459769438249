// @flow

export const GET_MANIFESTATION_TABLE =
  "[MANIFESTATION] GET_MANIFESTATION_TABLE";
export const GET_MANIFESTATION_TABLE_SUCCESS =
  "[MANIFESTATION] GET_MANIFESTATION_TABLE_SUCCESS";
export const GET_MANIFESTATION_TABLE_ERROR =
  "[MANIFESTATION] GET_MANIFESTATION_TABLE_ERROR";

export const ADD_MANIFESTATION_COMMENT =
  "[MANIFESTATION] ADD_MANIFESTATION_COMMENT";
export const ADD_MANIFESTATION_COMMENT_SUCCESS =
  "[MANIFESTATION] ADD_MANIFESTATION_COMMENT_SUCCESS";
export const ADD_MANIFESTATION_COMMENT_ERROR =
  "[MANIFESTATION] ADD_MANIFESTATION_COMMENT_ERROR";

export const ADD_MANIFESTATION_ACTION =
  "[MANIFESTATION] ADD_MANIFESTATION_ACTION";
export const ADD_MANIFESTATION_ACTION_SUCCESS =
  "[MANIFESTATION] ADD_MANIFESTATION_ACTION_SUCCESS";
export const ADD_MANIFESTATION_ACTION_ERROR =
  "[MANIFESTATION] ADD_MANIFESTATION_ACTION_ERROR";

export const GET_MANIFESTATION_DOUGHNUT =
  "[MANIFESTATION] GET_MANIFESTATION_DOUGHNUT";
export const GET_MANIFESTATION_DOUGHNUT_SUCCESS =
  "[MANIFESTATION] GET_MANIFESTATION_DOUGHNUT_SUCCESS";
export const GET_MANIFESTATION_DOUGHNUT_ERROR =
  "[MANIFESTATION] GET_MANIFESTATION_DOUGHNUT_ERROR";

export const GET_MANIFESTATION_COMMENTS =
  "[MANIFESTATION] GET_MANIFESTATION_COMMENTS";
export const GET_MANIFESTATION_COMMENTS_SUCCESS =
  "[MANIFESTATION] GET_MANIFESTATION_COMMENTS_SUCCESS";
export const GET_MANIFESTATION_COMMENTS_ERROR =
  "[MANIFESTATION] GET_MANIFESTATION_COMMENTS_ERROR";

export const GET_MANIFESTATION_LINE = "[MANIFESTATION] GET_MANIFESTATION_LINE";
export const GET_MANIFESTATION_LINE_SUCCESS =
  "[MANIFESTATION] GET_MANIFESTATION_LINE_SUCCESS";
export const GET_MANIFESTATION_LINE_ERROR =
  "[MANIFESTATION] GET_MANIFESTATION_LINE_ERROR";

type GetManifestationTable = {
  type: typeof GET_MANIFESTATION_TABLE,
  payload: object,
};
type GetManifestationTableSuccess = {
  type: typeof GET_MANIFESTATION_TABLE_SUCCESS,
  payload: Array,
};
type GetManifestationTableError = {
  type: typeof GET_MANIFESTATION_TABLE_ERROR,
  payload: any,
};

type AddManifestationComment = {
  type: typeof ADD_MANIFESTATION_COMMENT,
  payload: object,
};
type AddManifestationCommentSuccess = {
  type: typeof ADD_MANIFESTATION_COMMENT_SUCCESS,
  payload: Array,
};
type AddManifestationCommentError = {
  type: typeof ADD_MANIFESTATION_COMMENT_ERROR,
  payload: any,
};

type AddManifestationAction = {
  type: typeof ADD_MANIFESTATION_ACTION,
  payload: object,
};
type AddManifestationActionSuccess = {
  type: typeof ADD_MANIFESTATION_ACTION_SUCCESS,
  payload: Array,
};
type AddManifestationActionError = {
  type: typeof ADD_MANIFESTATION_ACTION_ERROR,
  payload: any,
};

type GetManifestationDoughnut = {
  type: typeof GET_MANIFESTATION_DOUGHNUT,
  payload: object,
};
type GetManifestationDoughnutSuccess = {
  type: typeof GET_MANIFESTATION_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetManifestationDoughnutError = {
  type: typeof GET_MANIFESTATION_DOUGHNUT_ERROR,
  payload: any,
};

type GetManifestationComments = {
  type: typeof GET_MANIFESTATION_COMMENTS,
  payload: object,
};
type GetManifestationCommentsSuccess = {
  type: typeof GET_MANIFESTATION_COMMENTS_SUCCESS,
  payload: Array,
};
type GetManifestationCommentsError = {
  type: typeof GET_MANIFESTATION_COMMENTS_ERROR,
  payload: any,
};

type GetManifestationLineChart = {
  type: typeof GET_MANIFESTATION_LINE_CHART,
  payload: object,
};
type GetManifestationLineChartSuccess = {
  type: typeof GET_MANIFESTATION_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetManifestationLineChartError = {
  type: typeof GET_MANIFESTATION_LINE_CHART_ERROR,
  payload: any,
};

export type ManifestationAction =
  | GetManifestationTable
  | GetManifestationTableSuccess
  | GetManifestationTableError
  | AddManifestationComment
  | AddManifestationCommentSuccess
  | AddManifestationCommentError
  | AddManifestationAction
  | AddManifestationActionSuccess
  | AddManifestationActionError
  | GetManifestationDoughnut
  | GetManifestationDoughnutSuccess
  | GetManifestationDoughnutError
  | GetManifestationComments
  | GetManifestationCommentsSuccess
  | GetManifestationCommentsError
  | GetManifestationLineChart
  | GetManifestationLineChartSuccess
  | GetManifestationLineChartError;
