import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles, styled } from "@material-ui/styles";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { TagCloud } from "react-tagcloud";
import { addKYCAction } from "../../store/actions/kyc";
import { getLastSearchedUser } from "../../store/services/search";
import { getUser } from "../../store/services/auth";
import { decrypt } from "../../store/services/crypto";
import { addNpsAction, addNpsComment } from "../../store/actions/nps";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  WarningExportModal,
  CommentDrawer,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  lightGrey,
  darkerGrey2,
  mediumGrey,
  sky,
  yellow,
  darkSky,
  black,
  mustard,
  yellow20,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";
import NPSFilter from "../../components/NPSFilter";
import interno from "../../assets/img/confidencial_restrito.svg";
import { addIndicatorComment } from "../../store/actions/bulletin";

const wordsMockData = [
  { value: "cobrança indevida", count: 10 },
  { value: "agilidade", count: 20 },
  { value: "atendimento agência", count: 30 },
  { value: "limite", count: 30 },
  { value: "atendimento telefônico", count: 20 },
  { value: "mau atendimento", count: 25 },
  { value: "tempo", count: 10 },
  { value: "canais", count: 30 },
  { value: "outros", count: 40 },
  { value: "atendimento", count: 30 },
  { value: "app way", count: 10 },
  { value: "valor da franquia", count: 10 },
  { value: "produtos", count: 40 },
  { value: "app santander", count: 20 },
  { value: "cartões", count: 20 },
  { value: "id", count: 15 },
  { value: "caixa eletrônico", count: 15 },
  { value: "seguros", count: 12 },
  { value: "empréstimos", count: 30 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "5px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "60px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    whiteSpace: "nowrap",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  wordCloud: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    borderRadius: "4px",
    marginBottom: "36px",
    padding: "16px 24px",
  },
  wordCloudContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: white,
    gap: "30px",
    height: "88px",
    boxShadow: "0px 3px 6px #00000029",
  },
  wordCloudTitle: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "13px",
  },
  wordsContainer: {
    fontFamily: "SantanderHeadline !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  placeholderIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px !important",
    marginLeft: "10px",
    position: "absolute",
    right: "2%",
    top: "30%",
    background: white,
    zIndex: "99",
  },
}));

const NPS = ({ match, line, loading, onAddNpsAction, table, doughnut }) => {
  const classes = useStyles();
  const [promotoresData, setPromotoresData] = useState([]);
  const [neutrosData, setNeutrosData] = useState([]);
  const [detratoresData, setDetratoresData] = useState([]);
  const [trabalhadosData, setTrabalhadosData] = useState([]);
  const [totaisData, setTotaisData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [npsSortModel, setNpsSortModel] = useState([
    {
      field: "action",
      sort: "asc",
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [docInputValue, setDocInputValue] = useState("");
  const [nomeInputValue, setNomeInputValue] = useState("");
  const [segInputValue, setSegInputValue] = useState("");
  const [showDocText, setShowDocText] = useState(false);

  useEffect(() => {
    let list = [];
    table.map((i, index) => {
      list = [
        ...list,
        {
          id: index,
          uniorg: i.cd_uniorg,
          action: i.bl_atuacao,
          date: convertToDate(i.dt_resposta),
          documento: i.nu_doc_mascara,
          nomeCliente: i.nm_cliente_mascara,
          comentarioCliente: i.resp_mascara,
          docCripto: i.nu_doc_cripto,
          nomeClienteCripto: i.nm_cliente_cripto,
          comentCripto: i.resp_cripto,
          segmento: i.tp_segmento,
          nota: i.nota - 0,
          prazo: i.nu_dias,
          docTool: "",
          nomeTool: "",
          commentTool: "",
          pernumper: i.pernumper,
          cdIndicador: i.cd_indicador,
        },
      ];
    });

    setRows(list);
    setBackupRows(list);
  }, [table]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, line.length]);

  useEffect(() => {
    let promotoresData = [];
    let neutrosData = [];
    let detratoresData = [];
    let trabalhadosData = [];
    let totaisData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        promotoresData.push(i.qtde_promotor);
        neutrosData.push(i.qtde_neutro);
        detratoresData.push(i.qtde_detrator);
        trabalhadosData.push(i.qtde_trab);
        totaisData.push(i.qtde_total);
        periodoData.push(i.dt_abrv);
      });

    setPromotoresData(promotoresData);
    setNeutrosData(neutrosData);
    setDetratoresData(detratoresData);
    setTrabalhadosData(trabalhadosData);
    setTotaisData(totaisData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.perc_trab === 100 ? setIsResolve(true) : setIsResolve(false);
  }, [doughnut]);

  const mockDataPieLabel = [
    `Trabalhado (${14}%)`,
    `Neutro (${20}%)`,
    `Detrator (${11}%)`,
    `Promotor (${25}%)`,
  ];

  const doughnutChartConfig = [
    {
      type: "doughnut",
      data: [
        doughnut.qtde_neutro,
        doughnut.qtde_detrator,
        doughnut.qtde_promotor,
      ],
      backgroundColor: [yellow, santander, turquoise],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Trabalhado`,
      data: trabalhadosData,
      fill: "true",
      borderColor: darkerGrey2,
      backgroundColor: darkerGrey2,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Promotores`,
      data: promotoresData,
      fill: "true",
      borderColor: turquoise,
      backgroundColor: turquoise,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Neutros`,
      data: neutrosData,
      fill: "true",
      borderColor: mustard,
      backgroundColor: mustard,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Detratores`,
      data: detratoresData,
      fill: "true",
      borderColor: santander,
      backgroundColor: santander,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "bar",
      label: `Total`,
      data: totaisData,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue, inputField) => {
    if (searchValue === "") {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "nomeCliente":
          setNomeInputValue(searchValue);
          break;
        case "segmento":
          setSegInputValue(searchValue);
          break;
      }
      setRows(backupRows);
    } else {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "nomeCliente":
          setNomeInputValue(searchValue);
          break;
        case "segmento":
          setSegInputValue(searchValue);
          break;
      }

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[inputField]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const getUrgencyColor = (nota) => {
    switch (true) {
      case nota >= 0 && nota < 7:
        return santander20;
      case nota >= 7 && nota <= 8:
        return yellow20;
      case nota > 8:
        return lighterGrey;
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const convertToDate = (dateString) => {
    let d = dateString.split("/");
    let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
    return dat;
  };

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 90,
      headerClassName: "header",
    },
    {
      field: "documento",
      headerName: "CPF/CNPJ",
      width: 180,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={docInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(e.target.value.replace(/[^\d]/, ""), "documento")
            }
            placeholder="CPF/CNPJ"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.docTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o CPF/CNPJ</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.docTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.docCripto);
                showValue(params.id, "docTool", "docCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "docTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "docTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "nomeCliente",
      headerName: "Nome",
      width: 160,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={nomeInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(
                e.target.value.replace(/[^A-Za-z0-9]/, ""),
                "nomeCliente"
              )
            }
            placeholder="Nome"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o Nome</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nomeClienteCripto);
                showValue(params.id, "nomeTool", "nomeClienteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "segmento",
      headerName: "Segmento",
      width: 160,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={segInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(
                e.target.value.replace(/[^A-Za-z0-9]/, ""),
                "segmento"
              )
            }
            placeholder="Segmento"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
    },
    {
      field: "nota",
      headerName: "Nota",
      width: 90,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.nota),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.nota}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Data da avaliação",
      width: 120,
      headerClassName: "header",
      renderCell: (params) => <>{convertDateToBR(params.row.date)}</>,
    },
    {
      field: "prazo",
      headerName: "Prazo (dias)",
      width: 120,
      headerClassName: "header",
    },
    {
      field: "comentarioCliente",
      headerName: "Comentário do cliente",
      width: 203,
      headerClassName: "header",
      sortable: false,

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.commentTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>
                    Clique e segure para copiar o comentario do cliente
                  </span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.commentTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.comentCripto);
                showValue(params.id, "commentTool", "comentCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "commentTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "commentTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 160,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                uniorg: params.row.uniorg,
                pernumper: params.row.pernumper,
                ind: params.row.cdIndicador,
                atuacao: params.row.action ? 1 : 0,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddNpsAction({
                uniorg: params.row.uniorg,
                atuacao: !params.row.action ? 1 : 0,
                aux: params.row.pernumper,
                ind: params.row.cdIndicador,
              });
            }}
          >
            {!params.row.action ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];
  const copyValue = async (value) => {
    navigator.clipboard.writeText(await decrypt(value));
  };

  const showValue = async (id, type, cryptoType) => {
    let newDocObj = {};
    newDocObj[type] = await decrypt(rows.find((x) => x.id === id)[cryptoType]);

    setRows((prevRows) =>
      prevRows.map((row) => {
        return row.id === id ? { ...row, ...newDocObj } : row;
      })
    );
  };

  const hideValue = (id, type) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [type]: "" } : row))
    );
  };

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const wordCloudColor = {
    hue: santander,
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const customDoughnuteConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataPieLabel,
    doughnutCenterLabel: doughnut.perc_trab,
    withLegend: false,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    withTooltip: false,
    isEXO: true,
    indicatorGoal: 100,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado semanal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: periodoData,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"NPS"}
        modalType="confidencial"
      />
      <Header isNPS={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>NPS</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {table.length > 0 ? (
              <Typography className={classes.uptime}>
                Última atualização: {table[0].dt_processamento}
              </Typography>
            ) : null}
            <Tooltip placement="bottom" title={"Exportar"}>
              <Button
                onClick={() => setOpenModal(true)}
                sx={{
                  "&:hover": {
                    background: "none",
                    color: "none",
                  },
                }}
              >
                <Icons icon="export-xls" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              style={{
                backgroundColor: isResolve ? lighterGrey : santander10,
              }}
            >
              <div className={classes.chartsContainer}>
                <div className={classes.pieChartContainer}>
                  <div
                    className={classes.bar}
                    style={{ backgroundColor: isResolve ? green : santander }}
                  ></div>
                  <div className={classes.overviewDataContainer}>
                    <div>
                      <Typography
                        sx={{
                          fontFamily: "SantanderHeadline !important",
                          fontWeight: "bold !important",
                          fontSize: "24px !important",
                          color: `${darkGrey} !important`,
                        }}
                      >
                        Situação atual
                      </Typography>

                      <Typography className={classes.overviewDataChart}>
                        Analista: {doughnut.qtde_analista}
                      </Typography>
                    </div>
                    <div className={classes.legendContainer}>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: turquoise }}
                        ></div>
                        <Typography className={classes.legend}>
                          Promotor: {doughnut.qtde_promotor} (
                          {doughnut.perc_promotor}
                          %)
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: yellow }}
                        ></div>
                        <Typography className={classes.legend}>
                          Neutro: {doughnut.qtde_neutro} ({doughnut.perc_neutro}
                          %)
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: santander }}
                        ></div>
                        <Typography className={classes.legend}>
                          Detrator: {doughnut.qtde_detrator} (
                          {doughnut.perc_detrator}
                          %)
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <ChartContainer customConfigs={customDoughnuteConfigs} />
                </div>
                <div className={classes.lineChartsContainer}>
                  <div className={classes.lineChartContainer}>
                    <ChartContainer customConfigs={customLineConfigs} />
                  </div>
                </div>
              </div>
              {/* <NPSFilter />
              <div className={classes.wordCloud}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.wordCloudContainer}>
                  <div className={classes.wordCloudTitle}>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Resposta
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontSize: "16px !important",
                        color: `${darkGrey} !important`,
                        whiteSpace: "nowrap",
                      }}
                    >
                      Termos mais frequentes
                    </Typography>
                  </div>
                  <div>
                    <TagCloud
                      minSize={10}
                      maxSize={33}
                      colorOptions={wordCloudColor}
                      tags={wordsMockData}
                      className={classes.wordsContainer}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>

      <CommentDrawer
        type="nps"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />

      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={npsSortModel}
            changeSortModel={setNpsSortModel}
            type="nps"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do EXO possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddNpsAction: (payload) => dispatch(addIndicatorComment(payload)),
    onAddNpsComment: (payload) => dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { line, doughnut, table, loading } = state.nps;
  const { gaInfo } = state.bulletin;

  return { match: ownProps?.match, line, doughnut, loading, gaInfo, table };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(NPS)
);
