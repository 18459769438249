import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { MenuItems } from "./";

import logoSmall from "../assets/img/logo_small.svg";

const useStyles = makeStyles((theme) => ({
  root: {},
  logoOpen: {
    marginRight: "16px",
    cursor: "pointer",
  },
  list: {
    padding: "1em !important",
  },
  drawerLogo: {
    margin: "2em 1em",
  },
  listItem: {
    padding: "1em 0.6em !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    fontFamily: "SantanderHeadline",
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    width: "100%",
    paddingLeft: "16px",
  },
}));

const MainMenu = () => {
  const classes = useStyles();
  const [shouldOpen, setShouldOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      (event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")) ||
      event.target.classList.contains("collapse-trigger")
    ) {
      return;
    }

    setShouldOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <MenuItems />
    </Box>
  );

  return (
    <>
      <img
        src={logoSmall}
        alt=""
        onClick={toggleDrawer(true)}
        className={classes.logoOpen}
      />
      <Drawer open={shouldOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </>
  );
};

export default MainMenu;
