import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  lighterGrey,
  mediumGrey,
  white,
  darkGrey,
  black,
  lightGrey,
  santander,
} from "../theme/colors";
import {
  MainMenu,
  FilterField,
  DateField,
  ChartIndex,
  TelemetriaLogo,
} from "./";
import { reduxForm, reset } from "redux-form";
import { getChecklist } from "../store/actions/checklist";
import { getBulletinSearch, getPersonInfo } from "../store/actions/bulletin";
import {
  getATMAvailability,
  getATMDoughnutChart,
  getATMLineChart,
} from "../store/actions/atm";

import {
  getKYCAvailability,
  getKYCPieChart,
  getKYCsLineChart,
} from "../store/actions/kyc";

import {
  getStoreAvailability,
  getStoreCalendarChart,
  getStoreDoughnutChart,
} from "../store/actions/storeAvailability";

import {
  getEXODoughnutChart,
  getEXOLineChart,
  getExoTable,
} from "../store/actions/exo";

import {
  getQueuesDoughnutChart,
  getQueuesTable,
} from "../store/actions/queues";

import {
  getReftDoughnut,
  getReftTable,
  getReftLine,
} from "../store/actions/reft";

import {
  getNPSDoughnutChart,
  getNPSLineChart,
  getNpsTable,
} from "../store/actions/nps";

import {
  getNQDoughnutChart,
  getNQLineChart,
  getNqTable,
} from "../store/actions/nq";

import {
  getManifestationTable,
  getManifestationDoughnutChart,
  getManifestationLineChart,
} from "../store/actions/manifestation";

import {
  getCheckTable,
  getCheckDoughnutChart,
  getCheckLineChart,
} from "../store/actions/check";

import { getAgencia, setAgencia } from "../store/services/checklist";
import { getAlreadySeenAlerts } from "../store/services/alerts";
import { getLastSearch, setLastSearch } from "../store/services/atm";
import { Button } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

import { Icons, Notifications, Loading } from ".";
import { getUser, getUserLvl } from "../store/services/auth";
import { hasNewAlerts } from "../store/services/alerts";
import { isEmpty, isEqual, xorWith } from "lodash";
//import { isStartOfRange } from "@mui/lab/internal/pickers/date-utils";
import {
  removeIdicatorType,
  setSelectedUniorg,
  getSelectedUniorg,
  removeSelectedUniorg,
} from "../utils/indicators";

const useStyles = makeStyles((theme) => ({
  root: {
    "& header": {
      backgroundColor: white,
      padding: "2.188em 1em 0em 1em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "24px",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "2.500em",
      position: "relative",
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
      },
    },
  },
  agenciasContainer: {
    background: lighterGrey,
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: "12px 15px",
    marginBottom: "32px",
  },
  agencia: {
    fontFamily: "SantanderMicroText",
    color: mediumGrey,
    background: white,
    border: `1px solid ${mediumGrey}`,
    borderRadius: "4px",
    fontSize: "14px",
    padding: "5px 16px",
    margin: "0px 15px 8px 15px",
    cursor: "pointer",
  },
  emailBtn: {
    background: `${white} !important`,
    border: `0.063em solid ${black} !important`,
    minWidth: "0em !important",
    width: "48px !important",
    height: "48px !important",
    padding: "0em !important",
    boxShadow: "none !important",
    "&:disabled": {
      opacity: "0.2",
      background: `${white} !important`,
    },
  },
  expand: {
    fontFamily: "SantanderMicroText",
    color: white,
    background: mediumGrey,
    border: `1px solid ${lightGrey}`,
    borderRadius: "4px",
    fontSize: "14px",
    padding: "5px 16px",
    margin: "0px 15px 8px 15px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  bellBtn: {
    cursor: "pointer",
  },
  notificationCircle: {
    position: "absolute",
    zIndex: "999998",
    borderRadius: "50%",
    background: santander,
    height: "8px",
    width: "8px",
  },
  uniorgContainer: {
    position: "relative",
  },
  uniorgAlertCircle: {
    position: "absolute",
    zIndex: "10",
    borderRadius: "50%",
    right: "4%",
    top: "-5%",
    background: santander,
    height: "12px",
    width: "12px",
    border: `2px solid ${white}`,
    color: white,
    fontSize: "10px",
    textAlign: "center",
  },
}));

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = dd + "-" + mm + "-" + yyyy;

let checkToday = new Date();
let checkdd = String(checkToday.getDate()).padStart(2, "0");
let checkmm = String(checkToday.getMonth() + 1).padStart(2, "0");
let checkyyyy = checkToday.getFullYear();
checkToday = checkyyyy + "-" + checkmm + "-" + checkdd;

const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

const indicatorsTypes = [];

const Header = ({
  onGetChecklist,
  onGetBulletinBySearch,
  onGetPersonInfo,
  onGetATMAvailability,
  onGetATMDoughnutChart,
  onGetATMLineChart,
  onGetKYCAvailability,
  onGetKYCPieChart,
  onGetKYCLineChart,
  onGetStoreAvailability,
  onGetStoreDoughnutChart,
  onGetStoreCalendar,
  onGetEXODoughnutChart,
  onGetEXOLineChart,
  onGetEXOTable,
  onGetQueuesDoughnutChart,
  onGetQueuesTable,
  onGetReftTable,
  onGetReftDoughnut,
  onGetReftLine,
  onGetNPSDoughnutChart,
  onGetNPSLineChart,
  onGetNPSTable,
  onGetNQDoughnutChart,
  onGetNQLineChart,
  onGetNQTable,
  onGetManifestationDoughnutChart,
  onGetManifestationLineChart,
  onGetManifestationTable,
  onGetCheckDoughnutChart,
  onGetCheckLineChart,
  onGetCheckTable,
  isChecklist = false,
  isBulletin = false,
  isATM = false,
  isKYC = false,
  isStore = false,
  isEXO = false,
  isNQ = false,
  isQueues = false,
  isReft = false,
  isNPS = false,
  isManifestation = false,
  isCheck = false,
  gaInfo,
  filters,
  alerts,
  bulletin,
  isPanel,
  url,
  loadingATM,
  getPageScreenshot = () => {},
  getCurrentPageInfoMethod = () => {},
  accordionIsExpanded,
}) => {
  const classes = useStyles();
  const [formFields, setFormFields] = useState(
    getUserLvl() === "1"
      ? {
          data: 0,
          ga: getUser(),
          rede: 0,
          agencia: 0,
          regional: 0,
        }
      : {
          data: 0,
          ga: 0,
          rede: 0,
          agencia: 0,
          regional: 0,
        }
  );
  const [exportFields, setExportFields] = useState(
    getUserLvl() === "1"
      ? {
          data: 0,
          ga: getUser(),
          rede: 0,
          agencia: 0,
          regional: 0,
        }
      : {
          data: 0,
          ga: 0,
          rede: 0,
          agencia: 0,
          regional: 0,
        }
  );
  const [redeInfo, setRedeInfo] = useState([]);
  const [regionalInfo, setRegionalInfo] = useState([]);
  const [dataCheck, setDataCheck] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(black);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setAgencia("0");
  }, [formFields.ga, formFields.rede, formFields.agencia, formFields.regional]);

  useEffect(() => {
    if (
      indicatorsTypes.some((item) => document.referrer.includes(item)) &&
      getSelectedUniorg()
    ) {
      getUniorg(getSelectedUniorg());
      setAgencia(getSelectedUniorg());
      isSelectedUniorg(getSelectedUniorg());
    }
  }, [formFields.ga, formFields.rede, formFields.agencia, formFields.regional]);

  useEffect(() => {
    setDataCheck(bulletin?.dt_Check);
  }, [bulletin]);

  useEffect(() => {
    setInterval(() => {
      if (isBulletin) {
        onGetBulletinBySearch(getLastSearch());
        onGetReftTable(getLastSearch());
      }
      if (isATM) onGetATMAvailability(getLastSearch());
      if (isKYC) onGetKYCAvailability(getLastSearch());
      if (isStore) onGetStoreAvailability(getLastSearch());
      if (isNQ) onGetNQTable(getLastSearch());
      if (isEXO) onGetEXOTable(getLastSearch());
      if (isQueues) onGetQueuesTable(getLastSearch());
      if (isReft) onGetReftTable(getLastSearch());
      if (isNPS) onGetNPSTable(getLastSearch());
      if (isManifestation) onGetManifestationTable(getLastSearch());
      if (isCheck) onGetCheckTable(getLastSearch());
    }, 300000);
  }, []);

  useEffect(() => {
    if (typeof filters.gAs !== "undefined") {
      filters.gAs.map((i) => {
        if (
          i.cd_Matricula === parseInt(getUser()) &&
          !isSelected &&
          formFields.rede === 0 &&
          formFields.regional === 0
        ) {
          setFormFields({
            ...formFields,
            ga: parseInt(getUser()),
            rede: 0,
            regional: 0,
            agencia: 0,
          });
        }
      });
    }
  }, [filters, isSelected]);

  useEffect(() => {
    setExportFields(formFields);
    setShowMore(false);
    if (isChecklist && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        onGetChecklist({ agencia: 0 });
      } else {
        onGetPersonInfo(0);
        onGetChecklist(formFields);
      }
    }
    if (isBulletin && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetBulletinBySearch(formFields);
        onGetReftTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
      } else {
        onGetPersonInfo(0);
        onGetBulletinBySearch(formFields);
        onGetReftTable(formFields);
      }
    }
    if (isATM && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetATMAvailability(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetATMLineChart(formFields);
          onGetATMDoughnutChart(formFields);
        }
      } else {
        onGetPersonInfo(0);
        onGetATMAvailability(formFields);
        if (accordionIsExpanded) {
          onGetATMLineChart(formFields);
          onGetATMDoughnutChart(formFields);
        }
      }
    }
    if (isKYC && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetKYCAvailability(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetKYCLineChart(formFields);
          onGetKYCPieChart(formFields);
        }
      } else {
        onGetKYCAvailability(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetKYCLineChart(formFields);
          onGetKYCPieChart(formFields);
        }
      }
    }
    if (isStore && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetStoreAvailability(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetStoreDoughnutChart(formFields);
          onGetStoreCalendar(formFields);
        }
      } else {
        onGetStoreAvailability(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetStoreDoughnutChart(formFields);
          onGetStoreCalendar(formFields);
        }
      }
    }
    if (isEXO && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetEXOTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetEXOLineChart(formFields);
          onGetEXODoughnutChart(formFields);
        }
      } else {
        onGetEXOTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetEXOLineChart(formFields);
          onGetEXODoughnutChart(formFields);
        }
      }
    }
    if (isNQ && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetNQTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          // onGetNQLineChart(formFields);
          onGetNQDoughnutChart(formFields);
        }
      } else {
        onGetNQTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          // onGetNQLineChart(formFields);
          onGetNQDoughnutChart(formFields);
        }
      }
    }
    if (isQueues && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetQueuesTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          //onGetKYCLineChart(formFields);
          onGetQueuesDoughnutChart(formFields);
        }
      } else {
        onGetQueuesTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          //onGetKYCLineChart(formFields);
          onGetQueuesDoughnutChart(formFields);
        }
      }
    }
    if (isReft && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetReftTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          //onGetKYCLineChart(formFields);
          onGetReftDoughnut(formFields);
          onGetReftLine(formFields);
        }
      } else {
        onGetReftTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          //onGetKYCLineChart(formFields);
          onGetReftDoughnut(formFields);
          onGetReftLine(formFields);
        }
      }
    }
    if (isNPS && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetNPSTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetNPSLineChart(formFields);
          onGetNPSDoughnutChart(formFields);
        }
      } else {
        onGetNPSTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetNPSLineChart(formFields);
          onGetNPSDoughnutChart(formFields);
        }
      }
    }
    if (isManifestation && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetManifestationTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetManifestationLineChart(formFields);
          onGetManifestationDoughnutChart(formFields);
        }
      } else {
        onGetManifestationTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetManifestationLineChart(formFields);
          onGetManifestationDoughnutChart(formFields);
        }
      }
    }
    if (isCheck && formFields.data !== 0) {
      if (formFields.ga !== 0 || getUserLvl() === "1") {
        onGetCheckTable(formFields);
        onGetPersonInfo(getUserLvl() === "1" ? getUser() : formFields.ga);
        if (accordionIsExpanded) {
          onGetCheckLineChart(formFields);
          onGetCheckDoughnutChart(formFields);
        }
      } else {
        onGetCheckTable(formFields);
        onGetPersonInfo(0);
        if (accordionIsExpanded) {
          onGetCheckLineChart(formFields);
          onGetCheckDoughnutChart(formFields);
        }
      }
    }
  }, [formFields, accordionIsExpanded]);

  useEffect(() => {
    if (hasNewAlerts() === "true") setHasNewNotifications(santander);
    else return setHasNewNotifications(black);
  }, [alerts, hasNewAlerts]);

  useEffect(() => {
    for (let index = 0; index < alerts.length; index++) {
      if (alerts.length !== 0) {
        if (isBulletin) {
          onGetBulletinBySearch(getLastSearch());
          onGetReftTable(getLastSearch());
        }
        if (isATM) onGetATMAvailability(getLastSearch());
        if (isKYC) onGetKYCAvailability(getLastSearch());
        if (isStore) onGetStoreAvailability(getLastSearch());
        if (isNQ) onGetNQTable(getLastSearch());
        if (isEXO) onGetEXOTable(getLastSearch());
        if (isQueues) onGetQueuesTable(getLastSearch());
        if (isReft) onGetReftTable(getLastSearch());
        if (isNPS) onGetNPSTable(getLastSearch());
        if (isManifestation) onGetManifestationTable(getLastSearch());
        if (isCheck) onGetCheckTable(getLastSearch());
      }
    }
  }, [alerts]);

  useEffect(() => {
    if (
      isATM ||
      isKYC ||
      isStore ||
      isEXO ||
      isQueues ||
      isReft ||
      isNPS ||
      isNQ ||
      isManifestation ||
      isCheck
    ) {
      changeInputValue(
        url?.params.type === "agencia"
          ? "AGÊNCIA"
          : url?.params.type.toUpperCase(),
        url?.params.id
      );
    }
  }, [filters]);

  const getAgenciaBulletin = (agencia) => {
    getCurrentPageInfoMethod("selectedAgency", agencia);
    onGetBulletinBySearch({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });
    onGetReftTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });
  };

  const getAgenciaATM = (agencia) => {
    setAgencia(agencia);
    onGetATMAvailability({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetATMLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetATMDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaKYC = (agencia) => {
    setAgencia(agencia);
    onGetKYCAvailability({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetKYCLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetKYCPieChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaAvailability = (agencia) => {
    setAgencia(agencia);
    onGetStoreAvailability({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetStoreDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
      onGetStoreCalendar({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaNQ = (agencia) => {
    setAgencia(agencia);
    onGetNQTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetNQDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      // onGetNQLineChart({
      //   data: formFields.data,
      //   ga: 0,
      //   rede: 0,
      //   agencia: agencia,
      //   regional: 0,
      // });
    }
  };

  const getAgenciaEXO = (agencia) => {
    setAgencia(agencia);
    onGetEXOTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetEXODoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetEXOLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaQueues = (agencia) => {
    setAgencia(agencia);
    onGetQueuesTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetQueuesDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      // onGetEXOLineChart({
      //   data: formFields.data,
      //   ga: 0,
      //   rede: 0,
      //   agencia: agencia,
      //   regional: 0,
      // });
    }
  };

  const getAgenciaReft = (agencia) => {
    setAgencia(agencia);
    onGetReftTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetReftDoughnut({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetReftLine({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaNPS = (agencia) => {
    setAgencia(agencia);
    onGetNPSTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetNPSDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetNPSLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaManifestation = (agencia) => {
    setAgencia(agencia);
    onGetManifestationTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetManifestationDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetManifestationLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const getAgenciaCheck = (agencia) => {
    setAgencia(agencia);
    onGetCheckTable({
      data: formFields.data,
      ga: 0,
      rede: 0,
      agencia: agencia,
      regional: 0,
    });

    if (accordionIsExpanded) {
      onGetCheckDoughnutChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });

      onGetCheckLineChart({
        data: formFields.data,
        ga: 0,
        rede: 0,
        agencia: agencia,
        regional: 0,
      });
    }
  };

  const changeInputValue = (input, value) => {
    if (isBulletin || isChecklist) {
      getCurrentPageInfoMethod(input, value);
    }

    switch (input) {
      case "CLEAR":
        setFormFields({
          data: formFields.data,
          ga: 0,
          rede: 0,
          agencia: 0,
          regional: 0,
        });
        setRedeInfo([]);
        setRegionalInfo([]);
        setLastSearch({
          data: formFields.data,
          ga: 0,
          rede: 0,
          agencia: 0,
          regional: 0,
        });
        break;
      case "DATA":
        setFormFields({ ...formFields, data: value });
        break;
      case "PERFIL":
        setFormFields({
          ...formFields,
          ga: value,
          rede: 0,
          regional: 0,
          agencia: 0,
        });
        setRedeInfo([]);
        setRegionalInfo([]);
        setLastSearch({
          ...formFields,
          ga: value,
          rede: 0,
          regional: 0,
          agencia: 0,
        });
        break;
      case "REDE":
        setFormFields({
          ...formFields,
          ga: 0,
          rede: value,
          regional: 0,
          agencia: 0,
        });

        if (Object.entries(filters).length !== 0) {
          let agenciasRede = filters.agencias.filter((i) => {
            return i.cd_Rede == value;
          });
          setRedeInfo(agenciasRede);
        }

        setRegionalInfo([]);
        setLastSearch({
          ...formFields,
          ga: 0,
          rede: value,
          regional: 0,
          agencia: 0,
        });
        break;
      case "REGIONAL":
        setFormFields({
          ...formFields,
          ga: 0,
          rede: 0,
          regional: value,
          agencia: 0,
        });

        if (Object.entries(filters).length !== 0) {
          let agenciasRede = filters.agencias.filter((i) => {
            return i.cd_Regional == value;
          });
          setRegionalInfo(agenciasRede);
        }

        setRedeInfo([]);
        setLastSearch({
          ...formFields,
          ga: 0,
          rede: 0,
          regional: value,
          agencia: 0,
        });
        break;
      case "AGÊNCIA":
        setFormFields({
          ...formFields,
          ga: 0,
          rede: 0,
          regional: 0,
          agencia: value,
        });
        setRedeInfo([]);
        setRegionalInfo([]);
        setLastSearch({
          ...formFields,
          ga: 0,
          rede: 0,
          regional: 0,
          agencia: value,
        });
        break;
      default:
        break;
    }
  };

  const getUniorg = (uniorg) => {
    let handleIsSelected;
    if (uniorg === parseInt(getAgencia())) {
      setIsSelected(false);
      handleIsSelected = false;
      setSelectedUniorg(0);
    } else {
      setIsSelected(true);
      handleIsSelected = true;
      setSelectedUniorg(uniorg);
    }

    setExportFields({
      ...formFields,
      agencia: uniorg,
    });

    if (isChecklist) {
      if (handleIsSelected) {
        getCurrentPageInfoMethod("selectedAgency", uniorg);
        onGetChecklist({ agencia: uniorg });
      } else {
        getCurrentPageInfoMethod("selectedAgency", 0);
        onGetChecklist({ agencia: 0 });
      }
    }

    if (isBulletin) {
      if (handleIsSelected) {
        getAgenciaBulletin(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaBulletin(0);
      }
    }

    if (isATM) {
      if (handleIsSelected) {
        getAgenciaATM(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaATM(0);
      }
    }

    if (isKYC) {
      if (handleIsSelected) {
        getAgenciaKYC(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaKYC(0);
      }
    }

    if (isStore) {
      if (handleIsSelected) {
        getAgenciaAvailability(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaAvailability(0);
      }
    }

    if (isEXO) {
      if (handleIsSelected) {
        getAgenciaEXO(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaEXO(0);
      }
    }

    if (isNQ) {
      if (handleIsSelected) {
        getAgenciaNQ(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaNQ(0);
      }
    }
    if (isQueues) {
      if (handleIsSelected) {
        getAgenciaQueues(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaQueues(0);
      }
    }
    if (isReft) {
      if (handleIsSelected) {
        getAgenciaReft(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaReft(0);
      }
    }

    if (isNPS) {
      if (handleIsSelected) {
        getAgenciaNPS(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaNPS(0);
      }
    }

    if (isManifestation) {
      if (handleIsSelected) {
        getAgenciaManifestation(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaManifestation(0);
      }
    }
    if (isCheck) {
      if (handleIsSelected) {
        getAgenciaCheck(uniorg);
      } else {
        setFormFields({ ...formFields, agencia: 0 });
        getAgenciaCheck(0);
      }
    }
  };

  const isSelectedUniorg = (uniorg) => {
    if (isSelected && uniorg === parseInt(getAgencia())) {
      return true;
    } else {
      return false;
    }
  };

  const shouldDisableBtn = () =>
    (formFields.data === 0 || formFields.data === today) &&
    bulletin?.dt_Check !== checkToday;

  const closeNotificationModal = () => {
    setOpenNotifications(false);
    if (hasNewAlerts() === "true") setHasNewNotifications(santander);
    else return setHasNewNotifications(black);
  };

  const checkIfUniorgHasAlerts = (uniorg) => {
    for (let i = 0; i < alerts.length; i++) {
      if (alerts[i].cd_Uniorg === uniorg) return true;
    }
  };

  const getNumberOfAlerts = () => {
    let alertCount = 0;

    for (let i = 0; i < alerts.length; i++) {
      if (
        alerts[i].title === "Disponibilidade - ATM" ||
        alerts[i].title === "KYC - Formulários" ||
        alerts[i].title === "Disponibilidade - Loja"
      )
        alertCount++;
    }
    return alertCount === 0 || alertCount === 1 ? "" : alertCount;
  };

  return (
    <div className={classes.root}>
      <form style={{ background: white }}>
        <header>
          <MainMenu />
          {!isPanel ? (
            <>
              <FilterField
                isChecklist={
                  isChecklist ||
                  isReft ||
                  
                  isNPS ||
                  isNQ ||
                  isManifestation ||
                  isCheck
                }
                isBulletin={isBulletin || isATM || isKYC || isStore || isQueues || isEXO }
                onChangeMethod={changeInputValue}
              />
              <DateField
                isChecklist={
                  isChecklist ||
                  isATM ||
                  isKYC ||
                  isStore ||
                  isEXO ||
                  isQueues ||
                  isReft ||
                  isNPS ||
                  isNQ ||
                  isManifestation ||
                  isCheck
                }
                onChangeMethod={changeInputValue}
              />
              <div
                className={classes.bellBtn}
                onClick={() => setOpenNotifications(true)}
              >
                <Icons icon="bell" customColor={hasNewNotifications} />
              </div>
              <Notifications
                openNotifications={openNotifications}
                onClose={closeNotificationModal}
              />
              {/*hasNewNotifications === santander ? (
                <div
                  className={classes.notificationCircle}
                  style={
                    isBulletin
                      ? { right: "12.5%", top: "50%" }
                      : { right: "7.8%", top: "50%" }
                  }
                ></div>
                ) : null*/}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "280px",
              }}
            >
              <TelemetriaLogo />
            </div>
          )}

          {isBulletin && !isPanel ? (
            <>
              <Button
                className={classes.emailBtn}
                variant="contained"
                onClick={() => getPageScreenshot(exportFields)}
              >
                <Icons icon="email" />
              </Button>

              {/* 
              <Tooltip
              placement="bottom"
              title={
                shouldDisableBtn()
                  ? `Última atualização do Boletim Diário: ${dataCheck || ""}`
                  : ""
              }
              onClick={() =>
                shouldDisableBtn() ? window.location.reload() : null
              }
            >
              <span>
                <Button
                  className={classes.emailBtn}
                  variant="contained"
                  onClick={() => getPageScreenshot(exportFields)}
                  
                >
                  <Icons icon="email" />
                </Button>
              </span>
            </Tooltip>
              */}
            </>
          ) : null}
        </header>
      </form>

      <>
        {gaInfo.length >= 1 && !isPanel ? (
          <div className={classes.agenciasContainer}>
            {gaInfo.map((i, index) => {
              return (
                <>
                  {index < 8 ? (
                    <div className={classes.uniorgContainer} key={index}>
                      <div
                        className={classes.agencia}
                        onClick={() => getUniorg(i.cd_Uniorg)}
                        style={
                          isSelectedUniorg(i.cd_Uniorg)
                            ? { background: darkGrey, color: white }
                            : null
                        }
                      >
                        {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                      </div>
                      {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                        <div className={classes.uniorgAlertCircle}>
                          {getNumberOfAlerts()}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      {showMore ? (
                        <div className={classes.uniorgContainer}>
                          <div
                            key={index}
                            className={classes.agencia}
                            onClick={() => getUniorg(i.cd_Uniorg)}
                            style={
                              isSelectedUniorg(i.cd_Uniorg)
                                ? { background: darkGrey, color: white }
                                : null
                            }
                          >
                            {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                          </div>
                          {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                            <div className={classes.uniorgAlertCircle}></div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              );
            })}
            {gaInfo.length > 8 ? (
              <div
                className={classes.expand}
                onClick={() => setShowMore(!!!showMore)}
              >
                {showMore ? (
                  <>
                    Clique para diminuir <Icons icon="chevron-up-small" />
                  </>
                ) : (
                  `... e mais ${
                    gaInfo.length - 8
                  } agência(s) (clique para ver todas)`
                )}
              </div>
            ) : null}
          </div>
        ) : null}

        {redeInfo.length >= 1 && !isPanel ? (
          <div className={classes.agenciasContainer}>
            {redeInfo.map((i, index) => {
              return (
                <>
                  {index < 8 ? (
                    <div className={classes.uniorgContainer} key={index}>
                      <div
                        className={classes.agencia}
                        onClick={() => getUniorg(i.cd_Uniorg)}
                        style={
                          isSelectedUniorg(i.cd_Uniorg)
                            ? { background: darkGrey, color: white }
                            : null
                        }
                      >
                        {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                      </div>
                      {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                        <div className={classes.uniorgAlertCircle}></div>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      {showMore ? (
                        <div className={classes.uniorgContainer}>
                          <div
                            key={index}
                            className={classes.agencia}
                            onClick={() => getUniorg(i.cd_Uniorg)}
                            style={
                              isSelectedUniorg(i.cd_Uniorg)
                                ? { background: darkGrey, color: white }
                                : null
                            }
                          >
                            {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                          </div>
                          {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                            <div className={classes.uniorgAlertCircle}></div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              );
            })}
            {redeInfo.length > 8 ? (
              <div
                className={classes.expand}
                onClick={() => setShowMore(!!!showMore)}
              >
                {showMore ? (
                  <>
                    Clique para diminuir <Icons icon="chevron-up-small" />
                  </>
                ) : (
                  `... e mais ${
                    redeInfo.length - 8
                  } agência(s) (clique para ver todas)`
                )}
              </div>
            ) : null}
          </div>
        ) : null}

        {regionalInfo.length >= 1 && !isPanel ? (
          <div className={classes.agenciasContainer}>
            {regionalInfo.map((i, index) => {
              return (
                <>
                  {index < 8 ? (
                    <div className={classes.uniorgContainer} key={index}>
                      <div
                        className={classes.agencia}
                        onClick={() => getUniorg(i.cd_Uniorg)}
                        style={
                          isSelectedUniorg(i.cd_Uniorg)
                            ? { background: darkGrey, color: white }
                            : null
                        }
                      >
                        {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                      </div>
                      {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                        <div className={classes.uniorgAlertCircle}></div>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      {showMore ? (
                        <div className={classes.uniorgContainer}>
                          <div
                            key={index}
                            className={classes.agencia}
                            onClick={() => getUniorg(i.cd_Uniorg)}
                            style={
                              isSelectedUniorg(i.cd_Uniorg)
                                ? { background: darkGrey, color: white }
                                : null
                            }
                          >
                            {`${i.cd_Uniorg} - ${i.nm_Agencia}`}
                          </div>
                          {checkIfUniorgHasAlerts(i.cd_Uniorg) ? (
                            <div className={classes.uniorgAlertCircle}></div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              );
            })}
            {regionalInfo.length > 8 ? (
              <div
                className={classes.expand}
                onClick={() => setShowMore(!!!showMore)}
              >
                {showMore ? (
                  <>
                    Clique para diminuir <Icons icon="chevron-up-small" />
                  </>
                ) : (
                  `... e mais ${
                    regionalInfo.length - 8
                  } agência(s) (clique para ver todas)`
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </>

      {isPanel && (
        <ChartIndex
          isBulletin={isBulletin}
          isPanel={isPanel}
          onChangeMethod={changeInputValue}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetForm: () => dispatch(reset("checklistFilterForm")),
    onGetChecklist: (payload) => dispatch(getChecklist(payload)),
    onGetBulletinBySearch: (payload) => dispatch(getBulletinSearch(payload)),
    onGetPersonInfo: (gaId) => dispatch(getPersonInfo(gaId)),
    onGetATMAvailability: (payload) => dispatch(getATMAvailability(payload)),
    onGetATMDoughnutChart: (payload) => dispatch(getATMDoughnutChart(payload)),
    onGetATMLineChart: (payload) => dispatch(getATMLineChart(payload)),
    onGetKYCPieChart: (payload) => dispatch(getKYCPieChart(payload)),
    onGetKYCLineChart: (payload) => dispatch(getKYCsLineChart(payload)),
    onGetKYCAvailability: (payload) => dispatch(getKYCAvailability(payload)),
    onGetStoreAvailability: (payload) =>
      dispatch(getStoreAvailability(payload)),
    onGetStoreDoughnutChart: (payload) =>
      dispatch(getStoreDoughnutChart(payload)),
    onGetStoreCalendar: (payload) => dispatch(getStoreCalendarChart(payload)),
    onGetEXODoughnutChart: (payload) => dispatch(getEXODoughnutChart(payload)),
    onGetEXOLineChart: (payload) => dispatch(getEXOLineChart(payload)),
    onGetEXOTable: (payload) => dispatch(getExoTable(payload)),
    onGetQueuesDoughnutChart: (payload) =>
      dispatch(getQueuesDoughnutChart(payload)),
    onGetQueuesTable: (payload) => dispatch(getQueuesTable(payload)),
    onGetReftTable: (payload) => dispatch(getReftTable(payload)),
    onGetReftDoughnut: (payload) => dispatch(getReftDoughnut(payload)),
    onGetReftLine: (payload) => dispatch(getReftLine(payload)),
    onGetNPSDoughnutChart: (payload) => dispatch(getNPSDoughnutChart(payload)),
    onGetNPSLineChart: (payload) => dispatch(getNPSLineChart(payload)),
    onGetNPSTable: (payload) => dispatch(getNpsTable(payload)),
    onGetNQDoughnutChart: (payload) => dispatch(getNQDoughnutChart(payload)),
    onGetNQLineChart: (payload) => dispatch(getNQLineChart(payload)),
    onGetNQTable: (payload) => dispatch(getNqTable(payload)),
    onGetManifestationDoughnutChart: (payload) =>
      dispatch(getManifestationDoughnutChart(payload)),
    onGetManifestationLineChart: (payload) =>
      dispatch(getManifestationLineChart(payload)),
    onGetManifestationTable: (payload) =>
      dispatch(getManifestationTable(payload)),
    onGetCheckDoughnutChart: (payload) =>
      dispatch(getCheckDoughnutChart(payload)),
    onGetCheckLineChart: (payload) => dispatch(getCheckLineChart(payload)),
    onGetCheckTable: (payload) => dispatch(getCheckTable(payload)),
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.checklist;
  const { gaInfo, filters } = state.bulletin;
  const { alerts } = state.alerts;
  const loadingATM = state.atm.loading;
  return { loading, loadingATM, gaInfo, filters, alerts };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "checklistFilterForm",
    enableReinitialize: true,
  })
)(Header);
