import { Box, Button, Modal } from "@mui/material";
import { connect } from "react-redux";
import { compose } from "redux";
import { getBucketInfo } from "../../store/actions/comp";
import { getUser } from "../../store/services/auth";
import { black, darkGrey, santander, white } from "../../theme/colors";

const ConfirmCheckModal = ({ data, open, onClose, onGetBucketInfo }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: white,
          p: 4,
          padding: "20px 40px 30px 40px",
          minWidth: "510px",
        }}
      >
        <div
          style={{
            fontFamily: "SantanderMicroText !important",
            fontSize: "24px",
            fontWeight: "bold",
            color: `${darkGrey} !important`,
            marginLeft: "16px",
          }}
        >
          <div
            style={{
              fontFamily: "SantanderMicroText",
              color: black,
            }}
          >
            <p>
              Cheque {data.nu_cheque} foi tratado como{" "}
              <span style={{ color: data.color }}>{data.ds_tratamento}</span>
            </p>
          </div>
          <Button
            sx={{
              background: `${santander} !important`,
              fontFamily: "SantanderMicroText!important",
              fontSize: "16px !important",
              width: "194px !important",
              height: "46px !important",
              padding: "0 !important",
              boxShadow: "none !important",
              textTransform: "capitalize",
            }}
            variant="contained"
            onClick={() => {
              onGetBucketInfo(getUser());
              onClose();
            }}
          >
            Carregar novo cheque
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBucketInfo: (payload) => dispatch(getBucketInfo(payload)),
  };
};

const mapStateToProps = (state) => {
  const { bucketInfo, loading } = state.comp;
  return { bucketInfo, loading };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmCheckModal
);
