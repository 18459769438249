import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { addReftAction } from "../../store/actions/reft";
import csvIcon from "../../assets/icons/csv_icon.svg";
import { getUser } from "../../store/services/auth";
import { getLastSearchedUser } from "../../store/services/search";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  CommentDrawer,
  WarningExportModal,
  QueuesHeatmap,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  black,
  lightGrey,
  mediumGrey,
  yellow,
  turquoise20,
  beige,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";

import interno from "../../assets/img/interno.svg";
import { addIndicatorComment } from "../../store/actions/bulletin";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "180px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "75%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "80px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const convertToDate = (dateString) => {
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

const Reft = ({
  match,
  line,
  loading,
  onAddReftAction,
  table,
  gaInfo,
  doughnut,
}) => {
  const classes = useStyles();
  const [devolvidasData, setDevolvidasData] = useState([]);
  const [suprimidasData, setSuprimidasData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exoSortModel, setExoSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);

  useEffect(() => {
    let list = [];
    if (table[0] !== "forbidden") {
      table.map((i, index) => {
        list = [
          ...list,
          {
            id: index + 1,
            cdIndicador: i.cd_indicador,
            uniorg: i.cd_Uniorg,
            caseId: i.cd_Reft,
            sellDate: convertToDate(i.dt_Venda),
            urgStatus: convertToDate(i.dt_Pendencia),
            daysOpen: parseInt(i.qtde_Dias_Aberto),
            status: i.estado,
            product: i.nm_Produto,
            action: i.bl_Atuacao,
          },
        ];
      });
      setRows(list);
      setBackupRows(list);
    }
  }, [table, gaInfo]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut]);

  useEffect(() => {
    let devolvidasData = [];
    let suprimidasData = [];
    let totalData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        devolvidasData.push(i.perc_Devolvidas);
        suprimidasData.push(i.perc_Suprimidas);
        totalData.push(i.perc_Total);
        periodoData.push(i.dt_Status);
      });

    setDevolvidasData(devolvidasData);
    setSuprimidasData(suprimidasData);
    setTotalData(totalData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.qtde_Total > 0 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut]);

  const doughnutLabels = [
    `Devolvidas (${doughnut.perc_Devolvidas})`,
    `Suprimidas (D-1) (${doughnut.perc_Suprimidas})`,
  ];

  const doughnutChartConfig = [
    {
      data: [doughnut.qtde_Devolvidas, doughnut.qtde_Suprimidas],
      backgroundColor: [santander, yellow],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Devolvidas`,
      data: devolvidasData,
      borderColor: santander,
      backgroundColor: santander,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Suprimidas`,
      data: suprimidasData,
      borderColor: yellow,
      backgroundColor: yellow,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },

    {
      type: "bar",
      label: `Total`,
      data: totalData,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setInputValue(searchValue);
      setRows(backupRows);
    } else {
      setInputValue(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const getUrgencyColor = (status) => {
    if (status === "Devolvida") return santander20;
    else if (status === "Suprimida") return beige;
  };

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "caseId",
      headerName: "ID do Case (REFT)",
      width: 160,
      headerClassName: "header",
    },
    {
      field: "sellDate",
      headerName: "Tempo de Espera",
      width: 120,
      headerClassName: "header",
      renderCell: (params) => <>{convertDateToBR(params.row.sellDate)}</>,
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Data da <br /> Venda
        </div>
      ),
    },
    {
      field: "urgStatus",
      headerName: "Tempo de Espera",
      width: 140,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.status),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {convertDateToBR(params.row.urgStatus)}
        </div>
      ),
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Data da <br /> Pendência
        </div>
      ),
    },
    {
      field: "daysOpen",
      headerName: "Tempo de Espera",
      width: 120,
      headerClassName: "header",
      renderHeader: (params) => (
        <div
          style={{
            lineHeight: "16px",
            paddingTop: "12px",
            paddingBottom: "12px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          Dias em <br />
          Aberto
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Estado",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "product",
      headerName: "Produto",
      width: 400,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 140,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                ind: params.row.cdIndicador,
                uniorg: params.row.uniorg,
                aux: params.row.caseId,
                atuacao: params.row.action ? 1 : 0,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddReftAction({
                ind: params.row.cdIndicador,
                uniorg: params.row.uniorg,
                aux: params.row.caseId,
                atuacao: !params.row.action ? 1 : 0,
              });
            }}
          >
            {!params.row.action ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const customPieConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: doughnutLabels,
    doughnutCenterLabel: doughnut.qtde_Total,
    withLegend: false,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    indicatorGoal: 0,
    isEXO: true,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado mensal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: periodoData,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"REFT"}
        modalType="interno"
      />
      <Header isReft={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
        // defaultExpanded={true}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>REFT - Devolvidas</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {table.length > 0 ? (
              <>
                <Typography className={classes.uptime}>
                  Última atualização: {table[0].dt_Atualizacao}
                </Typography>
                <Tooltip placement="bottom" title={"Exportar"}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    sx={{
                      "&:hover": {
                        background: "none",
                        color: "none",
                      },
                    }}
                  >
                    <Icons icon="export-xls" />
                  </Button>
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>

        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: isResolve ? lighterGrey : santander10 }}
            >
              <div className={classes.pieChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {doughnut.qtde_Ga}
                    </Typography>
                  </div>
                  <div className={classes.legendContainer}>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: santander }}
                      ></div>
                      <Typography className={classes.legend}>
                        Devolvidas: {doughnut.qtde_Devolvidas} (
                        {doughnut.perc_Devolvidas})
                      </Typography>
                    </div>

                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: yellow }}
                      ></div>
                      <Typography className={classes.legend}>
                        Suprimidas (D-1): {doughnut.qtde_Suprimidas} (
                        {doughnut.perc_Suprimidas})
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: "none" }}
                      ></div>
                      <Typography className={classes.legend}>
                        Ocorrências que não constam mais como Devolvidas
                      </Typography>
                    </div>
                  </div>
                </div>

                <ChartContainer customConfigs={customPieConfigs} />
              </div>
              <div className={classes.lineChartsContainer}>
                <div className={classes.lineChartContainer}>
                  <ChartContainer customConfigs={customLineConfigs} />
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="reft"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />
      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={exoSortModel}
            changeSortModel={setExoSortModel}
            type="reft"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do EXO possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Interno
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado publicado por meio de canais internos e disponíveis
            para todos os funcionários do banco, como comunicados de mudanças
            organizacionais, comunicados departamentais, comunicados exclusivos
            aos funcionários Santander e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddReftAction: (payload) => dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { gaInfo } = state.bulletin;
  const { doughnut, table, loading, line } = state.reft;

  return {
    match: ownProps?.match,
    line,
    loading,
    table,
    gaInfo,
    doughnut,
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Reft)
);
