// @flow

import * as fromTypes from "../types/agency";

export const registerAgency = (payload: object): fromTypes.AgencyAction => ({
  type: fromTypes.REGISTER_AGENCY,
  payload,
});
export const registerAgencySuccess = (
  payload: any
): fromTypes.AgencyAction => ({
  type: fromTypes.REGISTER_AGENCY_SUCCESS,
  payload,
});
export const registerAgencyError = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.REGISTER_AGENCY_ERROR,
  payload,
});

export const deleteAgency = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.DELETE_AGENCY,
  payload,
});
export const deleteAgencySuccess = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.DELETE_AGENCY_SUCCESS,
  payload,
});
export const deleteAgencyError = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.DELETE_AGENCY_ERROR,
  payload,
});

export const getAgencyInfo = (uniorg: int): fromTypes.AgencyAction => ({
  type: fromTypes.GET_AGENCY_INFO,
  uniorg,
});
export const getAgencyInfoSuccess = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.GET_AGENCY_INFO_SUCCESS,
  payload,
});
export const getAgencyInfoError = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.GET_AGENCY_INFO_ERROR,
  payload,
});
export const getOperations = (uniorg: int): fromTypes.AgencyAction => ({
  type: fromTypes.GET_OPERATIONS,
  uniorg,
});
export const getOperationsSuccess = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.GET_OPERATIONS_SUCCESS,
  payload,
});
export const getOperationsError = (payload: any): fromTypes.AgencyAction => ({
  type: fromTypes.GET_OPERATIONS_ERROR,
  payload,
});
