// @flow

import * as fromTypes from "../types/queues";

export const getQueuesTable = (payload: object): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_TABLE,
  payload,
});
export const getQueuesTableSuccess = (
  payload: array
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_TABLE_SUCCESS,
  payload,
});
export const getQueuesTableError = (payload: any): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_TABLE_ERROR,
  payload,
});

export const addQueuesComment = (payload: object): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_COMMENT,
  payload,
});
export const addQueuesCommentSuccess = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_COMMENT_SUCCESS,
  payload,
});
export const addQueuesCommentError = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_COMMENT_ERROR,
  payload,
});

export const addQueuesAction = (payload: object): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_ACTION,
  payload,
});
export const addQueuesActionSuccess = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_ACTION_SUCCESS,
  payload,
});
export const addQueuesActionError = (payload: any): fromTypes.QueuesAction => ({
  type: fromTypes.ADD_QUEUES_ACTION_ERROR,
  payload,
});

export const getQueuesDoughnutChart = (
  payload: object
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_DOUGHNUT,
  payload,
});
export const getQueuesDoughnutChartSuccess = (
  payload: array
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_DOUGHNUT_SUCCESS,
  payload,
});
export const getQueuesDoughnutChartError = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_DOUGHNUT_ERROR,
  payload,
});

export const getQueuesComments = (payload: object): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_COMMENTS,
  payload,
});
export const getQueuesCommentsSuccess = (
  payload: array
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_COMMENTS_SUCCESS,
  payload,
});
export const getQueuesCommentsError = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_COMMENTS_ERROR,
  payload,
});

export const getQueuesHeatmapChart = (
  payload: object
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_HEATMAP,
  payload,
});
export const getQueuesHeatmapChartSuccess = (
  payload: array
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_HEATMAP_SUCCESS,
  payload,
});
export const getQueuesHeatmapChartError = (
  payload: any
): fromTypes.QueuesAction => ({
  type: fromTypes.GET_QUEUES_HEATMAP_ERROR,
  payload,
});
