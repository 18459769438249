import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  black,
  toastSuccess,
  white,
  toastWarn,
  toastError,
  toastInfo,
} from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  icon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px",
  },
  inputIcon: {
    position: "absolute",
    right: "4%",
    top: "22%",
    background: white,
    zIndex: "99",
  },
  listInputIcon: {
    position: "absolute",
    right: "30%",
    top: "30%",
    background: white,
    zIndex: "99",
  },
  clickableIcon: {
    cursor: "pointer",
  },
  emailIcon: {
    "&:before": {
      content: "'\\f1fc'",
    },
  },
  calendarIcon: {
    "&:before": {
      content: "'\\f166'",
    },
  },
  chevronIcon: {
    "&:before": {
      content: "'\\f192'",
    },
  },
  accountUser: {
    "&:before": {
      content: "'\\f117'",
    },
  },
  securityClose: {
    "&:before": {
      content: "'\\f34b'",
    },
  },
  searchIcon: {
    "&:before": {
      content: "'\\f348'",
    },
  },
  closeIcon: {
    "&:before": {
      content: "'\\f1ad'",
    },
  },
  securityOpen: {
    "&:before": {
      content: "'\\f34e'",
    },
  },
  communicationIcon: {
    "&:before": {
      content: "'\\f1b5'",
    },
  },
  checklistIcon: {
    "&:before": {
      content: "'\\f18b'",
    },
  },
  chevronRight: {
    "&:before": {
      content: "'\\f197'",
    },
  },
  timeIcon: {
    "&:before": {
      content: "'\\f397'",
    },
  },
  arrowRightIcon: {
    "&:before": {
      content: "'\\f130'",
    },
  },
  arrowLeftIcon: {
    "&:before": {
      content: "'\\f12f'",
    },
  },
  receiptBills: {
    "&:before": {
      content: "'\\f311'",
    },
  },
  signOffIcon: {
    "&:before": {
      content: "'\\f35d'",
    },
  },
  addCircleIcon: {
    "&:before": {
      content: "'\\f119'",
    },
  },
  circleUserIcon: {
    "&:before": {
      content: "'\\f1a2'",
    },
  },
  placeIcon: {
    "&:before": {
      content: "'\\f2f0'",
    },
  },
  cityIcon: {
    "&:before": {
      content: "'\\f1a4'",
    },
  },
  franchiseIcon: {
    "&:before": {
      content: "'\\f238'",
    },
  },
  checkmarkIcon: {
    "&:before": {
      content: "'\\f18d'",
    },
  },
  chevronUp: {
    "&:before": {
      color: "#fff",
      content: "'\\f19d'",
    },
  },
  toastCheckmarkIcon: {
    border: `6px solid ${toastSuccess}`,
    color: toastSuccess,
    borderRadius: "50%",
    "&:before": {
      content: "'\\f18d'",
      color: toastSuccess,
    },
  },
  toastWarnIcon: {
    border: `6px solid ${toastWarn}`,
    color: toastWarn,
    borderRadius: "50%",
    "&:before": {
      content: "'\\f3c5'",
      color: toastWarn,
    },
  },
  toastErrorIcon: {
    border: `6px solid ${toastError}`,
    color: toastError,
    borderRadius: "50%",
    "&:before": {
      content: "'\\f1ac'",
      color: toastError,
    },
  },
  toastInfoIcon: {
    border: `6px solid ${toastInfo}`,
    borderRadius: "50%",
    "&:before": {
      content: "'\\f3c5'",
      color: toastInfo,
    },
  },
  checkAsigment: {
    "&:before": {
      content: "'\\f184'",
    },
  },
  infoCircleIcon: {
    "&:before": {
      content: "'\\f266'",
    },
  },
  chevronLeft: {
    "&:before": {
      content: "'\\f193'",
    },
  },
  homeIcon: {
    "&:before": {
      content: "'\\f258'",
    },
  },
  newUserIcon: {
    "&:before": {
      content: "'\\f1a1'",
    },
  },
  supportIcon: {
    "&:before": {
      content: "'\\f2e9'",
    },
  },
  editIcon: {
    "&:before": {
      content: "'\\f1f6'",
    },
  },
  teamInteractionIcon: {
    "&:before": {
      content: "'\\f390'",
    },
  },
  minusInACircle: {
    "&:before": {
      content: "'\\F29B'",
    },
  },
  chevronUpBig: {
    "&:before": {
      content: "'\\F19E'",
    },
  },
  close1px: {
    "&:before": {
      content: "'\\f1ac'",
      fontSize: "24px",
    },
  },
  closeSmall: {
    "&:before": {
      content: "'\\f1ac'",
      fontSize: "16px",
    },
  },
  errorIcon: {
    "&:before": {
      content: "'\\f20d'",
      fontSize: "28px",
    },
  },
  alertIcon: {
    "&:before": {
      content: "'\\f123'",
    },
  },
  bellIcon: {
    "&:before": {
      content: "'\\f14f'",
    },
  },
  chevronDownSlim: {
    "&:before": {
      content: "'\\F18F'",
    },
  },
  chevronUpSlim: {
    "&:before": {
      content: "'\\F19B'",
    },
  },
  analysisIcon: {
    "&:before": {
      content: "'\\F127'",
    },
  },
  burndowncharctIcon: {
    "&:before": {
      content: "'\\F160'",
    },
  },
  businessStoreIcon: {
    "&:before": {
      content: "'\\F163'",
    },
  },
  trashBinIcon: {
    "&:before": {
      content: "'\\f3a3'",
    },
  },
  signUpIcon: {
    "&:before": {
      content: "'\\f35e'",
    },
  },
  atmCheckmark: {
    fontSize: "16px",
    "&:before": {
      content: "'\\f18d'",
    },
  },
  checkmarkInACircle: {
    "&:before": {
      content: "'\\F18C'",
    },
  },
  closeInACircle: {
    transform: "rotate(45deg)",
    "&:before": {
      content: "'\\F119'",
    },
  },
  visibilityOn: {
    "&:before": {
      content: "'\\F3C1'",
    },
  },
  visibilityOff: {
    "&:before": {
      content: "'\\F3C0'",
    },
  },
  arrowUp: {
    fontSize: "16px",
    "&:before": {
      content: "'\\F131'",
    },
  },
  exportXLS: {
    fontSize: "28px",
    "&:before": {
      content: "'\\F1E7'",
    },
  },
  finger: {
    "&:before": {
      content: "'\\F22B'",
    },
  },
  idea: {
    "&:before": {
      content: "'\\F25C'",
    },
  },
  copy: {
    "&:before": {
      content: "'\\F1BE'",
    },
  },
  checkbook: {
    "&:before": {
      content: "'\\F16D'",
    },
  },
}));

const Icons = ({
  icon,
  isInputIcon,
  isListInputIcon,
  clickable,
  customColor,
  customClass,
}) => {
  const classes = useStyles();

  const getIcon = () => {
    switch (icon) {
      case "account-user":
        return classes.accountUser;
      case "security-close":
        return classes.securityClose;
      case "security-open":
        return classes.securityOpen;
      case "email":
        return classes.emailIcon;
      case "chevron-down":
        return classes.chevronIcon;
      case "calendar":
        return classes.calendarIcon;
      case "search":
        return classes.searchIcon;
      case "close":
        return classes.closeIcon;
      case "communication-message":
        return classes.communicationIcon;
      case "checklist":
        return classes.checklistIcon;
      case "chevron-right-1px":
        return classes.chevronRight;
      case "time":
        return classes.timeIcon;
      case "arrow-right":
        return classes.arrowRightIcon;
      case "arrow-left":
        return classes.arrowLeftIcon;
      case "receipt-bills":
        return classes.receiptBills;
      case "sign-off":
        return classes.signOffIcon;
      case "add-in-a-circle":
        return classes.addCircleIcon;
      case "circle-account-user":
        return classes.circleUserIcon;
      case "place":
        return classes.placeIcon;
      case "city":
        return classes.cityIcon;
      case "franchise":
        return classes.franchiseIcon;
      case "checkmark":
        return classes.checkmarkIcon;
      case "chevron-up-small":
        return classes.chevronUp;
      case "toast-checkmark":
        return classes.toastCheckmarkIcon;
      case "toast-warn":
        return classes.toastWarnIcon;
      case "toast-error":
        return classes.toastErrorIcon;
      case "toast-info":
        return classes.toastInfoIcon;
      case "check-asigment":
        return classes.checkAsigment;
      case "information-in-a-circle":
        return classes.infoCircleIcon;
      case "chevron-left-1px":
        return classes.chevronLeft;
      case "home":
        return classes.homeIcon;
      case "circle-account-add-user":
        return classes.newUserIcon;
      case "phone-support":
        return classes.supportIcon;
      case "edit":
        return classes.editIcon;
      case "team-interaction":
        return classes.teamInteractionIcon;
      case "minus-in-a-circle":
        return classes.minusInACircle;
      case "chevron-up":
        return classes.chevronUpBig;
      case "error":
        return classes.errorIcon;
      case "alert":
        return classes.alertIcon;
      case "bell":
        return classes.bellIcon;
      case "chevron-down-1px":
        return classes.chevronDownSlim;
      case "chevron-up-1px":
        return classes.chevronUpSlim;
      case "analysis":
        return classes.analysisIcon;
      case "burndowncharct":
        return classes.burndowncharctIcon;
      case "business-store":
        return classes.businessStoreIcon;
      case "trash-bin":
        return classes.trashBinIcon;
      case "sign-up":
        return classes.signUpIcon;
      case "atm-checkmark":
        return classes.atmCheckmark;
      case "checkmark-in-a-circle":
        return classes.checkmarkInACircle;
      case "close-1px":
        return classes.close1px;
      case "close-small":
        return classes.closeSmall;
      case "close-in-a-circle":
        return classes.closeInACircle;
      case "visibility-on":
        return classes.visibilityOn;
      case "visibility-off":
        return classes.visibilityOff;
      case "arrow-up":
        return classes.arrowUp;
      case "export-xls":
        return classes.exportXLS;
      case "finger":
        return classes.finger;
      case "idea":
        return classes.idea;
      case "copy":
        return classes.copy;
      case "checkbook":
        return classes.checkbook;
      default:
        break;
    }
  };

  return (
    <span
      className={`${customClass ? customClass : classes.icon} ${
        isInputIcon ? classes.inputIcon : ""
      } 
      ${clickable ? classes.clickableIcon : ""}
      ${isListInputIcon ? classes.listInputIcon : ""}
      ${getIcon()}`}
      style={{ color: customColor ? customColor : black }}
    />
  );
};

export default Icons;
