import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/comp";
import * as fromActions from "../actions/comp";
import { getToken, logout, getUser } from "../services/auth";
import { setAgencia, getAgencia } from "../services/checklist";
import { getBaseAPI } from "../services/api";
import { setLastSearch, getLastSearch } from "../services/atm";
import { alertToast } from "../../components/Toast";
import { setLastSearchedUser } from "../services/search";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getBucketInfo({ payload }) {
  try {
    const response = yield axios.get(
      `${BASE_API}/CompensacaoCheque/balde/${payload}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getBucketInfoSuccess(response.data.data));
      if (Object.keys(response.data.data).length === 0) {
        alertToast("warn", "Não existem mais cheques para tratamento!");
      }
    }
  } catch (err) {
    yield put(fromActions.getBucketInfoError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getSheetPaperInfo({ payload }) {
  const payloadToSend = {
    cd_uniorg: payload.cd_uniorg,
    id_cheque: payload.id_cheque,
    headers: headers,
  };
  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/folha`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.getSheetPaperInfoSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getSheetPaperInfoError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getWorkedOutTable({ payload }) {
  try {
    const response = yield axios.get(
      `${BASE_API}/CompensacaoCheque/lista/${payload}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getWorkedOutTableSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getWorkedOutTableError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getDoughnutChart({ payload }) {
  const payloadToSend = {
    matricula: payload.ga,
    headers: headers,
  };
  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/graficoSituacao`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.getDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getDoughnutChartError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getLineChart({ payload }) {
  const payloadToSend = {
    matricula: payload.ga,
    headers: headers,
  };
  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/graficoHist`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.getLineChartSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getLineChartError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* removeGA({ payload }) {
  const payloadToSend = {
    matricula: payload.ga,
    id_cheque: payload.id_cheque,
    headers: headers,
  };
  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/removerGa`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.removeGASuccess(response.data.data));
      yield put(fromActions.getWorkedOutTable(getUser()));
    }
  } catch (err) {
    yield put(fromActions.removeGAError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getTotalTrab() {
  try {
    const response = yield axios.get(
      `${BASE_API}/CompensacaoCheque/trabalhados/${getUser()}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getTotalTrabSuccess(response.data.data));
    }
  } catch (err) {
    yield put(fromActions.getTotalTrabError(err));
    console.log(err);
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchComp() {
  yield takeLatest(fromTypes.GET_BUCKET_INFO, getBucketInfo);
  yield takeLatest(fromTypes.GET_SHEET_PAPER, getSheetPaperInfo);
  yield takeLatest(fromTypes.GET_WORKED_OUT_TABLE, getWorkedOutTable);
  yield takeLatest(fromTypes.GET_DOUGHNUT_CHART, getDoughnutChart);
  yield takeLatest(fromTypes.GET_LINE_CHART, getLineChart);
  yield takeLatest(fromTypes.REMOVE_GA, removeGA);
  yield takeLatest(fromTypes.GET_TOTAL_TRAB, getTotalTrab);
}
