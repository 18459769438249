// @flow

export const GET_BULLETIN = "[BULLETIN] GET BULLETIN";
export const GET_BULLETIN_SUCCESS = "[BULLETIN] GET BULLETIN SUCCESS";
export const GET_BULLETIN_ERROR = "[BULLETIN] GET BULLETIN ERROR";

export const GET_BULLETIN_URGENCY = "[BULLETIN] GET BULLETIN URGENCY";
export const GET_BULLETIN_URGENCY_SUCCESS =
  "[BULLETIN] GET BULLETIN URGENCY SUCCESS";
export const GET_BULLETIN_URGENCY_ERROR =
  "[BULLETIN] GET BULLETIN URGENCY ERROR";

export const GET_BULLETIN_SEARCH = "[BULLETIN] GET BULLETTIN SEARCH";
export const GET_BULLETIN_SEARCH_SUCCESS =
  "[BULLETIN] GET BULLETIN SEARCH SUCCESS";
export const GET_BULLETIN_SEARCH_ERROR = "[BULLETIN] GET BULLETIN SEARCH ERROR";

export const GET_FILTERS = "[BULLETIN] GET FILTERS";
export const GET_FILTERS_SUCCESS = "[BULLETIN] GET FILTERS SUCCESS";
export const GET_FILTERS_ERROR = "[BULLETIN] GET FILTERS ERROR";

export const GET_PERSON_INFO = "[BULLETIN] GET GA INFO";
export const GET_PERSON_INFO_SUCCESS = "[BULLETIN] GET GA INFO SUCCESS";
export const GET_PERSON_INFO_ERROR = "[BULLETIN] GET GA INFO ERROR";

export const SET_EXPORT_CHECKED = "[BULLETIN] SET_EXPORT_CHECKED";

export const GET_ACTION_CATEGORIES = "[BULLETIN] GET_ACTION_CATEGORIES";
export const GET_ACTION_CATEGORIES_SUCCESS = "[BULLETIN] GET_ACTION_CATEGORIES_SUCCESS";
export const GET_ACTION_CATEGORIES_ERROR = "[BULLETIN] GET_ACTION_CATEGORIES_ERROR";

export const GET_INDICATOR_COMMENTS = "[BULLETIN] GET_INDICATOR_COMMENTS";
export const GET_INDICATOR_COMMENTS_SUCCESS = "[BULLETIN] GET_INDICATOR_COMMENTS_SUCCESS";
export const GET_INDICATOR_COMMENTS_ERROR = "[BULLETIN] GET_INDICATOR_COMMENTS_ERROR";

export const ADD_INDICATOR_COMMENT = "[BULLETIN] ADD_INDICATOR_COMMENT";
export const ADD_INDICATOR_COMMENT_SUCCESS = "[BULLETIN] ADD_INDICATOR_COMMENT_SUCCESS";
export const ADD_INDICATOR_COMMENT_ERROR = "[BULLETIN] ADD_INDICATOR_COMMENT_ERROR";

type GetBulletin = {
  type: typeof GET_BULLETIN,
};
type GetBulletinSuccess = {
  type: typeof GET_BULLETIN_SUCCESS,
  payload: any,
};
type GetBulletinError = {
  type: typeof GET_BULLETIN_ERROR,
  payload: any,
};

type GetBulletinUrgency = {
  type: typeof GET_BULLETIN_URGENCY,
};
type GetBulletinUrgencySuccess = {
  type: typeof GET_BULLETIN_URGENCY_SUCCESS,
  payload: any,
};
type GetBulletinUrgencyError = {
  type: typeof GET_BULLETIN_URGENCY_ERROR,
  payload: any,
};

type GetBulletinSearch = {
  type: typeof GET_BULLETIN_SEARCH,
  payload: Object,
};
type GetBulletinSearchSuccess = {
  type: typeof GET_BULLETIN_SEARCH_SUCCESS,
  payload: any,
};
type GetBulletinSearchError = {
  type: typeof GET_BULLETIN_SEARCH_ERROR,
  payload: any,
};

type GetFilters = {
  type: typeof GET_FILTERS,
};
type GetFiltersSuccess = {
  type: typeof GET_FILTERS_SUCCESS,
  payload: any,
};
type GetFiltersError = {
  type: typeof GET_FILTERS_ERROR,
  payload: any,
};

type GetPersonInfo = {
  type: typeof GET_PERSON_INFO,
  gaId: string,
};
type GetPersonInfoSuccess = {
  type: typeof GET_PERSON_INFO_SUCCESS,
  payload: any,
};
type GetPersonInfoError = {
  type: typeof GET_PERSON_INFO_ERROR,
  payload: any,
};

type SetExportChecked = {
  type: typeof SET_EXPORT_CHECKED,
  payload: object,
};

type GetActionCategories = {
  type: typeof GET_ACTION_CATEGORIES,
  ind: int,
};
type GetActionCategoriesSuccess = {
  type: typeof GET_ACTION_CATEGORIES_SUCCESS,
  payload: any,
};
type GetActionCategoriesError = {
  type: typeof GET_ACTION_CATEGORIES_ERROR,
  payload: any,
};

type GetIndicatorComments = {
  type: typeof GET_INDICATOR_COMMENTS,
  payload: object,
};
type GetIndicatorCommentsSuccess = {
  type: typeof GET_INDICATOR_COMMENTS_SUCCESS,
  payload: any,
};
type GetIndicatorCommentsError = {
  type: typeof GET_INDICATOR_COMMENTS_ERROR,
  payload: any,
};

type AddIndicatorComment = {
  type: typeof ADD_INDICATOR_COMMENT,
  payload: object,
};
type AddIndicatorCommentSuccess = {
  type: typeof ADD_INDICATOR_COMMENT_SUCCESS,
  payload: any,
};
type AddIndicatorCommentError = {
  type: typeof ADD_INDICATOR_COMMENT_ERROR,
  payload: any,
};

export type BulletinAction =
  | GetBulletin
  | GetBulletinSuccess
  | GetBulletinError
  | GetBulletinUrgency
  | GetBulletinUrgencySuccess
  | GetBulletinUrgencyError
  | GetBulletinSearch
  | GetBulletinSearchSuccess
  | GetBulletinSearchError
  | GetFilters
  | GetFiltersSuccess
  | GetFiltersError
  | GetPersonInfo
  | GetPersonInfoSuccess
  | GetPersonInfoError
  | SetExportChecked
  | GetActionCategories
  | GetActionCategoriesSuccess
  | GetActionCategoriesError
  | GetIndicatorComments
  | GetIndicatorCommentsSuccess
  | GetIndicatorCommentsError
  | AddIndicatorComment
  | AddIndicatorCommentSuccess
  | AddIndicatorCommentError
