import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles, styled } from "@material-ui/styles";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { decrypt } from "../../store/services/crypto";
import { addCheckAction } from "../../store/actions/check";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  WarningExportModal,
  CommentDrawer,
  QueuesHeatmap,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  darkerGrey2,
  mediumGrey,
  yellow,
  black,
  mustard,
  yellow20,
  mustard10,
  lightGrey,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";
import interno from "../../assets/img/confidencial_restrito.svg";
import CheckModal from "../../components/CheckModal";

const totalMockData = [50, 60, 40, 70, 50, 90];
const trabalhadosMockData = [20, 20, 10, 10, 20, 30];
const foraPrazoMockData = [15, 20, 10, 30, 15, 30];
const dentroPrazoMockData = [15, 20, 20, 30, 15, 30];
const regularizadosMockData = [40, 55, 30, 50, 40, 70];
const periodosMockData = [
  "Dez/21",
  "Jan/22",
  "Fev/22",
  "Mar/22",
  "Abr/22",
  "Mai/22",
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "5px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "80px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    whiteSpace: "nowrap",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  wordCloud: {
    display: "flex",
    alignItems: "center",
    height: "88px",
    borderRadius: "4px",
    marginBottom: "36px",
    padding: "16px 24px",
  },
  wordCloudContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: white,
    gap: "30px",
    height: "88px",
    boxShadow: "0px 3px 6px #00000029",
  },
  wordCloudTitle: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "13px",
  },
  wordsContainer: {
    fontFamily: "SantanderHeadline !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  placeholderIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px !important",
    marginLeft: "10px",
    position: "absolute",
    right: "2%",
    top: "30%",
    background: white,
    zIndex: "99",
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "SantanderMicroText, sans-serif",
    color: mediumGrey,
    fontWeight: "bold",
    fontSize: "16px",
    paddingTop: "5px",
  },

  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    border: `1px solid ${black}`,
    borderRadius: "4px",
    fontFamily: "SantanderMicroText",
    color: darkGrey,
  },
}));

const Check = ({ match, line, loading, onAddCheckAction, table, doughnut }) => {
  const classes = useStyles();
  const [devolvidosData, setDevolvidosData] = useState([]);
  const [pagosData, setPagosData] = useState([]);
  const [foraEscopoData, setForaEscopoData] = useState([]);
  const [naoTratadosData, setNaoTratadosData] = useState([]);
  const [totaisData, setTotaisData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [nqSortModel, setNqSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [alertIdInputValue, setAlertIdInputValue] = useState("");
  const [docInputValue, setDocInputValue] = useState("");
  const [nomeInputValue, setNomeInputValue] = useState("");
  const [matInputValue, setMatInputValue] = useState("");
  const [showDocText, setShowDocText] = useState(false);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [modalInfos, setModalInfos] = useState({});
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    let list = [];
    table.map((i, index) => {
      list = [
        ...list,
        {
          id: index,
          cdInd: i.cd_indicador,
          uniorg: i.cd_uniorg,
          nomeGerente: i.nm_gerente_mascara,
          nmGerenteCripto: i.nm_gerente_cripto,
          cdCarteira: i.cd_carteira,
          cheque: i.nu_cheque,
          valor: i.vl_cheque,
          agencia: i.nu_ag_mascara,
          agCripto: i.nu_ag_cripto,
          conta: i.nu_conta_mascara,
          contaCripto: i.nu_conta_cripto,
          motivo: i.nu_motivo === "15K" ? "-" : i.nu_motivo,
          tipo: i.nu_motivo === "15K" ? "15K" : "ME",
          dsMotivo: i.ds_motivo,
          nomeCliente: i.nm_cliente_mascara,
          nomeClienteCripto: i.nm_cliente_cripto,
          documento: i.nu_doc_mascara,
          docCripto: i.nu_doc_cripto,
          docTool: "",
          nomeTool: "",
          nomeGerenteTool: "",
          agTool: "",
          contaTool: "",
          actionPG: i.bl_atuacao,
          actionDEV: i.bl_atuacao,
          actionX: i.bl_atuacao,
          action: i.bl_atuacao,
          idCheque: i.id_cheque,
          imgStatus: i.bl_img,
        },
      ];
    });

    setRows(list);
    setBackupRows(list);
  }, [table]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, line.length]);

  useEffect(() => {
    let devolvidosData = [];
    let pagosData = [];
    let foraEscopoData = [];
    let naoTratadosData = [];
    let totaisData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        devolvidosData.push(i.qtde_devolvido);
        pagosData.push(i.qtde_pago);
        foraEscopoData.push(i.qtde_f_escopo);
        naoTratadosData.push(i.qtde_n_trabalhado);
        totaisData.push(i.qtde_total);
        periodoData.push(i.dt_abr);
      });

    setDevolvidosData(devolvidosData);
    setPagosData(pagosData);
    setForaEscopoData(foraEscopoData);
    setNaoTratadosData(naoTratadosData);
    setTotaisData(totaisData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.qtde_total > 0 ? setIsResolve(false) : setIsResolve(true);
  }, []);

  const mockDataPieLabel = [
    `Trabalhado (${14}%)`,
    `Neutro (${20}%)`,
    `Detrator (${11}%)`,
    `Promotor (${25}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [
        doughnut.qtde_dev,
        doughnut.qtde_f_escop,
        doughnut.qtde_pag,
        doughnut.qtde_n_trab,
      ],
      backgroundColor: [santander, yellow, turquoise, lightGrey],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Devolvidos`,
      data: devolvidosData,
      fill: "true",
      borderColor: santander,
      backgroundColor: santander,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Pagos`,
      data: pagosData,
      fill: "true",
      borderColor: turquoise,
      backgroundColor: turquoise,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Fora do escopo`,
      data: foraEscopoData,
      fill: "true",
      borderColor: mustard,
      backgroundColor: mustard,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Não tratados`,
      data: naoTratadosData,
      fill: "true",
      borderColor: lightGrey,
      backgroundColor: lightGrey,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "bar",
      label: `Total`,
      data: totaisData,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue, inputField) => {
    if (searchValue === "") {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "nomeCliente":
          setNomeInputValue(searchValue);
          break;
        case "matricula":
          setMatInputValue(searchValue);
          break;
        case "idAlerta":
          setAlertIdInputValue(searchValue);
          break;
      }
      setRows(backupRows);
    } else {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "nomeCliente":
          setNomeInputValue(searchValue);
          break;
        case "matricula":
          setMatInputValue(searchValue);
          break;
        case "idAlerta":
          setAlertIdInputValue(searchValue);
          break;
      }

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[inputField]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const getUrgencyColor = (prazo) => {
    if (!prazo || Math.sign(prazo) === -1) {
      return santander20;
    } else if (prazo === 1) {
      return mustard10;
    } else {
      return yellow20;
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da uniorg</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.uniorg, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "nomeGerente",
      headerName: "Gerente",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeGerenteTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o nome do gerente</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeGerenteTool} - {params.row.cdCarteira}
                    <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nmGerenteCripto);
                showValue(params.id, "nomeGerenteTool", "nmGerenteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeGerenteTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeGerenteTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "cheque",
      headerName: "Cheque",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <LightTooltip
            title={
              params.row.imgStatus ? (
                <>
                  <span>Clique para ver a imagem do cheque</span>
                </>
              ) : (
                <>
                  <span>
                    A imagem não foi carregada!
                    <br />
                    <a
                      style={{ color: santander, textDecoration: "underline" }}
                      href="https://dcd.santanderbr.corp/dcd/pages/acessarImagem/santander.xhtml"
                      target="_blank"
                    >
                      Clique aqui
                    </a>{" "}
                    {""}e visualize o cheque através do Painel Gerencial de
                    Produtos Digitalizados.
                  </span>
                </>
              )
            }
          >
            <div>
              <Button
                className={classes.editBtn}
                disabled={!params.row.imgStatus}
                onClick={() => {
                  setOpenCheckModal(true);
                  setModalInfos(params.row);
                }}
              >
                {params.row.imgStatus ? (
                  <Icons icon="visibility-on" customColor={santander} />
                ) : (
                  <Icons icon="visibility-off" customColor={lightGrey} />
                )}
              </Button>
            </div>
          </LightTooltip>

          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.cheque, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: "valor",
      headerName: "Valor",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o valor do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.valor, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },

    {
      field: "motivo",
      headerName: "Motivo",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          {params.row.motivo === "-" ? (
            <span>{params.value}</span>
          ) : (
            <LightTooltip
              title={
                !displayTooltip ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>Clique e segure para copiar o motivo do cheque</span>
                    <Icons icon={"copy"} customClass={classes.listIcon} />
                  </div>
                ) : (
                  <>
                    <span>
                      {params.row.motivo} - {params.row.dsMotivo} <br></br>
                    </span>
                    <span>(item copiado)</span>
                  </>
                )
              }
            >
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onMouseDown={() => {
                  setDisplayTooltip(true);
                  copyValue(
                    `${params.row.motivo} - ${params.row.dsMotivo}`,
                    false
                  );
                }}
                onMouseUp={() => {
                  setDisplayTooltip(false);
                }}
                onMouseLeave={() => {
                  setDisplayTooltip(false);
                }}
              >
                {params.value}
              </span>
            </LightTooltip>
          )}
        </>
      ),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 90,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o tipo do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.value === "ME"
                      ? "Motivos especiais"
                      : "Acima de 15k"}{" "}
                    <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.tipo, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "agencia",
      headerName: "Ag",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.agTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da agência</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.agTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.agCripto);
                showValue(params.id, "agTool", "agCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "agTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "agTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "conta",
      headerName: "Conta",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.contaTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da conta</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.contaTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.contaCripto);
                showValue(params.id, "contaTool", "contaCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "contaTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "contaTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },

    {
      field: "nomeCliente",
      headerName: "Cliente",
      width: 100,
      headerClassName: "header",

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o nome do cliente</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nomeClienteCripto);
                showValue(params.id, "nomeTool", "nomeClienteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "documento",
      headerName: "CPF/CNPJ",
      width: 170,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={docInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(e.target.value.replace(/[^\d-./]/, ""), "documento")
            }
            placeholder="CPF/CNPJ"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.docTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o CPF/CNPJ</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.docTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.docCripto);
                showValue(params.id, "docTool", "docCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "docTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "docTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },

    {
      field: "action",
      headerName: "Atuação",
      width: 220,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                ind: params.row.cdInd,
                uniorg: params.row.uniorg,
                info_aux: params.row.idCheque,
                action: params.row.action,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Tooltip
            placement="bottom"
            title={`Cheque ${params.row.cheque} / Valor ${params.row.valor} / Pago`}
          >
            <Button
              className={classes.editBtn}
              onClick={() => {
                onAddCheckAction({
                  ind: params.row.cdInd,
                  uniorg: params.row.uniorg,
                  aux: params.row.idCheque,
                  atuacao: !params.row.actionPG ? 1 : 0,
                });
              }}
            >
              <span
                className={classes.actionButton}
                style={{
                  padding: "5px 10px",
                  background: params.row.actionPG === 1 ? turquoise : "none",
                  color: params.row.actionPG === 1 ? white : darkGrey,
                }}
              >
                PG
              </span>
            </Button>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={`Cheque ${params.row.cheque} / Valor ${params.row.valor} / Devolvido`}
          >
            <Button
              className={classes.editBtn}
              onClick={() => {
                onAddCheckAction({
                  ind: params.row.cdInd,
                  uniorg: params.row.uniorg,
                  aux: params.row.idCheque,
                  atuacao: !params.row.actionDEV ? 2 : 0,
                });
              }}
            >
              <span
                className={classes.actionButton}
                style={{
                  padding: "5px 10px",
                  background: params.row.actionDEV === 2 ? santander : "none",
                  color: params.row.actionDEV === 2 ? white : darkGrey,
                }}
              >
                DEV
              </span>
            </Button>
          </Tooltip>

          <Tooltip placement="bottom" title={"Fora do escopo"}>
            <Button
              className={classes.editBtn}
              onClick={() => {
                onAddCheckAction({
                  ind: params.row.cdInd,
                  uniorg: params.row.uniorg,
                  aux: params.row.idCheque,
                  atuacao: !params.row.actionX ? 3 : 0,
                });
              }}
            >
              <div
                className={classes.actionButton}
                style={{
                  background: params.row.actionX === 3 ? yellow : "none",
                  color: params.row.actionX === 3 ? white : darkGrey,
                }}
              >
                <Icons icon="close-1px" />
              </div>
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const copyValue = async (value, isCrypt = true) => {
    isCrypt
      ? navigator.clipboard.writeText(await decrypt(value))
      : navigator.clipboard.writeText(value);
  };

  const showValue = async (id, type, cryptoType) => {
    let newDocObj = {};
    newDocObj[type] = await decrypt(rows.find((x) => x.id === id)[cryptoType]);

    setRows((prevRows) =>
      prevRows.map((row) => {
        return row.id === id ? { ...row, ...newDocObj } : row;
      })
    );
  };

  const hideValue = (id, type) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [type]: "" } : row))
    );
  };

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const setWithoutValue = (dentro, fora) => {
    if (dentro === "0" && fora === "0") {
      return true;
    } else {
      return false;
    }
  };

  const customDoughnuteConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataPieLabel,
    doughnutCenterLabel: doughnut.qtde_total,
    withLegend: false,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    withTooltip: false,
    isEXO: true,
    indicatorGoal: 0,
    customHeight: 1.69,
    customPaddingBottom: "30px",
    withoutValue: setWithoutValue(
      doughnut.qtde_dentro_prazo,
      doughnut.qtde_fora_prazo
    ),
  };

  const customLineConfigs = {
    // title: "Resultado semanal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: periodoData,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
    customChartHeight: 268,
    customChartPaddingTop: 0,
  };

  return (
    <div className={classes.root}>
      {openCheckModal ? (
        <CheckModal
          open={openCheckModal}
          onClose={() => setOpenCheckModal(!!!openCheckModal)}
          data={modalInfos}
        />
      ) : null}
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"Cheque"}
        modalType="confidencial"
      />
      <Header isCheck={true} url={match} accordionIsExpanded={isExpanded} />

      <div className={classes.headerContainer}>
        <div className={classes.pathContainer}>
          {document.referrer ? (
            <>
              <Typography
                className={classes.title}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  setIndicatorType({
                    ...match.params,
                  });
                  window.location.href = getPreviousLink().url;
                }}
              >
                {getPreviousLink().name}
              </Typography>
              <Icons icon="chevron-right-1px" />
            </>
          ) : null}

          <Typography className={classes.title}>
            Compensação de cheques
          </Typography>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {table.length > 0 ? (
            <Typography className={classes.uptime}>
              Última atualização: {table[0].dt_processamento}
            </Typography>
          ) : null}
          <Tooltip placement="bottom" title={"Exportar"}>
            <Button
              onClick={() => setOpenModal(true)}
              sx={{
                "&:hover": {
                  background: "none",
                  color: "none",
                },
              }}
            >
              <Icons icon="export-xls" />
            </Button>
          </Tooltip>
        </div>
      </div>
      {hasLoaded && !loading ? (
        <div className={classes.chartsContainer}>
          <div className={classes.pieChartContainer}>
            <div
              className={classes.bar}
              style={{ backgroundColor: isResolve ? green : santander }}
            ></div>
            <div className={classes.overviewDataContainer}>
              <div>
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontWeight: "bold !important",
                    fontSize: "24px !important",
                    color: `${darkGrey} !important`,
                  }}
                >
                  Situação atual
                </Typography>

                <Typography className={classes.overviewDataChart}>
                  Analista: {doughnut.qtde_analista}
                </Typography>
                <Typography className={classes.overviewDataChart}>
                  Uniorg: {doughnut.qtde_uniorg}
                </Typography>
                <Typography className={classes.overviewDataChart}>
                  Motivos Especiais: {doughnut.qtde_motivo_esp}
                </Typography>
                <Typography className={classes.overviewDataChart}>
                  Acida de 15K: {doughnut.qtde_acima_15}
                </Typography>
              </div>
              <div className={classes.legendContainer}>
                <div className={classes.legendLine}>
                  <div
                    className={classes.colorLegendSquare}
                    style={{ backgroundColor: turquoise }}
                  ></div>
                  <Typography className={classes.legend}>
                    Pagos: {doughnut.qtde_pag} ({doughnut.perc_pag})
                  </Typography>
                </div>
                <div className={classes.legendLine}>
                  <div
                    className={classes.colorLegendSquare}
                    style={{ backgroundColor: yellow }}
                  ></div>
                  <Typography className={classes.legend}>
                    Fora do escopo: {doughnut.qtde_f_escop} (
                    {doughnut.perc_f_escop})
                  </Typography>
                </div>
                <div className={classes.legendLine}>
                  <div
                    className={classes.colorLegendSquare}
                    style={{ backgroundColor: santander }}
                  ></div>
                  <Typography className={classes.legend}>
                    Devolvidos: {doughnut.qtde_dev} ({doughnut.perc_dev})
                  </Typography>
                </div>
                <div className={classes.legendLine}>
                  <div
                    className={classes.colorLegendSquare}
                    style={{ backgroundColor: lightGrey }}
                  ></div>
                  <Typography className={classes.legend}>
                    Não tratados: {doughnut.qtde_n_trab} ({doughnut.perc_n_trab}
                    )
                  </Typography>
                </div>
              </div>
            </div>

            <ChartContainer customConfigs={customDoughnuteConfigs} />
          </div>
          <div className={classes.lineChartsContainer}>
            <div className={classes.lineChartContainer}>
              <div className={classes.titlesContainer}>
                <div>
                  <span>Resultado Mensal</span>
                </div>
                <span style={{ color: turquoise }}>
                  Total compensado: {line[0]?.vlr_pago}
                </span>
                <span style={{ color: santander }}>
                  Total devolvido: {line[0]?.vlr_devolvido}
                </span>
              </div>
              <ChartContainer customConfigs={customLineConfigs} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <ChartATMSkeleton />
          <Loading />
        </>
      )}

      <CommentDrawer
        type="check"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />

      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={nqSortModel}
            changeSortModel={setNqSortModel}
            type="cheque"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do EXO possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddCheckAction: (payload) => dispatch(addCheckAction(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { line, doughnut, table, loading } = state.check;
  const { gaInfo } = state.bulletin;

  return { match: ownProps?.match, line, doughnut, loading, gaInfo, table };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Check)
);
