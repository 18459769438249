// @flow

import * as fromTypes from "../types/profile";

export const getGAInfo = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GA_INFO,
  id,
});
export const getGAInfoSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GA_INFO_SUCCESS,
  payload,
});
export const getGAInfoError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GA_INFO_ERROR,
  payload,
});

export const getSupervisorInfo = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISOR_INFO,
  id,
});
export const getSupervisorInfoSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISOR_INFO_SUCCESS,
  payload,
});
export const getSupervisorInfoError = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISOR_INFO_ERROR,
  payload,
});

export const getUsers = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USERS,
  id,
});
export const getUsersSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USERS_SUCCESS,
  payload,
});
export const getUsersError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USERS_ERROR,
  payload,
});

export const registerUser = (payload: object): fromTypes.ProfileAction => ({
  type: fromTypes.REGISTER_USER,
  payload,
});
export const registerUserSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.REGISTER_USER_SUCCESS,
  payload,
});
export const registerUserError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.REGISTER_USER_ERROR,
  payload,
});

export const getUserDetails = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_DETAILS,
  id,
});
export const getUserDetailsSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_DETAILS_SUCCESS,
  payload,
});
export const getUserDetailsError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_DETAILS_ERROR,
  payload,
});

export const updateUser = (payload: object): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_USER,
  payload,
});
export const updateUserSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_USER_SUCCESS,
  payload,
});
export const updateUserError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_USER_ERROR,
  payload,
});

export const deleteUser = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_USER,
  id,
});
export const deleteUserSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_USER_SUCCESS,
  payload,
});
export const deleteUserError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_USER_ERROR,
  payload,
});

export const getGenders = (): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GENDERS,
});
export const getGendersSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GENDERS_SUCCESS,
  payload,
});
export const getGendersError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_GENDERS_ERROR,
  payload,
});

export const getTypes = (): fromTypes.ProfileAction => ({
  type: fromTypes.GET_TYPES,
});
export const getTypesSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_TYPES_SUCCESS,
  payload,
});
export const getTypesError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_TYPES_ERROR,
  payload,
});

export const getUniorgList = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_UNIORG_LIST,
  id,
});
export const getUniorgListSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_UNIORG_LIST_SUCCESS,
  payload,
});
export const getUniorgListError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_UNIORG_LIST_ERROR,
  payload,
});

export const changeGASelection = (
  payload: object
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_GA_SELECTION,
  payload,
});
export const changeGASelectionSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_GA_SELECTION_SUCCESS,
  payload,
});
export const changeGASelectionError = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_GA_SELECTION_ERROR,
  payload,
});

export const changeSupervisorSelection = (
  payload: object
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_SUPERVISOR_SELECTION,
  payload,
});
export const changeSupervisorSelectionSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_SUPERVISOR_SELECTION_SUCCESS,
  payload,
});
export const changeSupervisorSelectionError = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_SUPERVISOR_SELECTION_ERROR,
  payload,
});

export const getRoles = (): fromTypes.ProfileAction => ({
  type: fromTypes.GET_ROLES,
});
export const getRolesSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_ROLES_SUCCESS,
  payload,
});
export const getRolesError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_ROLES_ERROR,
  payload,
});

export const getSupervisors = (): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISORS,
});
export const getSupervisorsSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISORS_SUCCESS,
  payload,
});
export const getSupervisorsError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_SUPERVISORS_ERROR,
  payload,
});

export const changeProfilePass = (
  payload: object
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_PROFILE_PASS,
  payload,
});
export const changeProfilePassSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_PROFILE_PASS_SUCCESS,
  payload,
});
export const changeProfilePassError = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.CHANGE_PROFILE_PASS_ERROR,
  payload,
});

export const getProfile = (): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE,
});
export const getProfileSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE_SUCCESS,
  payload,
});
export const getProfileError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE_ERROR,
  payload,
});

export const getProfileById = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE_BY_ID,
  id,
});
export const getProfileByIdSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE_BY_ID_SUCCESS,
  payload,
});
export const getProfileByIdError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_PROFILE_BY_ID_ERROR,
  payload,
});

export const getUserInfo = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_INFO,
  id,
});
export const getUserInfoSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_INFO_SUCCESS,
  payload,
});
export const getUserInfoError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_INFO_ERROR,
  payload,
});

export const getUserOperation = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_OPERATION,
  id,
});
export const getUserOperationSuccess = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_OPERATION_SUCCESS,
  payload,
});
export const getUserOperationError = (
  payload: any
): fromTypes.ProfileAction => ({
  type: fromTypes.GET_USER_OPERATION_ERROR,
  payload,
});

export const getWatermarkUserInfo = (id: int): fromTypes.ProfileAction => ({
  type: fromTypes.GET_WATERMARK_USER_INFO,
  id,
});
export const getWatermarkUserInfoSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_WATERMARK_USER_INFO_SUCCESS,
  payload,
});
export const getWatermarkUserInfoError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.GET_WATERMARK_USER_INFO_ERROR,
  payload,
});