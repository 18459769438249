// @flow

export const GET_EXO_TABLE = "[EXO] GET_EXO_TABLE";
export const GET_EXO_TABLE_SUCCESS = "[EXO] GET_EXO_TABLE_SUCCESS";
export const GET_EXO_TABLE_ERROR = "[EXO] GET_EXO_TABLE_ERROR";

export const ADD_EXO_COMMENT = "[EXO] ADD_EXO_COMMENT";
export const ADD_EXO_COMMENT_SUCCESS = "[EXO] ADD_EXO_COMMENT_SUCCESS";
export const ADD_EXO_COMMENT_ERROR = "[EXO] ADD_EXO_COMMENT_ERROR";

export const ADD_EXO_ACTION = "[EXO] ADD_EXO_ACTION";
export const ADD_EXO_ACTION_SUCCESS = "[EXO] ADD_EXO_ACTION_SUCCESS";
export const ADD_EXO_ACTION_ERROR = "[EXO] ADD_EXO_ACTION_ERROR";

export const GET_EXO_DOUGHNUT = "[EXO] GET_EXO_DOUGHNUT";
export const GET_EXO_DOUGHNUT_SUCCESS = "[EXO] GET_EXO_DOUGHNUT_SUCCESS";
export const GET_EXO_DOUGHNUT_ERROR = "[EXO] GET_EXO_DOUGHNUT_ERROR";

export const GET_EXO_COMMENTS = "[EXO] GET_EXO_COMMENTS";
export const GET_EXO_COMMENTS_SUCCESS = "[EXO] GET_EXO_COMMENTS_SUCCESS";
export const GET_EXO_COMMENTS_ERROR = "[EXO] GET_EXO_COMMENTS_ERROR";

export const GET_EXO_LINE = "[EXO] GET_EXO_LINE";
export const GET_EXO_LINE_SUCCESS = "[EXO] GET_EXO_LINE_SUCCESS";
export const GET_EXO_LINE_ERROR = "[EXO] GET_EXO_LINE_ERROR";

type GetExoTable = {
  type: typeof GET_EXO_TABLE,
  payload: object,
};
type GetExoTableSuccess = {
  type: typeof GET_EXO_TABLE_SUCCESS,
  payload: Array,
};
type GetExoTableError = {
  type: typeof GET_EXO_TABLE_ERROR,
  payload: any,
};

type AddExoComment = {
  type: typeof ADD_EXO_COMMENT,
  payload: object,
};
type AddExoCommentSuccess = {
  type: typeof ADD_EXO_COMMENT_SUCCESS,
  payload: Array,
};
type AddExoCommentError = {
  type: typeof ADD_EXO_COMMENT_ERROR,
  payload: any,
};

type AddExoAction = {
  type: typeof ADD_EXO_ACTION,
  payload: object,
};
type AddExoActionSuccess = {
  type: typeof ADD_EXO_ACTION_SUCCESS,
  payload: Array,
};
type AddExoActionError = {
  type: typeof ADD_EXO_ACTION_ERROR,
  payload: any,
};

type GetExoDoughnut = {
  type: typeof GET_EXO_DOUGHNUT,
  payload: object,
};
type GetExoDoughnutSuccess = {
  type: typeof GET_EXO_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetExoDoughnutError = {
  type: typeof GET_EXO_DOUGHNUT_ERROR,
  payload: any,
};

type GetExoComments = {
  type: typeof GET_EXO_COMMENTS,
  payload: object,
};
type GetExoCommentsSuccess = {
  type: typeof GET_EXO_COMMENTS_SUCCESS,
  payload: Array,
};
type GetExoCommentsError = {
  type: typeof GET_EXO_COMMENTS_ERROR,
  payload: any,
};

type GetEXOLineChart = {
  type: typeof GET_EXO_LINE_CHART,
  payload: object,
};
type GetEXOLineChartSuccess = {
  type: typeof GET_EXO_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetEXOLineChartError = {
  type: typeof GET_EXO_LINE_CHART_ERROR,
  payload: any,
};

export type EXOAction =
  | GetExoTable
  | GetExoTableSuccess
  | GetExoTableError
  | AddExoComment
  | AddExoCommentSuccess
  | AddExoCommentError
  | AddExoAction
  | AddExoActionSuccess
  | AddExoActionError
  | GetExoDoughnut
  | GetExoDoughnutSuccess
  | GetExoDoughnutError
  | GetExoComments
  | GetExoCommentsSuccess
  | GetExoCommentsError
  | GetEXOLineChart
  | GetEXOLineChartSuccess
  | GetEXOLineChartError;
