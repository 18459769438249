import React from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { Loading, MainMenu, RegisterForm, UserInfo } from "../components";
import { withRouter } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { lighterGrey, darkGrey } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "14px !important",
    color: `${darkGrey} !important`,
  },
}));

const Register = ({ loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <Typography className={classes.title}>Gestão de Usuários</Typography>
          <Typography className={classes.subtitle}>
            Todos os campos com * são de preenchimento obrigatório.
          </Typography>
          <RegisterForm edit={false} />
        </Grid>
        <Grid item md={4}>
        </Grid>
      </Grid>
    </div>
  );
};


const mapStateToProps = (state) => {
  const { loading } = state.auth;
  return { loading };
};

export default withRouter(
  compose(connect(mapStateToProps))(Register)
);
