// @flow

export const GET_REFT_TABLE = "[REFT] GET_REFT_TABLE";
export const GET_REFT_TABLE_SUCCESS = "[REFT] GET_REFT_TABLE_SUCCESS";
export const GET_REFT_TABLE_ERROR = "[REFT] GET_REFT_TABLE_ERROR";

export const ADD_REFT_ACTION = "[REFT] ADD_REFT_ACTION";
export const ADD_REFT_ACTION_SUCCESS = "[REFT] ADD_REFT_ACTION_SUCCESS";
export const ADD_REFT_ACTION_ERROR = "[REFT] ADD_REFT_ACTION_ERROR";

export const ADD_REFT_COMMENT = "[REFT] ADD_REFT_COMMENT";
export const ADD_REFT_COMMENT_SUCCESS = "[REFT] ADD_REFT_COMMENT_SUCCESS";
export const ADD_REFT_COMMENT_ERROR = "[REFT] ADD_REFT_COMMENT_ERROR";

export const GET_REFT_COMMENTS = "[REFT] GET_REFT_COMMENTS";
export const GET_REFT_COMMENTS_SUCCESS = "[REFT] GET_REFT_COMMENTS_SUCCESS";
export const GET_REFT_COMMENTS_ERROR = "[REFT] GET_REFT_COMMENTS_ERROR";

export const GET_REFT_DOUGHNUT = "[REFT] GET_REFT_DOUGHNUT";
export const GET_REFT_DOUGHNUT_SUCCESS = "[REFT] GET_REFT_DOUGHNUT_SUCCESS";
export const GET_REFT_DOUGHNUT_ERROR = "[REFT] GET_REFT_DOUGHNUT_ERROR";

export const GET_REFT_LINE = "[REFT] GET_REFT_LINE";
export const GET_REFT_LINE_SUCCESS = "[REFT] GET_REFT_LINE_SUCCESS";
export const GET_REFT_LINE_ERROR = "[REFT] GET_REFT_LINE_ERROR";

type GetReftTable = {
  type: typeof GET_REFT_TABLE,
  payload: object,
};
type GetReftTableSuccess = {
  type: typeof GET_REFT_TABLE_SUCCESS,
  payload: Array,
};
type GetReftTableError = {
  type: typeof GET_REFT_TABLE_ERROR,
  payload: any,
};

type AddReftAction = {
  type: typeof ADD_REFT_ACTION,
  payload: object,
};
type AddReftActionSuccess = {
  type: typeof ADD_REFT_ACTION_SUCCESS,
  payload: Array,
};
type AddReftActionError = {
  type: typeof ADD_REFT_ACTION_ERROR,
  payload: any,
};

type AddReftComment = {
  type: typeof ADD_REFT_COMMENT,
  payload: object,
};
type AddReftCommentSuccess = {
  type: typeof ADD_REFT_COMMENT_SUCCESS,
  payload: Array,
};
type AddReftCommentError = {
  type: typeof ADD_REFT_COMMENT_ERROR,
  payload: any,
};

type GetReftComments = {
  type: typeof GET_REFT_COMMENTS,
  payload: object,
};
type GetReftCommentsSuccess = {
  type: typeof GET_REFT_COMMENTS_SUCCESS,
  payload: Array,
};
type GetReftCommentsError = {
  type: typeof GET_REFT_COMMENTS_ERROR,
  payload: any,
};

type GetReftDoghnut = {
  type: typeof GET_REFT_DOUGHNUT,
  payload: object,
};
type GetReftDoghnutSuccess = {
  type: typeof GET_REFT_DOUGHNUT_SUCCESS,
  payload: Array,
};
type GetReftDoghnutError = {
  type: typeof GET_REFT_DOUGHNUT_ERROR,
  payload: any,
};

type GetReftLine = {
  type: typeof GET_REFT_LINE,
  payload: object,
};
type GetReftLineSuccess = {
  type: typeof GET_REFT_LINE_SUCCESS,
  payload: Array,
};
type GetReftLineError = {
  type: typeof GET_REFT_LINE_ERROR,
  payload: any,
};

export type ReftAction =
  | GetReftTable
  | GetReftTableSuccess
  | GetReftTableError
  | AddReftAction
  | AddReftActionSuccess
  | AddReftActionError
  | AddReftComment
  | AddReftCommentSuccess
  | AddReftCommentError
  | GetReftComments
  | GetReftCommentsSuccess
  | GetReftCommentsError
  | GetReftDoghnut
  | GetReftDoghnutSuccess
  | GetReftDoghnutError
  | GetReftLine
  | GetReftLineSuccess
  | GetReftLineError;
