import { makeStyles } from "@material-ui/styles";
import Icons from "./Icons";
import { santander, white, turquoise, yellow } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "235px",
    height: "235px",
    backgroundColor: santander,
    marginTop: "32px",
    marginRight: "31px",
    borderRadius: "100%",
  },
  iconCustomClass: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "80px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "80px",
    letterSpacing: "normal",
    lineHeight: "80px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "80px",
  },
}));

const FeedBackCircle = ({ feedbackStatus }) => {
  const classes = useStyles();

  const getBackgroundColor = (statusCode) => {
    switch (statusCode) {
      case 1:
        return turquoise;
      case 2:
        return yellow;
      case 3:
        return santander;
      default:
        break;
    }
  };

  const getIcon = (statusCode) => {
    switch (statusCode) {
      case 1:
        return "checkmark-in-a-circle";
      case 2:
        return "minus-in-a-circle";
      case 3:
        return "close-in-a-circle";
      default:
        break;
    }
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: getBackgroundColor(feedbackStatus) }}
    >
      <Icons
        icon={getIcon(feedbackStatus)}
        customColor={white}
        customClass={classes.iconCustomClass}
      />
    </div>
  );
};

export default FeedBackCircle;
