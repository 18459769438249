// @flow

import * as fromTypes from "../types/nq";

export const getNqTable = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_TABLE,
  payload,
});
export const getNqTableSuccess = (payload: array): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_TABLE_SUCCESS,
  payload,
});
export const getNqTableError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_TABLE_ERROR,
  payload,
});

export const addNqComment = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_COMMENT,
  payload,
});
export const addNqCommentSuccess = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_COMMENT_SUCCESS,
  payload,
});
export const addNqCommentError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_COMMENT_ERROR,
  payload,
});

export const addNqAction = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_ACTION,
  payload,
});
export const addNqActionSuccess = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_ACTION_SUCCESS,
  payload,
});
export const addNqActionError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.ADD_NQ_ACTION_ERROR,
  payload,
});

export const getNQDoughnutChart = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_DOUGHNUT,
  payload,
});
export const getNQDoughnutChartSuccess = (
  payload: array
): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_DOUGHNUT_SUCCESS,
  payload,
});
export const getNQDoughnutChartError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_DOUGHNUT_ERROR,
  payload,
});

export const getNqComments = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_COMMENTS,
  payload,
});
export const getNqCommentsSuccess = (payload: array): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_COMMENTS_SUCCESS,
  payload,
});
export const getNqCommentsError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_COMMENTS_ERROR,
  payload,
});

export const getNQLineChart = (payload: object): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_LINE,
  payload,
});
export const getNQLineChartSuccess = (payload: array): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_LINE_SUCCESS,
  payload,
});
export const getNQLineChartError = (payload: any): fromTypes.NQAction => ({
  type: fromTypes.GET_NQ_LINE_ERROR,
  payload,
});
