import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { isAuthenticated } from "../store/services/auth";
import { Button } from "@material-ui/core";
import { TelemetriaLogo } from "../components";

// TO DO: Transformar imagens SVG em componentes
import logoSantanderWhite from "../assets/img/logo-santander-white.svg";
import logoTelemetria from "../assets/img/logo-telemetria.svg";
import background from "../assets/img/default_BG.png";
import { santander, white, mediumGrey } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    padding: "0",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${background}) no-repeat 0px -300px`,
    backgroundSize: "160vw",
  },
  nav: {
    margin: "0",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) ",
    textAlign: "center",
  },
  logoSantanderWhite: {
    margin: "2.313em",
    height: "2.313em",
  },
  login: {
    marginTop: "48px",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    ["@media (max-height: 645px)"]: {
      display: "block",
      position: "relative",
      margin: "auto",
      transform: "none",
      top: "auto",
      left: "auto",
      paddingBottom: "2em",
    },
  },
  whiteFloater: {
    backgroundColor: "white",
    textAlign: "center",
    margin: "0 auto",
    padding: "2em",
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      width: "684px",
      padding: "60px",
    },
  },
  logoTelemetriaContainer: {
    width: "280px",
    display: "block",
    margin: "auto",
    marginBottom: "3.188em",
  },
  idField: {
    marginBottom: "1.1em",
  },
  passField: {
    marginBottom: "2.250em",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  forgotPass: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    paddingLeft: "0 !important",
  },
  submitBtn: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${santander} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  emailTitle: {
    fontFamily: "SantanderMicroText",
    fontSize: "20px",
    color: mediumGrey,
    fontWeight: "bold",
    marginBottom: "5px",
  },
  emailSubtitle: {
    fontFamily: "SantanderMicroText",
    fontSize: "16px",
    color: mediumGrey,
    marginTop: "0px",
    marginBottom: "84px",
  },
  backBtn: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    display: "block",
    margin: "auto",
    fontSize: "16px !important",
    padding: "12px 20px !important",
  },
}));

const RequestSent = () => {
  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated()) {
      window.location.href = "/home";
    }
  }, []);

  return (
    <div className={classes.root}>
      <header>
        <nav className={classes.nav}>
          <img
            src={logoSantanderWhite}
            className={classes.logoSantanderWhite}
            alt="Santander"
          />
        </nav>
      </header>
      <section className={classes.login}>
        <div className={classes.whiteFloater}>
          <div className={classes.logoTelemetriaContainer}>
            <TelemetriaLogo />
          </div>

          <div>
            <p className={classes.emailTitle}>E-mail enviado com sucesso!</p>
            <p className={classes.emailSubtitle}>
              Verifique a Caixa de Entrada do seu e-mail e siga as orientações.
            </p>
            <Button
              onClick={() => (window.location.href = "/")}
              className={classes.backBtn}
            >
              Voltar
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestSent;
