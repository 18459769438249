// @flow

export const GET_ATM_AVAILABILITY = "[ATM] GET_ATM_AVAILABILITY";
export const GET_ATM_AVAILABILITY_SUCCESS =
  "[ATM] GET_ATM_AVAILABILITY_SUCCESS";
export const GET_ATM_AVAILABILITY_ERROR = "[ATM] GET_ATM_AVAILABILITY_ERROR";

export const GET_ATM_COMMENTS = "[ATM] GET_ATM_COMMENTS";
export const GET_ATM_COMMENTS_SUCCESS = "[ATM] GET_ATM_COMMENTS_SUCCESS";
export const GET_ATM_COMMENTS_ERROR = "[ATM] GET_ATM_COMMENTS_ERROR";

export const SEND_ATM_COMMENTS = "[ATM] SEND_ATM_COMMENTS";
export const SEND_ATM_COMMENTS_SUCCESS = "[ATM] SEND_ATM_COMMENTS_SUCCESS";
export const SEND_ATM_COMMENTS_ERROR = "[ATM] SEND_ATM_COMMENTS_ERROR";

export const GET_ATM_DOUNGNUT_CHART = "[ATM] GET_ATM_DOUNGNUT_CHART";
export const GET_ATM_DOUNGNUT_CHART_SUCCESS =
  "[ATM] GET_ATM_DOUNGNUT_CHART_SUCCESS";
export const GET_ATM_DOUNGNUT_CHART_ERROR =
  "[ATM] GET_ATM_DOUNGNUT_CHART_ERROR";

export const GET_ATM_LINE_CHART = "[ATM] GET_ATM_LINE_CHART";
export const GET_ATM_LINE_CHART_SUCCESS = "[ATM] GET_ATM_LINE_CHART_SUCCESS";
export const GET_ATM_LINE_CHART_ERROR = "[ATM] GET_ATM_LINE_CHART_ERROR";

type GetATMAvailability = {
  type: typeof GET_ATM_AVAILABILITY,
  payload: object,
};
type GetATMAvailabilitySuccess = {
  type: typeof GET_ATM_AVAILABILITY_SUCCESS,
  payload: Array,
};
type GetATMAvailabilityError = {
  type: typeof GET_ATM_AVAILABILITY_ERROR,
  payload: any,
};

type GetATMComments = {
  type: typeof GET_ATM_COMMENTS,
  payload: object,
};
type GetATMCommentsSuccess = {
  type: typeof GET_ATM_COMMENTS_SUCCESS,
  payload: Array,
};
type GetATMCommentsError = {
  type: typeof GET_ATM_COMMENTS_ERROR,
  payload: any,
};

type SendATMComments = {
  type: typeof SEND_ATM_COMMENTS,
  payload: object,
};
type SendATMCommentsSuccess = {
  type: typeof SEND_ATM_COMMENTS_SUCCESS,
  payload: Array,
};
type SendATMCommentsError = {
  type: typeof SEND_ATM_COMMENTS_ERROR,
  payload: any,
};

type GetATMDoughnutChart = {
  type: typeof GET_ATM_DOUNGNUT_CHART,
  payload: object,
};
type GetATMDoughnutChartSuccess = {
  type: typeof GET_ATM_DOUNGNUT_CHART_SUCCESS,
  payload: Array,
};
type GetATMDoughnutChartError = {
  type: typeof GET_ATM_DOUNGNUT_CHART_ERROR,
  payload: any,
};
type GetATMLineChart = {
  type: typeof GET_ATM_LINE_CHART,
  payload: object,
};
type GetATMLineChartSuccess = {
  type: typeof GET_ATM_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetATMLineChartError = {
  type: typeof GET_ATM_LINE_CHART_ERROR,
  payload: any,
};

export type ATMAction =
  | GetATMAvailability
  | GetATMAvailabilitySuccess
  | GetATMAvailabilityError
  | GetATMComments
  | GetATMCommentsSuccess
  | GetATMCommentsError
  | SendATMComments
  | SendATMCommentsSuccess
  | SendATMCommentsError
  | GetATMDoughnutChart
  | GetATMDoughnutChartSuccess
  | GetATMDoughnutChartError
  | GetATMLineChart
  | GetATMLineChartSuccess
  | GetATMLineChartError;
