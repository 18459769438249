import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {configureStore, history} from "./store";
import { unregister } from './registerServiceWorker';
import "./index.css";
import App from "./App";

const store = configureStore();

unregister();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
