import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import { black, darkGrey, white, inputSky, lightGrey } from "../theme/colors";
import Icons from "./Icons";
import Flatpickr from "react-flatpickr";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

import "flatpickr/dist/themes/light.css";
import "../theme/flatpickrcustom.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "16px",
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    left: "3%",
    top: "25%",
    background: white,
    color: darkGrey,
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: "transparent",
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
      "&::placeholder": {
        color: lightGrey,
      },
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
      "&::placeholder": {
        color: lightGrey,
      },
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
    position: "absolute",
  },
  calendar: {
    position: "absolute",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-25%",
      fontSize: "0.9em",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "3%",
      top: "25%",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "3%",
    background: white,
    color: darkGrey,
    fontSize: "14px",
    lineHeight: "21px",
    top: "-12px",
  },
}));

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + (dd - 1);

const DateField = ({ onChangeMethod = () => {}, defaultValue }) => {
  const classes = useStyles();
  const [currentDate, setCurrentDate] = useState("");
  const [inputValue, setInputValue] = useState();
  const datePickerRef = useRef(null);

  function adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    else return numero;
  }

  useEffect(() => {
    if (defaultValue) changeInput(defaultValue);
    else changeInput([new Date(), getDefaultDates()]);
  }, []);

  useEffect(() => {
    if (defaultValue) {
      let formatedDefaultValue = defaultValue.map((i) => {
        let d = i.split("/");
        let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
        return dat;
      });
      changeInput(formatedDefaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    onChangeMethod(currentDate);
  }, [currentDate]);

  const changeInput = (value) => {
    setInputValue(value);
    let data = [new Date(value[0]), new Date(value[1])];
    let dataAtualFormatada = data.map((i) => {
      let dataVar =
        adicionaZero(i.getDate().toString()) +
        "-" +
        adicionaZero(i.getMonth() + 1).toString() +
        "-" +
        i.getFullYear();
      return dataVar;
    });
    setCurrentDate(dataAtualFormatada);
  };

  const getDefaultDates = () => {
    var result = new Date();
    result.setDate(result.getDate() + 5);
    return result;
  };

  return (
    <div className={classes.root}>
      <Flatpickr
        ref={datePickerRef}
        options={{
          dateFormat: "d-m-Y",
          locale: "pt",
          minDate: "today",
          mode: "range",
        }}
        onChange={changeInput}
        value={inputValue}
        className={`${classes.input} dss-form-field__input`}
      />
      <span style={{ pointerEvents: "none" }}>
        <Icons icon="calendar" isInputIcon />
      </span>
    </div>
  );
};

export default DateField;
