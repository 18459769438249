// @flow

import * as fromTypes from "../types/atm";

export const getATMAvailability = (payload: object): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_AVAILABILITY,
  payload,
});
export const getATMAvailabilitySuccess = (
  payload: array
): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_AVAILABILITY_SUCCESS,
  payload,
});
export const getATMAvailabilityError = (payload: any): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_AVAILABILITY_ERROR,
  payload,
});

export const getATMComments = (payload: object): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_COMMENTS,
  payload,
});
export const getATMCommentsSuccess = (payload: array): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_COMMENTS_SUCCESS,
  payload,
});
export const getATMCommentsError = (payload: any): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_COMMENTS_ERROR,
  payload,
});

export const sendATMComments = (payload: object): fromTypes.ATMAction => ({
  type: fromTypes.SEND_ATM_COMMENTS,
  payload,
});
export const sendATMCommentsSuccess = (
  payload: array
): fromTypes.ATMAction => ({
  type: fromTypes.SEND_ATM_COMMENTS_SUCCESS,
  payload,
});
export const sendATMCommentsError = (payload: any): fromTypes.ATMAction => ({
  type: fromTypes.SEND_ATM_COMMENTS_ERROR,
  payload,
});

export const getATMDoughnutChart = (payload: object): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_DOUNGNUT_CHART,
  payload,
});
export const getATMDoughnutChartSuccess = (
  payload: array
): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_DOUNGNUT_CHART_SUCCESS,
  payload,
});
export const getATMDoughnutChartError = (
  payload: any
): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_DOUNGNUT_CHART_ERROR,
  payload,
});

export const getATMLineChart = (payload: object): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_LINE_CHART,
  payload,
});
export const getATMLineChartSuccess = (
  payload: array
): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_LINE_CHART_SUCCESS,
  payload,
});
export const getATMLineChartError = (payload: any): fromTypes.ATMAction => ({
  type: fromTypes.GET_ATM_LINE_CHART_ERROR,
  payload,
});
