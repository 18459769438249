// @flow

import * as fromTypes from "../types/nq";

type State = {
  loading: boolean,
  doughnut: object,
  line: array,
  table: array,
  nqComments: array,
};

export const initialState = {
  loading: false,
  table: [],
  doughnut: {},
  line: [],
  nqComments: [],
};

const setLoading = (state, loading) => ({ ...state, loading });

const setTable = (state, { payload }) => ({
  ...state,
  table: payload,
  loading: false,
});

const setDoughnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  nqComments: payload,
  loading: false,
});

const setLine = (state, { payload }) => ({
  ...state,
  line: payload,
  loading: false,
});

const reducer = (state: State = initialState, action: fromTypes.NQAction) => {
  switch (action.type) {
    case fromTypes.GET_NQ_TABLE:
    case fromTypes.ADD_NQ_COMMENT:
    case fromTypes.ADD_NQ_ACTION:
    case fromTypes.GET_NQ_DOUGHNUT:
    case fromTypes.GET_NQ_COMMENTS:
    case fromTypes.GET_NQ_LINE:
      return setLoading(state, true);
    // success
    case fromTypes.ADD_NQ_COMMENT:
    case fromTypes.ADD_NQ_ACTION:
      return setLoading(state, true);
    case fromTypes.GET_NQ_TABLE_SUCCESS:
      return setTable(state, action);
    case fromTypes.GET_NQ_DOUGHNUT_SUCCESS:
      return setDoughnut(state, action);
    case fromTypes.GET_NQ_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_NQ_LINE_SUCCESS:
      return setLine(state, action);
    // error
    case fromTypes.GET_NQ_TABLE_ERROR:
    case fromTypes.ADD_NQ_COMMENT_ERROR:
    case fromTypes.ADD_NQ_ACTION_ERROR:
    case fromTypes.GET_NQ_DOUGHNUT_ERROR:
    case fromTypes.GET_NQ_COMMENTS_ERROR:
    case fromTypes.GET_NQ_LINE_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
