import { all } from "redux-saga/effects";
import { watchAuth } from "./auth";
import { watchBulletin } from "./bulletin";
import { watchChecklist } from "./checklist";
import { watchProfile } from "./profile";
import { watchAgency } from "./agency";
import { watchAlerts } from "./alerts";
import { watchATM } from "./atm";
import { watchIndicator } from "./indicator";
import { watchKYC } from "./kyc";
import { watchStore } from "./storeAvailability";
import { watchNQ } from "./nq";
import { watchEXO } from "./exo";
import { watchAnalytics } from "./analytics";
import { watchQueues } from "./queues";
import { watchReft } from "./reft";
import { watchNPS } from "./nps";
import { watchTerms } from "./terms";
import { watchManifestation } from "./manifestation";
import { watchCheck } from "./check";
import { watchComp } from "./comp";

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchBulletin(),
    watchChecklist(),
    watchProfile(),
    watchAgency(),
    watchAlerts(),
    watchATM(),
    watchIndicator(),
    watchKYC(),
    watchStore(),
    watchNQ(),
    watchEXO(),
    watchAnalytics(),
    watchQueues(),
    watchReft(),
    watchNPS(),
    watchTerms(),
    watchManifestation(),
    watchCheck(),
    watchComp(),
  ]);
}
