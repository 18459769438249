import { makeStyles, styled } from "@material-ui/styles";
import { Typography, Tooltip, tooltipClasses, Button } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useState } from "react";

import {
  darkGrey,
  turquoise,
  santander,
  white,
  yellow,
  lightGrey,
  black,
  mediumGrey,
  lighterGrey,
  bulletinGrey,
  black2,
} from "../theme/colors";

const currentDate = new Date().getDate();
const currentMouth = new Date().toLocaleString("pt-br", { month: "long" });
const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 15px 10px 17px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  switchButtonContainer: {
    display: "flex",
    gap: "12px",
    "& .MuiToggleButton-root.Mui-selected": {
      color: `${white} !important`,
      backgroundColor: `${santander} !important`,
      border: `1px solid ${santander} !important`,
    },
  },
  button: {
    color: `${lightGrey} !important`,
    border: `1px solid ${lightGrey} !important`,
    borderRadius: "30px !important",
    padding: "8px 16px !important",
    textTransform: "capitalize !important",
  },
  heatmapContainer: {
    display: "flex",
    marginTop: "25px",
  },
  mouthContainer: {},
  daysContainer: {
    width: "651px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dayContainer: {
    width: "19px",
  },
  quareDayContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "19px",
    height: "15px",
    border: "0.5px solid",
  },
  mouthDaysContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "5px",
    marginTop: "4px",
  },
  hoursSubtitles: {
    lineHeight: "1.41 !important",
    [theme.breakpoints.down("xl")]: {
      lineHeight: "1.35 !important",
    },
  },
  legendText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },

  tooltipText: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "14px !important",
    color: `${bulletinGrey} !important`,
    backgroundColor: `${white} !important`,
  },
  comingSoon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${white}`,
    boxShadow: "0px 3px 6px #00000029",
  },
}));

const QueuesHeatmap = ({
  heatMapData,
  mouthDays,
  dayHours,
  onChangeDayMethod,
  onChangeCategoryMethod,
}) => {
  const classes = useStyles();

  const [category, setCategory] = useState("caixa");

  const getBackgroundColor = (colorCode) => {
    switch (colorCode) {
      case 1:
        return turquoise;
      case 2:
        return yellow;
      case 3:
        return santander;
      case 4:
        return lighterGrey;
      default:
        break;
    }
  };

  const getBorderColor = (colorCode) => {
    switch (colorCode) {
      case 1:
        return turquoise;
      case 2:
        return yellow;
      case 3:
        return santander;
      case 4:
        return lighterGrey;
      default:
        break;
    }
  };

  const getTextColor = (colorCode) => {
    switch (colorCode) {
      case 2:
      case 3:
        return white;
      default:
        return darkGrey;
    }
  };

  const getTooltipText = (colorCode, status, time, customers) => {
    switch (colorCode) {
      case 1:
        return `Tempo de espera ${status}: ${time} `;
      case 2:
        return `Próximo do limite`;
      case 3:
        return `Acima do objetivo`;
      default:
        break;
    }
  };

  const handleSelectCategory = (event, newCategory) => {
    setCategory(newCategory);
    onChangeCategoryMethod(newCategory);
  };

  const handleSelectDay = () => {
    onChangeDayMethod();
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography
          sx={{
            fontFamily: "SantanderHeadline !important",
            fontWeight: "bold !important",
            fontSize: "16px !important",
            color: `${darkGrey} !important`,
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          Resultado Mensal
        </Typography>

        {/* <ToggleButtonGroup
          value={category}
          exclusive
          onChange={handleSelectCategory}
          className={classes.switchButtonContainer}
        >
          <ToggleButton value="caixa" className={classes.button}>
            Caixa
          </ToggleButton>
          <ToggleButton value="gerencia" className={classes.button}>
            Gerência
          </ToggleButton>
        </ToggleButtonGroup> */}
      </div>

      {/* <div className={classes.heatmapContainer}>
        <div className={classes.mouthContainer}>
          <div className={classes.daysContainer} onClick={handleSelectDay}>
            {heatMapData.map((dayInfosArr) => {
              return (
                <div className={classes.dayContainer}>
                  {dayInfosArr.map((item) => {
                    return (
                      <CustomTooltip
                        placement="bottom"
                        title={
                          item.colorCode === 4 ? (
                            <>
                              <Typography className={classes.tooltipText}>
                                Sem senha
                              </Typography>
                            </>
                          ) : (
                            <>
                              {item.pico ? (
                                <Typography className={classes.tooltipText}>
                                  {item.period} - horário de pico
                                </Typography>
                              ) : null}
                              <Typography className={classes.tooltipText}>
                                Clique para ver detalhes
                              </Typography>
                            </>
                          )
                        }
                      >
                        <span
                          className={classes.quareDayContainer}
                          style={{
                            backgroundColor: getBackgroundColor(item.colorCode),
                            borderColor: `${item.pico ? black2 : white}`,
                            cursor: "pointer",
                          }}
                        ></span>
                      </CustomTooltip>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={classes.mouthDaysContainer}>
            {heatMapData.map((day) => {
              return (
                <Typography className={classes.legendText}>
                  {day[0].day}
                </Typography>
              );
            })}
          </div>
        </div>

        <div style={{ marginLeft: "25px" }}>
          {dayHours.map((hour) => {
            return (
              <Typography
                className={`${classes.legendText} ${classes.hoursSubtitles}`}
              >
                {hour}
              </Typography>
            );
          })}
        </div>
      </div> */}

      <div className={classes.comingSoon}>
        <Typography
          sx={{
            fontFamily: "SantanderMicroText",
            fontSize: "24px !important",
            color: `${bulletinGrey} !important`,
          }}
        >
          Em breve...
        </Typography>
      </div>
    </div>
  );
};

export default QueuesHeatmap;
