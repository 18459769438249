import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/reft";
import * as fromActions from "../actions/reft";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  if (ga === 0 && agencia === 0) ga = parseInt(getUser());

  try {
    const response = yield axios.post(`${BASE_API}/Reft/reft/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getReftTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Reft.");
    yield put(fromActions.getReftTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getReftDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Reft/reftGraficoSituacao`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getReftDoughnutSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getReftDoughnutError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getReftLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Reft/reftGraficoMensal`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getReftLineSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getReftLineError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addReftAction({ payload }) {
  const payloadToSend = {
    headers: headers,
    ind: payload.ind,
    uniorg: payload.uniorg,
    aux: payload.aux,
    mtr: parseInt(getUser()),
    atuacao: payload.action,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Reft/reftAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addReftActionSuccess(response.data.data));
      yield put(fromActions.getReftTable(getLastSearch()));
      yield put(fromActions.getReftDoughnut(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addReftActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addReftComment({ payload }) {
  const payloadToSend = {
    headers: headers,
    ind: payload.ind,
    uniorg: payload.uniorg,
    aux: payload.aux,
    mtr: parseInt(getUser()),
    com: payload.comment,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Reft/reftAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addReftCommentSuccess(response.data.data));
      yield put(fromActions.getReftTable(getLastSearch()));
      yield put(
        fromActions.getReftComments({
          ind: payload.ind,
          uniorg: payload.uniorg,
          info_aux: payload.aux,
        })
      );
      yield put(fromActions.getReftDoughnut(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addReftCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getReftComments({ payload }) {
  const { ind, uniorg, info_aux } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Reft/reftComentarios/`, {
      headers: headers,
      ind,
      uniorg,
      info_aux,
    });
    if (response.status === 200) {
      yield put(fromActions.getReftCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getReftCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchReft() {
  yield takeLatest(fromTypes.GET_REFT_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_REFT_ACTION, addReftAction);
  yield takeLatest(fromTypes.ADD_REFT_COMMENT, addReftComment);
  yield takeLatest(fromTypes.GET_REFT_DOUGHNUT, getReftDoughnut);
  yield takeLatest(fromTypes.GET_REFT_COMMENTS, getReftComments);
  yield takeLatest(fromTypes.GET_REFT_LINE, getReftLine);
}
