import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { Loading, MainMenu, List } from "../components";
import {
  lighterGrey,
  darkGrey,
  boston,
  white,
  santander,
  santander20,
} from "../theme/colors";

import { getAgencyInfo } from "../store/actions/agency";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px 0px",
    minHeight: "100vh",
  },
  titleContainer: {
    padding: "0px 40px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "14px !important",
    color: `${darkGrey} !important`,
  },
  btnContainer: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginTop: "120px",
  },
}));

const AgencyManagement = ({ loading, onGetAgenciesList, onGetAgencyInfo }) => {
  const classes = useStyles();

  const onSearchUniorg = (uniorg) => {
    onGetAgencyInfo(uniorg);
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>
              Gestão de Agências
            </Typography>
          </div>

          <List type="agencies" showCheckbox />
        </Grid>
        <Grid item md={4}>
          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              onClick={() => window.history.back()}
              sx={{
                height: "48px",
                background: white,
                color: `${santander} `,
                border: `1px solid ${santander} `,
                fontFamily: "SantanderMicroText",
                textTransform: "none",
                textDecoration: "none",
                display: "block",
                margin: "auto",
                "&:hover": {
                  background: santander20,
                  color: `${santander} `,
                },
              }}
            >
              Voltar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    onGetAgencyInfo: (uniorg) => dispatch(getAgencyInfo(uniorg)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { loading } = state.auth;
  return { loading, match: ownProps?.match };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(AgencyManagement)
);
