import { toast } from "react-toastify";
import Icons from "./Icons";
import {
  toastWarn,
  black,
  toastInfo,
  toastSuccess,
  toastError,
  darkGrey,
} from "../theme/colors";

export const alertToast = (type, text, title, date) => {
  switch (type) {
    case "warn":
      return toast.warn(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText",
            color: black,
          }}
        >
          {text}
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-warn" customColor={toastWarn} />
          ),
        }
      );
    case "info":
      return toast.info(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText",
            color: black,
          }}
        >
          {text}
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-info" customColor={toastInfo} />
          ),
          autoClose: 15000,
        }
      );
    case "success":
      return toast.success(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText",
            color: black,
          }}
        >
          {text}
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-checkmark" customColor={toastSuccess} />
          ),
        }
      );
    case "error":
      return toast.error(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText",
            color: black,
          }}
        >
          {text}
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-error" customColor={toastError} />
          ),
        }
      );
    case "alert-error":
      return toast.error(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText !important",
            color: `${darkGrey} !important`,
          }}
        >
          <div
            style={{
              fontFamily: "SantanderMicroText !important",
              color: `${darkGrey} !important`,
              fontWeight: "400 !important",
              fontSize: "12px !important",
            }}
          >
            {title} - {date}
          </div>
          <div
            style={{
              fontFamily: "SantanderMicroText",
              color: `${black}`,
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-error" customColor={toastError} />
          ),
          autoClose: false,
        }
      );
    case "alert-warn":
      return toast.warn(
        <div
          style={{
            marginLeft: "16px",
            fontFamily: "SantanderMicroText !important",
            color: `${darkGrey} !important`,
          }}
        >
          <div
            style={{
              fontFamily: "SantanderMicroText !important",
              color: `${darkGrey} !important`,
              fontWeight: "400 !important",
              fontSize: "12px !important",
            }}
          >
            {title} - {date}
          </div>
          <div
            style={{
              fontFamily: "SantanderMicroText",
              color: `${black}`,
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-warn" customColor={toastError} />
          ),
          autoClose: false,
        }
      );
    case "alert-success":
      return toast.success(
        <div
          style={{
            fontFamily: "SantanderMicroText !important",
            color: `${darkGrey} !important`,
            marginLeft: "16px",
          }}
        >
          <div
            style={{
              fontFamily: "SantanderMicroText !important",
              color: `${darkGrey} !important`,
              fontWeight: "400 !important",
              fontSize: "12px !important",
            }}
          >
            {title} - {date}
          </div>
          <div
            style={{
              fontFamily: "SantanderMicroText",
              color: black,
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-checkmark" customColor={toastError} />
          ),
        }
      );
    case "cheque":
      return toast.success(
        <div
          style={{
            fontFamily: "SantanderMicroText !important",
            color: `${darkGrey} !important`,
            marginLeft: "16px",
          }}
        >
          <div
            style={{
              fontFamily: "SantanderMicroText",
              color: black,
            }}
            // dangerouslySetInnerHTML={{ __html: text }}
          >
            <p>
              Cheque {text.nu_cheque} foi tratado como{" "}
              <span style={{ color: text.color }}>{text.ds_tratamento}</span>
            </p>
          </div>
        </div>,
        {
          icon: ({ theme, type }) => (
            <Icons icon="toast-checkmark" customColor={toastError} />
          ),
        }
      );
    default:
      break;
  }
};
