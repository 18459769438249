// @flow

export const GET_KYC_AVAILABILITY = "[KYC] GET_KYC_AVAILABILITY";
export const GET_KYC_AVAILABILITY_SUCCESS =
  "[KYC] GET_KYC_AVAILABILITY_SUCCESS";
export const GET_KYC_AVAILABILITY_ERROR = "[KYC] GET_KYC_AVAILABILITY_ERROR";

export const ADD_KYC_ACTION = "[KYC] ADD_KYC_ACTION";
export const ADD_KYC_ACTION_SUCCESS = "[KYC] ADD_KYC_ACTION_SUCCESS";
export const ADD_KYC_ACTION_ERROR = "[KYC] ADD_KYC_ACTION_ERROR";

export const GET_KYC_PIE_CHART = "[KYC] GET_KYC_PIE_CHART";
export const GET_KYC_PIE_CHART_SUCCESS = "[KYC] GET_KYC_PIE_CHART_SUCCESS";
export const GET_KYC_PIE_CHART_ERROR = "[KYC] GET_KYC_PIE_CHART_ERROR";

export const GET_KYC_LINE_CHART = "[KYC] GET_KYC_LINE_CHART";
export const GET_KYC_LINE_CHART_SUCCESS = "[KYC] GET_KYC_LINE_CHART_SUCCESS";
export const GET_KYC_LINE_CHART_ERROR = "[KYC] GET_KYC_LINE_CHART_ERROR";

type GetKYCAvailability = {
  type: typeof GET_KYC_AVAILABILITY,
  payload: object,
};
type GetKYCAvailabilitySuccess = {
  type: typeof GET_KYC_AVAILABILITY_SUCCESS,
  payload: Array,
};
type GetKYCAvailabilityError = {
  type: typeof GET_KYC_AVAILABILITY_ERROR,
  payload: any,
};

type AddKYCAction = {
  type: typeof ADD_KYC_ACTION,
  payload: object,
};
type AddKYCActionSuccess = {
  type: typeof ADD_KYC_ACTION_SUCCESS,
  payload: any,
};
type AddKYCActionError = {
  type: typeof ADD_KYC_ACTION_ERROR,
  payload: any,
};

type GetKYCPieChart = {
  type: typeof GET_KYC_PIE_CHART,
  payload: object,
};
type GetKYCPieChartSuccess = {
  type: typeof GET_KYC_PIE_CHART_SUCCESS,
  payload: Array,
};
type GetKYCPieChartError = {
  type: typeof GET_KYC_PIE_CHART_ERROR,
  payload: any,
};

type GetKYCLineChart = {
  type: typeof GET_KYC_LINE_CHART,
  payload: object,
};
type GetKYCLineChartSuccess = {
  type: typeof GET_KYC_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetKYCLineChartError = {
  type: typeof GET_KYC_LINE_CHART_ERROR,
  payload: any,
};

export type KYCAction =
  | GetKYCAvailability
  | GetKYCAvailabilitySuccess
  | GetKYCAvailabilityError
  | AddKYCAction
  | AddKYCActionSuccess
  | AddKYCActionError
  | GetKYCPieChart
  | GetKYCPieChartSuccess
  | GetKYCPieChartError
  | GetKYCLineChart
  | GetKYCLineChartSuccess
  | GetKYCLineChartError;
