import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { black, darkGrey, white, inputSky, boston } from "../theme/colors";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
  error: {
    fontSize: "0.750em",
    color: boston,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
}));

const TextField = ({
  input,
  meta: { touched, error },
  type,
  placeholder,
  icon,
  maxLength,
  onlyNumbers,
  onKeyDown,
  disabled,
  defaultValue,
  parentValueCheck = () => {},
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [inputType, setType] = useState("");
  const [iconType, setIcon] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setType(type);
    setIcon(icon);
  }, [type]);

  useEffect(() => {
    if (defaultValue || defaultValue === "") {
      inputRef.current.focus();
      changeValue(defaultValue.toString());
      inputRef.current.blur();
    }
  }, [defaultValue]);

  const changeValue = (value) => {
    if (onlyNumbers) {
      setInputValue(value.replace(/[^\d]/, ""));
      parentValueCheck(value.replace(/[^\d]/, ""));
    } else {
      setInputValue(value);
      parentValueCheck(value);
    }
  };

  const viewPass = () => {
    if (inputType === "password" && type === "password") {
      setType("text");
      setIcon("security-open");
    } else if (inputType === "text" && type === "password") {
      setType("password");
      setIcon("security-close");
    }
  };

  const getPlaceholderClass = () => {
    if (inputValue === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (inputValue === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (inputValue !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  return (
    <div className={classes.root}>
      <div
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <input
          {...input}
          ref={inputRef}
          error={touched && !!error}
          className={`${classes.input} dss-form-field__input`}
          value={inputValue}
          onChange={(e) => changeValue(e.target.value)}
          type={inputType}
          maxLength={maxLength || "50"}
          onKeyDown={onKeyDown}
          disabled={disabled}
          style={
            touched && !!error ? { border: `0.06em solid ${boston}` } : null
          }
        />
      </div>
      <span className={getPlaceholderClass()}>{placeholder}</span>
      {iconType !== "" ? (
        <div onClick={viewPass}>
          <Icons icon={iconType} isInputIcon clickable={type === "password"} />
        </div>
      ) : null}

      {touched && !!error ? <p className={classes.error}>{error}</p> : null}
    </div>
  );
};

export default TextField;
