import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { signIn, SendPassRequest } from "../store/actions/auth";
import { getUserOperation } from "../store/actions/profile";
import { TextField, Loading, TelemetriaLogo } from "../components";
import { required, onlyNumbers } from "../utils/validators";
import { isAuthenticated } from "../store/services/auth";
import { getOperation } from "../store/services/profile";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { santander, white, mediumGrey, boston } from "../theme/colors";
import { alertToast } from "../components/Toast";

// TO DO: Transformar imagens SVG em componentes
import logoSantanderWhite from "../assets/img/logo-santander-white.svg";
import defaultBG from "../assets/img/default_BG.png";
import bgSP1 from "../assets/img/cidade.png";
import bgRJ1 from "../assets/img/bg-rj-1.png";
import bgRJ2 from "../assets/img/bg-rj-2.png";
import bgRJ3 from "../assets/img/bg-rj-3.png";
import bgRJ4 from "../assets/img/bg-rj-4.png";
import bgRJ5 from "../assets/img/bg-rj-5.png";
import bgRJ6 from "../assets/img/bg-rj-6.png";

const useStyles = makeStyles((theme) => ({
  root: {},
  nav: {
    margin: "0",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) ",
    textAlign: "center",
  },
  logoSantanderWhite: {
    margin: "2.313em",
    height: "2.313em",
  },
  login: {
    marginTop: "48px",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    ["@media (max-height: 645px)"]: {
      display: "block",
      position: "relative",
      margin: "auto",
      transform: "none",
      top: "auto",
      left: "auto",
      paddingBottom: "2em",
    },
  },
  whiteFloater: {
    backgroundColor: "white",
    textAlign: "center",
    margin: "0 auto",
    padding: "2em",
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      width: "684px",
      padding: "60px",
    },
  },
  logoTelemetriaContainer: {
    width: "280px",
    display: "block",
    margin: "auto",
    marginBottom: "3.188em",
  },
  idField: {
    marginBottom: "1.1em",
  },
  passField: {
    marginBottom: "2.250em",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  forgotPass: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    paddingLeft: "0 !important",
  },
  submitBtn: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  emailTitle: {
    fontFamily: "SantanderMicroText",
    fontSize: "20px",
    color: mediumGrey,
    fontWeight: "bold",
    marginBottom: "5px",
  },
  emailSubtitle: {
    fontFamily: "SantanderMicroText",
    fontSize: "16px",
    color: mediumGrey,
    marginTop: "0px",
    marginBottom: "84px",
  },
  backBtn: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    display: "block",
    margin: "auto",
    fontSize: "16px !important",
    padding: "12px 20px !important",
  },
}));

const Login = ({
  loading,
  operation,
  handleSubmit,
  onSignIn,
  onSendPassRequest,
  onGetUserOperation,
}) => {
  const classes = useStyles();
  const submitRef = useRef(null);
  const [currentUserValue, setCurrentUserValue] = useState("");
  const [userNameValue, setUserNameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [background, setBackground] = useState(defaultBG);

  const bgSPList = [bgSP1];
  const bgRJList = [bgRJ1, bgRJ2, bgRJ3, bgRJ4, bgRJ5, bgRJ6];

  useEffect(() => {
    if (isAuthenticated()) {
      window.location.href = "/home";
    }
  }, []);

  useEffect(() => {
    const storageOperation = getOperation();
    getBG(storageOperation);
  }, []);

  useEffect(() => {
    getBG(operation);
  }, [operation]);

  const getBG = (operation) => {
    switch (operation) {
      case "default":
        setBackground(defaultBG);
        break;
      case "SP":
        setBackground(bgSPList[Math.floor(Math.random() * bgSPList.length)]);
        break;
      case "RJ":
        setBackground(bgRJList[Math.floor(Math.random() * bgRJList.length)]);
      default:
        break;
    }
  };

  const onSubmit = ({ UserName, Password }) => {
    onSignIn({ UserName, Password });
  };

  const onKeyPressSubmit = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      submitRef.current.focus();
      submitRef.current.click();
    }
  };

  const changeUser = (user) => {
    setCurrentUserValue(user);
  };

  const requestPasswordChange = () => {
    if (currentUserValue.length === 6) {
      onSendPassRequest({ UserName: currentUserValue });
    } else alertToast("warn", "Digite sua matrícula para recuperar a senha.");
  };

  const changeBG = (id) => {
    onGetUserOperation(id);
  };

  return (
    <div
      className={classes.root}
      style={{
        margin: "0",
        padding: "0",
        minHeight: "100vh",
        background: `url(${background}) center no-repeat`,
        backgroundSize: "cover",
      }}
    >
      {loading ? <Loading /> : null}
      <header>
        <nav className={classes.nav}>
          <img
            src={logoSantanderWhite}
            className={classes.logoSantanderWhite}
            alt="Santander"
          />
        </nav>
      </header>
      <section className={classes.login}>
        <div className={classes.whiteFloater}>
          <div className={classes.logoTelemetriaContainer}>
            <TelemetriaLogo />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.idField}>
              <Field
                type="text"
                maxLength="10"
                name="UserName"
                icon="account-user"
                placeholder="Matrícula"
                parentValueCheck={changeUser}
                component={TextField}
                validate={[required, onlyNumbers]}
                onKeyDown={onKeyPressSubmit}
                onlyNumbers
                value={userNameValue}
                onBlur={(e) => changeBG(e.target.value)}
                onChange={(e) => {
                  setUserNameValue(e.target.value);
                }}
              />
            </div>
            <div className={classes.passField}>
              <Field
                name="Password"
                type="password"
                icon="security-close"
                placeholder="Senha"
                validate={[required]}
                component={TextField}
                onKeyDown={onKeyPressSubmit}
                value={passwordValue}
                onChange={(e) => {
                  setPasswordValue(e.target.value);
                }}
              />
            </div>
            <div className={classes.btnContainer}>
              <Button
                type="submit"
                className={classes.submitBtn}
                disabled={loading}
                ref={submitRef}
              >
                Acessar
              </Button>

              <Button
                type="button"
                className={classes.forgotPass}
                onClick={requestPasswordChange}
              >
                Esqueci minha senha
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: (payload) => dispatch(signIn(payload)),
    onSendPassRequest: (payload) => dispatch(SendPassRequest(payload)),
    onGetUserOperation: (id) => dispatch(getUserOperation(id)),
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.auth;
  const { operation } = state.profile;
  return { loading, operation };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: "signInForm",
      enableReinitialize: true,
    })
  )(Login)
);
