import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "../store/services/auth";
import { RealTimeAlerts, Watermark, TermModal } from "../components";
import { getUserTerms } from "../store/actions/terms";
import { getUser } from "../store/services/auth";

const ProtectedRoute = ({
  onGetUserTerms,
  history,
  token,
  termsToAgree,
  match,
  component: Component,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated()) {
      window.location.href = "/";
    }
  }, [token, Component]);

  useEffect(() => {
    onGetUserTerms(getUser());
  }, []);

  useEffect(() => {
    if (
      termsToAgree.length >= 1 &&
      (window.location.pathname === "/home" ||
        termsToAgree[0].prazo_assinatura <= 0)
    )
      setOpen(true);
    else setOpen(false);
  }, [termsToAgree]);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    else setOpen(false);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated() ? (
          <>
            <TermModal
              open={open}
              onClose={handleClose}
              onOpen={() => setOpen(true)}
            />
            <Watermark />
            <RealTimeAlerts />
            <Component {...props} />
          </>
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserTerms: (id) => dispatch(getUserTerms(id)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { token } = state?.auth;
  const { termsToAgree } = state.terms;
  return { token, termsToAgree, match: ownProps?.match };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
