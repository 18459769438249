import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Loading,
  MenuItems,
  UserInfo,
  TelemetriaLogo,
  Icons,
} from "../components";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Paper } from "@mui/material";
import { darkGrey } from "../theme/colors";
import { getUserInfo } from "../store/actions/profile";
import { getUser } from "../store/services/auth";
import { getAlertsHistory } from "../store/actions/alerts";

const useStyles = makeStyles((theme) => ({
  root: {},
  menu: {
    position: "fixed",
    left: "0",
    top: "0",
    bottom: "0",
    overflowY: "scroll",
    boxShadow: "0px 2px 16px #00000066",
  },
  menuItems: {},
  container: {
    padding: "40px",
  },
  logoContainer: {
    width: "245px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
    paddingTop: "40px",
  },
  subtitle: {
    paddingTop: "8px",
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "16px !important",
    "& span": {
      fontWeight: "bold !important",
    },
  },
  alertTitle: {
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "12px !important",
  },
  alertMsg: {
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "400 !important",
    fontSize: "16px !important",
    "& span": {
      fontWeight: "bold !important",
    },
  },
  alertsPaper: {
    padding: "32px 16px !important",
    maxHeight: "23vh",
    overflowY: "scroll",
    ["@media (min-height: 900px)"]: {
      maxHeight: "30vh",
    },
  },
  alertContainer: {
    display: "flex",
    alignItems: "end",
    paddingBottom: "20px",
  },
  alertIcon: {
    paddingRight: "16px",
  },
}));

const Home = ({ loading, onGetUserInfo, onGetAlertsHistory, userInfo, alertList }) => {
  const classes = useStyles();
  const [carouselAlerts, setCarouselAlerts] = useState([]);

  useEffect(() => {
    onGetUserInfo(getUser());
    onGetAlertsHistory(getUser());
  }, [onGetUserInfo]);

  const getGreeting = () => {
    let currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Bom dia";
    } else if (currentHour < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  };

  const getAlertIcon = (type) => {
    switch (type) {
      case 1:
        return <Icons icon="toast-warn" />;
      case 2:
        return <Icons icon="toast-checkmark" />;
      case 3:
        return <Icons icon="toast-info" />;
      case 4:
        return <Icons icon="toast-error" />;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={2.5}>
          <div className={classes.menu}>
            <div className={classes.menuItems}>
              <MenuItems />
            </div>
          </div>
        </Grid>
        <Grid item md={5.5}>
          <div className={classes.container}>
            <div className={classes.logoContainer}>
              <TelemetriaLogo />
            </div>
            <Typography className={classes.title}>
              {getGreeting()}, {userInfo?.info?.nm_Func}! <br /> Seja
              bem-vindo(a) ao Portal de Telemetria Rede.
            </Typography>
            <Typography className={classes.subtitle}>
              <span>Clique no menu ao lado</span> para ter acesso às suas
              ferramentas de trabalho. Lembre-se de selecionar uma Rede,
              Regional, Agência ou senão um perfil. Em caso de dúvidas,{" "}
              <span>entre em contato com nosso suporte.</span>
            </Typography>
            {alertList.length > 0 ? (
              <div>
                <Typography className={classes.title}>Alertas</Typography>
                <br />
                <Paper variant="outlined" className={classes.alertsPaper}>
                  {alertList.map((i, index) => (
                    <div className={classes.alertContainer} key={index}>
                      <div className={classes.alertIcon}>
                        {getAlertIcon(i.tp_Info)}
                      </div>
                      <div>
                        <Typography className={classes.alertTitle}>{`${
                          i.dt_Alerta
                        } - ${i.titulo ? i.titulo : ""}`}</Typography>
                        <Typography
                          className={classes.alertMsg}
                          dangerouslySetInnerHTML={{ __html: i.texto }}
                        ></Typography>
                      </div>
                    </div>
                  ))}
                </Paper>
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item md={4}>
          <UserInfo />
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onGetAlertsHistory: (id) => dispatch(getAlertsHistory(id)),
  };
};

const mapStateToProps = (state) => {
  const { loading, userInfo } = state.profile;
  const { alertList } = state.alerts;
  return { loading, userInfo, alertList };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Home)
);
