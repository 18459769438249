import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/terms";
import * as fromActions from "../actions/terms";
import { reset } from "redux-form";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, getUser, logout } from "../services/auth";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getUserTerms({ id }) {
  try {
    const response = yield axios.get(
      `${BASE_API}/TermoConf/getTermosPendentes/${id}`,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getUserTermsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getUserTermsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* agreeWithTerm({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/TermoConf/addTermoUsuario/`,
      {
        idTermo: payload.id,
        cd_matricula: payload.cd_matricula,
        headers: headers,
      }
    );
    if (response.status === 200) {
      alertToast("success", "Termo assinado com sucesso.");
      yield put(fromActions.agreeWithTermSuccess(response.data.data));
      yield put(fromActions.getUserTerms(payload.cd_matricula));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível assinar o termo.");
    yield put(fromActions.agreeWithTermError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchTerms() {
  yield takeLatest(fromTypes.GET_USER_TERMS, getUserTerms);
  yield takeLatest(fromTypes.AGREE_WITH_TERM, agreeWithTerm);
}
