import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Countdown, Task } from "../components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { mediumGrey, lightGrey } from "../theme/colors";
import Icons from "./Icons";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "auto",
    width: "100%",
  },
  drawerTitle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ChecklistDrawer = ({
  title,
  showClock,
  finalHour,
  taskList,
  width,
  currentPage,
}) => {
  const classes = useStyles();
  const [shouldExpand, setShouldExpand] = useState(true);

  useEffect(() => {
    setShouldExpand(true);
  }, []);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <div className={classes.root}>
      <Accordion
        expanded={shouldExpand}
        sx={{
          border: "none",
          boxShadow: "none",
          width: "95%",
          display: "block",
          margin: "auto",
          marginLeft: "6px !important",
        }}
      >
        <AccordionSummary
          sx={{ padding: "0px", maxHeight: "87px", cursor: "default" }}
        >
          <div className={classes.drawerTitle}>
            <Typography
              onClick={() => setShouldExpand(!!!shouldExpand)}
              sx={{
                fontFamily: "SantanderMicroText",
                color: mediumGrey,
                fontWeight: "bold",
                verticalAlign: "middle",
              }}
            >
              {title}
            </Typography>
          </div>
          <Countdown finalHour={finalHour} showClock={showClock} />
          {!shouldExpand ? (
            <LightTooltip title="Expandir">
              <span>
                <Button
                  onClick={() => setShouldExpand(!!!shouldExpand)}
                  sx={{ minWidth: "16px" }}
                >
                  <Icons icon="chevron-down" customColor={lightGrey} />
                </Button>
              </span>
            </LightTooltip>
          ) : (
            <LightTooltip title="Diminuir">
              <span>
                <Button
                  onClick={() => setShouldExpand(!!!shouldExpand)}
                  sx={{ minWidth: "16px" }}
                >
                  <Icons icon="chevron-up" customColor={lightGrey} />
                </Button>
              </span>
            </LightTooltip>
          )}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>
          {taskList.map((i, index) => {
            return (
              <Task
                key={index}
                task={i}
                shouldExpand={shouldExpand}
                currentPageInfo={currentPage}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ChecklistDrawer;
