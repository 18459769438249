import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Box, Drawer, Typography, Button } from "@mui/material";
import {
  santander20,
  santander,
  white,
  boston,
  darkGrey,
  mediumGrey,
} from "../theme/colors";
import { getUser } from "../store/services/auth";
import { getATMComments, sendATMComments } from "../store/actions/atm";
import {
  getStoreComments,
  sendStoreComments,
} from "../store/actions/storeAvailability";
import { addExoComment, getExoComments } from "../store/actions/exo";
import { addNpsComment, getNpsComments } from "../store/actions/nps";
import { addQueuesComment, getQueuesComments } from "../store/actions/queues";
import { addReftComment, getReftComments } from "../store/actions/reft";
import { addNqComment, getNqComments } from "../store/actions/nq";
import {
  addManifestationComment,
  getManifestationComments,
} from "../store/actions/manifestation";
import { addCheckComment, getCheckComments } from "../store/actions/check";
import {
  getActionCategories,
  getIndicatorComments,
  addIndicatorComment,
} from "../store/actions/bulletin";
import { alertToast } from "./Toast";
import { Icons } from "./";

const useStyles = makeStyles((theme) => ({
  root: {},
  logoOpen: {
    cursor: "pointer",
  },
  list: {
    padding: "1em !important",
  },
  drawerLogo: {
    margin: "2em 1em",
  },
  listItem: {
    padding: "1em 0.6em !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    fontFamily: "SantanderHeadline",
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    width: "100%",
    paddingLeft: "16px",
  },
  btnHolder: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
    padding: "20px 0px 20px 0px !important",
  },
  commentsContainer: {
    height: "60vh",
    overflowY: "scroll",
  },
  textArea: {
    width: "457px",
    height: "70px",
    display: "block",
    margin: "auto",
    marginTop: "20px",
  },
  hrContainer: {
    position: "relative",
    height: "18px",
    padding: "16px 0px 16px 0px",
  },
  hr: {
    position: "absolute",
    width: "90%",
    border: `1px solid ${mediumGrey}`,
    opacity: "0.3",
  },
  hrDate: {
    position: "absolute !important",
    color: `${mediumGrey} !important`,
    background: `${white} !important`,
    fontSize: "12px !important",
    left: "45%",
    transform: "translateX(-45%)",
  },
  author: {
    color: `${darkGrey} !important`,
    fontSize: "12px !important",
    fontFamily: "SantanderMicroText !important",
  },
  comment: {
    color: `${darkGrey} !important`,
    fontSize: "16px !important",
    fontFamily: "SantanderMicroText !important",
  },
  categories: {},
  selectedCategory: {
    border: `1px solid ${santander} !important`,
    background: `${santander} !important`,
    fontFamily: "SantanderMicroText !important",
    color: `${white} !important`,
    borderRadius: "19px !important",
    textTransform: "none !important",
    margin: "4px 8px 4px 0px !important",
  },
  commonCategory: {
    border: `1px solid ${santander} !important`,
    background: `${white} !important`,
    fontFamily: "SantanderMicroText !important",
    color: `${santander} !important`,
    borderRadius: "19px !important",
    textTransform: "none !important",
    margin: "4px 8px 4px 0px !important",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeDrawerBtn: {
    background: "none",
    border: "none",
    cursor: "pointer",
  },
}));

const CommentDrawer = ({
  type,
  shouldOpen,
  onCloseMethod,
  onGetActionCategories,
  onGetIndicatorComments,
  onAddIndicatorComments,
  actionCategories,
  indicatorComments,
  issue,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [btnText, setBtnText] = useState("Salvar");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (issue.uniorg) {
      onGetIndicatorComments({
        uniorg: issue.uniorg,
        ind: issue.ind,
        info_aux: issue.aux,
      });
      onGetActionCategories(issue.ind);
    }
    /* 
    if (type === "atm" && issue.equipment !== "") {
      onGetATMComments({
        uniorg: issue.uniorg,
        ind: issue.ind,
        info_aux: issue.aux,
      });
      onGetActionCategories(issue.ind);
    }

    if (type === "store" && issue.id) onGetStoreComments({ uniorg: issue.id });
    if (type === "exo" && issue.nuDoc)
      onGetExoComments({
        uniorg: issue.uniorg,
        nuDoc: issue.nuDoc,
        finalProduct: issue.finalProduct,
        date: issue.date,
      });
    if (type === "nps" && issue.pernumper)
      onGetNpsComments({
        uniorg: issue.uniorg,
        pernumper: issue.pernumper,
        ind: issue.ind,
      });
    if (type === "queues" && issue.uniorg) {
      onGetQueuesComments({
        uniorg: issue.uniorg,
        ind: issue.ind,
      });
      onGetActionCategories(issue.ind);
    }
    if (type === "reft" && issue.aux)
      onGetReftComments({
        ind: issue.ind,
        uniorg: issue.uniorg,
        info_aux: issue.aux,
      });
    if (type === "nq" && issue.aux) {
      onGetNqComments({
        uniorg: issue.uniorg,
        ind: issue.ind,
        info_aux: issue.aux,
      });
    }
    if (type === "manifestacao" && issue.info_aux) {
      onGetManifestationComments({
        ind: issue.ind,
        uniorg: issue.uniorg,
        info_aux: issue.info_aux,
      });
    }
    */
  }, [issue]);

  useEffect(() => {
    if (type === "exo" && indicatorComments.length >= 1) {
      let partesData = indicatorComments[0].dt_reg.split(" ")[0];
      partesData = partesData.split("/");
      let dtReg = new Date(partesData[2], partesData[1] - 1, partesData[0]);
      if (
        dtReg.toLocaleDateString("en-US") ===
        new Date().toLocaleDateString("en-US")
      ) {
        setComment(indicatorComments[0].comentario);
        setBtnText("Alterar");
      }
    } else {
      setComment("");
      setBtnText("Salvar");
    }
  }, [indicatorComments]);

  const getTypeCommentsContainer = () => {
    switch (type) {
      case "atm":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Equipamento {issue.equipment}
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>
            <div className={classes.commentsContainer}>
              <Typography className={classes.comment}>
                Selecione o motivo de atuação:
              </Typography>
              <div className={classes.categories}>
                {actionCategories.map((i, index) => (
                  <Button
                    variant="contained"
                    onClick={() => setSelectedCategory(i)}
                    className={
                      selectedCategory.cd_opc === i.cd_opc
                        ? classes.selectedCategory
                        : classes.commonCategory
                    }
                  >
                    {i.opc_comentario}
                  </Button>
                ))}
              </div>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          {index !== 0 &&
                          i.dt_reg.split(" ")[0] !==
                            indicatorComments[index - 1].dt_reg.split(
                              " "
                            )[0] ? (
                            <div className={classes.hrContainer}>
                              <hr className={classes.hr} />
                              <Typography className={classes.hrDate}>
                                {i.dt_reg.split(" ")[0]}
                              </Typography>
                            </div>
                          ) : index === 0 ? (
                            <div className={classes.hrContainer}>
                              <hr className={classes.hr} />
                              <Typography className={classes.hrDate}>
                                {i.dt_reg.split(" ")[0]}
                              </Typography>
                            </div>
                          ) : (
                            <br />
                          )}
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.opc_comentario}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "store":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Agência {issue.uniorgName} - {issue.id}
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "exo":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: EXO Documento {issue.nuDoc}
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "queues":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Filas agência {issue.uniorg}
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              <Typography className={classes.comment}>
                Selecione o motivo de atuação:
              </Typography>
              <div className={classes.categories}>
                {actionCategories.map((i, index) => (
                  <Button
                    variant="contained"
                    onClick={() => setSelectedCategory(i)}
                    className={
                      selectedCategory.cd_opc === i.cd_opc
                        ? classes.selectedCategory
                        : classes.commonCategory
                    }
                  >
                    {i.opc_comentario}
                  </Button>
                ))}
              </div>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.opc_comentario}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "reft":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Reft agência {issue.agencia}
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}{" "}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "nps":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>Atuação: NPS</Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "nq":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>Atuação: NQ</Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - {i.nm_func} -{" "}
                            {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "manifestacao":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Manifestações
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - Nº: {issue.aux} -{" "}
                            {i.nm_func} - {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      case "check":
        return (
          <>
            <div className={classes.drawerHeader}>
              <Typography className={classes.title}>
                Atuação: Compensação de cheques
              </Typography>
              <button
                className={classes.closeDrawerBtn}
                onClick={onCloseMethod}
              >
                <Icons icon="close" />
              </button>
            </div>

            <div className={classes.commentsContainer}>
              {indicatorComments.length > 0 ? (
                <>
                  {indicatorComments.map((i, index) => (
                    <>
                      {i.opc_comentario === "Outros" && !i.comentario ? null : (
                        <div key={index}>
                          <div className={classes.hrContainer}>
                            <hr className={classes.hr} />
                            <Typography className={classes.hrDate}>
                              {i.dt_reg.split(" ")[0]}
                            </Typography>
                          </div>
                          <Typography className={classes.author}>
                            {i.dt_reg.split(" ")[1]} - Nº: {issue.aux} -{" "}
                            {i.nm_func} - {i.cd_matricula}
                          </Typography>
                          <Typography className={classes.comment}>
                            {i.comentario}
                          </Typography>
                        </div>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </div>
          </>
        );
      default:
        break;
    }
  };

  const sendComment = () => {
    if (type === "atm" || type === "queues") {
      if (selectedCategory.opc_comentario === "Outros" && comment === "")
        alertToast("warn", "Você deve digitar um comentário.");
      else {
        onAddIndicatorComments({
          ind: issue.ind,
          uniorg: issue.uniorg,
          aux: issue.aux,
          comment: comment,
          category: selectedCategory.cd_opc,
          atuacao: issue.atuacao,
        });
        setComment("");
        setSelectedCategory("");
        /*
        switch (type) {
          case "atm":
            onSendATMComments({
              uniorg: issue.uniorg,
              ind: issue.ind,
              action: issue.action,
              aux: issue.aux,
              comentario: comment,
              Category: selectedCategory,
            });
            break;
          case "queues":
            onAddQueuesComment({
              ind: issue.ind,
              uniorg: issue.uniorg,
              comment: comment,
              category: selectedCategory,
            });
            break;
          default:
            break;
        }
        */
      }
    } else if (comment !== "") {
      onAddIndicatorComments({
        ind: issue.ind,
        uniorg: issue.uniorg,
        aux: issue.aux,
        comment: comment,
        atuacao: issue.atuacao,
      });
      /*
      switch (type) {
        case "store":
          onSendStoreComments({
            ind: issue.ind,
            uniorg: issue.uniorg,
            aux: "",
            atuacao: issue.action,
            com: comment,
          });
          break;
        case "exo":
          onAddExoComment({
            nuDoc: issue.nuDoc,
            nu_Prop: issue.nu_Prop,
            nu_Cont_Ag: issue.nu_Cont_Ag,
            tpPerson: issue.tpPerson,
            uniorg: issue.uniorg,
            finalProduct: issue.finalProduct,
            date: issue.date,
            urgStatus: issue.urgStatus,
            comentario: comment,
          });
          break;
        case "reft":
          onAddReftComment({
            ind: issue.ind,
            uniorg: issue.uniorg,
            aux: issue.aux,
            comment: comment,
          });
          break;
        case "nps":
          onAddNpsComment({
            uniorg: issue.uniorg,
            action: issue.action,
            pernumper: issue.pernumper,
            ind: issue.ind,
            comentario: comment,
          });
          break;
        case "nq":
          onAddNqComment({
            ind: issue.ind,
            aux: issue.idAlerta,
            uniorg: issue.uniorg,
            action: issue.action,
            comentario: comment,
          });
          break;
        case "manifestacao":
          onAddManifestationComment({
            uniorg: issue.uniorg,
            ind: issue.ind,
            aux: issue.info_aux,
            atuacao: issue.atuacao === "False" || comment ? true : false,
            comentario: comment,
          });
          break;
        default:
          break;
      }
      */
      setComment("");
      setSelectedCategory("");
    } else {
      alertToast("warn", "Digite um comentário.");
    }
  };

  return (
    <Drawer
      anchor={"right"}
      open={shouldOpen}
      onClose={onCloseMethod}
      sx={{ "& .MuiDrawer-paper": { borderRadius: "5px" } }}
    >
      <Box
        sx={{ width: 492, padding: "16px", boxSizing: "border-box" }}
        role="presentation"
      >
        {getTypeCommentsContainer()}
        <div>
          <textarea
            className={classes.textArea}
            maxLength="255"
            type="text"
            value={comment}
            disabled={
              (type === "atm" || type === "queues") && selectedCategory === ""
            }
            onChange={(e) => setComment(e.target.value)}
          />
          <div className={classes.btnHolder}>
            <Button
              variant="contained"
              onClick={onCloseMethod}
              sx={{
                height: "48px",
                fontFamily: "SantanderMicroText ",
                background: white,
                textTransform: "none ",
                color: `${santander} `,
                border: `1px solid ${santander} `,
                display: "block",
                fontSize: "16px ",
                "&:hover": {
                  background: santander20,
                  color: `${santander} `,
                },
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                sendComment();
                setComment("");
              }}
              disabled={
                (type === "atm" || type === "queues") && selectedCategory === ""
              }
              sx={{
                height: "48px",
                background: boston,
                color: white,
                fontFamily: "SantanderMicroText",
                textTransform: "none",
                textDecoration: "none",
                "&:hover": {
                  background: boston,
                  color: white,
                },
              }}
            >
              {btnText}
            </Button>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetATMComments: (payload) => dispatch(getATMComments(payload)),
    onSendATMComments: (payload) => dispatch(sendATMComments(payload)),
    onGetStoreComments: (payload) => dispatch(getStoreComments(payload)),
    onSendStoreComments: (payload) => dispatch(sendStoreComments(payload)),
    onGetExoComments: (payload) => dispatch(getExoComments(payload)),
    onAddExoComment: (payload) => dispatch(addExoComment(payload)),
    onGetNpsComments: (payload) => dispatch(getNpsComments(payload)),
    onAddNpsComment: (payload) => dispatch(addNpsComment(payload)),
    onGetQueuesComments: (payload) => dispatch(getQueuesComments(payload)),
    onAddQueuesComment: (payload) => dispatch(addQueuesComment(payload)),
    onGetReftComments: (payload) => dispatch(getReftComments(payload)),
    onAddReftComment: (payload) => dispatch(addReftComment(payload)),
    onGetNqComments: (payload) => dispatch(getNqComments(payload)),
    onAddNqComment: (payload) => dispatch(addNqComment(payload)),
    onGetManifestationComments: (payload) =>
      dispatch(getManifestationComments(payload)),
    onAddManifestationComment: (payload) =>
      dispatch(addManifestationComment(payload)),
    onGetCheckComments: (payload) => dispatch(getCheckComments(payload)),
    onAddCheckComment: (payload) => dispatch(addCheckComment(payload)),
    onGetActionCategories: (ind) => dispatch(getActionCategories(ind)),
    onGetIndicatorComments: (payload) =>
      dispatch(getIndicatorComments(payload)),
    onAddIndicatorComments: (payload) => dispatch(addIndicatorComment(payload)),
  };
};

const mapStateToProps = (state) => {
  const { atmComments } = state.atm;
  const { storeComments } = state.storeAvailability;
  const { exoComments } = state.exo;
  const { queuesComments } = state.queues;
  const { reftComments } = state.reft;
  const { npsComments } = state.nps;
  const { nqComments } = state.nq;
  const { manifestationComments } = state.manifestation;
  const { checkComments } = state.check;
  const { actionCategories, indicatorComments } = state.bulletin;
  return {
    atmComments,
    storeComments,
    exoComments,
    queuesComments,
    reftComments,
    npsComments,
    nqComments,
    manifestationComments,
    checkComments,
    actionCategories,
    indicatorComments,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommentDrawer
);
