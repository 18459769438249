import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography, Tooltip, Button, tooltipClasses } from "@mui/material";
import {
  Loading,
  Table,
  BulletinSkeleton,
  BackToTopButton,
  Modal,
  Icons,
} from "../../components";
import { HeaderComp } from "../../components/compensacao";
import {
  bulletinGrey,
  santander,
  black,
  white,
  darkGrey,
  mediumGrey,
  lighterGrey,
  black2,
  darkerGrey2,
} from "../../theme/colors";
import { alertToast } from "../../components/Toast";

import { sendLog } from "../../store/actions/analytics";

//import publico from "../assets/img/publico.svg";
import interno from "../../assets/img/interno.svg";
import { getBucketInfo } from "../../store/sagas/comp";
import Act from "../../components/compensacao/Act";
import WorkedOut from "../../components/compensacao/WorkedOut";
import { getUserLvl } from "../../store/services/auth";
//import confidencial from "../assets/img/confidencial.svg";
//import confidencialRestrito from "../assets/img/confidencial_restrito.svg";
//import secreto from "../assets/img/secreto.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },

  checkInfosContainer: {
    padding: "15px 0 20px 0",
    height: "73px",
    width: "100%",
    backgroundColor: lighterGrey,
    borderTop: `1px solid ${mediumGrey}`,
    borderBottom: `1px solid ${mediumGrey}`,
  },

  infosTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  infoContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    border: `1px solid ${black}`,
    borderRadius: "4px",
    fontFamily: "SantanderMicroText",
    color: darkGrey,
  },

  infoTitle: {
    fontFamily: "SantanderMicroText!important",
    fontSize: "16px !important",
  },
  checkContainer: {
    margin: "15px 0 60px 0",
    display: "flex",
  },

  checkImgsContentContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    gap: "45px",
  },
  checkImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > span": {
      fontFamily: "SantanderMicroText !important",
      color: darkerGrey2,
      fontSize: "16px",
      fontWeight: "bold",
      transform: "rotate(-90deg)",
      whiteSpace: "nowrap",
      paddingTop: "100px",
    },
  },

  checkImgInfos: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "78px 0 0 21px",
    "&  span": {
      fontFamily: "SantanderMicroText !important",
      color: black2,
      fontSize: "16px",
      fontWeight: "bold",
    },
  },

  infoToCopy: {
    ontFamily: "SantanderMicroText !important",
    fontSize: "16px",
    color: black2,
    textDecoration: "underline",
    cursor: "pointer",
  },

  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },

  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
}));

const WorkComp = ({ gaInfo, loading, onSendLog, bucketInfo }) => {
  const classes = useStyles();
  const [pageState, setPageState] = useState("padrao");
  const ref = useRef(null);
  const [isPanel, setIsPanel] = useState(false);

  const getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };

  useEffect(() => {
    if (getUserLvl() === "1" || getUserLvl() === "4") {
      setPageState("padrao");
    } else {
      setPageState("gerente");
    }
  }, []);

  const onSetPageState = (state) => {
    setPageState(state);
  };

  const renderAction = (param) => {
    switch (param) {
      case "trabalhados":
      case "gerente":
        return <WorkedOut />;
      default:
        return <Act />;
    }
  };

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <HeaderComp onSetPageState={onSetPageState} type={pageState} />
      {renderAction(pageState)}
      <BackToTopButton />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSendLog: (payload) => dispatch(sendLog(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { bucketInfo } = state.comp;
  return { bucketInfo };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(WorkComp)
);
