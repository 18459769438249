import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/nq";
import * as fromActions from "../actions/nq";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser, getUserLvl } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/Nq/Nq/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getNqTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Nq.");
    yield put(fromActions.getNqTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNqDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Nq/graficoSituacao`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getNQDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getNQDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNQLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Nq/graficoMensal`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getNQLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getNQLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addNQAction({ payload }) {
  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  const payloadToSend = {
    uniorg: payload.uniorg,
    mtr: parseInt(getUser()),
    aux: payload.aux,
    ind: payload.ind,
    atuacao: payload.action,
    com: payload.com,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Nq/NqAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addNqActionSuccess(response.data.data));
      yield put(fromActions.getNqTable(getLastSearch()));
      yield put(fromActions.getNQDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addNqActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addNQComment({ payload }) {
  const payloadToSend = {
    uniorg: payload.uniorg,
    mtr: parseInt(getUser()),
    aux: payload.aux,
    ind: payload.ind,
    atuacao: payload.action,
    com: payload.comentario,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Nq/NqAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addNqCommentSuccess(response.data.data));
      yield put(fromActions.getNqTable(getLastSearch()));
      yield put(
        fromActions.getNqComments({
          uniorg: payload.uniorg,
          ind: payload.ind,
          aux: payload.aux,
        })
      );
      yield put(fromActions.getNQDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addNqCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getNqComments({ payload }) {
  const { uniorg, ind, aux } = payload;

  let convertDate = new Date(Date.parse(payload.date));
  let formatDate = `${convertDate.getFullYear()}-${String(
    convertDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertDate.getDate()).padStart(2, "0")}`;

  try {
    const response = yield axios.post(`${BASE_API}/Nq/nqComentarios/`, {
      headers: headers,
      uniorg: uniorg,
      ind: ind,
      info_aux: aux,
    });
    if (response.status === 200) {
      yield put(fromActions.getNqCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getNqCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchNQ() {
  yield takeLatest(fromTypes.GET_NQ_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_NQ_ACTION, addNQAction);
  yield takeLatest(fromTypes.ADD_NQ_COMMENT, addNQComment);
  yield takeLatest(fromTypes.GET_NQ_DOUGHNUT, getNqDoughnut);
  yield takeLatest(fromTypes.GET_NQ_COMMENTS, getNqComments);
  yield takeLatest(fromTypes.GET_NQ_LINE, getNQLine);
}
