// @flow

import * as fromTypes from "../types/comp";

export const getBucketInfo = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_BUCKET_INFO,
  payload,
});
export const getBucketInfoSuccess = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_BUCKET_INFO_SUCCESS,
  payload,
});
export const getBucketInfoError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_BUCKET_INFO_ERROR,
  payload,
});

export const getSheetPaperInfo = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_SHEET_PAPER,
  payload,
});
export const getSheetPaperInfoSuccess = (
  payload: any
): fromTypes.CompAction => ({
  type: fromTypes.GET_SHEET_PAPER_SUCCESS,
  payload,
});
export const getSheetPaperInfoError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_SHEET_PAPER_ERROR,
  payload,
});

export const getWorkedOutTable = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_WORKED_OUT_TABLE,
  payload,
});
export const getWorkedOutTableSuccess = (
  payload: any
): fromTypes.CompAction => ({
  type: fromTypes.GET_WORKED_OUT_TABLE_SUCCESS,
  payload,
});
export const getWorkedOutTableError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_WORKED_OUT_TABLE_ERROR,
  payload,
});

export const getDoughnutChart = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_DOUGHNUT_CHART,
  payload,
});
export const getDoughnutChartSuccess = (
  payload: any
): fromTypes.CompAction => ({
  type: fromTypes.GET_DOUGHNUT_CHART_SUCCESS,
  payload,
});
export const getDoughnutChartError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_DOUGHNUT_CHART_ERROR,
  payload,
});

export const getLineChart = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_LINE_CHART,
  payload,
});
export const getLineChartSuccess = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_LINE_CHART_SUCCESS,
  payload,
});
export const getLineChartError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_LINE_CHART_ERROR,
  payload,
});

export const removeGA = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.REMOVE_GA,
  payload,
});
export const removeGASuccess = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.REMOVE_GA_SUCCESS,
  payload,
});
export const removeGAError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.REMOVE_GA_ERROR,
  payload,
});

export const getTotalTrab = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_TOTAL_TRAB,
  payload,
});
export const getTotalTrabSuccess = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_TOTAL_TRAB_SUCCESS,
  payload,
});
export const getTotalTrabError = (payload: any): fromTypes.CompAction => ({
  type: fromTypes.GET_TOTAL_TRAB_ERROR,
  payload,
});
