// @flow

import * as fromTypes from "../types/analytics";

export const sendLog = (payload: object): fromTypes.AnalyticsAction => ({
  type: fromTypes.SEND_LOG,
  payload,
});
export const sendLogSuccess = (payload: any): fromTypes.AnalyticsAction => ({
  type: fromTypes.SEND_LOG_SUCCESS,
  payload,
});
export const sendLogError = (payload: any): fromTypes.AnalyticsAction => ({
  type: fromTypes.SEND_LOG_ERROR,
  payload,
});
