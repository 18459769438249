import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/profile";
import * as fromActions from "../actions/profile";
import { logout } from "../services/auth";
import { reset } from "redux-form";
import { getBaseAPI } from "../services/api";
import { setOperation } from "../services/profile";
import { alertToast } from "../../components/Toast";

const BASE_API = `${getBaseAPI()}`;

export function* getGAInfo({ id }) {
  try {
    const response = yield axios.get(
      `${BASE_API}/Usuario/carteiraAgencias/${id}`
    );
    if (response.status === 200) {
      yield put(fromActions.getGAInfoSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getGAInfoError(err));
  }
}

export function* getSupervisorInfo({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/carteiraGAs/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getSupervisorInfoSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getSupervisorInfoError(err));
  }
}

export function* getUsers({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/infoUsuarios/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getUsersSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível recuperar a lista de usuários.");
    yield put(fromActions.getUsersError(err));
  }
}

export function* registerUser({ payload }) {
  let {
    Nm_Func,
    Cd_Tratamento,
    Nm_Exibicao,
    NomeExibicao,
    Cd_Matricula,
    Email,
    Ddd,
    Telefone,
    Cd_Perfil,
    Cd_Cargo,
    Cd_Gestor,
  } = payload;
  Cd_Tratamento = parseInt(Cd_Tratamento);
  Cd_Matricula = parseInt(Cd_Matricula);
  Cd_Perfil = parseInt(Cd_Perfil);
  Cd_Cargo = parseInt(Cd_Cargo);
  Cd_Gestor = parseInt(Cd_Gestor);
  try {
    const response = yield axios.post(`${BASE_API}/Usuario/addUsuario/`, {
      Nm_Func,
      Cd_Tratamento,
      Nm_Exibicao,
      NomeExibicao,
      Cd_Matricula,
      Email,
      Ddd,
      Telefone,
      Cd_Perfil,
      Cd_Cargo,
      Cd_Gestor,
    });
    if (response.status === 200) {
      yield put(fromActions.registerUserSuccess(response.data.data));
      alertToast("success", "Usuário cadastrado com sucesso!");
      setTimeout(() => {
        window.location.href = `/perfil/${Cd_Matricula}`;
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível cadastrar o usuário.");
    yield put(fromActions.registerUserError(err));
  }
}

export function* getUserDetails({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getUsuario/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getUserDetailsSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível acessar as informações do usuário.");
    yield put(fromActions.getUserDetailsError(err));
  }
}

export function* updateUser({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/Usuario/updateUsuario/`,
      payload
    );
    if (response.status === 200) {
      yield put(fromActions.registerUserSuccess(response.data.data));
      alertToast("success", "Usuário atualizado com sucesso.");
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível atualizar o usuário.");
    yield put(fromActions.registerUserError(err));
  }
}

export function* deleteUser({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/delUsuario/${id}`);
    if (response.status === 200) {
      yield put(fromActions.deleteUserSuccess(response.data.data));
      alertToast("success", "Usuário deletado com sucesso!");
      setTimeout(() => {
        window.location.href = `/usuarios`;
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível apagar o usuário.");
    yield put(fromActions.deleteUserError(err));
  }
}

export function* getGenders() {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getTratamentos`);
    if (response.status === 200) {
      yield put(fromActions.getGendersSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getGendersError(err));
  }
}

export function* getTypes() {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getPerfisUsuario`);
    if (response.status === 200) {
      yield put(fromActions.getTypesSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getTypesError(err));
  }
}

export function* getUniorgList({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/infoAgencias/`);
    if (response.status === 200) {
      yield put(fromActions.getUniorgListSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível recuperar a lista de agências.");
    yield put(fromActions.getUniorgListError(err));
  }
}

export function* changeGASelection({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/Usuario/addVinculoGAAgencia`,
      payload
    );
    if (response.status === 200) {
      yield put(
        fromActions.changeSupervisorSelectionSuccess(response.data.data)
      );
      alertToast("success", "Carterização editada com sucesso!");
      yield put(fromActions.getGAInfo(payload?.matricula));
      yield put(fromActions.getUniorgList());
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.changeSupervisorSelectionError(err));
    alertToast("error", "Não foi possível editar a carterização.");
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* changeSupervisorSelection({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/Usuario/addVinculoGestorGA`,
      payload
    );
    if (response.status === 200) {
      yield put(
        fromActions.changeSupervisorSelectionSuccess(response.data.data)
      );
      alertToast("success", "Carterização editada com sucesso!");
      yield put(fromActions.getSupervisorInfo(payload?.cd_Matricula_Gestor));
      yield put(fromActions.getUsers(0));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.changeSupervisorSelectionError(err));
    alertToast("error", "Não foi possível editar a carterização.");
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getRoles() {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getCargosUsuario`);
    if (response.status === 200) {
      yield put(fromActions.getRolesSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getRolesError(err));
  }
}

export function* getSupervisors() {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getGestores`);
    if (response.status === 200) {
      yield put(fromActions.getSupervisorsSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getSupervisorsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* sendPassRequest({ payload }) {
  let { UserName, Password, ConfirmPassword } = payload;
  try {
    const responsePass = yield axios.post(
      `${BASE_API}/Account/CodePasswReset`,
      { UserName }
    );
    if (responsePass.status === 200) {
      try {
        const Code = responsePass.data;
        delete axios.defaults.headers.common["Authorization"];
        const responseFinal = yield axios.post(
          `${BASE_API}/Account/ResetPassword`,
          {
            UserName,
            Password,
            ConfirmPassword,
            Code,
          }
        );
        if (responseFinal.status === 200) {
          yield put(fromActions.changeProfilePassSuccess());
          yield put(reset("authChangePassForm"));
          alertToast(
            "success",
            "Senha trocada com sucesso! Por favor, entre novamente..."
          );
          logout();
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        }
      } catch (err) {
        alertToast(
          "error",
          "Não foi possível trocar de senha. Verifique se todos os requisitos foram cumpridos."
        );
        console.log(err);
        yield put(fromActions.changeProfilePassError(err));
      }
    }
  } catch (err) {
    alertToast("error", "Não foi possível trocar a senha.");
    yield put(fromActions.changeProfilePassError(err));
  }
}

export function* getProfileById({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getPerfilM/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getProfileByIdSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getProfileByIdError(err));
  }
}

export function* getProfile() {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getPerfil`);
    if (response.status === 200) {
      yield put(fromActions.getProfileSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getProfileError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getUserInfo({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/infoFunc/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getUserInfoSuccess(response.data.data[0]));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getUserInfoError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getUserOperation({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/getOperacao/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getUserOperationSuccess(response.data.data));
      setOperation(response.data.data);
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getUserOperationError(err));
  }
}

export function* getWatermarkUserInfo({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/Usuario/infoFunc/${id}`);
    if (response.status === 200) {
      yield put(fromActions.getWatermarkUserInfoSuccess(response.data.data[0]));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getWatermarkUserInfoError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchProfile() {
  yield takeLatest(fromTypes.GET_GA_INFO, getGAInfo);
  yield takeLatest(fromTypes.GET_SUPERVISOR_INFO, getSupervisorInfo);
  yield takeLatest(fromTypes.GET_USERS, getUsers);
  yield takeLatest(fromTypes.REGISTER_USER, registerUser);
  yield takeLatest(fromTypes.GET_USER_DETAILS, getUserDetails);
  yield takeLatest(fromTypes.UPDATE_USER, updateUser);
  yield takeLatest(fromTypes.DELETE_USER, deleteUser);
  yield takeLatest(fromTypes.GET_GENDERS, getGenders);
  yield takeLatest(fromTypes.GET_TYPES, getTypes);
  yield takeLatest(fromTypes.GET_UNIORG_LIST, getUniorgList);
  yield takeLatest(fromTypes.CHANGE_GA_SELECTION, changeGASelection);
  yield takeLatest(
    fromTypes.CHANGE_SUPERVISOR_SELECTION,
    changeSupervisorSelection
  );
  yield takeLatest(fromTypes.GET_ROLES, getRoles);
  yield takeLatest(fromTypes.GET_SUPERVISORS, getSupervisors);
  yield takeLatest(fromTypes.CHANGE_PROFILE_PASS, sendPassRequest);
  yield takeLatest(fromTypes.GET_PROFILE, getProfile);
  yield takeLatest(fromTypes.GET_PROFILE_BY_ID, getProfileById);
  yield takeLatest(fromTypes.GET_USER_INFO, getUserInfo);
  yield takeLatest(fromTypes.GET_USER_OPERATION, getUserOperation);
  yield takeLatest(fromTypes.GET_WATERMARK_USER_INFO, getWatermarkUserInfo);
}
