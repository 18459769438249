import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  darkGrey,
  white,
  mediumGrey,
  santander,
  green,
  lightGrey,
  taskSubBg,
  lighterGrey,
  boston,
  santander20,
} from "../theme/colors";
import Icons from "./Icons";
import { changeChecklistItem } from "../store/actions/checklist";
import { alertToast } from "./Toast";
import ConfirmTaskModal from "./ConfirmTaskModal";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { getUser } from "../store/services/auth";
import { getAgencia } from "../store/services/checklist";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1em",
    fontFamily: "SantanderMicroText",
  },
  input: {
    width: "38px",
    height: "24px",
    color: white,
    border: `1px solid ${white}`,
    borderRadius: "4px 0px 0px 4px",
    fontFamily: "SantanderHeadline",
    fontWeight: "bold",
    textAlign: "center",
    margin: "0.3em 0em",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 4px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  increaseBtn: {
    minWidth: "12px !important",
    width: "12.5px !important",
    height: "13px !important",
    textAlign: "center !important",
    borderBottom: `1px solid ${white}`,
    borderLeft: `1px solid ${white}`,
    borderRadius: "0px 5px 0px 0px !important",
    lineHeight: "10px !important",
    fontSize: "12px !important",
    color: `${white} !important`,
    "&:nth-child(2)": {
      borderRadius: "0px 0px 5px 0px !important",
      borderBottom: `0px`,
      borderTop: `1px solid ${white}`,
    },
    "&:disabled": {
      opacity: "0.3 !important",
    },
  },
  taskInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: taskSubBg,
    padding: "8px",
  },
  taskInfoTextRight: {
    color: darkGrey,
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    width: "320px",
  },
  taskInfoTextLeft: {
    color: darkGrey,
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    width: "320px",
    textAlign: "right",
  },
  commentBar: {
    background: `${mediumGrey} !important`,
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    width: "100%",
    height: "44px",
    cursor: "pointer",
  },
  commentContainer: {
    background: lighterGrey,
    padding: "22px",
    "& textarea": {
      padding: "0px",
      width: "100%",
      height: "165px",
      borderRadius: "4px",
      resize: "none",
    },
  },
  commentBtnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  commentTitle: {
    color: white,
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "regular",
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    marginRight: "10px",
  },
  addCommentBar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    fontWeight: "regular",
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    padding: "12px 24px 12px 0px",
    cursor: "pointer",
    color: mediumGrey,
    "& span": {
      marginLeft: "12px",
    },
  },
  commentList: {
    background: lighterGrey,
  },
  comment: {
    fontFamily: "SantanderMicroText",
    fontSize: "12px",
    textAlign: "right",
    color: darkGrey,
    padding: "12px",
    overflowWrap: "break-word",
  },
  commentDetails: {
    fontFamily: "8px",
    color: mediumGrey,
    marginBottom: "0px",
    marginTop: "8px",
    "& span": {
      fontWeight: "bold",
    },
  },
  todoTaskBtns: {
    display: "flex",
  },
  uniorgAlertCircle: {
    position: "absolute",
    borderRadius: "50%",
    left: "-8%",
    top: "25%",
    background: santander,
    height: "8px",
    width: "8px",
    border: `2px solid ${white}`,
  },
}));

const tasksWithLink = [
  "ATM",
  "KYC - Formulários pendentes",
  "Disponibilidade da loja",
  "REFT - Devolvidas",
  "NQ - Alertas pendentes",
  "EXO - Legado",
  "EXO - Mês",
  // "Fomento B2G",
  //"Compensação - Motivos Especiais",
  //"Compensação - Acima de 15k",
  "Tratamento de Manifestações",
];

const Task = ({
  task,
  loading,
  onChangeChecklistItem,
  shouldExpand,
  currentPageInfo,
  gaInfo,
  alerts,
}) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [greyValue, setGreyValue] = useState(0);
  const [redValue, setRedValue] = useState(0);
  const [greenValue, setGreenValue] = useState(0);
  const [comment, setComment] = useState("");
  const [valueTimeout, setValueTimeout] = useState(false);
  const [shouldOpenNewComment, setShouldOpenNewComment] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [indicator, setIndicator] = useState("");

  const limitedTasks = (task) => {
    return (
      task.title === "Boletim Diário" ||
      task.title === "Disponibilidade da loja" ||
      task.title === "ATM" ||
      task.title === "REFT - Devolvidas"
    );
  };

  const sumTasks = (task) => {
    return (
      task.title === "Compensação - Motivos Especiais" ||
      task.title === "Compensação - Acima de 15k" ||
      task.title === "Tratamento de clientes críticos" ||
      task.title === "Liberação ID PJ" ||
      task.title === "Acertos Contábeis" ||
      task.title === "Gestão de Siglas" ||
      task.title === "Contratos formalizados - Remoto" ||
      task.title === "Contratos liberados – Loja"
    );
  };

  const setDisableTaskButton = (title) => {
    /*switch (title) {
      case "Compensação - Motivos Especiais":
      case "Compensação - Acima de 15k":
        return true;
    }*/
    return false;
  };

  useEffect(() => {
    if (
      task.title === "Boletim Diário" ||
      task.title === "Disponibilidade da loja" ||
      task.title === "REFT - Devolvidas"
    ) {
      setGreyValue(1);
    } else setGreyValue(task.amount);
    setRedValue(task.not_Ok);
    setGreenValue(task.ok);
  }, [task]);

  useEffect(() => {
    setShowDetails(false);
  }, [shouldExpand]);

  useEffect(() => {
    if (confirmSend) {
      sendFinishedTask();
      setShouldOpenModal(false);
    }
  }, [confirmSend]);

  const changeTaskValue = (field, value) => {
    if (value < 0) return;
    let estagio = task.phase;
    let qte = greyValue;
    let ok = greenValue;
    let n_ok = redValue;

    const { id } = task;
    switch (field) {
      case "red":
        if (limitedTasks(task) && value > qte) return;
        setRedValue(parseInt(value));
        if (limitedTasks(task) && parseInt(value) + greenValue > qte) {
          let inputValue = greenValue - 1 >= 0 ? greenValue - 1 : 0;
          setGreenValue(inputValue);
          ok = inputValue;
        }
        if (sumTasks(task)) {
          qte = parseInt(value) + ok;
          setGreyValue(qte);
        }
        if (value || value === 0) {
          if (valueTimeout) clearTimeout(valueTimeout);
          setValueTimeout(
            setTimeout(() => {
              onChangeChecklistItem({
                id,
                qte,
                ok,
                n_ok: parseInt(value),
                estagio,
              });
            }, 1000)
          );
        }
        break;
      case "green":
        if (limitedTasks(task) && value > qte) return;
        setGreenValue(parseInt(value));
        if (limitedTasks(task) && parseInt(value) + redValue > qte) {
          let inputValue = redValue - 1 >= 0 ? redValue - 1 : 0;
          setRedValue(inputValue);
          n_ok = inputValue;
        }
        if (sumTasks(task)) {
          qte = parseInt(value) + n_ok;
          setGreyValue(qte);
        }
        if (value || value === 0) {
          if (valueTimeout) clearTimeout(valueTimeout);
          setValueTimeout(
            setTimeout(() => {
              onChangeChecklistItem({
                id,
                qte,
                n_ok,
                ok: parseInt(value),
                estagio,
              });
            }, 1000)
          );
        }
        break;
      default:
        break;
    }
  };

  const moveTask = () => {
    let estagio = task.phase === 2 ? task.phase - 1 : task.phase + 1;
    let qte = greyValue;
    let n_ok = redValue;
    let ok = greenValue;
    const { id } = task;
    if (valueTimeout) clearTimeout(valueTimeout);
    setValueTimeout(
      setTimeout(() => {
        onChangeChecklistItem({ id, estagio, qte, n_ok, ok });
      }, 800)
    );
  };

  const openTask = () => {
    if (task.phase === 1 || task.phase === 2) setShowDetails(!!!showDetails);
  };

  const sendComment = () => {
    if (comment !== "") {
      let qte = greyValue;
      let n_ok = redValue;
      let ok = greenValue;
      onChangeChecklistItem({
        estagio: task.phase,
        id: task.id,
        comentario: comment,
        qte,
        n_ok,
        ok,
      });
      setComment("");
    } else alertToast("warn", "O comentário não pode estar vazio.");
  };

  const finishTask = () => {
    if (
      (task.title === "Boletim Diário" &&
        greyValue + redValue + greenValue <= 1 &&
        !confirmSend) ||
      (task.title === "Disponibilidade da loja" &&
        greyValue + redValue + greenValue <= 1 &&
        !confirmSend) ||
      (task.title === "ATM" &&
        redValue + greenValue !== greyValue &&
        !confirmSend) ||
      (task.title === "REFT - Devolvidas" &&
        redValue + greenValue !== greyValue &&
        !confirmSend)
    ) {
      setIndicator(task.title);
      setShouldOpenModal(true);
    } else {
      sendFinishedTask();
    }
  };

  const sendFinishedTask = () => {
    let estagio = 2;
    let qte = greyValue;
    let n_ok = redValue;
    let ok = greenValue;
    const { id } = task;
    if (valueTimeout) clearTimeout(valueTimeout);
    setValueTimeout(
      setTimeout(() => {
        onChangeChecklistItem({ id, estagio, qte, n_ok, ok });
      }, 800)
    );
    setConfirmSend(false);
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const getURL = () => {
    switch (currentPageInfo.type) {
      case "selectedAgency":
        return `agencia/${currentPageInfo.id}`;
      case "DATA":
        if (gaInfo.length > 0) {
          return `perfil/${getUser()}`;
        } else {
          return "clear/0";
        }
      case "AGÊNCIA" || "agência":
        return `agencia/${currentPageInfo.id}`;
      default:
        return `${currentPageInfo.type.toLowerCase()}/${currentPageInfo.id}`;
    }
  };

  const getURLType = () => {
    switch (task.title) {
      case "ATM":
        return "atm";
      case "Disponibilidade da loja":
        return "loja";
      case "KYC - Formulários pendentes":
        return "kyc";
      case "REFT - Devolvidas":
        return "reft";
      case "NQ - Alertas pendentes":
        return "nq-alertas";
      case "EXO - Legado":
      case "EXO - Mês":
        return "exo";
      // case "Fomento B2G":
      //   return "b2g";
      // case "Compensação - Motivos Especiais":
      // case "Compensação - > 15k":
      //   return "cheque";
      case "Tratamento de Manifestações":
        return "manifestacoes";
    }
  };

  const checkIfRowHasAlerts = () => {
    for (let i = 0; i < alerts.length; i++) {
      if (
        task.title === "Disponibilidade - ATM" ||
        task.title === "KYC - Formulários" ||
        task.title === "Disponibilidade - Loja" ||
        task.title === "REFT - Devolvidas"
      ) {
        if (
          alerts[i].cd_Uniorg === parseInt(getAgencia()) &&
          alerts[i].titulo === task.title &&
          parseInt(getAgencia()) !== 0
        ) {
          return true;
        } else if (
          parseInt(getAgencia()) === 0 &&
          alerts[i].titulo === task.title &&
          alerts[i].cd_Uniorg !== 0
        )
          return true;
      }
    }
  };

  const getLinkToIndicator = () => {
    if (tasksWithLink.includes(task.title)) {
      return (
        <Typography
          sx={{
            fontFamily: "SantanderMicroText",
            fontSize: "18px",
            color: darkGrey,
            padding: "4px",
            marginRight: "4px",
            cursor: "pointer",
            textDecoration: "underline",
            position: "relative",
          }}
          onClick={() => {
            window.location.href = `/${getURLType()}/${getURL()}`;
          }}
        >
          {checkIfRowHasAlerts() ? (
            <div className={classes.uniorgAlertCircle}></div>
          ) : null}
          {task.title}
        </Typography>
      );
    } else {
      return (
        <Typography
          sx={{
            fontFamily: "SantanderMicroText",
            fontSize: "18px",
            color: darkGrey,
            padding: "4px",
            marginRight: "4px",
            cursor: "pointer",
          }}
          onClick={task.phase === 2 ? openTask : null}
        >
          {task.title}
        </Typography>
      );
    }
  };

  return (
    <div className={classes.container}>
      <ConfirmTaskModal
        open={shouldOpenModal}
        finish={() => {
          setConfirmSend(true);
          finishTask();
        }}
        onClose={() => setShouldOpenModal(false)}
        indicator={indicator}
      />
      <div className={classes.root}>
        <div
          className={classes.title}
          style={task.phase === 2 ? { width: "100%" } : null}
        >
          {task.phase === 2 ? (
            <div>
              <Button
                onClick={moveTask}
                disabled={loading}
                sx={{ minWidth: "16px" }}
              >
                <Icons icon="chevron-left-1px" />
              </Button>
            </div>
          ) : null}
          {getLinkToIndicator()}
          {task.phase === 1 ? (
            <LightTooltip title={task.subtitle}>
              <span>
                <Icons icon="information-in-a-circle" customColor={lightGrey} />
              </span>
            </LightTooltip>
          ) : null}
          {task.phase === 2 && tasksWithLink.includes(task.title) ? (
            <div style={{ marginLeft: "auto" }}>
              <Button
                onClick={openTask}
                disabled={loading}
                sx={{
                  minWidth: "16px",
                }}
              >
                <Icons icon="chevron-down-1px" customColor={mediumGrey} />
              </Button>
            </div>
          ) : null}
        </div>

        {task.phase === 0 ? (
          <div className={classes.todoTaskBtns}>
            <Button
              onClick={moveTask}
              disabled={loading}
              sx={{ minWidth: "16px" }}
            >
              <Icons icon="chevron-right-1px" />
            </Button>
            <LightTooltip
              title="Enviar para a coluna 'Feito' "
              placement="right"
            >
              <span>
                <Button onClick={finishTask} sx={{ minWidth: "16px" }}>
                  <Icons icon="check-asigment" customColor={lightGrey} />
                </Button>
              </span>
            </LightTooltip>
          </div>
        ) : null}
        {task.phase === 1 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <LightTooltip title="Ver e inserir comentários">
              <Button onClick={openTask} sx={{ minWidth: "16px" }}>
                <Icons icon="receipt-bills" customColor={lightGrey} />
              </Button>
            </LightTooltip>
            <div className={classes.inputContainer}>
              <input
                type="number"
                className={classes.input}
                style={{
                  background:
                    task.amount.isFixed === "True" ? lightGrey : mediumGrey,
                }}
                value={greyValue}
                onClick={() => setShowDetails(true)}
                onBlur={(e) => {
                  if (e.target.value === "") setGreyValue(0);
                }}
                disabled
              />
              <div className={classes.btnContainer}>
                <Button
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : mediumGrey,
                  }}
                  className={classes.increaseBtn}
                  disabled
                >
                  +
                </Button>
                <Button
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : mediumGrey,
                  }}
                  className={classes.increaseBtn}
                  disabled
                >
                  -
                </Button>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <input
                type="number"
                className={classes.input}
                style={{
                  background:
                    task.amount.isFixed === "True" ? lightGrey : santander,
                }}
                value={redValue}
                onClick={() => setShowDetails(true)}
                onChange={(e) => changeTaskValue("red", e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") setRedValue(0);
                }}
                disabled={
                  setDisableTaskButton(task.title) ||
                  task.not_Ok.isFixed === "True"
                }
              />
              <div className={classes.btnContainer}>
                <Button
                  onClick={() => changeTaskValue("red", redValue + 1)}
                  className={classes.increaseBtn}
                  disabled={
                    setDisableTaskButton(task.title) ||
                    task.amount.isFixed === "True"
                  }
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : santander,
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => changeTaskValue("red", redValue - 1)}
                  className={classes.increaseBtn}
                  disabled={
                    setDisableTaskButton(task.title) ||
                    task.amount.isFixed === "True" ||
                    redValue === 0
                  }
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : santander,
                  }}
                >
                  -
                </Button>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <input
                type="number"
                className={classes.input}
                style={{
                  background:
                    task.amount.isFixed === "True" ? lightGrey : green,
                }}
                value={greenValue}
                onClick={() => setShowDetails(true)}
                onChange={(e) => changeTaskValue("green", e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") setGreenValue(0);
                }}
                disabled={
                  setDisableTaskButton(task.title) || task.ok.isFixed === "True"
                }
              />
              <div className={classes.btnContainer}>
                <Button
                  onClick={() => changeTaskValue("green", greenValue + 1)}
                  className={classes.increaseBtn}
                  disabled={
                    setDisableTaskButton(task.title) ||
                    task.amount.isFixed === "True"
                  }
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : green,
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => changeTaskValue("green", greenValue - 1)}
                  className={classes.increaseBtn}
                  disabled={
                    setDisableTaskButton(task.title) ||
                    task.amount.isFixed === "True" ||
                    greenValue === 0
                  }
                  style={{
                    background:
                      task.amount.isFixed === "True" ? lightGrey : green,
                  }}
                >
                  -
                </Button>
              </div>
            </div>
            <LightTooltip title="Enviar para a coluna 'Feito' ">
              <span>
                <Button onClick={finishTask} sx={{ minWidth: "16px" }}>
                  <Icons icon="check-asigment" customColor={lightGrey} />
                </Button>
              </span>
            </LightTooltip>
          </div>
        ) : null}
      </div>
      {showDetails ? (
        <>
          {task.phase === 0 ? null : (
            <>
              {task.phase === 2 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.inputContainer}>
                    <input
                      type="number"
                      className={classes.input}
                      style={{
                        background:
                          task.amount.isFixed === "True"
                            ? lightGrey
                            : mediumGrey,
                      }}
                      value={greyValue}
                      onClick={() => setShowDetails(true)}
                      onBlur={(e) => {
                        if (e.target.value === "") setGreyValue(0);
                      }}
                      disabled
                    />
                    <div className={classes.btnContainer}>
                      <Button
                        style={{
                          background:
                            task.amount.isFixed === "True"
                              ? lightGrey
                              : mediumGrey,
                        }}
                        className={classes.increaseBtn}
                        disabled
                      >
                        +
                      </Button>
                      <Button
                        style={{
                          background:
                            task.amount.isFixed === "True"
                              ? lightGrey
                              : mediumGrey,
                        }}
                        className={classes.increaseBtn}
                        disabled
                      >
                        -
                      </Button>
                    </div>
                  </div>
                  <div className={classes.inputContainer}>
                    <input
                      type="number"
                      className={classes.input}
                      style={{
                        background:
                          task.amount.isFixed === "True"
                            ? lightGrey
                            : santander,
                      }}
                      value={redValue}
                      onClick={() => setShowDetails(true)}
                      onChange={(e) => changeTaskValue("red", e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "") setRedValue(0);
                      }}
                      disabled={task.not_Ok.isFixed === "True"}
                    />
                    <div className={classes.btnContainer}>
                      <Button
                        onClick={() => changeTaskValue("red", redValue + 1)}
                        className={classes.increaseBtn}
                        disabled={task.amount.isFixed === "True"}
                        style={{
                          background:
                            task.amount.isFixed === "True"
                              ? lightGrey
                              : santander,
                        }}
                      >
                        +
                      </Button>
                      <Button
                        onClick={() => changeTaskValue("red", redValue - 1)}
                        className={classes.increaseBtn}
                        disabled={task.amount.isFixed === "True"}
                        style={{
                          background:
                            task.amount.isFixed === "True"
                              ? lightGrey
                              : santander,
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </div>
                  <div className={classes.inputContainer}>
                    <input
                      type="number"
                      className={classes.input}
                      style={{
                        background:
                          task.amount.isFixed === "True" ? lightGrey : green,
                      }}
                      value={greenValue}
                      onClick={() => setShowDetails(true)}
                      onChange={(e) => changeTaskValue("green", e.target.value)}
                      onBlur={(e) => {
                        if (e.target.value === "") setGreenValue(0);
                      }}
                      disabled={task.ok.isFixed === "True"}
                    />
                    <div className={classes.btnContainer}>
                      <Button
                        onClick={() => changeTaskValue("green", greenValue + 1)}
                        className={classes.increaseBtn}
                        disabled={task.amount.isFixed === "True"}
                        style={{
                          background:
                            task.amount.isFixed === "True" ? lightGrey : green,
                        }}
                      >
                        +
                      </Button>
                      <Button
                        onClick={() => changeTaskValue("green", greenValue - 1)}
                        className={classes.increaseBtn}
                        disabled={task.amount.isFixed === "True"}
                        style={{
                          background:
                            task.amount.isFixed === "True" ? lightGrey : green,
                        }}
                      >
                        -
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={classes.commentList}>
                {task.comments.map((i, index) => {
                  return (
                    <div className={classes.comment} key={index}>
                      <div>{i.comentario}</div>
                      <p className={classes.commentDetails}>
                        Realizado por <span>{i.nM_USUARIO}</span> em {i.data} às{" "}
                        {i.hora}
                      </p>
                    </div>
                  );
                })}
              </div>

              {!shouldOpenNewComment ? (
                <div
                  className={classes.addCommentBar}
                  onClick={() =>
                    setShouldOpenNewComment(!!!shouldOpenNewComment)
                  }
                >
                  <Icons icon="add-in-a-circle" />
                  <span>Inserir novo comentário</span>
                </div>
              ) : (
                <div className={classes.commentContainer}>
                  <textarea
                    maxLength="255"
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <div className={classes.commentBtnsContainer}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        setShouldOpenNewComment(!!!shouldOpenNewComment)
                      }
                      sx={{
                        width: "104px",
                        height: "48px",
                        fontFamily: "SantanderMicroText ",
                        background: white,
                        textTransform: "none ",
                        color: `${santander} `,
                        border: `1px solid ${santander} `,
                        display: "block",
                        fontSize: "16px ",
                        "&:hover": {
                          background: santander20,
                          color: `${santander} `,
                        },
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={sendComment}
                      variant="contained"
                      sx={{
                        width: "104px",
                        height: "48px",
                        background: boston,
                        color: white,
                        fontFamily: "SantanderMicroText",
                        textTransform: "none",
                        textDecoration: "none",
                        "&:hover": {
                          background: boston,
                          color: white,
                        },
                      }}
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      <hr style={{ marginTop: "0px" }} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeChecklistItem: (payload) => dispatch(changeChecklistItem(payload)),
  };
};

const mapStateToProps = (state) => {
  const { loading, comments } = state.checklist;
  const { alerts } = state.alerts;
  const { gaInfo } = state.bulletin;
  return { loading, comments, gaInfo, alerts };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Task);
