// @flow

export const setLastSearch = async (lastSearch: object) =>
  sessionStorage.setItem("lastSearch", JSON.stringify(lastSearch));

export const getLastSearch = (): object => {
  const lastSearch = sessionStorage.getItem("lastSearch");
  if (lastSearch && lastSearch === "undefined") {
    sessionStorage.removeItem("lastSearch");
    return "";
  }

  return JSON.parse(lastSearch) || "teste";
};
