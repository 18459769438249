// @flow

export const setAlreadySeenAlerts = (alert: object) => {};

export const getAlreadySeenAlerts = (): array => {
  const alreadySeenAlerts = localStorage.getItem("alertas");
  if (alreadySeenAlerts && alreadySeenAlerts === "undefined") {
    localStorage.removeItem("alertas");
    return "";
  }

  return JSON.parse(alreadySeenAlerts) || [];
};

export const getCurrentAlertsAlreadySeenAlertsDiff = (
  currentAlerts: array
): array => {
  const alreadySeenAlertsArray = getAlreadySeenAlerts();
  const isSameAlert = (a, b) => a.id == b.id;
  const onlyInCurrentAlerts = () =>
    currentAlerts.filter(
      (currentValue) =>
        !alreadySeenAlertsArray.some((alreadySeenValue) =>
          isSameAlert(currentValue, alreadySeenValue)
        )
    );
  return onlyInCurrentAlerts();
};

export const setHasNewAlerts = async (has: string) =>
  sessionStorage.setItem("hasNewAlerts", has);

export const hasNewAlerts = (): string => {
  const newAlerts = sessionStorage.getItem("hasNewAlerts");
  if (newAlerts && newAlerts === "undefined") {
    sessionStorage.setItem("hasNewAlerts", "false");
    return "";
  }

  return newAlerts || "false";
};
