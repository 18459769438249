import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/agency";
import * as fromActions from "../actions/agency";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";

const BASE_API = `${getBaseAPI()}`;

export function* registerAgency({ payload }) {
  let { uniorg, searchedUniorg, operacao } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Agencia/addAgencia`, {
      uniorg,
      ativar: true,
      id_operacao: operacao,
    });
    if (response.status === 200) {
      yield put(fromActions.registerAgencySuccess(response.data.data));
      alertToast("success", "Agencia cadastrada com sucesso!");
      yield put(fromActions.getAgencyInfo(searchedUniorg));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível cadastrar a agencia.");
    yield put(fromActions.registerAgencyError(err));
  }
}

export function* deleteAgency({ payload }) {
  let { uniorg, searchedUniorg } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Agencia/addAgencia`, {
      uniorg,
      ativar: false,
    });
    if (response.status === 200) {
      yield put(fromActions.deleteAgencySuccess(response.data.data));
      alertToast("success", "Agencia removida com sucesso!");
      yield put(fromActions.getAgencyInfo(searchedUniorg));
    }
  } catch (err) {
    console.log(err);
    alertToast("error", "Não foi possível remover a agencia.");
    yield put(fromActions.deleteAgencyError(err));
  }
}

export function* getAgencyInfo({ uniorg }) {
  try {
    const response = yield axios.get(`${BASE_API}/Agencia/agencias/${uniorg}`);
    if (response.status === 200) {
      yield put(fromActions.getAgencyInfoSuccess(response.data.data));
      yield put(fromActions.getOperations());
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getAgencyInfoError(err));
  }
}

export function* getOperations() {
  try {
    const response = yield axios.get(`${BASE_API}/Agencia/operacoes`);
    if (response.status === 200) {
      yield put(fromActions.getOperationsSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.getOperationsError(err));
  }
}

export function* watchAgency() {
  yield takeLatest(fromTypes.REGISTER_AGENCY, registerAgency);
  yield takeLatest(fromTypes.DELETE_AGENCY, deleteAgency);
  yield takeLatest(fromTypes.GET_AGENCY_INFO, getAgencyInfo);
  yield takeLatest(fromTypes.GET_OPERATIONS, getOperations);
}
