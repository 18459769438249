import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/analytics";
import * as fromActions from "../actions/analytics";
import { getToken, getUser, logout } from "../services/auth";
import { getBaseAPI } from "../services/api";

const BASE_API = getBaseAPI();

export function* sendLog({ payload }) {
  const headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  let { info } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Log/addLog`, {
      info,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.sendLogSuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(fromActions.sendLogError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchAnalytics() {
  yield takeLatest(fromTypes.SEND_LOG, sendLog);
}
