// @flow

import * as fromTypes from "../types/alerts";

type State = {
  loading: boolean,
  reports: array,
  alerts: array,
  alertList: array,
};

export const initialState = {
  loading: false,
  reports: [],
  alerts: [],
  alertList: [],
};

const updateReports = (state, { payload }) => ({
  ...state,
  reports: payload,
  loading: false,
});

const updateAlerts = (state, { payload }) => ({
  ...state,
  alerts: payload,
  loading: false,
});

const updateAlertList = (state, { payload }) => ({
  ...state,
  alertList: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (
  state: State = initialState,
  action: fromTypes.AlertAction
) => {
  switch (action.type) {
    case fromTypes.GET_LAST_TEN_REPORTS:
    case fromTypes.GET_ALL_REPORTS:
    case fromTypes.GET_ALERTS:
    case fromTypes.SEND_ALERT:
    case fromTypes.EDIT_ALERT:
    case fromTypes.DELETE_ALERT:
    case fromTypes.GET_ALERTS_FULL_LIST:
    case fromTypes.SET_ALERT_READ:
    case fromTypes.GET_ALERTS_HISTORY:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_LAST_TEN_REPORTS_SUCCESS:
      return updateReports(state, action);
    case fromTypes.GET_ALL_REPORTS_SUCCESS:
      return updateReports(state, action);
    case fromTypes.GET_ALERTS_SUCCESS:
      return updateAlerts(state, action);
    case fromTypes.GET_ALERTS_FULL_LIST_SUCCESS:
    case fromTypes.GET_ALERTS_HISTORY_SUCCESS:
      return updateAlertList(state, action);
    case fromTypes.SEND_ALERT_SUCCESS:
    case fromTypes.EDIT_ALERT_SUCCESS:
    case fromTypes.DELETE_ALERT_SUCCESS:
    case fromTypes.SET_ALERT_READ_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.GET_LAST_TEN_REPORTS_ERROR:
    case fromTypes.GET_ALL_REPORTS_ERROR:
    case fromTypes.GET_ALERTS_ERROR:
    case fromTypes.SEND_ALERT_ERROR:
    case fromTypes.EDIT_ALERT_ERROR:
    case fromTypes.DELETE_ALERT_ERROR:
    case fromTypes.GET_ALERTS_FULL_LIST_ERROR:
    case fromTypes.SET_ALERT_READ_ERROR:
    case fromTypes.GET_ALERTS_HISTORY_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
