import React from "react";

const Checked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 50 49.971"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Retângulo_1"
            dataName="Retângulo 1"
            width="50"
            height="49.971"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Grupo_2" dataName="Grupo 2" transform="translate(0 0)">
        <g id="Grupo_1" dataName="Grupo 1" clipPath="url(#clip-path)">
          <path
            id="Caminho_1"
            dataName="Caminho 1"
            d="M25,1.711a23.4,23.4,0,1,1-23.4,23.4A23.4,23.4,0,0,1,25,1.711"
            fill="red"
          />
          <path
            id="Caminho_2"
            dataName="Caminho 2"
            d="M25.01,0c13.57-.09,25,11.46,24.99,24.99a25.337,25.337,0,0,1-25.02,24.98A25.36,25.36,0,0,1,0,24.971C0,11.431,11.47-.1,25.01,0m22.5,24.9c-.05-.68-.08-1.37-.15-2.05a21.969,21.969,0,0,0-1.66-6.57,23.156,23.156,0,0,0-8.83-10.42,20.912,20.912,0,0,0-13.98-3.31A22.7,22.7,0,0,0,16.71,4.1a23.1,23.1,0,0,0-9.25,6.82,20.855,20.855,0,0,0-4.85,11.62,24.1,24.1,0,0,0,.47,7.29,22.136,22.136,0,0,0,4.63,9.43,23.331,23.331,0,0,0,4.91,4.5,20.757,20.757,0,0,0,10.89,3.67,23.194,23.194,0,0,0,9.75-1.59,22.942,22.942,0,0,0,8.5-5.93,22.137,22.137,0,0,0,4.1-6.33,21.526,21.526,0,0,0,1.65-8.68"
          />
          <path
            id="Caminho_3"
            dataName="Caminho 3"
            d="M23,31.971c.29-.46.55-.88.82-1.3Q29.355,21.9,34.9,13.131a1.122,1.122,0,0,1,1.23-.63,1.3,1.3,0,0,1,1.13.95,1.383,1.383,0,0,1-.21,1.16Q34.02,19.306,31,24q-3.345,5.19-6.69,10.39c-.48.74-1.06.96-1.73.62a1.8,1.8,0,0,1-.49-.38q-4.575-4.755-9.15-9.53a1.405,1.405,0,0,1,.21-2.14,1.118,1.118,0,0,1,1.47.24c.41.41.81.84,1.21,1.26q3.405,3.57,6.82,7.14c.1.12.21.22.35.37"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Checked;
