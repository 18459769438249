import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/kyc";
import * as fromActions from "../actions/kyc";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser } from "../services/auth";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";
import { setLastSearchedUser } from "../services/search";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getForms({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/Kyc/Kyc/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getKYCAvailabilitySuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    if (err.response.data.errors) {
      yield put(fromActions.getKYCAvailabilitySuccess(["forbidden"]));
    } else {
      yield put(fromActions.getKYCAvailabilityError(err));
      alertToast("error", "Não foi possível recuperar a lista de formulários.");
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* addKYCAction({ payload }) {
  const payloadToSend = {
    ind: payload.ind,
    aux: payload.aux,
    uniorg: payload.uniorg,
    mtr: Number(getUser()),
    atuacao: payload.action,
    com: "",
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Kyc/kycAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addKYCActionSuccess(response.data.data));
      yield put(fromActions.getKYCAvailability(getLastSearch()));
      yield put(fromActions.getKYCPieChart(getLastSearch()));
      yield put(fromActions.getKYCsLineChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addKYCActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getKYCPieChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Kyc/graficoRosca`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getKYCPieChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getKYCPieChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getKYCsLineChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Kyc/graficoSemanal`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getKYCsLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getKYCsLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchKYC() {
  yield takeLatest(fromTypes.GET_KYC_AVAILABILITY, getForms);
  yield takeLatest(fromTypes.ADD_KYC_ACTION, addKYCAction);
  yield takeLatest(fromTypes.GET_KYC_PIE_CHART, getKYCPieChart);
  yield takeLatest(fromTypes.GET_KYC_LINE_CHART, getKYCsLineChart);
}
