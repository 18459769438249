import { useEffect, useState } from "react";
import { Bar, Line, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { makeStyles } from "@material-ui/styles";

import {
  santander,
  yellow,
  green,
  mediumGrey,
  darkGrey,
  turquoise,
  white,
  lighterGrey,
} from "../theme/colors";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  PointElement,
  LineElement,
  ArcElement
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
  },
}));

const ChartContainer = ({ customConfigs }) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (customConfigs.indicator) {
      setChartData(customConfigs.propChartData);
    } else {
      setChartData([
        customConfigs.propChartData[2]?.data?.num.replace(/\D/, ""),
        customConfigs.propChartData[3]?.data?.num.replace(/\D/, ""),
        customConfigs.propChartData[4]?.data?.num.replace(/\D/, ""),
      ]);
    }
  }, [customConfigs.propChartData]);

  useEffect(() => {
    if (customConfigs.indicator) {
      setLabels(customConfigs.chartLabels);
    } else {
      setLabels([
        customConfigs.propChartData[2]?.data?.detail,
        customConfigs.propChartData[3]?.data?.detail,
        customConfigs.propChartData[4]?.data?.detail,
      ]);
    }
  }, []);

  const getIndicatorGoal = () => {
    if (customConfigs.indicatorGoal >= 97) {
      return customConfigs.doughnutCenterLabel >= customConfigs.indicatorGoal
        ? green
        : santander;
    } else if (customConfigs.indicatorGoal === 0) {
      return customConfigs.doughnutCenterLabel > customConfigs.indicatorGoal
        ? santander
        : green;
    } else if (customConfigs.indicatorGoal.charAt(0) === "q") {
      switch (customConfigs.indicatorGoal) {
        case "qDentro":
          return green;
        case "qProx":
          return yellow;
        case "qFora":
          return santander;
        case "qSem":
          return lighterGrey;
      }
    }
  };

  const defaultBarChartConfig = [
    {
      label: labels,
      data: chartData,
      backgroundColor: [santander, yellow, green],
      maxBarThickness: 32,
    },
  ];

  const data = {
    labels,
    datasets: customConfigs.chartConfig
      ? customConfigs.chartConfig
      : defaultBarChartConfig,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: {
        top: customConfigs.customChartPaddingTop,
      },
    },
    scales: {
      y: {
        ticks: {
          min: 0,
          max: 100,
          callback: function (value) {
            return customConfigs.withPercentage ? value + "%" : value;
          },
        },
        beginAtZero: true,
        display: customConfigs.withLines,
        stacked: customConfigs.withStacked,
      },
      x: {
        stacked: customConfigs.withStacked,
        display:
          customConfigs.chartType === "line" ? true : customConfigs.withLines,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        reverse: !!customConfigs.reverseLegend,
        display: customConfigs.withLegend,
        position:
          customConfigs.chartType === "doughnut" ||
          customConfigs.chartType === "pie"
            ? "bottom"
            : "right",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      title: {
        display: true,
        text: customConfigs.title,
        align: customConfigs.chartType === "line" ? "start" : "center",
        font: {
          family: "SantanderMicroText, sans-serif",
          color: mediumGrey,
          fontWeight: "bold",
          size: "16",
        },
      },
      tooltip: {
        enabled: customConfigs.withTooltip,
      },

      datalabels: {
        anchor:
          customConfigs.chartType === "doughnut" ||
          customConfigs.chartType === "pie"
            ? ""
            : customConfigs.chartType === "bar"
            ? "end"
            : "start",
        color:
          customConfigs.chartType === "doughnut" ||
          customConfigs.chartType === "pie"
            ? white
            : darkGrey,
        align:
          customConfigs.chartType === "doughnut"
            ? ""
            : customConfigs.chartType === "bar"
            ? "end"
            : "start",
        offset:
          customConfigs.chartType === "line"
            ? "0"
            : customConfigs.isEXO
            ? "-14"
            : "-5",
        clamp: true,
        fontWeight: "bold",
        font: {
          family: "SantanderMicroText",
        },
        formatter: function (value, context) {
          let stringToNumberValue =
            customConfigs.chartType === "line"
              ? parseFloat(
                  context.chart.data.datasets[0].data[context.dataIndex]
                )
              : parseInt(
                  context.chart.data.datasets[0].data[context.dataIndex]
                );
          let formattedValue = stringToNumberValue.toLocaleString({
            minimumFractionDigits: 2,
          });

          if (!customConfigs.isMulti) {
            switch (customConfigs.chartType) {
              case "doughnut":
                return customConfigs.withoutValue || formattedValue == 0
                  ? ""
                  : formattedValue;
              case "line":
                return `    ${formattedValue} %`;
              case "bar":
                return formattedValue;
              case "pie":
                return customConfigs.withoutValue || formattedValue == 0
                  ? ""
                  : formattedValue;
            }
          } else {
            return null;
          }
        },
      },
    },
  };

  let plugins = [
    {
      id: "text",
      beforeDraw: function (chart, a, b) {
        let width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        let fontSize = (height / 150).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        ctx.fillStyle = getIndicatorGoal();

        let text =
            customConfigs.doughnutCenterLabel ||
            customConfigs.doughnutCenterLabel === 0
              ? customConfigs.withDoughnutCenterLabelPercentage
                ? `${customConfigs.doughnutCenterLabel}%`
                : `${customConfigs.doughnutCenterLabel}`
              : "",
          textX = Math.round((width - ctx.measureText(text).width) / 1.99),
          textY =
            height /
            (!customConfigs.isMulti ? customConfigs.customHeight : 1.85);

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const getChartType = (type) => {
    switch (type) {
      case "bar":
        return <Bar data={data} options={options} height={250} width={500} />;
      case "line":
        return (
          <Line
            data={data}
            options={options}
            height={
              customConfigs.customChartHeight
                ? customConfigs.customChartHeight
                : 300
            }
          />
        );
      case "doughnut":
        return <Doughnut data={data} options={options} plugins={plugins} />;
      case "pie":
        return <Pie data={data} options={options} height={280} />;
    }
  };
  return (
    <div
      className={classes.root}
      style={{
        paddingBottom: !customConfigs.withLegend
          ? `${customConfigs.customPaddingBottom}`
          : "0",

        maxWidth: customConfigs.limitWidth
          ? `${customConfigs.customMaxWidth}px`
          : "unset",
      }}
    >
      {getChartType(customConfigs.chartType)}
    </div>
  );
};

export default ChartContainer;
