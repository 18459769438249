// @flow
export const GET_INDICATOR_INFO = "[INDICATOR] GET_INDICATOR_INFO";
export const GET_INDICATOR_INFO_SUCCESS =
  "[INDICATOR] GET_INDICATOR_INFO_SUCCESS";
export const GET_INDICATOR_INFO_ERROR = "[INDICATOR]  GET_INDICATOR_INFO_ERROR";

export const GET_COMMENTS_LIST = "[INDICATOR] GET_COMMENTS_LIST";
export const GET_COMMENTS_LIST_SUCCESS =
  "[INDICATOR] GET_COMMENTS_LIST SUCCESS";
export const GET_COMMENTS_LIST_ERROR = "[INDICATOR] GET_COMMENTS_LIST ERROR";

export const REGISTER_COMMENT = "[INDICATOR] REGISTER COMMENT";
export const REGISTER_COMMENT_SUCCESS = "[INDICATOR] REGISTER COMMENT SUCCESS";
export const REGISTER_COMMENT_ERROR = "[INDICATOR] REGISTER COMMENT ERROR";

type GetIndicatorInfo = {
  type: typeof GET_Indicator_INFO,
  id: int,
};
type GetIndicatorInfoSuccess = {
  type: typeof GET_INDICATOR_INFO_SUCCESS,
  payload: any,
};
type GetIndicatorInfoError = {
  type: typeof GET_INDICATOR_INFO_ERROR,
  payload: any,
};

type getComments = {
  type: typeof GET_COMMENTS_LIST,
  payload: Object,
};
type getCommentsSuccess = {
  type: typeof GET_COMMENTS_LIST_SUCCESS,
  payload: any,
};
type getCommentsError = {
  type: typeof GET_COMMENTS_LIST_ERROR,
  payload: any,
};
type RegisterComment = {
  type: typeof REGISTER_COMMENT,
  payload: Object,
};
type RegisterCommentSuccess = {
  type: typeof REGISTER_COMMENT_SUCCESS,
  payload: any,
};
type RegisterCommentError = {
  type: typeof REGISTER_COMMENT_ERROR,
  payload: any,
};

export type IndicatorAction =
  | GetIndicatorInfo
  | GetIndicatorInfoSuccess
  | GetIndicatorInfoError
  | getComments
  | getCommentsSuccess
  | getCommentsError
  | RegisterComment
  | RegisterCommentSuccess
  | RegisterCommentError;
