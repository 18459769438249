// @flow

export const SEND_LOG = "[ANALYTICS] SEND_LOG";
export const SEND_LOG_SUCCESS = "[PROFILE] SEND_LOG_SUCCESS";
export const SEND_LOG_ERROR = "[PROFILE] SEND_LOG_ERROR";

type SendLog = {
  type: typeof SEND_LOG,
  id: Object,
};
type SendLogSuccess = {
  type: typeof SEND_LOG_SUCCESS,
  payload: any,
};
type SendLogError = {
  type: typeof SEND_LOG_ERROR,
  payload: any,
};

export type AnalyticsAction = SendLog | SendLogSuccess | SendLogError;
