import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "150px",
  },
}));
const CustomNoRowsOverlay = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>{text}</Typography>
    </div>
  );
};

export default CustomNoRowsOverlay;
