import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/manifestation";
import * as fromActions from "../actions/manifestation";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser, getUserLvl } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/manifestacao/`,
      {
        ga,
        rede,
        regional,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getManifestationTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Manifestation.");
    yield put(fromActions.getManifestationTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getManifestationDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/graficoSituacao`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(
        fromActions.getManifestationDoughnutChartSuccess(response.data.data)
      );
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getManifestationDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getManifestationLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/graficoMensal`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(
        fromActions.getManifestationLineChartSuccess(response.data.data)
      );
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getManifestationLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addManifestationAction({ payload }) {
  const payloadToSend = {
    ind: payload.ind,
    uniorg: payload.uniorg,
    mtr: parseInt(getUser()),
    aux: payload.aux,
    atuacao: payload.atuacao,
    com: payload.com,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/manifestacaoAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addManifestationActionSuccess(response.data.data));
      yield put(fromActions.getManifestationTable(getLastSearch()));
      yield put(fromActions.getManifestationDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addManifestationActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addManifestationComment({ payload }) {
  const payloadToSend = {
    ind: payload.ind,
    uniorg: payload.uniorg,
    mtr: parseInt(getUser()),
    aux: payload.aux,
    atuacao: payload.atuacao,
    com: payload.comentario,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/manifestacaoAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addManifestationCommentSuccess(response.data.data));
      yield put(fromActions.getManifestationTable(getLastSearch()));
      yield put(
        fromActions.getManifestationComments({
          uniorg: payload.uniorg,
          ind: payload.ind,
          info_aux: payload.aux,
        })
      );
      yield put(fromActions.getManifestationDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addManifestationCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getManifestationComments({ payload }) {
  const { uniorg, ind, info_aux } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/Manifestacao/manifestacaoComentarios/`,
      {
        headers: headers,
        uniorg,
        ind,
        info_aux,
      }
    );
    if (response.status === 200) {
      yield put(
        fromActions.getManifestationCommentsSuccess(response.data.data)
      );
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getManifestationCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchManifestation() {
  yield takeLatest(fromTypes.GET_MANIFESTATION_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_MANIFESTATION_ACTION, addManifestationAction);
  yield takeLatest(
    fromTypes.ADD_MANIFESTATION_COMMENT,
    addManifestationComment
  );
  yield takeLatest(
    fromTypes.GET_MANIFESTATION_DOUGHNUT,
    getManifestationDoughnut
  );
  yield takeLatest(
    fromTypes.GET_MANIFESTATION_COMMENTS,
    getManifestationComments
  );
  yield takeLatest(fromTypes.GET_MANIFESTATION_LINE, getManifestationLine);
}
