import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { Loading, MainMenu, RegisterForm, UserInfo } from "../components";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import {
  lighterGrey,
  darkGrey,
  santander,
  santander20,
  boston,
  white,
} from "../theme/colors";
import { getProfileById } from "../store/actions/profile";
import { getUserLvl, getUser } from "../store/services/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px",
    minHeight: "100vh",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "14px !important",
    color: `${darkGrey} !important`,
  },
  btnContainer: {
    padding: "0px 0px 0px 128px",
  },
}));

const Profile = ({ onGetProfileById, loading, profileDetails, match }) => {
  const classes = useStyles();

  useEffect(() => {
    if (getUserLvl() === "1" && match?.params?.id !== getUser())
      window.location.href = "/home";
  }, []);

  useEffect(() => {
    onGetProfileById(match?.params?.id);
  }, [onGetProfileById, match]);

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <Typography className={classes.title}>Perfil de Usuário</Typography>
          <Typography className={classes.subtitle}>
            Todos os campos com * são de preenchimento obrigatório.
          </Typography>
          <RegisterForm edit={true} id={match?.params?.id} />
        </Grid>
        <Grid item md={4}>
          <UserInfo id={match?.params?.id} />
          <div className={classes.btnContainer}>
            {getUserLvl() !== "1" ? (
              <Button
                variant="contained"
                onClick={() =>
                  (window.location.href = `/carterizacao/agencia/${match?.params?.id}`)
                }
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Gerir Agências
              </Button>
            ) : null}
            {profileDetails === 2 || profileDetails === 3 ? (
              <Button
                variant="contained"
                onClick={() =>
                  (window.location.href = `/carterizacao/usuarios/${match?.params?.id}`)
                }
                sx={{
                  marginTop: "12px",
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Gerir Usuários
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProfileById: (id) => dispatch(getProfileById(id)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { loading, profileDetails } = state.profile;
  return { loading, profileDetails, match: ownProps?.match };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(Profile)
);
