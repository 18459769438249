import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { addKYCAction } from "../../store/actions/kyc";
import csvIcon from "../../assets/icons/csv_icon.svg";
import { getUser } from "../../store/services/auth";
import { getLastSearchedUser } from "../../store/services/search";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  WarningExportModal,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  black,
  lightGrey,
  darkerGrey2,
  mediumGrey,
  turquoise20,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";

import interno from "../../assets/img/confidencial.svg";

import {
  getGAInfo,
  getSupervisorInfo,
  getUserInfo,
} from "../../store/actions/profile";
import { addIndicatorComment } from "../../store/actions/bulletin";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    height: "40px",
    width: "75%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "50px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const KYC = ({
  match,
  pie,
  line,
  forms,
  loading,
  gaInfo,
  onAddKYCAction,
  onGetSupervisorInfo,
  onGetGAInfo,
  onGetUserInfo,
}) => {
  const classes = useStyles();
  const [concluidoData, setConcluidoData] = useState([]);
  const [canceladosData, setCanceladosData] = useState([]);
  const [semFormData, setSemFormData] = useState([]);
  const [vencidosData, setVencidosData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [kycSortModel, setKycSortModel] = useState([
    {
      field: "action",
      sort: "asc",
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    onGetGAInfo(getUser());
    onGetSupervisorInfo(getUser());
    onGetUserInfo(getUser());
  }, [onGetSupervisorInfo, onGetGAInfo]);

  useEffect(() => {
    let list = [];
    if (forms[0] !== "forbidden") {
      forms.map((i, index) => {
        list = [
          ...list,
          {
            id: index,
            cdIndicador: i.cd_indicador,
            pernumper: i.nu_Penumper,
            uniorg: i.cd_Uniorg,
            uniorgName: i.nm_Agencia,
            urgStatus: i.st_Exo,
            action: i.atuacao,
            term: i.dt_Venc_Calculado,
            person: i.tp_Cliente,
          },
        ];
      });
      setRows(list);
      setBackupRows(list);
    }
  }, [forms]);

  useEffect(() => {
    setHasLoaded(false);
    if (pie) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [pie, line.length]);

  useEffect(() => {
    let concluidoData = [];
    let canceladosData = [];
    let semFormData = [];
    let vencidosoData = [];

    // REFATORAR ISSO
    line.concluido &&
      line.concluido.map((i) => {
        concluidoData.push(i.qtde_Kyc);
      });

    line.cancelados &&
      line.cancelados.map((i) => {
        canceladosData.push(i.qtde_Kyc);
      });

    line.sem_form &&
      line.sem_form.map((i) => {
        semFormData.push(i.qtde_Kyc);
      });

    line.vencidos &&
      line.vencidos.map((i) => {
        vencidosoData.push(i.qtde_Kyc);
      });

    setConcluidoData(concluidoData);
    setCanceladosData(canceladosData);
    setSemFormData(semFormData);
    setVencidosData(vencidosoData);
  }, [line]);

  useEffect(() => {
    pie.nu_Pendente > 0 ? setIsResolve(false) : setIsResolve(true);
  }, [pie.nu_Pendente]);

  const mockDataPieLabel = [
    `Pendente (${pie.perc_Pendente}%)`,
    `Trabalhados (${pie.perc_Trabalhado}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [pie.nu_Pendente, pie.nu_Trabalhado],
      backgroundColor: [santander, lightGrey],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Cancelados`,
      data: canceladosData,
      borderColor: mediumGrey,
      backgroundColor: mediumGrey,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Sem Formulários`,
      data: semFormData,
      borderColor: darkerGrey2,
      backgroundColor: darkerGrey2,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Vencidos`,
      data: vencidosData,
      borderColor: lightGrey,
      backgroundColor: lightGrey,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },

    {
      type: "bar",
      label: `Concluido`,
      data: concluidoData,
      borderColor: turquoise20,
      backgroundColor: turquoise20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
    {
      type: "bar",
      label: `Total`,
      data: line.qtde_ocorrencias,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setInputValue(searchValue);
      setRows(backupRows);
    } else {
      setInputValue(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const getUrgencyColor = (status) => {
    if (status === "0") {
      return santander20;
    } else return lighterGrey;
  };

  const cols = [
    {
      field: "pernumper",
      headerName: "Pernumper",
      width: 210,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={inputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => requestSearch(e.target.value.replace(/[^\d]/, ""))}
            placeholder="Pernumper"
            className={classes.pernumpInput}
          />
          <Icons icon="search" isListInputIcon />
        </div>
      ),
    },
    {
      field: "person",
      headerName: "Pessoa",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "uniorg",
      headerName: "Uniorg",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "uniorgName",
      headerName: "Agência",
      width: 310,
      headerClassName: "header",
    },
    {
      field: "urgStatus",
      headerName: "Status",
      width: 200,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.action),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: params.row.urgStatus.length > 16 ? "12px" : "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.urgStatus}
        </div>
      ),
    },
    {
      field: "term",
      headerName: "Prazo",
      width: 210,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Ação",
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddKYCAction({
                aux: params.row.pernumper,
                ind: params.row.cdIndicador,
                uniorg: params.row.uniorg,
                atuacao: params.row.action === "0" ? 1 : 0,
              });
            }}
          >
            {params.row.action === "0" ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </>
      ),
    },
  ];

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const customDoughnutConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataPieLabel,
    doughnutCenterLabel: pie.nu_Qtde,
    withLegend: false,
    withDoughnutCenterLabelPercentage: false,
    withLines: false,
    withStacked: false,
    indicatorGoal: 0,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado semanal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: line.periodos,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={forms}
        type={"KYC"}
        modalType="confidencial"
      />
      <Header isKYC={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>KYC - Formulário</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {forms.length > 0 ? (
              <Typography className={classes.uptime}>
                Última atualização: {forms[0].ult_Atualizacao}
              </Typography>
            ) : null}
            <Tooltip placement="bottom" title={"Exportar"}>
              <Button
                onClick={() => setOpenModal(true)}
                sx={{
                  "&:hover": {
                    background: "none",
                    color: "none",
                  },
                }}
              >
                <Icons icon="export-xls" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: isResolve ? lighterGrey : santander10 }}
            >
              <div className={classes.pieChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {pie.nu_Analista}
                    </Typography>
                  </div>
                  <div className={classes.legendContainer}>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: lightGrey }}
                      ></div>
                      <Typography className={classes.legend}>
                        Trabalhados: {pie.nu_Trabalhado} ({pie.perc_Trabalhado}
                        %)
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: santander }}
                      ></div>
                      <Typography className={classes.legend}>
                        Pendente: {pie.nu_Pendente} ({pie.perc_Pendente}%)
                      </Typography>
                    </div>
                  </div>
                </div>

                <ChartContainer customConfigs={customDoughnutConfigs} />
              </div>
              <div className={classes.lineChartsContainer}>
                <div className={classes.lineChartContainer}>
                  <ChartContainer customConfigs={customLineConfigs} />
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      {forms[0] !== "forbidden" ? (
        <IndicatorList
          rows={rows}
          cols={cols}
          sortModel={kycSortModel}
          changeSortModel={setKycSortModel}
          getStatusBGMethod={getUrgencyColor}
          type="kyc"
        />
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do KYC possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddKYCAction: (payload) => dispatch(addIndicatorComment(payload)),
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onGetGAInfo: (id) => dispatch(getGAInfo(id)),
    onGetSupervisorInfo: (id) => dispatch(getSupervisorInfo(id)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { pie, line, forms, loading } = state.kyc;
  const { userInfo } = state.profile;
  const { gaInfo } = state.bulletin;

  return {
    match: ownProps?.match,
    pie,
    line,
    forms,
    loading,
    userInfo,
    gaInfo,
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(KYC)
);
