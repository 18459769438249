// @flow

export const GET_BUCKET_INFO = "[COMP] GET BUCKET INFO";
export const GET_BUCKET_INFO_SUCCESS = "[COMP] GET BUCKET INFO SUCCESS";
export const GET_BUCKET_INFO_ERROR = "[COMP] GET BUCKET INFO ERROR";

export const GET_SHEET_PAPER = "[COMP] GET SHEET PAPER";
export const GET_SHEET_PAPER_SUCCESS = "[COMP] GET SHEET PAPER SUCCESS";
export const GET_SHEET_PAPER_ERROR = "[COMP] GET SHEET PAPER ERROR";

export const GET_WORKED_OUT_TABLE = "[COMP] GET WORKED OUT TABLE";
export const GET_WORKED_OUT_TABLE_SUCCESS =
  "[COMP] GET WORKED OUT TABLE SUCCESS";
export const GET_WORKED_OUT_TABLE_ERROR = "[COMP] GET WORKED OUT TABLE ERROR";

export const GET_DOUGHNUT_CHART = "[COMP] GET DOUGHNUT CHART";
export const GET_DOUGHNUT_CHART_SUCCESS = "[COMP] GET DOUGHNUT CHART SUCCESS";
export const GET_DOUGHNUT_CHART_ERROR = "[COMP] GET DOUGHNUT CHART ERROR";

export const GET_LINE_CHART = "[COMP] GET LINE CHART";
export const GET_LINE_CHART_SUCCESS = "[COMP] GET LINE CHART SUCCESS";
export const GET_LINE_CHART_ERROR = "[COMP] GET LINE CHART ERROR";

export const REMOVE_GA = "[COMP] REMOVE GA";
export const REMOVE_GA_SUCCESS = "[COMP] REMOVE GA SUCCESS";
export const REMOVE_GA_ERROR = "[COMP] REMOVE GA ERROR";

export const GET_TOTAL_TRAB = "[COMP] GET TOTAL TRAB";
export const GET_TOTAL_TRAB_SUCCESS = "[COMP] GET TOTAL TRAB SUCCESS";
export const GET_TOTAL_TRAB_ERROR = "[COMP] GET TOTAL TRAB ERROR";

type GetBucketInfo = {
  type: typeof GET_BUCKET_INFO,
  payload: any,
};
type GetBucketInfoSuccess = {
  type: typeof GET_BUCKET_INFO_SUCCESS,
  payload: any,
};
type GetBucketInfoError = {
  type: typeof GET_BUCKET_INFO_ERROR,
  payload: any,
};

type GetSheetPaperInfo = {
  type: typeof GET_SHEET_PAPER,
  payload: any,
};
type GetSheetPaperInfoSuccess = {
  type: typeof GET_SHEET_PAPER_SUCCESS,
  payload: any,
};
type GetSheetPaperInfoError = {
  type: typeof GET_SHEET_PAPER_ERROR,
  payload: any,
};

type GetWorkedOutTable = {
  type: typeof GET_WORKED_OUT_TABLE,
  payload: any,
};
type GetWorkedOutTableSuccess = {
  type: typeof GET_WORKED_OUT_TABLE_SUCCESS,
  payload: any,
};
type GetWorkedOutTableError = {
  type: typeof GET_WORKED_OUT_TABLE_ERROR,
  payload: any,
};

type GetDoughnutChart = {
  type: typeof GET_DOUGHNUT_CHART,
  payload: any,
};
type GetDoughnutChartSuccess = {
  type: typeof GET_DOUGHNUT_CHART_SUCCESS,
  payload: any,
};
type GetDoughnutChartError = {
  type: typeof GET_DOUGHNUT_CHART_ERROR,
  payload: any,
};

type GetLineChart = {
  type: typeof GET_LINE_CHART,
  payload: any,
};
type GetLineChartSuccess = {
  type: typeof GET_LINE_CHART_SUCCESS,
  payload: any,
};
type GetLineChartError = {
  type: typeof GET_LINE_CHART_ERROR,
  payload: any,
};

type RemoveGA = {
  type: typeof REMOVE_GA,
  payload: any,
};
type RemoveGASuccess = {
  type: typeof REMOVE_GA_SUCCESS,
  payload: any,
};
type RemoveGAError = {
  type: typeof REMOVE_GA_ERROR,
  payload: any,
};

type GetTotalTrab = {
  type: typeof GET_TOTAL_TRAB,
  payload: any,
};
type GetTotalTrabSuccess = {
  type: typeof GET_TOTAL_TRAB_SUCCESS,
  payload: any,
};
type GetTotalTrabError = {
  type: typeof GET_TOTAL_TRAB_ERROR,
  payload: any,
};

export type CompAction =
  | GetBucketInfo
  | GetBucketInfoSuccess
  | GetBucketInfoError
  | GetSheetPaperInfo
  | GetSheetPaperInfoSuccess
  | GetSheetPaperInfoError
  | GetWorkedOutTable
  | GetWorkedOutTableSuccess
  | GetWorkedOutTableError
  | GetDoughnutChart
  | GetDoughnutChartSuccess
  | GetDoughnutChartError
  | GetLineChart
  | GetLineChartSuccess
  | GetLineChartError
  | RemoveGA
  | RemoveGASuccess
  | RemoveGAError
  | GetTotalTrab
  | GetTotalTrabSuccess
  | GetTotalTrabError;
