import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getFilters } from "../store/actions/bulletin";
import { getChecklist } from "../store/actions/checklist";
import { makeStyles } from "@material-ui/styles";
import { black, darkGrey, white, inputSky } from "../theme/colors";
import Box from "@mui/material/Box";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Icons from "./Icons";
import { getAgencia } from "../store/services/checklist";
import { getUserLvl } from "../store/services/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  inputContainer: {
    width: "705px",
  },
  input: {
    fontFamily: "SantanderMicroText !important",
    boxSizing: "border-box",
    width: "705px",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1.7em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
  inputCategory: {
    fontFamily: "SantanderMicroText",
  },
  inputCategoryTitleContainer: {
    display: "flex",
    fontWeight: "bold",
    padding: "10px 16px",
    alignItems: "center",
  },
  inputCategoryTitle: {
    marginLeft: "12px",
  },
  divider: {
    width: "95%",
  },
}));

const FilterField = ({
  onGetFilters,
  onGetChecklist,
  filters,
  changedForm,
  onChangeMethod,
  isChecklist,
  isBulletin,
  isATM,
  isKYC,
}) => {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [filterType, setFilterType] = useState("");
  const [totalFilters, setTotalFilters] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (isChecklist && getAgencia() !== "0") {
      onGetChecklist(parseInt(getAgencia()));
    }
  }, []);

  useEffect(() => {
    onGetFilters();
  }, [onGetFilters]);

  useEffect(() => {
    //TO-DO: refatorar isso
    let totalFiltersArray = [];
    if (filters.redes && filters.regionais && filters.agencias && filters.gAs) {
      if (isBulletin || ((isATM || isKYC) && !isChecklist)) {
        if (getUserLvl() !== "1") {
          totalFiltersArray = [
            ...totalFiltersArray,
            { type: "CLEAR", label: "Consolidado de agências" },
          ];
        }
        for (let index = 0; index < filters.redes.length; index++) {
          totalFiltersArray = [
            ...totalFiltersArray,
            {
              ...filters.redes[index],
              label: `${filters.redes[index].cd_Rede} - ${filters.redes[index].ds_Rede}`,
              type: "REDE",
            },
          ];
        }
        for (let index = 0; index < filters.regionais.length; index++) {
          totalFiltersArray = [
            ...totalFiltersArray,
            {
              ...filters.regionais[index],
              label: `${filters.regionais[index].cd_Regional} - ${filters.regionais[index].ds_Regional}`,
              type: "REGIONAL",
            },
          ];
        }
      }
      for (let index = 0; index < filters.agencias.length; index++) {
        totalFiltersArray = [
          ...totalFiltersArray,
          {
            ...filters.agencias[index],
            label: `${filters.agencias[index].cd_Uniorg} - ${filters.agencias[index].nm_Agencia}`,
            type: "AGÊNCIA",
          },
        ];
      }

      for (let index = 0; index < filters.gAs.length; index++) {
        totalFiltersArray = [
          ...totalFiltersArray,
          {
            ...filters.gAs[index],
            label: `${filters.gAs[index].cd_Matricula} - ${filters.gAs[index].nm_Func}`,
            type: "PERFIL",
          },
        ];
      }
    }
    setTotalFilters([...totalFilters, ...totalFiltersArray]);
  }, [filters, isBulletin, isChecklist]);

  useEffect(() => {
    setId(0);
    //inputToFocus.current.focus();
  }, [changedForm]);

  useEffect(() => {
    if (filterType !== "" && id !== "") {
      onChangeMethod(filterType, id);
    }
  }, [id, filterType]);

  const changeValue = (value) => {
    setInputValue(value);
    if (value !== null) {
      setFilterType(value.type);
      const gaId = parseInt(value.label.split(" ")[0]);
      if (!isNaN(gaId) && gaId >= 10) {
        setId(gaId);
      } else {
        setId(0);
      }
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case "REDE":
        return "city";
      case "REGIONAL":
        return "franchise";
      case "AGÊNCIA":
        return "place";
      case "PERFIL":
        return "circle-account-user";
      default:
        break;
    }
  };

  const getPlaceholderClass = () => {
    if (inputValue === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (inputValue === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (inputValue !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 50,
  });

  return (
    <div className={classes.root}>
      <Autocomplete
        sx={{
          width: "705px",
          "& input": {
            "&::placeholder": {
              color: black,
            },
            "&:hover": {
              borderColor: `${darkGrey} !important`,
            },
            "&:focusVisible": {
              border: `1px solid ${inputSky} !important`,
              outline: `${inputSky} !important`,
            },
            "&:focus": {
              borderColor: `${inputSky} !important`,
              outline: `${inputSky} 1px !important`,
            },
          },
        }}
        filterOptions={filterOptions}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        noOptionsText={"Não foram encontradas opções com esse termo."}
        onChange={(event, value) => changeValue(value)}
        autoHighlight
        options={totalFilters}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{ fontFamily: "SantanderMicroText" }}
          >
            {option.label}
          </Box>
        )}
        renderGroup={(params) => (
          <>
            {params.group === "CLEAR" ? (
              <div>
                {params.children.map((i, index) => (
                  <span key={index}>{i}</span>
                ))}
                <hr className={classes.divider} />
              </div>
            ) : (
              <div className={classes.inputCategory}>
                <div className={classes.inputCategoryTitleContainer}>
                  <Icons icon={getIcon(params.group)} />
                  <div className={classes.inputCategoryTitle}>
                    {params.group}
                  </div>
                </div>
                {params.children.map((i, index) => (
                  <span key={index}>{i}</span>
                ))}
                <hr className={classes.divider} />
              </div>
            )}
          </>
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              className={`${classes.input} dss-form-field__input`}
              {...params.inputProps}
              style={{
                fontFamily: "SantanderMicroText !important",
                boxSizing: "border-box",
                width: "705px",
                height: "3.6em",
                border: `0.06em solid ${black}`,
                borderRadius: "0.250em",
                padding: "0em 1.7em",
              }}
            />
            <span className={classes.provisionalPlaceholder}>
              Digite uma palavra-chave
            </span>
            <Icons icon="search" isInputIcon />
          </div>
        )}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFilters: () => dispatch(getFilters()),
    onGetChecklist: (agencia) => dispatch(getChecklist(agencia)),
  };
};

const mapStateToProps = (state) => {
  const { filters } = state.bulletin;
  return { filters };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FilterField
);
