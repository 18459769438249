import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { getLastSearchedUser } from "../../store/services/search";
import { setIndicatorType } from "../../utils/indicators";
import { getUser } from "../../store/services/auth";

import csvIcon from "../../assets/icons/csv_icon.svg";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  CustomCalendar,
  Icons,
  CommentDrawer,
  FeedBackCircle,
  Checked,
  WarningExportModal,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  yellow,
  yellow10,
  black,
  santander20,
  santander70,
  mediumGrey,
} from "../../theme/colors";

import interno from "../../assets/img/interno.svg";

const currentDate = new Date().getDate();

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  doughnutChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
    width: "501px",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  legendContainer: {
    width: "100%",
    height: "50px",
    marginBottom: "15px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  checkBall: {
    border: `1px solid ${black}`,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    background: santander70,
    textAlign: "center",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const StoreAvailability = ({
  match,
  doughnut,
  calendar,
  stores,
  loading,
  gaInfo,
}) => {
  const classes = useStyles();
  const [lineDataChart, setLineDataChart] = useState([]);
  const [linePeriodDataChart, setLinePeriodDataChart] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [isMouthResultResolve, setIsMouthResultResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(true);
  const [oldDisp, setOldDisp] = useState(0);
  const [rows, setRows] = useState([]);
  const [storeSortModel, setStoreSortModel] = useState([
    {
      field: "urgStatus",
      sort: "desc",
    },
  ]);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let list = [];
    if (stores[0] !== "forbidden") {
      stores.map((i) => {
        list = [
          ...list,
          {
            id: i.cd_Uniorg,
            cdIndicador: i.cd_indicador,
            uniorgName: i.nm_Agencia,
            urgStatus: i.st_Agencia,
            date: i.dt_Ocorrencia,
            problem: i.ds_Problema,
            details: i.obs_Problema,
            category: i.resp_Problema,
            action: i.bl_Atuacao,
          },
        ];
      });
      setRows(list);
    }
  }, [stores]);

  useEffect(() => {
    setHasLoaded(false);
    if (
      Object.keys(doughnut).length !== 0 ||
      Object.keys(calendar).length !== 0
    ) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 2000);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, calendar]);

  useEffect(() => {
    doughnut.perc_Abr < 100 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut.perc_Abr]);

  const getDaysInMonth = () => {
    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      days.push(date.getDate().toString().padStart(2, "0"));
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const getColorCodeToArray = (day) => {
    if (parseInt(day) > currentDate) {
      return 4;
    } else if (calendar.fechadas.some((item) => item.dia == day)) {
      return 3;
    } else if (calendar.parcial.some((item) => item.dia == day)) {
      return 2;
    } else if (
      !calendar.fechadas.some((item) => item.dia == day) &&
      !calendar.parcial.some((item) => item.dia == day)
    ) {
      return 1;
    }
  };

  const getStatusToArray = (day) => {
    if (parseInt(day) > currentDate) {
      return "Aberta";
    } else if (calendar.fechadas.some((item) => item.dia == day)) {
      return "Fechada";
    } else if (calendar.parcial.some((item) => item.dia == day)) {
      return "Aberta Parcial";
    } else if (
      !calendar.fechadas.some((item) => item.dia == day) &&
      !calendar.parcial.some((item) => item.dia == day)
    ) {
      return "Aberta";
    }
  };

  const getProblemToArray = (day) => {
    if (parseInt(day) > currentDate) {
      return "";
    } else if (calendar.fechadas.some((item) => item.dia == day)) {
      let errorProblem;
      calendar.fechadas.forEach(function (item) {
        if (item.dia == day) errorProblem = item.ds_Problema;
      });
      return errorProblem;
    } else if (calendar.parcial.some((item) => item.dia == day)) {
      let warningProblem;
      calendar.parcial.forEach(function (item) {
        if (item.dia == day) warningProblem = item.ds_Problema;
      });
      return warningProblem;
    } else if (
      !calendar.fechadas.some((item) => item.dia == day) &&
      !calendar.parcial.some((item) => item.dia == day)
    ) {
      return "";
    }
  };

  const mountArr = () => {
    const days = getDaysInMonth();
    const heatMapMock = [];
    for (let i = 0; i < days.length; i++) {
      heatMapMock.push({
        day: days[i],
        colorCode: getColorCodeToArray(days[i]),
        status: getStatusToArray(days[i]),
        problem: getProblemToArray(days[i]),
      });
    }

    return heatMapMock;
  };

  const mockDataPieLabel = [
    `${doughnut.st_Fch} (${doughnut.perc_Fch}%)`,
    `${doughnut.st_Apr} (${doughnut.perc_Apr}%)`,
    `${doughnut.st_Abr} (${doughnut.perc_Abr}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [doughnut.qtde_Fch, doughnut.qtde_Apr, doughnut.qtde_Abr],
      backgroundColor: [santander, yellow, turquoise],
      cutout: 80,
    },
  ];

  const getBackgroundColorByStatus = (statusCode) => {
    switch (statusCode) {
      case 0:
        return isResolve ? lighterGrey : santander10;
      case 1:
        return lighterGrey;
      case 2:
        return yellow10;
      case 3:
        return santander10;
      default:
        break;
    }
  };

  const getBarColorByStatus = (statusCode) => {
    switch (statusCode) {
      case 0:
        return isResolve ? green : santander;
      case 1:
        return green;
      case 2:
        return yellow;
      case 3:
        return santander;
      default:
        break;
    }
  };

  const getUrgencyColor = (issueDays) => {
    if (issueDays === "Fechado") return santander20;
    else return lighterGrey;
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const cols = [
    {
      field: "id",
      headerName: "Uniorg",
      width: 75,
      headerClassName: "header",
    },
    {
      field: "uniorgName",
      headerName: "Agência",
      width: 300,
      headerClassName: "header",
    },
    {
      field: "urgStatus",
      headerName: "Status",
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.urgStatus),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
          }}
        >
          {params.row.urgStatus}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Data",
      width: 105,
      headerClassName: "header",
    },
    {
      field: "problem",
      headerName: "Problema",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "details",
      headerName: "Observações",
      width: 200,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top-start" title={params.row.details || ""}>
          <span>{params.row.details}</span>
        </Tooltip>
      ),
    },
    {
      field: "category",
      headerName: "Responsável",
      width: 200,
      headerClassName: "header",
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                ind: params.row.cdIndicador,
                uniorg: params.row.id,
                atuacao: params.row.action ? 1 : 0,
              })
            }
          >
            {params.row.action === false ? (
              <Icons icon="sign-up" />
            ) : (
              <Checked />
            )}
          </Button>
        </>
      ),
    },
  ];

  const getStoreStatus = (status) => {
    switch (status) {
      case 1:
        return "Aberta";
      case 2:
        return "Aberta Parcial";
      case 3:
        return "Fechada";
    }
  };

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const customDoughnutConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataPieLabel,
    doughnutCenterLabel: doughnut.perc_Abr,
    withLegend: false,
    withDoughnutCenterLabelPercentage: true,
    withLines: false,
    withStacked: false,
    indicatorGoal: 100,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={stores}
        type={"LOJA"}
        modalType="interno"
      />
      <Header isStore={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>
              Disponibilidade - Loja
            </Typography>
          </div>

          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {stores.length > 0 ? (
              <Typography className={classes.uptime}>
                Última atualização: {stores[0].ult_Atualizacao}
              </Typography>
            ) : null}
            <Tooltip placement="bottom" title={"Exportar"}>
              <Button
                onClick={() => setOpenModal(true)}
                sx={{
                  "&:hover": {
                    background: "none",
                    color: "none",
                  },
                }}
              >
                <Icons icon="export-xls" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{
                backgroundColor: getBackgroundColorByStatus(
                  doughnut.st_Grafico
                ),
              }}
            >
              <div className={classes.doughnutChartContainer}>
                <div
                  className={classes.bar}
                  style={{
                    backgroundColor: getBarColorByStatus(doughnut.st_Grafico),
                  }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "18px !important",
                        color: `${darkGrey} !important`,
                        marginTop: "20px",
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {doughnut.qtde_Ga}
                    </Typography>
                    {doughnut.st_Grafico === 0 ? (
                      <Typography className={classes.overviewDataChart}>
                        Lojas: {doughnut.qtde_Loja}
                      </Typography>
                    ) : (
                      <Typography className={classes.overviewDataChart}>
                        Lojas: {getStoreStatus(doughnut.st_Grafico)}
                      </Typography>
                    )}
                  </div>

                  {doughnut.st_Grafico === 0 ? (
                    <div className={classes.legendContainer}>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: turquoise }}
                        ></div>
                        <Typography className={classes.legend}>
                          {doughnut.st_Abr}: {doughnut.qtde_Abr} (
                          {doughnut.perc_Abr}%)
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: yellow }}
                        ></div>
                        <Typography className={classes.legend}>
                          {doughnut.st_Apr}: {doughnut.qtde_Apr} (
                          {doughnut.perc_Apr}%)
                        </Typography>
                      </div>
                      <div className={classes.legendLine}>
                        <div
                          className={classes.colorLegendSquare}
                          style={{ backgroundColor: santander }}
                        ></div>
                        <Typography className={classes.legend}>
                          {doughnut.st_Fch}: {doughnut.qtde_Fch} (
                          {doughnut.perc_Fch}%)
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </div>

                {doughnut.st_Grafico === 0 ? (
                  <ChartContainer customConfigs={customDoughnutConfigs} />
                ) : (
                  <FeedBackCircle feedbackStatus={doughnut.st_Grafico} />
                )}
              </div>
              <div className={classes.lineChartsContainer}>
                {Object.keys(calendar).length !== 0 ? (
                  <CustomCalendar heatMapData={mountArr()} />
                ) : null}
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="store"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />
      {stores[0] !== "forbidden" ? (
        <IndicatorList
          rows={rows}
          cols={cols}
          sortModel={storeSortModel}
          changeSortModel={setStoreSortModel}
          getStatusBGMethod={getUrgencyColor}
          type="store"
        />
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos de Disponibilidade de Loja possui dados
          sensíveis que são mostrados apenas à quem tem a(s) agência(s) na
          carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Interno
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            "Qualquer dado publicado por meio de canais internos e disponíveis
            para todos os funcionários do banco, como comunicados de mudanças
            organizacionais, comunicados departamentais, comunicados exclusivos
            aos funcionários Santander e etc.",
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { doughnut, calendar, stores, loading } = state.storeAvailability;
  const { gaInfo } = state.bulletin;
  return {
    match: ownProps?.match,
    doughnut,
    calendar,
    stores,
    gaInfo,
    loading,
  };
};

export default withRouter(compose(connect(mapStateToProps))(StoreAvailability));
