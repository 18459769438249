import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Typography, Button, Box } from "@mui/material";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  santander,
  white,
  santander20,
  boston,
  darkGrey,
  black,
} from "../theme/colors";
import { deleteAlert, getAlertsFullList } from "../store/actions/alerts";
import { getUser } from "../store/services/auth";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const DeleteAlertModal = ({
  onDeleteAlert,
  onGetAlertsFullList,
  open,
  onClose,
  id,
}) => {
  const classes = useStyles();

  const deleteAlert = () => {
    onDeleteAlert(id);
    onGetAlertsFullList(getUser());
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: white,
          p: 4,
        }}
      >
        <Typography className={classes.title}>Excluir alerta</Typography>
        <Typography className={classes.subtitle}>
          Deseja remover a mensagem?
        </Typography>
        <div className={classes.btnContainer}>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              height: "48px",
              fontFamily: "SantanderMicroText ",
              background: white,
              textTransform: "none ",
              color: `${santander} `,
              border: `1px solid ${santander} `,
              display: "block",
              fontSize: "16px ",
              "&:hover": {
                background: santander20,
                color: `${santander} `,
              },
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={deleteAlert}
            variant="contained"
            sx={{
              height: "48px",
              background: boston,
              color: white,
              fontFamily: "SantanderMicroText",
              textTransform: "none",
              textDecoration: "none",
              "&:hover": {
                background: boston,
                color: white,
              },
            }}
          >
            Remover
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAlert: (id) => dispatch(deleteAlert(id)),
    onGetAlertsFullList: (id) => dispatch(getAlertsFullList(id)),
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.profile;
  return { loading };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteAlertModal
);
