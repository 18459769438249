import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme/theme";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { NormalRoute, ProtectedRoute } from "./routes";
import {
  Login,
  Bulletin,
  ChangePass,
  Checklist,
  RequestSent,
  Home,
  Register,
  Profile,
  ProfileList,
  SelectPage,
  Alerts,
  DataCharts,
  AgencyManagement,
  ATM,
  KYC,
  StoreAvailability,
  NQAlerts,
  EXO,
  NPS,
  B2G,
  Queues,
  Reft,
  Check,
  Manifestations,
  WorkComp,
  WorkedOut,
  HomeComp,
} from "./views";
import { white, boston } from "./theme/colors";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = ({ history }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ position: "relative" }}>
        {process.env.REACT_APP_BASE_API === "development" ? (
          <>
            <div style={{ height: "64px" }}></div>
            <div
              style={{
                height: "18x",
                position: "fixed",
                top: "0%",
                background: boston,
                color: white,
                width: "100%",
                fontFamily: "SantanderHeadline",
                fontSize: "16px",
                textAlign: "center",
                boxShadow: "0px 6px 12px 0px rgba(0,0,0,0.7)",
                zIndex: "9999",
                fontWeight: "bold",
              }}
            >
              AMBIENTE DE TESTES
            </div>
          </>
        ) : null}
        <Router history={history}>
          <Route
            render={({ location }) => (
              <TransitionGroup style={{ flex: 1 }}>
                <CSSTransition
                  key={location.pathname}
                  timeout={450}
                  classNames="fade"
                  exit={false}
                >
                  <Switch location={location}>
                    <NormalRoute exact path="/" component={Login} />
                    <NormalRoute
                      exact
                      path="/reset-password"
                      component={ChangePass}
                    />
                    <NormalRoute
                      exact
                      path="/request-sent"
                      component={RequestSent}
                    />
                    <ProtectedRoute
                      exact
                      path="/home"
                      component={Home}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/boletim"
                      component={Bulletin}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/checklist"
                      component={Checklist}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/cadastro"
                      component={Register}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/perfil/:id"
                      component={Profile}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/usuarios"
                      component={ProfileList}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/carterizacao/"
                      component={ProfileList}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/carterizacao/:type/:id"
                      component={SelectPage}
                      history={history}
                    />
                    {/* <ProtectedRoute
                      exact
                      path="/painel-boletim"
                      component={DataCharts}
                      history={history}
                    /> */}
                    <ProtectedRoute
                      exact
                      path="/alertas"
                      component={Alerts}
                      history={history}
                    />
                    <ProtectedRoute
                      path="/agencias"
                      component={AgencyManagement}
                      history={history}
                    />
                    <ProtectedRoute
                      exact
                      path="/atm/:type/:id"
                      component={ATM}
                    />
                    <ProtectedRoute
                      exact
                      path="/kyc/:type/:id"
                      component={KYC}
                    />
                    <ProtectedRoute
                      exact
                      path="/loja/:type/:id"
                      component={StoreAvailability}
                    />
                    <ProtectedRoute
                      exact
                      path="/nq-alertas/:type/:id"
                      component={NQAlerts}
                    />
                    <ProtectedRoute
                      exact
                      path="/exo/:type/:id"
                      component={EXO}
                    />
                    <ProtectedRoute
                      exact
                      path="/nps/:type/:id"
                      component={NPS}
                    />

                    {/* <ProtectedRoute
                      exact
                      path="/b2g/:type/:id"
                      component={B2G}
                    /> */}
                    <ProtectedRoute
                      exact
                      path="/filas/:type/:id"
                      component={Queues}
                    />
                    <ProtectedRoute
                      exact
                      path="/reft/:type/:id"
                      component={Reft}
                    />
                    {/*
                      <ProtectedRoute
                      exact
                      path="/cheque/:type/:id"
                      component={Check}
                    />
                      */}
                    <ProtectedRoute
                      exact
                      path="/manifestacoes/:type/:id"
                      component={Manifestations}
                    />

                    <ProtectedRoute
                      exact
                      path="/compensacao"
                      component={WorkComp}
                    />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </Router>
      </div>
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        autoClose={5000}
        closeOnClick
      />
    </ThemeProvider>
  );
};

export default App;
