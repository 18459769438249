// @flow

import * as fromTypes from "../types/kyc";

export const getKYCAvailability = (payload: object): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_AVAILABILITY,
  payload,
});
export const getKYCAvailabilitySuccess = (
  payload: array
): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_AVAILABILITY_SUCCESS,
  payload,
});
export const getKYCAvailabilityError = (payload: any): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_AVAILABILITY_ERROR,
  payload,
});

export const addKYCAction = (payload: object): fromTypes.KYCAction => ({
  type: fromTypes.ADD_KYC_ACTION,
  payload,
});
export const addKYCActionSuccess = (payload: any): fromTypes.KYCAction => ({
  type: fromTypes.ADD_KYC_ACTION_SUCCESS,
  payload,
});
export const addKYCActionError = (payload: any): fromTypes.KYCAction => ({
  type: fromTypes.ADD_KYC_ACTION_ERROR,
  payload,
});

export const getKYCPieChart = (payload: object): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_PIE_CHART,
  payload,
});
export const getKYCPieChartSuccess = (payload: array): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_PIE_CHART_SUCCESS,
  payload,
});
export const getKYCPieChartError = (payload: any): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_PIE_CHART_ERROR,
  payload,
});

export const getKYCsLineChart = (payload: object): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_LINE_CHART,
  payload,
});
export const getKYCsLineChartSuccess = (
  payload: array
): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_LINE_CHART_SUCCESS,
  payload,
});
export const getKYCsLineChartError = (payload: any): fromTypes.KYCAction => ({
  type: fromTypes.GET_KYC_LINE_CHART_ERROR,
  payload,
});
