import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Box, Button, Typography } from "@material-ui/core";
import {
  white,
  santander,
  boston,
  darkGrey,
  santander20,
  black,
} from "../theme/colors";
import { Icons } from "./";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
    paddingBottom: "16px !important",
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
    "& a": {
      fontWeight: "bold !important",
      color: santander,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  closeBtn: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  },
}));

const FunctionModal = ({ open, onClose, title, Subtitle, buttonList }) => {
  const classes = useStyles();

  const getStyles = (type) => {
    switch (type) {
      case "main":
        return {
          height: "48px",
          background: boston,
          color: white,
          fontFamily: "SantanderMicroText",
          textTransform: "none",
          textDecoration: "none",
          "&:hover": {
            background: boston,
            color: white,
          },
        };
      case "secondary":
        return {
          height: "48px",
          fontFamily: "SantanderMicroText ",
          background: white,
          textTransform: "none ",
          color: `${santander} `,
          border: `1px solid ${santander} `,
          display: "block",
          fontSize: "16px ",
          marginRight: "16px",
          "&:hover": {
            background: santander20,
            color: `${santander} `,
          },
        };
      default:
        break;
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: white,
          p: 4,
        }}
      >
        <div className={classes.closeBtn} onClick={onClose}>
          <Icons icon="close-1px" />
        </div>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>
          <Subtitle />
        </Typography>
        <div className={classes.btnContainer}>
          {buttonList.map((i, index) => {
            return (
              <Button
                onClick={i.function}
                variant="contained"
                sx={getStyles(i.type)}
              >
                {i.text}
              </Button>
            );
          })}
        </div>
      </Box>
    </Modal>
  );
};

export default FunctionModal;
