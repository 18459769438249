import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { getLastSearchedUser } from "../../store/services/search";
import { getUser } from "../../store/services/auth";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  CommentDrawer,
  Icons,
  Checked,
  WarningExportModal,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  santander40,
  santander70,
  green20,
  black,
  mediumGrey,
} from "../../theme/colors";

import interno from "../../assets/img/interno.svg";
import { setIndicatorType } from "../../utils/indicators";
import csvIcon from "../../assets/icons/csv_icon.svg";

const mockDataLine1 = [2, 1, 3, 2, 3, 4];
const mockDataLine2 = [1, 3, 3, 2, 1, 4];
const mockDataLine3 = [1, 2, 2, 4, 4, 3];
const mockDataLine4 = [2, 4, 1, 3, 2, 1];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  doughnutChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "150px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  checkBall: {
    border: `1px solid ${black}`,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    background: santander70,
    textAlign: "center",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "50px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },
}));

const Indicator = ({ match, doughnut, line, atms, gaInfo, loading }) => {
  const classes = useStyles();
  const [lineDataChart, setLineDataChart] = useState([]);
  const [linePeriodDataChart, setLinePeriodDataChart] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [isMouthResultResolve, setIsMouthResultResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [oldDisp, setOldDisp] = useState(0);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [atmSortModel, setAtmSortModel] = useState([
    {
      field: "urgStatus",
      sort: "desc",
    },
  ]);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    let list = [];
    if (atms[0] !== "forbidden") {
      atms.map((i) => {
        list = [
          ...list,
          {
            id: `${i.cd_Uniorg}-${i.nu_Equipamento}`,
            nuEquipamento: i.nu_Equipamento,
            cdIndicador: i.cd_indicador,
            uniorg: i.cd_Uniorg,
            event: i.ds_Evento || "",
            uniorgName: i.ds_Agencia,
            status: i.st_Atual,
            action: i.atuacao,
            component: i.nm_Componente || "",
            urgStatus: isNaN(i.chamado_Aberto)
              ? ""
              : parseInt(i.chamado_Aberto) || "-",
            dispPercent: `${parseFloat(i.perc_Acum_Dia)}%`,
          },
        ];
      });
      setRows(list);
    }
  }, [atms]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut.perc_disp !== oldDisp) {
      setOldDisp(doughnut.perc_disp);

      setTimeout(() => {
        setHasLoaded(true);
      }, 2000);
    } else if (doughnut.perc_disp === oldDisp) {
      setHasLoaded(true);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut.perc_disp, line.length]);

  useEffect(() => {
    let lineDataDisp = [];
    let linePeriodDataDisp = [];
    line.map((i) => {
      lineDataDisp.push(i.qtde_disp);
      linePeriodDataDisp.push(i.dt_relatorio);
    });
    setLineDataChart(lineDataDisp.reverse());
    setLinePeriodDataChart(linePeriodDataDisp.reverse());
  }, [line]);

  useEffect(() => {
    doughnut.perc_disp <= doughnut.perc_meta_disp
      ? setIsResolve(false)
      : setIsResolve(true);
    if (line[0]) {
      line[0].perc_disp <= doughnut.perc_meta_disp
        ? setIsMouthResultResolve(false)
        : setIsMouthResultResolve(true);
    }
  }, [doughnut.perc_disp, doughnut.perc_meta_disp, line]);

  const mockDataDoughnutLabel = [
    `Indisponível (${doughnut.perc_n_disp}%)`,
    `Disponível (${doughnut.perc_disp}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [doughnut.qtde_n_disp, doughnut.qtde_disp],
      backgroundColor: [santander, turquoise],
      cutout: 80,
    },
  ];

  const lineAvailableChartConfig = [
    {
      label: `Disponível (${lineDataChart.at(-1)}%)`,
      data: lineDataChart,
      borderColor: isMouthResultResolve ? green : santander,
      backgroundColor: isMouthResultResolve ? green : santander,
      fill: false,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
  ];

  const getUrgencyColor = (issueDays) => {
    if (issueDays !== "-") {
      if (issueDays <= 5) {
        return santander20;
      } else if (issueDays <= 15) {
        return santander40;
      } else {
        return santander70;
      }
    } else return green20;
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const cols = [
    {
      field: "uniorg",
      headerName: "Uniorg",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "uniorgName",
      headerName: "Agência",
      width: 250,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.uniorgName || ""}>
          <span>{params.row.uniorgName}</span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "id",
      headerName: "Equip.",
      width: 100,
      headerClassName: "header",
    },
    {
      field: "dispPercent",
      headerName: "Disp. (%)",
      width: 105,
      headerClassName: "header",
    },
    {
      field: "urgStatus",
      headerName: "Chamado aberto",
      width: 180,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.urgStatus || "-"),
            display: "block",
            margin: "auto",
            width: "60%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
          }}
        >
          {params.row.urgStatus || "-"}
        </div>
      ),
    },
    {
      field: "component",
      headerName: "Componente",
      width: 170,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.component || ""}>
          <span>{params.row.component}</span>
        </Tooltip>
      ),
    },
    {
      field: "event",
      headerName: "Evento",
      width: 200,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.event || ""}>
          <span>{params.row.event}</span>
        </Tooltip>
      ),
    },
    {
      field: "action",
      headerName: "Ação",
      width: 75,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <Button
            className={classes.editBtn}
            onClick={() => {
              openDrawer({
                uniorg: params.row.uniorg,
                ind: params.row.cdIndicador,
                aux: params.row.nuEquipamento.toString(),
                action: params.row.action ? 1 : 0,
              });
            }}
          >
            {params.row.action === "0" ? (
              <Icons icon="sign-up" />
            ) : (
              <div style={{ width: "20px", height: "20px" }}>
                <Checked />
              </div>
            )}
          </Button>
        </>
      ),
    },
  ];

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const customDoughnutConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    chartLabels: mockDataDoughnutLabel,
    doughnutCenterLabel: doughnut.perc_disp,
    withLegend: false,
    withDoughnutCenterLabelPercentage: true,
    withLines: false,
    withStacked: false,
    indicatorGoal: 97,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado mensal",
    chartConfig: lineAvailableChartConfig,
    chartType: "line",
    indicator: true,
    chartLabels: linePeriodDataChart,
    isMulti: false,
    withLines: false,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={atms}
        type={"ATM"}
        modalType="interno"
      />
      <Header isATM={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>
              Disponibilidade - ATM
            </Typography>
          </div>

          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {atms.length > 0 ? (
              <Typography className={classes.uptime}>
                Última atualização: {atms[0].ult_Atualizacao}
              </Typography>
            ) : null}
            <Tooltip placement="bottom" title={"Exportar"}>
              <Button
                onClick={() => setOpenModal(true)}
                sx={{
                  "&:hover": {
                    background: "none",
                    color: "none",
                  },
                }}
              >
                <Icons icon="export-xls" />
              </Button>
            </Tooltip>
          </div>
        </div>
        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: isResolve ? lighterGrey : santander10 }}
            >
              <div className={classes.doughnutChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {doughnut.qtde_analista}
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Equipamentos: {doughnut.qtde_equipamento}
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Disponibilidade: {doughnut.perc_disp}%
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Meta: {doughnut.perc_meta_disp}%
                    </Typography>
                  </div>
                  <div className={classes.legendContainer}>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: santander }}
                      ></div>
                      <Typography className={classes.legend}>
                        Indisponível: {doughnut.qtde_n_disp} (
                        {doughnut.perc_n_disp}
                        %)
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: turquoise }}
                      ></div>
                      <Typography className={classes.legend}>
                        Disponível: {doughnut.qtde_disp} ({doughnut.perc_disp}%)
                      </Typography>
                    </div>
                  </div>
                </div>

                <ChartContainer customConfigs={customDoughnutConfigs} />
              </div>
              <div className={classes.lineChartsContainer}>
                <div className={classes.lineChartContainer}>
                  <ChartContainer customConfigs={customLineConfigs} />
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="atm"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />

      {atms[0] !== "forbidden" ? (
        <IndicatorList
          rows={rows}
          cols={cols}
          sortModel={atmSortModel}
          changeSortModel={setAtmSortModel}
          getStatusBGMethod={getUrgencyColor}
          type="atm"
        />
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos de Disponibilidade de ATMs possui dados
          sensíveis que são mostrados apenas à quem tem a(s) agência(s) na
          carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Interno
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            "Qualquer dado publicado por meio de canais internos e disponíveis
            para todos os funcionários do banco, como comunicados de mudanças
            organizacionais, comunicados departamentais, comunicados exclusivos
            aos funcionários Santander e etc.",
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { doughnut, line, atms, loading } = state.atm;
  const { gaInfo } = state.bulletin;
  return { match: ownProps?.match, doughnut, line, atms, loading, gaInfo };
};

export default withRouter(compose(connect(mapStateToProps))(Indicator));
