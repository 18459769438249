// @flow

export const GET_GA_INFO = "[PROFILE] GET GA INFO";
export const GET_GA_INFO_SUCCESS = "[PROFILE] GET GA INFO SUCCESS";
export const GET_GA_INFO_ERROR = "[PROFILE] GET GA INFO ERROR";

export const GET_SUPERVISOR_INFO = "[PROFILE] GET SUPERVISOR INFO";
export const GET_SUPERVISOR_INFO_SUCCESS =
  "[PROFILE] GET SUPERVISOR INFO SUCCESS";
export const GET_SUPERVISOR_INFO_ERROR = "[PROFILE] GET SUPERVISOR INFO ERROR";

export const GET_USERS = "[PROFILE] GET_USERS";
export const GET_USERS_SUCCESS = "[PROFILE] GET_USERS SUCCESS";
export const GET_USERS_ERROR = "[PROFILE] GET_USERS ERROR";

export const REGISTER_USER = "[PROFILE] REGISTER USER";
export const REGISTER_USER_SUCCESS = "[PROFILE] REGISTER USER SUCCESS";
export const REGISTER_USER_ERROR = "[PROFILE] REGISTER USER ERROR";

export const GET_USER_DETAILS = "[PROFILE] GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "[PROFILE] GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "[PROFILE] GET_USER_DETAILS_ERROR";

export const UPDATE_USER = "[PROFILE] UPDATE_USER";
export const UPDATE_USER_SUCCESS = "[PROFILE] UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "[PROFILE] UPDATE_USER_ERROR";

export const DELETE_USER = "[PROFILE] DELETE_USER";
export const DELETE_USER_SUCCESS = "[PROFILE] DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "[PROFILE] DELETE_USER_ERROR";

export const GET_GENDERS = "[PROFILE] GET_GENDERS";
export const GET_GENDERS_SUCCESS = "[PROFILE] GET_GENDERS_SUCCESS";
export const GET_GENDERS_ERROR = "[PROFILE] GET_GENDERS_ERROR";

export const GET_TYPES = "[PROFILE] GET_TYPES";
export const GET_TYPES_SUCCESS = "[PROFILE] GET_TYPES_SUCCESS";
export const GET_TYPES_ERROR = "[PROFILE] GET_TYPES_ERROR";

export const GET_UNIORG_LIST = "[PROFILE] GET_UNIORG_LIST";
export const GET_UNIORG_LIST_SUCCESS = "[PROFILE] GET_UNIORG_LIST_SUCCESS";
export const GET_UNIORG_LIST_ERROR = "[PROFILE] GET_UNIORG_LIST_ERROR";

export const CHANGE_GA_SELECTION = "[PROFILE] CHANGE_GA_SELECTION";
export const CHANGE_GA_SELECTION_SUCCESS =
  "[PROFILE] CHANGE_GA_SELECTION_SUCCESS";
export const CHANGE_GA_SELECTION_ERROR = "[PROFILE] CHANGE_GA_SELECTION_ERROR";

export const CHANGE_SUPERVISOR_SELECTION =
  "[PROFILE] CHANGE SUPERVISOR SELECTION";
export const CHANGE_SUPERVISOR_SELECTION_SUCCESS =
  "[PROFILE] CHANGE SUPERVISOR SELECTION SUCCESS";
export const CHANGE_SUPERVISOR_SELECTION_ERROR =
  "[PROFILE] CHANGE SUPERVISOR SELECTION ERROR";

export const GET_ROLES = "[PROFILE] GET_ROLES";
export const GET_ROLES_SUCCESS = "[PROFILE] GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "[PROFILE]  GET_ROLES_ERROR";

export const GET_SUPERVISORS = "[PROFILE] GET_SUPERVISORS";
export const GET_SUPERVISORS_SUCCESS = "[PROFILE] GET_SUPERVISORS_SUCCESS";
export const GET_SUPERVISORS_ERROR = "[PROFILE]  GET_SUPERVISORS_ERROR";

export const CHANGE_PROFILE_PASS = "[PROFILE] CHANGE_PASS";
export const CHANGE_PROFILE_PASS_SUCCESS = "[PROFILE] CHANGE_PASS_SUCCESS";
export const CHANGE_PROFILE_PASS_ERROR = "[PROFILE]  CHANGE_PASS_ERROR";

export const GET_PROFILE = "[PROFILE] GET_PROFILE";
export const GET_PROFILE_SUCCESS = "[PROFILE] GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "[PROFILE]  GET_PROFILE_ERROR";

export const GET_PROFILE_BY_ID = "[PROFILE] GET_PROFILE_BY_ID";
export const GET_PROFILE_BY_ID_SUCCESS = "[PROFILE] GET_PROFILE_BY_ID_SUCCESS";
export const GET_PROFILE_BY_ID_ERROR = "[PROFILE]  GET_PROFILE_BY_ID_ERROR";

export const GET_USER_INFO = "[PROFILE] GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "[PROFILE] GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "[PROFILE]  GET_USER_INFO_ERROR";

export const GET_USER_OPERATION = "[PROFILE] GET_USER_OPERATION";
export const GET_USER_OPERATION_SUCCESS =
  "[PROFILE] GET_USER_OPERATION_SUCCESS";
export const GET_USER_OPERATION_ERROR = "[PROFILE]  GET_USER_OPERATION_ERROR";

export const GET_WATERMARK_USER_INFO = "[PROFILE] GET_WATERMARK_USER_INFO";
export const GET_WATERMARK_USER_INFO_SUCCESS =
  "[PROFILE] GET_WATERMARK_USER_INFO_SUCCESS";
export const GET_WATERMARK_USER_INFO_ERROR =
  "[PROFILE]  GET_WATERMARK_USER_INFO_ERROR";

type GetGAInfo = {
  type: typeof GET_GA_INFO,
  id: Int,
};
type GetGAInfoSuccess = {
  type: typeof GET_GA_INFO_SUCCESS,
  payload: any,
};
type GetGAInfoError = {
  type: typeof GET_GA_INFO_ERROR,
  payload: any,
};

type GetSupervisorInfo = {
  type: typeof GET_SUPERVISOR_INFO,
  id: Int,
};
type GetSupervisorInfoSuccess = {
  type: typeof GET_SUPERVISOR_INFO_SUCCESS,
  payload: any,
};
type GetSupervisorInfoError = {
  type: typeof GET_SUPERVISOR_INFO_ERROR,
  payload: any,
};

type GetUsers = {
  type: typeof GET_USERS,
  id: Int,
};
type GetUsersSuccess = {
  type: typeof GET_USERS_SUCCESS,
  payload: any,
};
type GetUsersError = {
  type: typeof GET_USERS_ERROR,
  payload: any,
};

type RegisterUser = {
  type: typeof REGISTER_USER,
  payload: Object,
};
type RegisterUserSuccess = {
  type: typeof GET_USERS_SUCCESS,
  payload: any,
};
type RegisterUserError = {
  type: typeof GET_USERS_ERROR,
  payload: any,
};

type GetUserDetails = {
  type: typeof GET_USER_DETAILS,
  id: Int,
};
type GetUserDetailsSuccess = {
  type: typeof GET_USER_DETAILS_SUCCESS,
  payload: any,
};
type GetUserDetailsError = {
  type: typeof GET_USER_DETAILS_ERROR,
  payload: any,
};

type UpdateUser = {
  type: typeof UPDATE_USER,
  payload: Object,
};
type UpdateUserSuccess = {
  type: typeof UPDATE_USER_SUCCESS,
  payload: any,
};
type UpdateUserError = {
  type: typeof UPDATE_USER_ERROR,
  payload: any,
};

type DeleteUser = {
  type: typeof DELETE_USER,
  id: Int,
};
type DeleteUserSuccess = {
  type: typeof DELETE_USER_SUCCESS,
  payload: any,
};
type DeleteUserError = {
  type: typeof DELETE_USER_ERROR,
  payload: any,
};

type GetGenders = {
  type: typeof GET_GENDERS,
};
type GetGendersSuccess = {
  type: typeof GET_GENDERS_SUCCESS,
  payload: any,
};
type GetGendersError = {
  type: typeof GET_GENDERS_ERROR,
  payload: any,
};

type GetTypes = {
  type: typeof GET_TYPES,
};
type GetTypesSuccess = {
  type: typeof GET_TYPES_SUCCESS,
  payload: any,
};
type GetTypesError = {
  type: typeof GET_TYPES_ERROR,
  payload: any,
};

type GetUniorgList = {
  type: typeof GET_UNIORG_LIST,
  id: int,
};
type GetUniorgListSuccess = {
  type: typeof GET_UNIORG_LIST_SUCCESS,
  payload: any,
};
type GetUniorgListError = {
  type: typeof GET_UNIORG_LIST_ERROR,
  payload: any,
};

type ChangeGASelection = {
  type: typeof CHANGE_GA_SELECTION,
  payload: object,
};
type ChangeGASelectionSuccess = {
  type: typeof CHANGE_GA_SELECTION_SUCCESS,
  payload: any,
};
type ChangeGASelectionError = {
  type: typeof CHANGE_GA_SELECTION_ERROR,
  payload: any,
};

type ChangeSupervisorSelection = {
  type: typeof CHANGE_SUPERVISOR_SELECTION,
  payload: Object,
};
type ChangeSupervisorSelectionSuccess = {
  type: typeof CHANGE_SUPERVISOR_SELECTION_SUCCESS,
  payload: any,
};
type ChangeSupervisorSelectionError = {
  type: typeof CHANGE_SUPERVISOR_SELECTION_ERROR,
  payload: any,
};

type GetRoles = {
  type: typeof GET_ROLES,
};
type GetRolesSuccess = {
  type: typeof GET_ROLES_SUCCESS,
  payload: any,
};
type GetRolesError = {
  type: typeof GET_ROLES_ERROR,
  payload: any,
};

type GetSupervisors = {
  type: typeof GET_SUPERVISORS,
};
type GetSupervisorsSuccess = {
  type: typeof GET_SUPERVISORS_SUCCESS,
  payload: any,
};
type GetSupervisorsError = {
  type: typeof GET_SUPERVISORS_ERROR,
  payload: any,
};

type ChangeProfilePass = {
  type: typeof CHANGE_PROFILE_PASS,
  payload: object,
};
type ChangeProfilePassSuccess = {
  type: typeof CHANGE_PROFILE_PASS_SUCCESS,
  payload: any,
};
type ChangeProfilePassError = {
  type: typeof CHANGE_PROFILE_PASS_ERROR,
  payload: any,
};

type GetProfile = {
  type: typeof GET_PROFILE,
};
type GetProfileSuccess = {
  type: typeof GET_PROFILE_SUCCESS,
  payload: any,
};
type GetProfileError = {
  type: typeof GET_PROFILE_ERROR,
  payload: any,
};

type GetProfileById = {
  type: typeof GET_PROFILE_BY_ID,
  id: int,
};
type GetProfileByIdSuccess = {
  type: typeof GET_PROFILE_BY_ID_SUCCESS,
  payload: any,
};
type GetProfileByIdError = {
  type: typeof GET_PROFILE_BY_ID_ERROR,
  payload: any,
};

type GetUserInfo = {
  type: typeof GET_USER_INFO,
  id: int,
};
type GetUserInfoSuccess = {
  type: typeof GET_USER_INFO_SUCCESS,
  payload: any,
};
type GetUserInfoError = {
  type: typeof GET_USER_INFO_ERROR,
  payload: any,
};

type GetUserOperation = {
  type: typeof GET_USER_OPERATION,
  id: int,
};
type GetUserOperationSuccess = {
  type: typeof GET_USER_OPERATION_SUCCESS,
  payload: any,
};
type GetUserOperationError = {
  type: typeof GET_USER_OPERATION_ERROR,
  payload: any,
};

type GetWatermarkUserInfo = {
  type: typeof GET_WATERMARK_USER_INFO,
  id: int,
};
type GetWatermarkUserInfoSuccess = {
  type: typeof GET_WATERMARK_USER_INFO_SUCCESS,
  payload: any,
};
type GetWatermarkUserInfoError = {
  type: typeof GET_WATERMARK_USER_INFO_ERROR,
  payload: any,
};

export type ProfileAction =
  | GetGAInfo
  | GetGAInfoSuccess
  | GetGAInfoError
  | GetSupervisorInfo
  | GetSupervisorInfoSuccess
  | GetSupervisorInfoError
  | GetUsers
  | GetUsersSuccess
  | GetUsersError
  | RegisterUser
  | RegisterUserSuccess
  | RegisterUserError
  | GetUserDetails
  | GetUserDetailsSuccess
  | GetUserDetailsError
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserError
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserError
  | GetGenders
  | GetGendersSuccess
  | GetGendersError
  | GetTypes
  | GetTypesSuccess
  | GetTypesError
  | GetUniorgList
  | GetUniorgListSuccess
  | GetUniorgListError
  | ChangeGASelection
  | ChangeGASelectionSuccess
  | ChangeGASelectionError
  | ChangeSupervisorSelection
  | ChangeSupervisorSelectionSuccess
  | ChangeSupervisorSelectionError
  | GetRoles
  | GetRolesSuccess
  | GetRolesError
  | GetSupervisors
  | GetSupervisorsSuccess
  | GetSupervisorsError
  | ChangeProfilePass
  | ChangeProfilePassSuccess
  | ChangeProfilePassError
  | GetProfile
  | GetProfileSuccess
  | GetProfileError
  | GetProfileById
  | GetProfileByIdSuccess
  | GetProfileByIdError
  | GetUserInfo
  | GetUserInfoSuccess
  | GetUserInfoError
  | GetUserOperation
  | GetUserOperationSuccess
  | GetUserOperationError
  | GetWatermarkUserInfo
  | GetWatermarkUserInfoSuccess
  | GetWatermarkUserInfoError;
