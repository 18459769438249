// @flow

import * as fromTypes from "../types/analytics";

type State = {
  loading: boolean,
};

export const initialState = {
  loading: false,
};

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (
  state: State = initialState,
  action: fromTypes.AnalyticsAction
) => {
  switch (action.type) {
    case fromTypes.SEND_LOG:
      return setLoading(state, true);
    // Success
    case fromTypes.SEND_LOG_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.SEND_LOG_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
