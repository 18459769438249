// @flow
//import decode from "jwt-decode";
import axios from "axios";

type Json = "application/json";

export const APPLICATION_JSON: Json = "application/json";

export const logout = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("agencia");
  sessionStorage.removeItem("lvl");
};

export const setToken = async (token: string) =>
  sessionStorage.setItem("token", token);

export const setUser = async (user: string) =>
  sessionStorage.setItem("user", user);

export const setUserLevel = async (lvl: string) =>
  sessionStorage.setItem("lvl", lvl);

export const getToken = (): string => {
  const token = sessionStorage.getItem("token");
  if (token && token === "undefined") {
    sessionStorage.removeItem("token");
    return "";
  }

  return token || "";
};

export const getUser = (): string => {
  const user = sessionStorage.getItem("user");
  if (user && user === "undefined") {
    sessionStorage.removeItem("user");
    return "";
  }

  return user || "teste";
};

export const getUserLvl = (): string => {
  const user = sessionStorage.getItem("lvl");
  if (user && user === "undefined") {
    sessionStorage.removeItem("lvl");
    return "";
  }

  return user || "";
};

export const isAuthenticated = (path = null) => {
  const token = getToken();
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  return !!token;
};
