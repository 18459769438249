import React from "react";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme) => ({
  containerSkeleton: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "16px 40px",
    marginBottom: "70px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 0",
    },
  },
  titleSkeleton: {
    display: "flex",
  },
  titleSkeletonPadding: {
    marginRight: "2em",
  },
  skeletonRect: {
    marginBottom: "5px",
  },
}));

const CheckSkeleton = ({ customHeight }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.containerSkeleton}>
        <Skeleton variant="rect" height={customHeight ? customHeight : 300} />
        <Skeleton variant="rect" height={customHeight ? customHeight : 300} />
      </div>
    </>
  );
};

export default CheckSkeleton;
