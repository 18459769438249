// @flow

export const GET_NPS_TABLE = "[NPS] GET_NPS_TABLE";
export const GET_NPS_TABLE_SUCCESS = "[NPS] GET_NPS_TABLE_SUCCESS";
export const GET_NPS_TABLE_ERROR = "[NPS] GET_NPS_TABLE_ERROR";

export const ADD_NPS_COMMENT = "[NPS] ADD_NPS_COMMENT";
export const ADD_NPS_COMMENT_SUCCESS = "[NPS] ADD_NPS_COMMENT_SUCCESS";
export const ADD_NPS_COMMENT_ERROR = "[NPS] ADD_NPS_COMMENT_ERROR";

export const ADD_NPS_ACTION = "[NPS] ADD_NPS_ACTION";
export const ADD_NPS_ACTION_SUCCESS = "[NPS] ADD_NPS_ACTION_SUCCESS";
export const ADD_NPS_ACTION_ERROR = "[NPS] ADD_NPS_ACTION_ERROR";

export const GET_NPS_DOUGHNUT = "[NPS] GET_NPS_DOUGHNUT";
export const GET_NPS_DOUGHNUT_SUCCESS = "[NPS] GET_NPS_DOUGHNUT_SUCCESS";
export const GET_NPS_DOUGHNUT_ERROR = "[NPS] GET_NPS_DOUGHNUT_ERROR";

export const GET_NPS_COMMENTS = "[NPS] GET_NPS_COMMENTS";
export const GET_NPS_COMMENTS_SUCCESS = "[NPS] GET_NPS_COMMENTS_SUCCESS";
export const GET_NPS_COMMENTS_ERROR = "[NPS] GET_NPS_COMMENTS_ERROR";

export const GET_NPS_LINE = "[NPS] GET_NPS_LINE";
export const GET_NPS_LINE_SUCCESS = "[NPS] GET_NPS_LINE_SUCCESS";
export const GET_NPS_LINE_ERROR = "[NPS] GET_NPS_LINE_ERROR";

type GetNpsTable = {
  type: typeof GET_NPS_TABLE,
  payload: object,
};
type GetNpsTableSuccess = {
  type: typeof GET_NPS_TABLE_SUCCESS,
  payload: Array,
};
type GetNpsTableError = {
  type: typeof GET_NPS_TABLE_ERROR,
  payload: any,
};

type AddNpsComment = {
  type: typeof ADD_NPS_COMMENT,
  payload: object,
};
type AddNpsCommentSuccess = {
  type: typeof ADD_NPS_COMMENT_SUCCESS,
  payload: Array,
};
type AddNpsCommentError = {
  type: typeof ADD_NPS_COMMENT_ERROR,
  payload: any,
};

type AddNpsAction = {
  type: typeof ADD_NPS_ACTION,
  payload: object,
};
type AddNpsActionSuccess = {
  type: typeof ADD_NPS_ACTION_SUCCESS,
  payload: Array,
};
type AddNpsActionError = {
  type: typeof ADD_NPS_ACTION_ERROR,
  payload: any,
};

type GetNpsDoughnut = {
  type: typeof GET_NPS_DOUGHNUT,
  payload: object,
};
type GetNpsDoughnutSuccess = {
  type: typeof GET_NPS_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetNpsDoughnutError = {
  type: typeof GET_NPS_DOUGHNUT_ERROR,
  payload: any,
};

type GetNpsComments = {
  type: typeof GET_NPS_COMMENTS,
  payload: object,
};
type GetNpsCommentsSuccess = {
  type: typeof GET_NPS_COMMENTS_SUCCESS,
  payload: Array,
};
type GetNpsCommentsError = {
  type: typeof GET_NPS_COMMENTS_ERROR,
  payload: any,
};

type GetNPSLineChart = {
  type: typeof GET_NPS_LINE_CHART,
  payload: object,
};
type GetNPSLineChartSuccess = {
  type: typeof GET_NPS_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetNPSLineChartError = {
  type: typeof GET_NPS_LINE_CHART_ERROR,
  payload: any,
};

export type NPSAction =
  | GetNpsTable
  | GetNpsTableSuccess
  | GetNpsTableError
  | AddNpsComment
  | AddNpsCommentSuccess
  | AddNpsCommentError
  | AddNpsAction
  | AddNpsActionSuccess
  | AddNpsActionError
  | GetNpsDoughnut
  | GetNpsDoughnutSuccess
  | GetNpsDoughnutError
  | GetNpsComments
  | GetNpsCommentsSuccess
  | GetNpsCommentsError
  | GetNPSLineChart
  | GetNPSLineChartSuccess
  | GetNPSLineChartError;
