import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/styles";
import { Modal, Box, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  white,
  santander,
  boston,
  lightGrey,
  black2,
  bulletinGrey,
  black,
} from "../theme/colors";

import { getUserInfo } from "../store/actions/profile";
import { sendLog } from "../store/actions/analytics";
import { getCheckImages } from "../store/actions/check";
import Icons from "./Icons";

import IndicatorList from "./IndicatorList";
import interno from "../assets/img/confidencial_restrito.svg";
import { decrypt } from "../store/services/crypto";
import { Tooltip, tooltipClasses } from "@mui/material";
import CheckSkeleton from "./CheckSkeleton";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
  root: {},

  btnContainer: {
    marginTop: "75px",
    display: "flex",
    justifyContent: "space-between",
  },

  returnBtn: {
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    textTransform: "none !important",
    padding: "0.25em 1em !important",
    fontSize: "1em !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  button: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  disabledButton: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${lightGrey} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  checkContainer: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  checkImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "& > span": {
      fontFamily: "SantanderMicroText !important",
      color: black2,
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    "& img": {
      marginRight: "1em",
    },
    marginTop: "10px",
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
}));

const CheckModal = ({
  open,
  onClose,
  data,
  onGetCheckImages,
  images,
  loading,
}) => {
  const classes = useStyles();
  const [showDocText, setShowDocText] = useState(false);
  const [rows, setRows] = useState([]);
  const [imagemFrente, setImagemFrente] = useState("");
  const [imagemVerso, setImagemVerso] = useState("");
  const [displayTooltip, setDisplayTooltip] = useState(false);

  useEffect(() => {
    if (data && open) {
      onGetCheckImages({ idCheque: data.idCheque, uniorg: data.uniorg });
    }
  }, [data]);

  useEffect(() => {
    if (images && open) {
      getDecryptImage(images.img_frente_cripto).then((valor) => {
        setImagemFrente(valor);
      });
      getDecryptImage(images.img_verso_cripto).then((valor) => {
        setImagemVerso(valor);
      });
    }
  }, [images]);

  useEffect(() => {
    if (images && open) {
      const list = [
        {
          id: 1,
          cdInd: images.cd_indicador,
          uniorg: images.cd_uniorg,
          nomeGerente: images.nm_gerente_mascara,
          nmGerenteCripto: images.nm_gerente_cripto,
          cheque: images.nu_cheque,
          valor: images.vl_cheque,
          agencia: images.nu_ag_mascara,
          agCripto: images.nu_ag_cripto,
          conta: images.nu_conta_mascara,
          contaCripto: images.nu_conta_cripto,
          motivo: images.nu_motivo,
          tipo: images.nu_motivo === "+15K" ? "15K" : "ME",
          nomeCliente: images.nm_cliente_mascara,
          nomeClienteCripto: images.nm_cliente_cripto,
          documento: images.nu_doc_mascara,
          docCripto: images.nu_doc_cripto,
          docTool: "",
          nomeTool: "",
          nomeGerenteTool: "",
          agTool: "",
          contaTool: "",
          actionPG: images.bl_atuacao,
          actionDEV: images.bl_atuacao,
          actionX: images.bl_atuacao,
          action: images.bl_atuacao,
          idCheque: images.id_cheque,
          imgStatus: images.bl_img,
        },
      ];

      setRows(list);
    }
  }, [images]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da uniorg</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.uniorg, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "nomeGerente",
      headerName: "Gerente",
      width: 100,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeGerenteTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o nome do gerente</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeGerenteTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nmGerenteCripto);
                showValue(params.id, "nomeGerenteTool", "nmGerenteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeGerenteTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeGerenteTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "cheque",
      headerName: "Cheque",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.cheque, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: "valor",
      headerName: "Valor",
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o valor do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.valor, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },

    {
      field: "motivo",
      headerName: "Motivo",
      width: 100,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o motivo do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.motivo, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 90,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              !displayTooltip ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o tipo do cheque</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>Item copiado!</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                setDisplayTooltip(true);
                copyValue(params.row.tipo, false);
              }}
              onMouseUp={() => {
                setDisplayTooltip(false);
              }}
              onMouseLeave={() => {
                setDisplayTooltip(false);
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "agencia",
      headerName: "Ag",
      width: 100,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.agTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da agência</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.agTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.agCripto);
                showValue(params.id, "agTool", "agCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "agTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "agTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "conta",
      headerName: "Conta",
      width: 100,
      headerClassName: "header",
      sortable: false,
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.contaTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o número da conta</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.contaTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.contaCripto);
                showValue(params.id, "contaTool", "contaCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "contaTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "contaTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },

    {
      field: "nomeCliente",
      headerName: "Cliente",
      width: 100,
      headerClassName: "header",
      sortable: false,

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.nomeTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o nome do cliente</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.nomeTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.nomeClienteCripto);
                showValue(params.id, "nomeTool", "nomeClienteCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "nomeTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "nomeTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "documento",
      headerName: "CPF/CNPJ",
      width: 170,
      headerClassName: "header",
      sortable: false,

      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.docTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o CPF/CNPJ</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.docTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.docCripto);
                showValue(params.id, "docTool", "docCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "docTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "docTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
  ];

  const copyValue = async (value, isCrypt = true) => {
    isCrypt
      ? navigator.clipboard.writeText(await decrypt(value))
      : navigator.clipboard.writeText(value);
  };

  const showValue = async (id, type, cryptoType) => {
    let newDocObj = {};
    newDocObj[type] = await decrypt(rows.find((x) => x.id === id)[cryptoType]);

    setRows((prevRows) =>
      prevRows.map((row) => {
        return row.id === id ? { ...row, ...newDocObj } : row;
      })
    );
  };

  const hideValue = (id, type) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [type]: "" } : row))
    );
  };

  const getDecryptImage = async (image) => {
    const retorno = await decrypt(image);
    return retorno;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: white,
          p: 4,
          padding: "20px 40px 30px 40px",
          minWidth: "1060px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontSize: "24px",
              fontFamily: "SantanderMicroText",
              color: black2,
            }}
          >
            {images.info_cheque}
          </h1>
          <Button
            sx={{
              padding: "0px !important",
              background: "none !important",
              minWidth: "16px !important",
              marginBottom: "10px",
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Icons icon="close-1px" />
          </Button>
        </div>

        <IndicatorList
          rows={rows}
          cols={cols}
          type="cheque"
          hidePagination={true}
        />
        <div
          className={classes.fixedBottom}
          onClick={() => setShowDocText(!!!showDocText)}
        >
          <div>
            <img src={interno} alt="" />
            Documento Confidencial
          </div>
          {showDocText ? (
            <p className={classes.docText}>
              Qualquer dado classificado como regulamentos externos de proceção
              de dados, como dados pessoais, dados de RH, dados não financeiros
              de Clientes, atendimento, interações com Clientes e etc.
            </p>
          ) : null}
        </div>

        <div className={classes.checkContainer}>
          {loading ? (
            <>
              <Loading />
              <CheckSkeleton />
            </>
          ) : (
            <>
              <div className={classes.checkImgContainer}>
                <span>Frente</span>

                {images && open ? (
                  <img
                    src={`data:image/png;base64,${imagemFrente}`}
                    width={800}
                    height={300}
                  />
                ) : null}
              </div>
              <div className={classes.checkImgContainer}>
                <span>Verso</span>

                {images && open ? (
                  <img
                    src={`data:image/png;base64,${imagemVerso}`}
                    width={800}
                    height={300}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onSendLog: (payload) => dispatch(sendLog(payload)),
    onGetCheckImages: (payload) => dispatch(getCheckImages(payload)),
  };
};

const mapStateToProps = (state) => {
  const { userInfo } = state.profile;
  const { images, loading } = state.check;
  return { userInfo, images, loading };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CheckModal
);
