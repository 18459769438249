import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { logout } from "../store/services/auth";
import { makeStyles } from "@material-ui/styles";
import { Modal, Box, Button, Typography } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import {
  white,
  santander,
  boston,
  darkGrey,
  santander20,
  black,
  darkerGrey2,
  mediumGrey,
} from "../theme/colors";
import { Icons } from "./";
import { agreeWithTerm, getUserTerms } from "../store/actions/terms";
import { getUser } from "../store/services/auth";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

import yellowIcon from "../assets/icons/alerta-amarelo.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  timeRemaining: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkerGrey2} !important`,
    marginBottom: "16px !important",
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,
    overflowY: "scroll",
    height: "72%",
    borderRadius: "4px",
    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
    "& a": {
      fontWeight: "bold !important",
      color: santander,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
  closeBtn: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
  },
  agreeContainer: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    justifyContent: "space-between",
  },
  checkbox: {
    fontFamily: "SantanderHeadline !important",
    display: "flex",
    alignItems: "center",
  },
  alert: {
    position: "fixed",
    zIndex: "9999999999",
    width: "500px",
    bottom: "0",
    right: "0",
    fontFamily: "SantanderMicroText",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: mediumGrey,
    fontSize: "14px",
    "& span": {
      fontWeight: "bold",
    },
    "& button": {
      color: santander,
      textDecoration: "underline",
      cursor: "pointer",
      border: "none",
      padding: "0",
      background: "none",
    },
    "& img": {
      width: "22px",
    },
  },
}));

const FunctionModal = ({
  onAgreeWithTerm,
  onGetUserTerms,
  termsToAgree,
  open,
  onClose,
  onOpen,
}) => {
  const classes = useStyles();
  const [currentTerm, setCurrentTerm] = useState({
    id: "",
    nome: "",
    termo: "",
  });
  const [enableCheckbox, setEnableCheckbox] = useState(false);
  const [selected, setSelected] = useState(false);
  const [mostUrgentTerm, setMostUrgentTerm] = useState(0);
  const listInnerRef = useRef();

  useEffect(() => {
    if (termsToAgree.length >= 1) {
      setCurrentTerm({ ...termsToAgree[0] });
      if (termsToAgree[0].termo.length <= 2500) setEnableCheckbox(true);
      setMostUrgentTerm(
        Math.min(...termsToAgree.map((i) => i.prazo_assinatura))
      );
    }
  }, [termsToAgree]);

  const agreeWithCurrentTerm = () => {
    onAgreeWithTerm({ id: currentTerm.id, cd_matricula: getUser() });
    setSelected(false);
    onClose();
    setEnableCheckbox(false);
  };

  const onScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom) {
      setEnableCheckbox(true);
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div className={classes.container}>
      <Modal open={open} onClose={onClose} disableEscapeKeyDown>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "55vw",
            height: "500px",
            bgcolor: white,
            p: 4,
          }}
        >
          <Typography className={classes.title}>{currentTerm.nome}</Typography>
          <Typography className={classes.timeRemaining}>
            {currentTerm.prazo_assinatura <= 0
              ? "Você precisa assinar o termo para prosseguir."
              : `Você tem ${currentTerm.prazo_assinatura} dias para assinar o termo.`}
          </Typography>
          <div className={classes.subtitle} onScroll={onScroll}>
            <Typography
              dangerouslySetInnerHTML={{ __html: currentTerm.termo }}
              ref={listInnerRef}
            ></Typography>
          </div>
          <div className={classes.btnContainer}>
            <Tooltip
              placement="top"
              title={
                currentTerm.prazo_assinatura <= 0
                  ? "Você precisa aceitar este termo. Se cancelar será deslogado."
                  : ""
              }
            >
              <span>
                <Button
                  onClick={() => {
                    if (currentTerm.prazo_assinatura <= 0) {
                      logout();
                      window.location.href = "/";
                    } else {
                      onClose();
                    }
                  }}
                  sx={{
                    height: "48px",
                    fontFamily: "SantanderMicroText ",
                    background: white,
                    textTransform: "none ",
                    color: `${santander} `,
                    border: `1px solid ${santander} `,
                    display: "block",
                    fontSize: "16px ",
                    marginRight: "16px",
                    "&:hover": {
                      background: santander20,
                      color: `${santander} `,
                    },
                    "&:disabled": {
                      opacity: "0.3 !important",
                    },
                  }}
                >
                  Cancelar
                </Button>
              </span>
            </Tooltip>
            <div className={classes.agreeContainer}>
              <div
                className={classes.checkbox}
                style={{ opacity: enableCheckbox ? "1" : "0.5" }}
              >
                <Tooltip
                  placement="top"
                  title={
                    !enableCheckbox
                      ? "Leia todo o termo para poder aceitar."
                      : ""
                  }
                >
                  <span>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                      onChange={() => setSelected(!!!selected)}
                      disabled={!enableCheckbox}
                    />
                  </span>
                </Tooltip>
                Declaro que li e concordo com as informações acima.
              </div>
              <Button
                onClick={agreeWithCurrentTerm}
                variant="contained"
                disabled={!selected}
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Aceitar
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {!open && termsToAgree.length > 0 ? (
        <div className={classes.alert}>
          <img src={yellowIcon} alt="" />
          Você tem <span> {mostUrgentTerm} dias </span> para assinar o termo [
          <button onClick={onOpen}>clique aqui para assinar</button>]
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAgreeWithTerm: (payload) => dispatch(agreeWithTerm(payload)),
    onGetUserTerms: (id) => dispatch(getUserTerms(id)),
  };
};

const mapStateToProps = (state) => {
  const { termsToAgree, loading } = state.terms;
  return {
    loading,
    termsToAgree,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FunctionModal
);
