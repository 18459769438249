import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Typography, Button, Box } from "@mui/material";

import {
  santander,
  white,
  santander20,
  boston,
  darkGrey,
  black,
  inputSky,
} from "../theme/colors";
import { RegisterDateField, SelectField } from "./";
import { alertToast } from "./Toast";
import { Checkbox } from "@mui/material";
import { required } from "../utils/validators";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
    marginBottom: "8px",
  },
  subtitle: {
    marginTop: "16px",
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    marginTop: "16px",
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,
  },
  smallSubtitle: {
    marginTop: "32px !important",
    fontFamily: "SantanderMicrotext !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  inputContainer: {
    padding: "32px 32px 0px 0px",
  },
  searchInput: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

const ChangeUniorgLinkModal = ({ details, open, onClose, onChangeMethod }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [dates, setDates] = useState(["", ""]);
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [operation, setOperation] = useState("");

  const close = () => {
    setChecked(false);
    onClose();
  };

  const changeDates = (dateRange) => {
    setDates(dateRange);
  };

  const selectUniorg = () => {
    if (checked && dates === ["", ""]) {
      alertToast("warn", "Escolha as datas.");
    } else {
      if (details.modalType === "add-agency" && operation === "") {
        alertToast("error", "Selecione uma operação");
      } else {
        onChangeMethod(
          details.uniorg,
          checked,
          dates,
          details.modalType,
          operation
        );
        setChecked(false);
      }
    }
  };

  const getModalContent = () => {
    switch (details.modalType) {
      case "add-temporary":
        return (
          <>
            <Typography className={classes.title}>
              Alterar vínculo de Agência
            </Typography>
            <Typography className={classes.subtitle}>
              A agência{" "}
              <span>
                {details.uniorg} - {details.uniorgName}
              </span>{" "}
              está associada ao usuário{" "}
              <span>
                {details.nm_ga_principal !== 0
                  ? details.cd_ga_principal
                  : details.userId}{" "}
                -{" "}
                {details.nm_ga_principal !== 0
                  ? details.nm_ga_principal
                  : details.userName}
              </span>
              .
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              Deseja trocar de usuário e associar{" "}
              <span>
                {details.newUserId} - {details.newUserName}
              </span>{" "}
              a esta Agência?
            </Typography>
            <div className={classes.input}>
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!!!checked)}
                sx={{ paddingLeft: "0px" }}
              />
              <label htmlFor="temporaryCheck" className={classes.label}>
                Vínculo temporário?
              </label>
            </div>
            {checked ? (
              <RegisterDateField onChangeMethod={changeDates} />
            ) : null}
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Alterar
              </Button>
            </div>
            {details.nm_ga_interino !== 0 && details.nm_ga_principal !== 0 ? (
              <div>
                <Typography className={classes.smallSubtitle}>
                  Temporariamente, de{" "}
                  <span>
                    {details.dt_inc_int} a {details.dt_fnl_int}
                  </span>{" "}
                  a agência estará vinculada ao usuário{" "}
                  <span>
                    {details.cd_ga_interino} - {details.nm_ga_interino}
                  </span>
                  .
                  <br />
                  Essa alteração cancela o vínculo temporário anterior.
                </Typography>
              </div>
            ) : null}
          </>
        );
      case "add-common":
        return (
          <>
            <Typography className={classes.title}>
              Vínculo com a Agência
            </Typography>
            <Typography className={classes.subtitle}>
              Deseja vincular a agência{" "}
              <span>
                {details.uniorg} - {details.uniorgName}
              </span>{" "}
              ao usuário{" "}
              <span>
                {details.newUserId} - {details.newUserName}
              </span>
              ?
            </Typography>
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Alterar
              </Button>
            </div>
          </>
        );
      case "add-common-ga":
        return (
          <>
            <Typography className={classes.title}>
              Vínculo com o usuário
            </Typography>
            <Typography className={classes.subtitle}>
              Deseja vincular o usuário{" "}
              <span>
                {details.id} - {details.userName}
              </span>{" "}
              ao usuário{" "}
              <span>
                {details.newUserId} - {details.newUserName}
              </span>
              ?
            </Typography>
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Alterar
              </Button>
            </div>
          </>
        );
      case "remove":
        return (
          <>
            <Typography className={classes.title}>
              Remover vínculo com a Agência
            </Typography>
            {details.nm_ga_interino !== 0 && details.nm_ga_principal !== 0 ? (
              <>
                <Typography className={classes.subtitle}>
                  A agência{" "}
                  <span>
                    {details.uniorg} - {details.uniorgName}
                  </span>{" "}
                  está associada ao usuário{" "}
                  <span>
                    {details.cd_ga_principal} - {details.nm_ga_principal}
                  </span>
                  .
                </Typography>
                <br />
                <Typography className={classes.subtitle}>
                  Temporariamente, de{" "}
                  <span>
                    {details.dt_inc_int} a {details.dt_fnl_int}
                  </span>{" "}
                  a agência estará vinculada ao usuário{" "}
                  <span>
                    {details.cd_ga_interino} - {details.nm_ga_interino}
                  </span>
                  .
                </Typography>
                <br />
                <Typography className={classes.subtitle}>
                  Deseja remover o vínculo temporário?
                </Typography>
              </>
            ) : (
              <Typography className={classes.subtitle}>
                Deseja remover o vínculo do usuário{" "}
                <span>
                  <br />
                  {details.newUserId} - {details.newUserName}
                </span>
                <br /> com a agência{" "}
                <span>
                  <br />
                  {details.uniorg} - {details.uniorgName}
                </span>
                ?
              </Typography>
            )}
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Remover
              </Button>
            </div>
          </>
        );
      case "remove-ga":
        return (
          <>
            <Typography className={classes.title}>
              Remover vínculo com o usuário
            </Typography>
            <Typography className={classes.subtitle}>
              Deseja remover o vínculo do usuário{" "}
              <span>
                {details.newUserId} - {details.newUserName}
              </span>{" "}
              com o usuário{" "}
              <span>
                {details.id} - {details.userName}
              </span>
              ?
            </Typography>
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Alterar
              </Button>
            </div>
          </>
        );
      case "add-agency":
        return (
          <form>
            <Typography className={classes.title}>
              Cadastro de agência
            </Typography>
            <Typography className={classes.subtitle}>
              Deseja cadastar a agência{" "}
              <span>
                {details.uniorg} - {details.uniorgName}
              </span>{" "}
              como Agência de Gestão Remota? <br />
              <br />
            </Typography>
            <>
              <SelectField
                type="text"
                name="cd_operacao"
                icon=""
                placeholder="Operação*"
                validate={[required]}
                dataList={details?.operacoes}
                meta={{ touched: false, error: false }}
                onGetInputValueMethod={(value) => {
                  setOperation(value);
                }}
                fieldWidth={450}
              />
            </>
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Confirmar
              </Button>
            </div>
          </form>
        );

      case "remove-agency":
        return (
          <>
            <Typography className={classes.title}>Remover agência</Typography>
            <Typography className={classes.subtitle}>
              Deseja remover a agência{" "}
              <span>
                {details.uniorg} - {details.uniorgName}
              </span>{" "}
              ?
            </Typography>
            <div className={classes.btnContainer}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  height: "48px",
                  fontFamily: "SantanderMicroText ",
                  background: white,
                  textTransform: "none ",
                  color: `${santander} `,
                  border: `1px solid ${santander} `,
                  display: "block",
                  fontSize: "16px ",
                  "&:hover": {
                    background: santander20,
                    color: `${santander} `,
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={selectUniorg}
                variant="contained"
                sx={{
                  height: "48px",
                  background: boston,
                  color: white,
                  fontFamily: "SantanderMicroText",
                  textTransform: "none",
                  textDecoration: "none",
                  "&:hover": {
                    background: boston,
                    color: white,
                  },
                }}
              >
                Confirmar
              </Button>
            </div>
          </>
        );
      default:
        break;
    }
  };

  const getPlaceholderClass = () => {
    if (searchText === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (searchText === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (searchText !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -60%)",
          width: "450px",
          bgcolor: white,
          p: 4,
        }}
      >
        {getModalContent()}
      </Box>
    </Modal>
  );
};

export default ChangeUniorgLinkModal;
