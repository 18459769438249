// @flow

import * as fromTypes from "../types/queues";

type State = {
  loading: boolean,
  doughnut: object,
  heatmap: array,
  table: array,
  queuesComments: array,
};

export const initialState = {
  loading: false,
  table: [],
  doughnut: {},
  heatmap: [],
  queuesComments: [],
};

const setLoading = (state, loading) => ({ ...state, loading });

const setTable = (state, { payload }) => ({
  ...state,
  table: payload,
  loading: false,
});

const setDoughnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  queuesComments: payload,
  loading: false,
});

const setHeatmap = (state, { payload }) => ({
  ...state,
  heatmap: payload,
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.QueuesAction
) => {
  switch (action.type) {
    case fromTypes.GET_QUEUES_TABLE:
    case fromTypes.ADD_QUEUES_COMMENT:
    case fromTypes.ADD_QUEUES_ACTION:
    case fromTypes.GET_QUEUES_DOUGHNUT:
    case fromTypes.GET_QUEUES_COMMENTS:
    case fromTypes.GET_QUEUES_HEATMAP:
      return setLoading(state, true);
    // success
    case fromTypes.ADD_QUEUES_COMMENT:
    case fromTypes.ADD_QUEUES_ACTION:
      return setLoading(state, true);
    case fromTypes.GET_QUEUES_TABLE_SUCCESS:
      return setTable(state, action);
    case fromTypes.GET_QUEUES_DOUGHNUT_SUCCESS:
      return setDoughnut(state, action);
    case fromTypes.GET_QUEUES_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_QUEUES_HEATMAP_SUCCESS:
      return setHeatmap(state, action);
    // error
    case fromTypes.GET_QUEUES_TABLE_ERROR:
    case fromTypes.ADD_QUEUES_COMMENT_ERROR:
    case fromTypes.ADD_QUEUES_ACTION_ERROR:
    case fromTypes.GET_QUEUES_DOUGHNUT_ERROR:
    case fromTypes.GET_QUEUES_COMMENTS_ERROR:
    case fromTypes.GET_QUEUES_HEATMAP_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
