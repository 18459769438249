import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@mui/material/Button";
import Icons from "./Icons";
import { santander, white } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    left: "93%",
    bottom: "65px",
    height: "20px",
    zIndex: "1",
  },
  button: {
    backgroundColor: `${santander} !important`,
    borderRadius: "50% !important",
    width: "30px",
    height: "30px",
    minWidth: "30px !important",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
}));

const BackToTopButton = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className={classes.root}>
      <Button
        onClick={scrollToTop}
        className={classes.button}
        style={{
          display: visible ? "flex" : "none",
        }}
      >
        <Icons icon="arrow-up" customColor={white} />
      </Button>
    </div>
  );
};

export default BackToTopButton;
