// @flow

import * as fromTypes from "../types/indicator";

export const getIndicatorInfo = (payload: any): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_INDICATOR_INFO,
  payload,
});
export const getIndicatorInfoSuccess = (
  payload: any
): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_INDICATOR_INFO_SUCCESS,
  payload,
});
export const getIndicatorInfoError = (
  payload: any
): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_INDICATOR_INFO_ERROR,
  payload,
});

export const getComments = (payload: object): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_COMMENTS_LIST,
  payload,
});
export const getCommentsSuccess = (
  payload: any
): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_COMMENTS_LIST_SUCCESS,
  payload,
});
export const getCommentsError = (payload: any): fromTypes.IndicatorAction => ({
  type: fromTypes.GET_COMMENTS_LIST_ERROR,
  payload,
});

export const registerComment = (
  payload: object
): fromTypes.IndicatorAction => ({
  type: fromTypes.REGISTER_COMMENT,
  payload,
});
export const registerCommentSuccess = (
  payload: any
): fromTypes.IndicatorAction => ({
  type: fromTypes.REGISTER_COMMENT_SUCCESS,
  payload,
});
export const registerCommentError = (
  payload: any
): fromTypes.IndicatorAction => ({
  type: fromTypes.REGISTER_COMMENT_ERROR,
  payload,
});
