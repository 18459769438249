import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { RegisterNewPassword } from "../store/actions/auth";
import { TextField, Loading, Icons, TelemetriaLogo } from "../components";
import { required } from "../utils/validators";
import { withRouter } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import {
  black,
  santander,
  white,
  mediumGrey,
  green,
  boston,
} from "../theme/colors";
import { newPassValidators } from "../utils/validators";
import { alertToast } from "../components/Toast";

// TO DO: Transformar imagens SVG em componentes
import logoSantanderWhite from "../assets/img/logo-santander-white.svg";
import logoTelemetria from "../assets/img/logo-telemetria.svg";
import background from "../assets/img/default_BG.png";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    padding: "0",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${background}) no-repeat 0px -300px`,
    backgroundSize: "160vw",
  },
  nav: {
    margin: "0",
    backgroundImage: "linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
    textAlign: "center",
  },
  logoSantanderWhite: {
    margin: "2.313em",
    height: "2.313em",
  },
  title: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${black} !important`,
    paddingLeft: "0 !important",
  },
  login: {
    marginTop: "48px",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    ["@media (max-height: 645px)"]: {
      display: "block",
      position: "relative",
      margin: "auto",
      transform: "none",
      top: "auto",
      left: "auto",
      paddingBottom: "2em",
    },
  },
  whiteFloater: {
    backgroundColor: "white",
    textAlign: "center",
    margin: "0 auto",
    padding: "2em",
    [theme.breakpoints.up("md")]: {
      boxSizing: "border-box",
      width: "684px",
      padding: "60px",
    },
  },
  logoTelemetriaContainer: {
    width: "280px",
    display: "block",
    margin: "auto",
    marginBottom: "3.188em",
  },
  containerForm: {
    display: "flex",
    width: "100%",
  },
  rules: {
    flexBasis: "45%",
    padding: "1em",
    "& li": {
      fontFamily: "SantanderHeadline",
      color: mediumGrey,
      textAlign: "left",
    },
  },
  rulesTitle: {
    fontFamily: "SantanderHeadline !important",
    color: mediumGrey,
    fontWeight: "bold !important",
    textAlign: "left",
    fontSize: "1.1em !important",
  },
  form: {
    flexBasis: "55%",
    padding: "24px 16px",
  },
  idField: {
    marginBottom: "1.1em",
  },
  passField: {
    marginBottom: "2.250em",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  forgotPass: {
    fontFamily: "SantanderMicroText !important",
    textTransform: "none !important",
    color: `${santander} !important`,
    paddingLeft: "0 !important",
  },
  returnBtn: {
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    textTransform: "none !important",
    padding: "0.25em 1em !important",
    fontSize: "1em !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  submitBtn: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  list: {
    listStyleType: "none",
    padding: "0px",
    "& li": {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const Home = ({ loading, handleSubmit, onRegisterNewPassword }) => {
  const classes = useStyles();
  const [id, setId] = useState("");
  const [code, setCode] = useState("");
  const [validations, setValidations] = useState({
    validLength: false,
    upperCase: false,
    lowerCase: false,
    numbers: false,
    specialCharacters: false,
  });
  const {
    isValidLength,
    hasUpperCase,
    hasLowerCase,
    hasNumbers,
    hasSpecialCharacters,
  } = newPassValidators;

  useEffect(() => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    setId(params.get("id"));
    setCode(encodeURIComponent(params.get("code")));
  }, []);

  const onSubmit = ({ NewPass, NewPassConfirm }) => {
    if (NewPass === NewPassConfirm) {
      onRegisterNewPassword({
        Username: id,
        Code: code,
        Password: NewPass,
        ConfirmPassword: NewPassConfirm,
      });
    } else {
      alertToast("error", "Senhas não correspondem!");
    }
  };

  const checkNewPass = (newPass) => {
    setValidations({
      validLength: isValidLength(newPass),
      upperCase: hasUpperCase(newPass),
      lowerCase: hasLowerCase(newPass),
      numbers: hasNumbers(newPass),
      specialCharacters: hasSpecialCharacters(newPass),
    });
  };

  const getCheckmark = (validator) =>
    validator ? (
      <Icons customColor={green} icon="checkmark" />
    ) : (
      <div style={{ padding: "0px 8px" }}>•</div>
    );

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <header>
        <nav className={classes.nav}>
          <img
            src={logoSantanderWhite}
            className={classes.logoSantanderWhite}
            alt="Santander"
          />
        </nav>
      </header>
      <section className={classes.login}>
        <div className={classes.whiteFloater}>
          <div className={classes.logoTelemetriaContainer}>
            <TelemetriaLogo />
          </div>
          <div className={classes.containerForm}>
            <div className={classes.rules}>
              <Typography className={classes.rulesTitle}>
                Defina uma senha para acesso ao Portal de Telemetria REDE, a
                partir dos seguintes requisitos:
              </Typography>
              <ul className={classes.list}>
                <li>
                  {getCheckmark(validations.validLength)}
                  <span
                    style={
                      validations.validLength ? { fontWeight: "bold" } : null
                    }
                  >
                    Entre 8 e 14 caracteres
                  </span>
                </li>
                <li>
                  {getCheckmark(validations.upperCase)}
                  <span
                    style={
                      validations.upperCase ? { fontWeight: "bold" } : null
                    }
                  >
                    Letras maiúsculas
                  </span>
                </li>
                <li>
                  {getCheckmark(validations.lowerCase)}
                  <span
                    style={
                      validations.lowerCase ? { fontWeight: "bold" } : null
                    }
                  >
                    Letras minúsculas
                  </span>
                </li>
                <li>
                  {getCheckmark(validations.numbers)}
                  <span
                    style={validations.numbers ? { fontWeight: "bold" } : null}
                  >
                    Números
                  </span>
                </li>
                <li>
                  {getCheckmark(validations.specialCharacters)}
                  <span
                    style={
                      validations.specialCharacters
                        ? { fontWeight: "bold" }
                        : null
                    }
                  >
                    Caracteres especiais
                  </span>
                </li>
              </ul>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <div className={classes.idField}>
                <Field
                  type="password"
                  name="NewPass"
                  icon="security-close"
                  placeholder="Defina uma senha"
                  parentValueCheck={checkNewPass}
                  component={TextField}
                  validate={[required]}
                />
              </div>
              <div className={classes.idField}>
                <Field
                  name="NewPassConfirm"
                  type="password"
                  icon="security-close"
                  placeholder="Confirme a senha escolhida"
                  validate={[required]}
                  component={TextField}
                />
              </div>

              <div className={classes.btnContainer}>
                <Button
                  type="submit"
                  className={classes.submitBtn}
                  disabled={loading}
                >
                  Confirmar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterNewPassword: (payload) => dispatch(RegisterNewPassword(payload)),
  };
};

const mapStateToProps = (state) => {
  const { loading } = state.auth;
  return { loading };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: "changePassForm",
      enableReinitialize: true,
    })
  )(Home)
);
