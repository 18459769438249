// @flow

import * as fromTypes from "../types/terms";

type State = {
  loading: boolean,
  termsToAgree: Array,
};

export const initialState = {
  loading: false,
  termsToAgree: [],
};

const setLoading = (state, loading) => ({ ...state, loading });

const updateTerms = (state, { payload }) => ({
  ...state,
  termsToAgree: payload,
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.AlertAction
) => {
  switch (action.type) {
    case fromTypes.GET_USER_TERMS:
    case fromTypes.AGREE_WITH_TERM:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_USER_TERMS_SUCCESS:
      return updateTerms(state, action);
    case fromTypes.AGREE_WITH_TERM_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.GET_USER_TERMS_ERROR:
    case fromTypes.AGREE_WITH_TERM_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
