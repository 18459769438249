import axios from "axios";
import { getToken, logout, getUser } from "../services/auth";
import { getBaseAPI } from "../services/api";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export const decrypt = (valor) => {
  return axios
    .post(`${BASE_API}/Cripto/decrypt`, {
      valor,
      headers: headers,
    })
    .then((response) => {
      return response.data;
    });
};
