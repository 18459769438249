// @flow
import * as fromTypes from "../types/profile";
import { getOperation } from "../services/profile";

type State = {
  loading: boolean,
  profileInfo: object,
  userList: array,
  userRegisterDetails: object,
  uniorgList: array,
  userInfo: object,
  userFormDetails: object,
  formOptions: object,
  profileDetails: int,
  operation: string,
  watermarkUserInfo: object,
};

export const initialState = {
  loading: false,
  profileInfo: {},
  userList: [],
  userRegisterDetails: {},
  uniorgList: [],
  userInfo: {
    info: {},
    uniorgs: [],
    users: [],
  },
  userFormDetails: {},
  formOptions: {
    genders: [],
    types: [],
    roles: [],
    supervisors: [],
  },
  profileDetails: 0,
  operation: getOperation(),
  watermarkUserInfo: {
    info: {},
    uniorgs: [],
    users: [],
  },
};

const setLoading = (state, loading) => ({ ...state, loading });

const setGAInfo = (state, { payload }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    uniorgs: payload,
  },
  loading: false,
});

const setSupervisorInfo = (state, { payload }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    users: payload,
  },
  loading: false,
});

const setUsers = (state, { payload }) => ({
  ...state,
  userList: payload,
  loading: false,
});

const setUserForm = (state, { payload }) => ({
  ...state,
  userFormDetails: payload,
  loading: false,
});

const setFormGenders = (state, { payload }) => ({
  ...state,
  formOptions: {
    ...state.formOptions,
    genders: payload,
  },
  loading: false,
});

const setFormTypes = (state, { payload }) => ({
  ...state,
  formOptions: {
    ...state.formOptions,
    types: payload,
  },
  loading: false,
});

const setRoles = (state, { payload }) => ({
  ...state,
  formOptions: {
    ...state.formOptions,
    roles: payload,
  },
  loading: false,
});

const setSupervisors = (state, { payload }) => ({
  ...state,
  formOptions: {
    ...state.formOptions,
    supervisors: payload,
  },
  loading: false,
});

const setUniorgList = (state, { payload }) => ({
  ...state,
  uniorgList: payload,
  loading: false,
});

const setProfileInfo = (state, { payload }) => ({
  ...state,
  profileInfo: payload,
  loading: false,
});

const setProfileDetails = (state, { payload }) => ({
  ...state,
  profileDetails: payload,
  loading: false,
});

const setUserInfo = (state, { payload }) => ({
  ...state,
  userInfo: {
    ...state.userInfo,
    info: payload,
  },
  loading: false,
});

const setOperation = (state, { payload }) => ({
  ...state,
  operation: payload,
  loading: false,
});

const setWatermarkUserInfo = (state, { payload }) => ({
  ...state,
  watermarkUserInfo: {
    ...state.watermarkUserInfo,
    info: payload,
  },
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.ProfileAction
) => {
  switch (action.type) {
    case fromTypes.CHANGE_SUPERVISOR_SELECTION:
    case fromTypes.CHANGE_GA_SELECTION:
    case fromTypes.GET_GA_INFO:
    case fromTypes.GET_SUPERVISOR_INFO:
    case fromTypes.GET_USERS:
    case fromTypes.REGISTER_USER:
    case fromTypes.GET_USER_DETAILS:
    case fromTypes.UPDATE_USER:
    case fromTypes.DELETE_USER:
    case fromTypes.GET_GENDERS:
    case fromTypes.GET_TYPES:
    case fromTypes.GET_UNIORG_LIST:
    case fromTypes.GET_ROLES:
    case fromTypes.GET_SUPERVISORS:
    case fromTypes.CHANGE_PROFILE_PASS:
    case fromTypes.GET_PROFILE:
    case fromTypes.GET_PROFILE_BY_ID:
    case fromTypes.GET_USER_INFO:
    case fromTypes.GET_USER_OPERATION:
    case fromTypes.GET_WATERMARK_USER_INFO:
      return setLoading(state, true);
    case fromTypes.CHANGE_SUPERVISOR_SELECTION_ERROR:
    case fromTypes.CHANGE_SUPERVISOR_SELECTION_SUCCESS:
    case fromTypes.GET_GA_INFO_ERROR:
    case fromTypes.GET_SUPERVISOR_INFO_ERROR:
    case fromTypes.GET_USERS_ERROR:
    case fromTypes.REGISTER_USER_SUCCESS:
    case fromTypes.REGISTER_USER_ERROR:
    case fromTypes.GET_USER_DETAILS_ERROR:
    case fromTypes.UPDATE_USER_SUCCESS:
    case fromTypes.UPDATE_USER_ERROR:
    case fromTypes.DELETE_USER_SUCCESS:
    case fromTypes.DELETE_USER_ERROR:
    case fromTypes.GET_GENDERS_ERROR:
    case fromTypes.GET_TYPES_ERROR:
    case fromTypes.GET_UNIORG_LIST_ERROR:
    case fromTypes.CHANGE_GA_SELECTION_SUCCESS:
    case fromTypes.CHANGE_GA_SELECTION_ERROR:
    case fromTypes.GET_ROLES_ERROR:
    case fromTypes.GET_SUPERVISORS_ERROR:
    case fromTypes.CHANGE_PROFILE_PASS_SUCCESS:
    case fromTypes.CHANGE_PROFILE_PASS_ERROR:
    case fromTypes.GET_PROFILE_ERROR:
    case fromTypes.GET_PROFILE_BY_ID_ERROR:
    case fromTypes.GET_USER_INFO_ERROR:
    case fromTypes.GET_USER_OPERATION_ERROR:
    case fromTypes.GET_WATERMARK_USER_INFO_ERROR:
      return setLoading(state, false);
    case fromTypes.GET_GA_INFO_SUCCESS:
      return setGAInfo(state, action);
    case fromTypes.GET_SUPERVISOR_INFO_SUCCESS:
      return setSupervisorInfo(state, action);
    case fromTypes.GET_USERS_SUCCESS:
      return setUsers(state, action);
    case fromTypes.GET_USER_DETAILS_SUCCESS:
      return setUserForm(state, action);
    case fromTypes.GET_GENDERS_SUCCESS:
      return setFormGenders(state, action);
    case fromTypes.GET_TYPES_SUCCESS:
      return setFormTypes(state, action);
    case fromTypes.GET_UNIORG_LIST_SUCCESS:
      return setUniorgList(state, action);
    case fromTypes.GET_ROLES_SUCCESS:
      return setRoles(state, action);
    case fromTypes.GET_SUPERVISORS_SUCCESS:
      return setSupervisors(state, action);
    case fromTypes.GET_PROFILE_SUCCESS:
      return setProfileInfo(state, action);
    case fromTypes.GET_PROFILE_BY_ID_SUCCESS:
      return setProfileDetails(state, action);
    case fromTypes.GET_USER_INFO_SUCCESS:
      return setUserInfo(state, action);
    case fromTypes.GET_USER_OPERATION_SUCCESS:
      return setOperation(state, action);
    case fromTypes.GET_WATERMARK_USER_INFO_SUCCESS:
      return setWatermarkUserInfo(state, action);
    default:
      return state;
  }
};

export default reducer;
