import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { black, darkGrey, white, inputSky } from "../theme/colors";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    left: "3%",
    top: "25%",
    background: white,
    color: darkGrey,
  },
  inputContainer: {
    opacity: "0",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "450px",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focus-visible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
  },
  regionaisMenu: {
    position: "absolute",
    left: "65%",
    width: "290px",
    height: "230px",
    overflowX: "auto",
    background: white,
    "& li": {
      listStyleType: "none",
    },
  },
  agenciasMenu: {
    position: "absolute",
    left: "125%",
    width: "290px",
    height: "230px",
    overflowX: "auto",
    background: white,
    "& li": {
      listStyleType: "none",
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-25%",
      fontSize: "0.9em",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "3%",
      top: "25%",
    },
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DateField = ({ input, meta: { touched, error }, filter }) => {
  const classes = useStyles();
  const [redes, setRedes] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [agencias, setAgencias] = useState([]);

  const GetRegionais = (cd_Rede) => {
    const regionaisList = filter.regionais.filter((i) => {
      return i.cd_Rede === cd_Rede;
    });
    setRegionais(regionaisList);
  };

  const GetAgencias = (cd_Regional) => {
    const agenciasList = filter.agencias.filter((i) => {
      return i.cd_Regional === cd_Regional;
    });
    setAgencias(agenciasList);
  };

  useEffect(() => {
    setRedes(filter.redes);
  }, [filter]);

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        className={classes.mainPopper}
        style={{ width: "290px" }}
        placement="bottom-start"
      />
    );
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        id="checkboxes-tags-demo"
        options={redes}
        disableCloseOnSelect
        getOptionLabel={(option) => option.ds_Rede}
        PopperComponent={CustomPopper}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div onClick={() => GetRegionais(option.cd_Rede)}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              REDE - {option.ds_Rede}
            </div>
          </li>
        )}
        renderInput={(params) => (
          <div className={classes.inputContainer} ref={params.InputProps.ref}>
            <input
              {...input}
              {...params.inputProps}
              error={touched && !!error}
              className={`${classes.input} dss-form-field__input`}
              type="text"
              disabled
            />
            <span className={`dss-form-field__label`}>
              Selecione uma localidade
            </span>
            <Icons icon="search" isInputIcon />
          </div>
        )}
      />
      {regionais.length >= 1 ? (
        <div className={classes.regionaisMenu}>
          {regionais.map((i, index) => {
            return (
              <li key={index} onClick={() => GetAgencias(i.cd_Regional)}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                />
                {i.ds_Regional}
              </li>
            );
          })}
        </div>
      ) : null}
      {agencias.length >= 1 ? (
        <div className={classes.agenciasMenu}>
          {agencias.map((i, index) => {
            return (
              <li key={index}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                />
                {i.nm_Agencia}
              </li>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default DateField;
