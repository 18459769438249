import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/storeAvailability";
import * as fromActions from "../actions/storeAvailability";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser } from "../services/auth";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";
import * as fromAlertsActions from "../actions/storeAvailability";
import { setLastSearchedUser } from "../services/search";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getStores({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(
      `${BASE_API}/Loja/lojaDisponibilidades/`,
      {
        ga,
        rede,
        regional,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getStoreAvailabilitySuccess(response.data.data));
    }
  } catch (err) {
    console.log(err);
    if (err.response.data.errors) {
      yield put(fromActions.getStoreAvailabilitySuccess(["forbidden"]));
    } else {
      yield put(fromActions.getStoreAvailabilityError(err));
      alertToast("error", "Não foi possível recuperar a lista de lojas.");
      if (err.response.status === 401) {
        logout();
        window.location.href = "/";
      }
    }
  }
}

export function* getStoreComments({ payload }) {
  const { ind, uniorg, info_aux } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Loja/lojaComentarios/`, {
      headers: headers,
      ind,
      uniorg,
      info_aux,
    });
    if (response.status === 200) {
      yield put(fromActions.getStoreCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getStoreCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* sendComment({ payload }) {
  const { ind, uniorg, aux, com, atuacao } = payload;

  try {
    const response = yield axios.post(`${BASE_API}/Loja/lojaComentarios/add`, {
      headers: headers,
      ind,
      uniorg,
      aux,
      mtr: parseInt(getUser()),
      com,
      atuacao,
    });
    if (response.status === 200) {
      alertToast("success", "Comentário enviado com sucesso.");
      yield put(fromActions.sendStoreCommentsSuccess(response.data.data));
      yield put(
        fromActions.getStoreComments({ ind, uniorg: uniorg, info_aux: aux })
      );
      yield put(fromActions.getStoreAvailability(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível enviar o comentário");
    yield put(fromActions.sendStoreCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getStoresDoungnutChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Loja/graficoRosca`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getStoreDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getStoreDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getStoresCalendarChart({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Loja/graficoCalendario`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getStoreCalendarChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getStoreCalendarChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchStore() {
  yield takeLatest(fromTypes.GET_STORE_AVAILABILITY, getStores);
  yield takeLatest(fromTypes.GET_STORE_COMMENTS, getStoreComments);
  yield takeLatest(fromTypes.SEND_STORE_COMMENTS, sendComment);
  yield takeLatest(fromTypes.GET_STORE_DOUNGNUT_CHART, getStoresDoungnutChart);
  yield takeLatest(fromTypes.GET_STORE_CALENDAR_CHART, getStoresCalendarChart);
}
