// @flow

export const GET_CHECK_TABLE = "[CHECK] GET_CHECK_TABLE";
export const GET_CHECK_TABLE_SUCCESS = "[CHECK] GET_CHECK_TABLE_SUCCESS";
export const GET_CHECK_TABLE_ERROR = "[CHECK] GET_CHECK_TABLE_ERROR";

export const ADD_CHECK_COMMENT = "[CHECK] ADD_CHECK_COMMENT";
export const ADD_CHECK_COMMENT_SUCCESS = "[CHECK] ADD_CHECK_COMMENT_SUCCESS";
export const ADD_CHECK_COMMENT_ERROR = "[CHECK] ADD_CHECK_COMMENT_ERROR";

export const ADD_CHECK_ACTION = "[CHECK] ADD_CHECK_ACTION";
export const ADD_CHECK_ACTION_SUCCESS = "[CHECK] ADD_CHECK_ACTION_SUCCESS";
export const ADD_CHECK_ACTION_ERROR = "[CHECK] ADD_CHECK_ACTION_ERROR";

export const GET_CHECK_DOUGHNUT = "[CHECK] GET_CHECK_DOUGHNUT";
export const GET_CHECK_DOUGHNUT_SUCCESS = "[CHECK] GET_CHECK_DOUGHNUT_SUCCESS";
export const GET_CHECK_DOUGHNUT_ERROR = "[CHECK] GET_CHECK_DOUGHNUT_ERROR";

export const GET_CHECK_COMMENTS = "[CHECK] GET_CHECK_COMMENTS";
export const GET_CHECK_COMMENTS_SUCCESS = "[CHECK] GET_CHECK_COMMENTS_SUCCESS";
export const GET_CHECK_COMMENTS_ERROR = "[CHECK] GET_CHECK_COMMENTS_ERROR";

export const GET_CHECK_LINE_CHART = "[CHECK] GET_CHECK_LINE_CHART";
export const GET_CHECK_LINE_CHART_SUCCESS =
  "[CHECK] GET_CHECK_LINE_CHART_SUCCESS";
export const GET_CHECK_LINE_CHART_ERROR = "[CHECK] GET_CHECK_LINE_CHART_ERROR";

export const GET_CHECK_IMAGES = "[CHECK] GET_CHECK_IMAGES";
export const GET_CHECK_IMAGES_SUCCESS = "[CHECK] GET_CHECK_IMAGES_SUCCESS";
export const GET_CHECK_IMAGES_ERROR = "[CHECK] GET_CHECK_IMAGES_ERROR";

type GetCheckTable = {
  type: typeof GET_CHECK_TABLE,
  payload: object,
};
type GetCheckTableSuccess = {
  type: typeof GET_CHECK_TABLE_SUCCESS,
  payload: Array,
};
type GetCheckTableError = {
  type: typeof GET_CHECK_TABLE_ERROR,
  payload: any,
};

type AddCheckComment = {
  type: typeof ADD_CHECK_COMMENT,
  payload: object,
};
type AddCheckCommentSuccess = {
  type: typeof ADD_CHECK_COMMENT_SUCCESS,
  payload: Array,
};
type AddCheckCommentError = {
  type: typeof ADD_CHECK_COMMENT_ERROR,
  payload: any,
};

type AddCheckAction = {
  type: typeof ADD_CHECK_ACTION,
  payload: object,
};
type AddCheckActionSuccess = {
  type: typeof ADD_CHECK_ACTION_SUCCESS,
  payload: Array,
};
type AddCheckActionError = {
  type: typeof ADD_CHECK_ACTION_ERROR,
  payload: any,
};

type GetCheckDoughnut = {
  type: typeof GET_CHECK_DOUGHNUT,
  payload: object,
};
type GetCheckDoughnutSuccess = {
  type: typeof GET_CHECK_DOUGHNUT_SUCCESS,
  payload: object,
};
type GetCheckDoughnutError = {
  type: typeof GET_CHECK_DOUGHNUT_ERROR,
  payload: any,
};

type GetCheckComments = {
  type: typeof GET_CHECK_COMMENTS,
  payload: object,
};
type GetCheckCommentsSuccess = {
  type: typeof GET_CHECK_COMMENTS_SUCCESS,
  payload: Array,
};
type GetCheckCommentsError = {
  type: typeof GET_CHECK_COMMENTS_ERROR,
  payload: any,
};

type GetCheckLineChart = {
  type: typeof GET_CHECK_LINE_CHART,
  payload: object,
};
type GetCheckLineChartSuccess = {
  type: typeof GET_CHECK_LINE_CHART_SUCCESS,
  payload: Array,
};
type GetCheckLineChartError = {
  type: typeof GET_CHECK_LINE_CHART_ERROR,
  payload: any,
};

type GetCheckImages = {
  type: typeof GET_CHECK_IMAGES,
  payload: object,
};

type GetCheckImagesSuccess = {
  type: typeof GET_CHECK_IMAGES_SUCCESS,
  payload: object,
};

type GetCheckImagesError = {
  type: typeof GET_CHECK_IMAGES_ERROR,
  payload: any,
};

export type CheckAction =
  | GetCheckTable
  | GetCheckTableSuccess
  | GetCheckTableError
  | AddCheckComment
  | AddCheckCommentSuccess
  | AddCheckCommentError
  | AddCheckAction
  | AddCheckActionSuccess
  | AddCheckActionError
  | GetCheckDoughnut
  | GetCheckDoughnutSuccess
  | GetCheckDoughnutError
  | GetCheckComments
  | GetCheckCommentsSuccess
  | GetCheckCommentsError
  | GetCheckLineChart
  | GetCheckLineChartSuccess
  | GetCheckLineChartError
  | GetCheckImages
  | GetCheckImagesSuccess
  | GetCheckImagesError;
