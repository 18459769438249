import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { black, darkGrey, white, inputSky } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    left: "3%",
    top: "25%",
    background: white,
    color: darkGrey,
  },
  inputContainer: {
    width: "450px",
  },
  input: {
    fontFamily: "SantanderMicroText !important",
    boxSizing: "border-box",
    width: "275px",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focus-visible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
  },
  error: {
    fontSize: "0.750em",
    color: black,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-25%",
      fontSize: "0.9em",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "3%",
      top: "25%",
    },
  },
  provisionalPlaceholder: {
    position: "absolute",
    left: "3%",
    background: white,
    color: darkGrey,
    fontSize: "14px",
    lineHeight: "21px",
    top: "-12px",
  },
}));

const PersonField = ({
  input,
  filters,
  selectRede,
  clearRegional,
  changedForm,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [id, setId] = useState("");
  const inputToFocus = useRef(null);

  const changeValue = (e) => {
    setInputValue(e.target.value);
    const redeId = parseInt(e.target.value.substring(0, 7));
    if (!isNaN(redeId) && redeId >= 1000000) {
      setId(redeId);
      selectRede(redeId);
      inputToFocus.current.focus();
    } else {
      setId(0);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [changedForm]);

  const clearInput = (e) => {
    setInputValue("");
    clearRegional();
  };

  return (
    <div className={classes.root}>
      <input
        {...input}
        value={id}
        ref={inputToFocus}
        style={{ position: "absolute", opacity: "0", pointerEvents: "none", }}
      />
      <input
        list="redes"
        className={`${classes.input} dss-form-field__input`}
        value={inputValue}
        onChange={changeValue}
        onFocus={clearInput}
      />
      <span className={classes.provisionalPlaceholder}>Selecione a Rede</span>
      {filters ? (
        <datalist id="redes">
          {filters.map((i) => {
            return (
              <option value={`${i.cd_Rede} - ${i.ds_Rede}`} key={i.cd_Rede} />
            );
          })}
        </datalist>
      ) : null}
    </div>
  );
};

export default PersonField;
