// @flow

import * as fromTypes from "../types/checklist";

export const getChecklist = (payload: Object): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_CHECKLIST,
  payload,
});
export const getChecklistSuccess = (
  payload: any
): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_CHECKLIST_SUCCESS,
  payload,
});
export const getChecklistError = (payload: any): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_CHECKLIST_ERROR,
  payload,
});

export const changeChecklistItem = (
  payload: Object
): fromTypes.ChecklistAction => ({
  type: fromTypes.CHANGE_CHECKLIST_ITEM,
  payload,
});
export const changeChecklistItemSuccess = (
  payload: any
): fromTypes.ChecklistAction => ({
  type: fromTypes.CHANGE_CHECKLIST_ITEM_SUCCESS,
  payload,
});
export const changeChecklistItemError = (
  payload: any
): fromTypes.ChecklistAction => ({
  type: fromTypes.CHANGE_CHECKLIST_ITEM_ERROR,
  payload,
});

export const getTaskComments = (
  id: int
): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_TASK_COMMENTS,
  id,
});
export const getTaskCommentsSuccess = (
  payload: any
): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_TASK_COMMENTS_SUCCESS,
  payload,
});
export const getTaskCommentsError = (
  payload: any
): fromTypes.ChecklistAction => ({
  type: fromTypes.GET_TASK_COMMENTS_ERROR,
  payload,
});
