// @flow
import * as fromTypes from "../types/checklist";

type State = {
  loading: boolean,
  checklist: object,
  comments: array,
};

export const initialState = {
  loading: false,
  checklist: {},
  comments: [],
};

const updateChecklist = (state, { payload }) => ({
  ...state,
  checklist: payload,
  loading: false,
});

const updateComments = (state, { payload }) => {
  //TO-DO: INSERIR UM COMENTARIO POR OBJETO
  return {
    ...state,
    comments: [
      ...state.comments,
      { task: payload.task, commentList: payload.commentList },
    ],
  };
};

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (
  state: State = initialState,
  action: fromTypes.BulletinAction
) => {
  switch (action.type) {
    case fromTypes.GET_CHECKLIST:
    case fromTypes.CHANGE_CHECKLIST_ITEM:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_CHECKLIST_SUCCESS:
    case fromTypes.CHANGE_CHECKLIST_ITEM_SUCCESS:
      return updateChecklist(state, action);
    case fromTypes.GET_TASK_COMMENTS_SUCCESS:
      return updateComments(state, action);
    // Error
    case fromTypes.GET_CHECKLIST_ERROR:
    case fromTypes.CHANGE_CHECKLIST_ITEM_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
