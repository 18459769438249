import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { getUser } from "../store/services/auth";
import { getWatermarkUserInfo } from "../store/actions/profile";
import { compose } from "redux";
import { connect } from "react-redux";
import { white } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: "9999999999",
    pointerEvents: "none",
  },
  grid1: {
    position: "absolute",
    top: "10%",
    left: "5%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid2: {
    position: "absolute",
    top: "10%",
    left: "40%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid3: {
    position: "absolute",
    top: "10%",
    left: "75%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid4: {
    position: "absolute",
    top: "40%",
    left: "20%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid6: {
    position: "absolute",
    top: "40%",
    left: "65%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid7: {
    position: "absolute",
    top: "75%",
    left: "5%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid8: {
    position: "absolute",
    top: "75%",
    left: "40%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
  grid9: {
    position: "absolute",
    top: "75%",
    left: "75%",
    textShadow:
      "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
    fontSize: "20px",
    fontFamily: "SantanderMicroText !important",
    color: white,
    opacity: "0.2",
  },
}));

const WaterMark = ({ watermarkUserInfo, onGetWatermarkUserInfo }) => {
  const classes = useStyles();

  useEffect(() => {
    onGetWatermarkUserInfo(getUser());
  }, []);

  const dataAtualFormatada = () => {
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  return (
    <div className={classes.root}>
      <div className={classes.grid1}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid2}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid3}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid4}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid6}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid7}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid8}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
      <div className={classes.grid9}>
        {watermarkUserInfo?.info?.nm_Func}
        <br />
        {"t" + getUser()}
        <br />
        {dataAtualFormatada()}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetWatermarkUserInfo: (id) => dispatch(getWatermarkUserInfo(id)),
  };
};

const mapStateToProps = (state) => {
  const { watermarkUserInfo } = state.profile;
  return { watermarkUserInfo };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WaterMark);
