// @flow

import * as fromTypes from "../types/storeAvailability";

export const getStoreAvailability = (payload: object): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_AVAILABILITY,
  payload,
});
export const getStoreAvailabilitySuccess = (
  payload: array
): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_AVAILABILITY_SUCCESS,
  payload,
});
export const getStoreAvailabilityError = (payload: any): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_AVAILABILITY_ERROR,
  payload,
});

export const getStoreComments = (payload: object): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_COMMENTS,
  payload,
});
export const getStoreCommentsSuccess = (payload: array): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_COMMENTS_SUCCESS,
  payload,
});
export const getStoreCommentsError = (payload: any): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_COMMENTS_ERROR,
  payload,
});

export const sendStoreComments = (payload: object): fromTypes.StoreAction => ({
  type: fromTypes.SEND_STORE_COMMENTS,
  payload,
});
export const sendStoreCommentsSuccess = (
  payload: array
): fromTypes.StoreAction => ({
  type: fromTypes.SEND_STORE_COMMENTS_SUCCESS,
  payload,
});
export const sendStoreCommentsError = (payload: any): fromTypes.StoreAction => ({
  type: fromTypes.SEND_STORE_COMMENTS_ERROR,
  payload,
});

export const getStoreDoughnutChart = (payload: object): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_DOUNGNUT_CHART,
  payload,
});
export const getStoreDoughnutChartSuccess = (
  payload: array
): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_DOUNGNUT_CHART_SUCCESS,
  payload,
});
export const getStoreDoughnutChartError = (
  payload: any
): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_DOUNGNUT_CHART_ERROR,
  payload,
});

export const getStoreCalendarChart = (payload: object): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_CALENDAR_CHART,
  payload,
});
export const getStoreCalendarChartSuccess = (
  payload: array
): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_CALENDAR_CHART_SUCCESS,
  payload,
});
export const getStoreCalendarChartError = (payload: any): fromTypes.StoreAction => ({
  type: fromTypes.GET_STORE_CALENDAR_CHART_ERROR,
  payload,
});
