import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { black, darkGrey, white, inputSky, boston } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "242px",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "1em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
  error: {
    fontSize: "0.750em",
    color: boston,
    textAlign: "left",
    margin: "0em",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
}));

const TextArea = ({
  input,
  meta: { touched, error },
  placeholder,
  maxLength,
  disabled,
  defaultValue,
}) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (defaultValue || defaultValue === "") {
      inputRef.current.focus();
      setInputValue(defaultValue);
      inputRef.current.blur();
    }
  }, [defaultValue]);

  const getPlaceholderClass = () => {
    if (inputValue === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (inputValue === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (inputValue !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  return (
    <div className={classes.root}>
      <div
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <textarea
          {...input}
          ref={inputRef}
          error={touched && !!error}
          className={`${classes.input} dss-form-field__input`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          maxLength={maxLength || "255"}
          disabled={disabled}
          style={
            touched && !!error ? { border: `0.06em solid ${boston}` } : null
          }
        />
      </div>
      <span className={getPlaceholderClass()}>{placeholder}</span>

      {touched && !!error ? <p className={classes.error}>{error}</p> : null}
    </div>
  );
};

export default TextArea;
