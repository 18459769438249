import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Box, Button, Typography } from "@material-ui/core";
import {
  santander,
  white,
  santander20,
  boston,
  darkGrey,
  black,
  green,
} from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  list: {
    paddingLeft: "20px",
  },
  green: {
    color: `${green} !important`,
  },
  red: {
    color: `${santander} !important`,
  },
}));

const ConfirmTaskModal = ({ open, onClose, finish, indicator }) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: white,
          p: 4,
        }}
      >
        <Typography className={classes.title}>Atenção!</Typography>
        <Typography className={classes.subtitle}>
          {indicator === "ATM" ? (
            <>
              A quantidade total de ATM(s) deve estar distribuída entre os
              campos:
              <ul className={classes.list}>
                <li>
                  <span className={classes.green}>VERDE:</span> digite o número
                  de ATM(s) disponíveis;
                </li>
                <li>
                  <span className={classes.red}>VERMELHO:</span> digite o número
                  de ATM(s) indisponíveis;
                </li>
              </ul>
            </>
          ) : (
            <>O indicador {indicator} está sem registro de atuação.</>
          )}
        </Typography>
        <div className={classes.btnContainer}>
          <Button
            onClick={onClose}
            variant="contained"
            variant="contained"
            sx={{
              height: "48px",
              background: boston,
              color: white,
              fontFamily: "SantanderMicroText",
              textTransform: "none",
              textDecoration: "none",
              "&:hover": {
                background: boston,
                color: white,
              },
            }}
          >
            Ok
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmTaskModal;
