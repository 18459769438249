// @flow

export const SIGN_IN = "[AUTH] SIGN-IN";
export const SIGN_IN_SUCCESS = "[AUTH] SIGN-IN SUCCESS";
export const SIGN_IN_ERROR = "[AUTH] SIGN-IN ERROR";

export const SEND_PASS_REQUEST = "[AUTH] SEND PASS REQUEST";
export const SEND_PASS_SUCCESS = "[AUTH] SEND PASS REQUEST SUCCESS";
export const SEND_PASS_ERROR = "[AUTH] SEND PASS REQUEST ERROR";

export const REGISTER_NEW_PASS = "[AUTH] REGISTER NEW PASSWORD";
export const REGISTER_NEW_PASS_SUCCESS = "[AUTH] REGISTER NEW PASSWORD SUCCESS";
export const REGISTER_NEW_PASS_ERROR = "[AUTH] REGISTER NEW PASSWORD ERROR";

type SignIn = {
  type: typeof SIGN_IN,
  payload: Object,
};
type SignInSuccess = {
  type: typeof SIGN_IN_SUCCESS,
  payload: any,
};
type SignInError = {
  type: typeof SIGN_IN_ERROR,
  payload: any,
};

type SendPassRequest = {
  type: typeof SEND_PASS_REQUEST,
  payload: Object,
};
type SendPassRequestSuccess = {
  type: typeof SEND_PASS_SUCCESS,
  payload: any,
};
type SendPassRequestError = {
  type: typeof SEND_PASS_ERROR,
  payload: any,
};

type RegisterNewPassword = {
  type: typeof REGISTER_NEW_PASS,
  paylaod: Object,
};
type RegisterNewPasswordSuccess = {
  type: typeof REGISTER_NEW_PASS_SUCCESS,
  payload: any,
};
type RegisterNewPasswordError = {
  type: typeof REGISTER_NEW_PASS_ERROR,
  payload: any,
};

export type AuthAction =
  | SignIn
  | SignInSuccess
  | SignInError
  | SendPassRequest
  | SendPassRequestSuccess
  | SendPassRequestError
  | RegisterNewPassword
  | RegisterNewPasswordSuccess
  | RegisterNewPasswordError;
