import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/queues";
import * as fromActions from "../actions/queues";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);
  try {
    const response = yield axios.post(`${BASE_API}/Filas/Filas/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getQueuesTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de Filas.");
    yield put(fromActions.getQueuesTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getQueuesDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(`${BASE_API}/Filas/graficoSituacao`, {
      rede,
      regional,
      ga,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      yield put(fromActions.getQueuesDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getQueuesDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

// export function* getQueuesHeatmap({ payload }) {
//   let { ga, rede, regional, agencia } = payload;
//   ga = parseInt(ga);
//   rede = parseInt(rede);
//   regional = parseInt(regional);
//   agencia = parseInt(agencia);

//   if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
//     agencia = parseInt(getAgencia());
//   }

//   try {
//     const response = yield axios.post(`${BASE_API}/Exo/graficoMensal`, {
//       rede,
//       regional,
//       ga,
//       agencia,
//       headers: headers,
//     });
//     if (response.status === 200) {
//       yield put(fromActions.getQueuesHeatmapSuccess(response.data.data));
//     }
//   } catch (err) {
//     console.error(err);
//     yield put(fromActions.getQueuesHeatmapError(err));
//     if (err.response.status === 401) {
//       logout();
//       window.location.href = "/";
//     }
//   }
// }

export function* addQueuesAction({ payload }) {
  const payloadToSend = {
    headers: headers,
    ind: payload.ind,
    uniorg: payload.uniorg,
    aux: "",
    mtr: parseInt(getUser()),
    atuacao: payload.action,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Filas/filasAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addQueuesActionSuccess(response.data.data));
      yield put(fromActions.getQueuesTable(getLastSearch()));
      yield put(fromActions.getQueuesDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addQueuesActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addQueuesComment({ payload }) {
  const payloadToSend = {
    headers: headers,
    ind: payload.ind,
    uniorg: payload.uniorg,
    aux: "",
    mtr: parseInt(getUser()),
    com: payload.comment,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/Filas/filasAtuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addQueuesCommentSuccess(response.data.data));
      yield put(fromActions.getQueuesTable(getLastSearch()));
      yield put(
        fromActions.getQueuesComments({
          uniorg: payload.uniorg,
          ind: payload.ind,
        })
      );
      yield put(fromActions.getQueuesDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addQueuesCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getQueuesComments({ payload }) {
  const { ind, uniorg } = payload;
  try {
    const response = yield axios.post(`${BASE_API}/Filas/filaComentarios/`, {
      headers: headers,
      ind,
      uniorg,
      info_aux: "",
    });
    if (response.status === 200) {
      yield put(fromActions.getQueuesCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getQueuesCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchQueues() {
  yield takeLatest(fromTypes.GET_QUEUES_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_QUEUES_ACTION, addQueuesAction);
  yield takeLatest(fromTypes.ADD_QUEUES_COMMENT, addQueuesComment);
  yield takeLatest(fromTypes.GET_QUEUES_DOUGHNUT, getQueuesDoughnut);
  yield takeLatest(fromTypes.GET_QUEUES_COMMENTS, getQueuesComments);
  // yield takeLatest(fromTypes.GET_QUEUES_HEATMAP, getQueuesHeatmap);
}
