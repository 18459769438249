// @flow
import * as fromTypes from "../types/agency";

export const initialState = {
  loading: false,
  agencyInfo: [],
  agenciesLength: 0,
  operacoes: [],

  profileDetails: 0,
};

const setLoading = (state, loading) => ({ ...state, loading });

const setAgencyInfo = (state, { payload }) => ({
  ...state,
  agencyInfo: payload,
  agenciesLength: payload.length,
  loading: false,
});

const setOperations = (state, { payload }) => ({
  ...state,
  operacoes: payload,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.ProfileAction
) => {
  switch (action.type) {
    case fromTypes.GET_AGENCIES:
    case fromTypes.REGISTER_AGENCY:
    case fromTypes.GET_AGENCY_DETAILS:
    case fromTypes.UPDATE_AGENCY:
    case fromTypes.DELETE_AGENCY:
    case fromTypes.GET_AGENCY_LIST:
    case fromTypes.GET_AGENCY_INFO:
      return setLoading(state, true);
    case fromTypes.GET_AGENCIES_ERROR:
    case fromTypes.REGISTER_AGENCY_SUCCESS:
    case fromTypes.REGISTER_AGENCY_ERROR:
    case fromTypes.GET_AGENCY_DETAILS_ERROR:
    case fromTypes.UPDATE_AGENCY_SUCCESS:
    case fromTypes.UPDATE_AGENCY_ERROR:
    case fromTypes.DELETE_AGENCY_SUCCESS:
    case fromTypes.DELETE_AGENCY_ERROR:
    case fromTypes.GET_AGENCY_LIST_ERROR:
    case fromTypes.GET_AGENCY_INFO_ERROR:
    case fromTypes.GET_OPERATIONS_ERROR:
      return setLoading(state, false);
    case fromTypes.UPDATE_AGENCY_SUCCESS:
    case fromTypes.GET_AGENCY_INFO_SUCCESS:
      return setAgencyInfo(state, action);
    case fromTypes.GET_OPERATIONS_SUCCESS:
      return setOperations(state, action);
    default:
      return state;
  }
};

export default reducer;
