import { put, takeLatest } from "@redux-saga/core/effects";
import axios from "axios";
import * as fromTypes from "../types/check";
import * as fromActions from "../actions/check";
import { getBaseAPI } from "../services/api";
import { alertToast } from "../../components/Toast";
import { getToken, logout, getUser, getUserLvl } from "../services/auth";
import { setLastSearchedUser } from "../services/search";
import { setAgencia, getAgencia } from "../services/checklist";
import { setLastSearch, getLastSearch } from "../services/atm";

const BASE_API = getBaseAPI();

const headers = {
  Authorization: `Bearer ${getToken()}`,
};

export function* getTable({ payload }) {
  let { ga, rede, regional, agencia } = payload;

  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  if (ga !== 0) setLastSearchedUser(ga);

  try {
    const response = yield axios.post(`${BASE_API}/CompensacaoCheque/lista/`, {
      ga,
      rede,
      regional,
      agencia,
      headers: headers,
    });
    if (response.status === 200) {
      setLastSearch({ ga, rede, regional, agencia });
      setAgencia(agencia);
      yield put(fromActions.getCheckTableSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de cheques.");
    yield put(fromActions.getCheckTableError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getCheckDoughnut({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/graficoSituacao`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getCheckDoughnutChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getCheckDoughnutChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getCheckLine({ payload }) {
  let { ga, rede, regional, agencia } = payload;
  ga = parseInt(ga);
  rede = parseInt(rede);
  regional = parseInt(regional);
  agencia = parseInt(agencia);

  if (ga !== 0 && getAgencia() !== "" && getAgencia() !== 0) {
    agencia = parseInt(getAgencia());
  }

  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/graficoHist`,
      {
        rede,
        regional,
        ga,
        agencia,
        headers: headers,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getCheckLineChartSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    yield put(fromActions.getCheckLineChartError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addCheckAction({ payload }) {
  const payloadToSend = {
    ind: payload.ind,
    uniorg: payload.uniorg,
    ga: parseInt(getUser()),
    aux: payload.aux,
    atuacao: payload.atuacao,
    com: payload.com,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/atuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      yield put(fromActions.addCheckActionSuccess(response.data.data));
      yield put(fromActions.getCheckTable(getLastSearch()));
      yield put(fromActions.getCheckDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar a atuação.");
    yield put(fromActions.addCheckActionError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* addCheckComment({ payload }) {
  const payloadToSend = {
    ind: payload.ind,
    uniorg: payload.uniorg,
    ga: parseInt(getUser()),
    aux: payload.aux,
    atuacao: payload.atuacao,
    com: payload.com,
    headers: headers,
  };

  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/atuacao/add`,
      payloadToSend
    );
    if (response.status === 200) {
      alertToast("success", "Comentário adicionado com sucesso.");
      yield put(fromActions.addCheckCommentSuccess(response.data.data));
      yield put(fromActions.getCheckTable(getLastSearch()));
      yield put(
        fromActions.getCheckComments({
          uniorg: payload.uniorg,
          ind: payload.ind,
          info_aux: payload.aux,
        })
      );
      yield put(fromActions.getCheckDoughnutChart(getLastSearch()));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível adicionar o comentário.");
    yield put(fromActions.addCheckCommentError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getCheckComments({ payload }) {
  const { uniorg, ind, info_aux } = payload;

  try {
    const response = yield axios.post(
      `${BASE_API}/CompensacaoCheque/comentarios/`,
      {
        headers: headers,
        uniorg,
        ind,
        info_aux,
      }
    );
    if (response.status === 200) {
      yield put(fromActions.getCheckCommentsSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar a lista de comentários");
    yield put(fromActions.getCheckCommentsError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* getCheckImages({ payload }) {
  const { idCheque, uniorg } = payload;

  console.log("payload");
  console.log(payload);

  try {
    const response = yield axios.post(`${BASE_API}/CompensacaoCheque/folha/`, {
      headers: headers,
      cd_uniorg: uniorg,
      id_cheque: idCheque,
    });

    if (response.status === 200) {
      yield put(fromActions.getCheckImagesSuccess(response.data.data));
    }
  } catch (err) {
    console.error(err);
    alertToast("error", "Não foi possível recuperar as imagens do cheque");
    yield put(fromActions.getCheckImagesError(err));
    if (err.response.status === 401) {
      logout();
      window.location.href = "/";
    }
  }
}

export function* watchCheck() {
  yield takeLatest(fromTypes.GET_CHECK_TABLE, getTable);
  yield takeLatest(fromTypes.ADD_CHECK_ACTION, addCheckAction);
  yield takeLatest(fromTypes.ADD_CHECK_COMMENT, addCheckComment);
  yield takeLatest(fromTypes.GET_CHECK_DOUGHNUT, getCheckDoughnut);
  yield takeLatest(fromTypes.GET_CHECK_COMMENTS, getCheckComments);
  yield takeLatest(fromTypes.GET_CHECK_LINE_CHART, getCheckLine);
  yield takeLatest(fromTypes.GET_CHECK_IMAGES, getCheckImages);
}
