import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Box, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { white, santander, boston, lightGrey } from "../theme/colors";

import { getUserInfo } from "../store/actions/profile";
import { sendLog } from "../store/actions/analytics";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {},

  btnContainer: {
    marginTop: "75px",
    display: "flex",
    justifyContent: "space-between",
  },

  returnBtn: {
    color: `${santander} !important`,
    border: `1px solid ${santander} !important`,
    textTransform: "none !important",
    padding: "0.25em 1em !important",
    fontSize: "1em !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  button: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${boston} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  disabledButton: {
    boxSizing: "content-box !important",
    fontFamily: "SantanderMicroText !important",
    background: `${lightGrey} !important`,
    textTransform: "none !important",
    fontSize: "1em !important",
    padding: "0.6em 1.1em !important",
    color: `${white} !important`,
    borderRadius: "0.250em !important",
    right: "0%",
  },
  embendContainer: {
    height: "710px",
    width: "1200px",
  },
}));

const PWBIModal = ({ open, onClose, reportUrl }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: white,
          p: 4,
          padding: "35px 40px 40px 40px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{
              padding: "0px !important",
              background: "none !important",
              minWidth: "16px !important",
              marginBottom: "10px",
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Icons icon="close-1px" />
          </Button>
        </div>
        <embed src={reportUrl} className={classes.embendContainer} />
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onSendLog: (payload) => dispatch(sendLog(payload)),
  };
};

const mapStateToProps = (state) => {
  const { userInfo } = state.profile;
  return { userInfo };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PWBIModal);
