import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { lighterGrey, mediumGrey, darkGrey } from "../../theme/colors";
import {
  MainMenu,
  FilterField,
  DateField,
  ChartIndex,
  TelemetriaLogo,
} from "..";

import { getUser, getUserLvl } from "../../store/services/auth";
import {
  getBucketInfo,
  getTotalTrab,
  getWorkedOutTable,
} from "../../store/actions/comp";

import { Modal, Box, Button } from "@material-ui/core";
import {
  white,
  santander,
  boston,
  lightGrey,
  black2,
  bulletinGrey,
  black,
} from "../../theme/colors";

import { getUserInfo } from "../../store/actions/profile";
import { sendLog } from "../../store/actions/analytics";
import { getCheckImages } from "../../store/actions/check";
import Icons from "../Icons";

import IndicatorList from "../IndicatorList";
import interno from "../../assets/img/confidencial_restrito.svg";
import { decrypt } from "../../store/services/crypto";
import { Tooltip, tooltipClasses } from "@mui/material";
import CheckSkeleton from "../CheckSkeleton";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
  root: {
    "& header": {
      backgroundColor: white,
      padding: "2.188em 1em 0em 1em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      gap: "24px",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "2.500em",
      position: "relative",
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
      },
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  link: {
    color: `${santander} !important`,
    "& > span": {
      fontWeight: "normal !important",
    },
    textDecoration: "underline !important",
    cursor: "pointer",
  },
  actionBtn: {
    background: `${santander} !important`,
    fontFamily: "SantanderMicroText!important",
    fontSize: "16px !important",
    height: "46px !important",
    padding: "20px !important",
    boxShadow: "none !important",
    textTransform: "capitalize !important",
  },
}));

const HeaderComp = ({
  type,
  trab,
  onGetBucketInfo,
  onGetWorkedOutTable,
  onGetTotalTrab,
  onSetPageState = () => {},
}) => {
  const classes = useStyles();

  const setNewState = (newState) => {
    onSetPageState(newState);
  };

  useEffect(() => {
    onGetTotalTrab();
  }, [type]);

  useEffect(() => {
    switch (type) {
      case "trabalhados":
      case "gerente":
        onGetWorkedOutTable(getUser());
        break;
    }
  }, [type]);

  const renderButton = (param) => {
    switch (param) {
      case "trabalhando":
        return (
          <Button
            className={classes.actionBtn}
            variant="contained"
            sx={{
              width: "390px !important",
              background: `${white} !important`,
              color: `${bulletinGrey} !important`,
              border: 1,
              borderColor: `${bulletinGrey} !important`,
            }}
            onClick={() => {
              setNewState("trabalhados");
              // onGetBucketInfo(getUser());
            }}
          >
            Visualizar cheques trabalhados
          </Button>
        );
      case "trabalhados":
        return (
          <Button
            className={classes.actionBtn}
            variant="contained"
            sx={{
              width: "240px !important",
              background: `${white} !important`,
              color: `${bulletinGrey} !important`,
              border: 1,
              borderColor: `${bulletinGrey} !important`,
            }}
            onClick={() => {
              setNewState("trabalhando");
              onGetBucketInfo(getUser());
            }}
          >
            Trabalhar cheque
          </Button>
        );

      case "padrao":
        return (
          <Button
            className={classes.actionBtn}
            variant="contained"
            sx={{ width: "240px !important" }}
            onClick={() => {
              setNewState("trabalhando");
              onGetBucketInfo(getUser());
            }}
          >
            Iniciar Compensação
          </Button>
        );
      case "gerente":
        return;
    }
  };

  return (
    <div className={classes.root}>
      <header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
            // width: type === "gerente" ? "100%" : "50%",
            width: "100%",
          }}
        >
          <MainMenu />

          {type === "gerente" ? null : (
            <Typography className={classes.title}>
              Compensação de cheques {">"}{" "}
              <span
                className={classes.link}
                onClick={() => {
                  setNewState("trabalhados");
                }}
              >
                {trab.qtde} cheques <span>trabalhados</span>
              </span>
            </Typography>
          )}
        </div>

        {renderButton(type)}
      </header>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetBucketInfo: (payload) => dispatch(getBucketInfo(payload)),
    onGetWorkedOutTable: (payload) => dispatch(getWorkedOutTable(payload)),
    onGetTotalTrab: (payload) => dispatch(getTotalTrab()),
  };
};

const mapStateToProps = (state) => {
  const { loading, trab } = state.comp;
  return { loading, trab };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HeaderComp
);
