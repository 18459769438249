// @flow
import * as fromTypes from "../types/indicator";

export const initialState = {
  loading: false,
  indicatorInfo: [],
  comments: [],
};

const setLoading = (state, loading) => ({ ...state, loading });

const setIndicatorInfo = (state, { payload }) => ({
  ...state,
  indicatorInfo: [payload],
  loading: false,
});

const setCommentsList = (state, { payload }) => ({
  ...state,
  comments: [payload],
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.IndicatorAction
) => {
  switch (action.type) {
    case fromTypes.REGISTER_COMMENT:
    case fromTypes.GET_INDICATOR_INFO:
      return setLoading(state, true);
    case fromTypes.REGISTER_COMMENT_SUCCESS:
    case fromTypes.REGISTER_COMMENT_ERROR:
    case fromTypes.GET_INDICATOR_INFO_ERROR:
      return setLoading(state, false);
    case fromTypes.GET_INDICATOR_INFO_SUCCESS:
      return setIndicatorInfo(state, action);
    case fromTypes.GET_COMMENTS_LIST_SUCCESS:
      return setCommentsList(state, action);
    default:
      return state;
  }
};

export default reducer;
