import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Icons } from "./";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  santander,
  lighterGrey,
  darkGrey,
  listSelectedBg,
  white,
  boston,
  black,
  inputSky,
} from "../theme/colors";
import {
  getUsers,
  getUniorgList,
  getGAInfo,
  getSupervisorInfo,
  changeGASelection,
  changeSupervisorSelection,
} from "../store/actions/profile";
import {
  getAgencyInfo,
  registerAgency,
  deleteAgency,
} from "../store/actions/agency";
import { getAlertsFullList, deleteAlert } from "../store/actions/alerts";
import { getUser } from "../store/services/auth";

import Tooltip from "@mui/material/Tooltip";
import ChangeUniorgLinkModal from "./ChangeUniorgLinkModal";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import CustomNoResultsOverlay from "./CustomNoResultsOverlay";
import { SearchAgency } from "../components";
import DeleteAlertModal from "./DeleteAlertModal";

const useStyles = makeStyles((theme) => ({
  root: {},
  userLink: {
    fontFamily: "SantanderMicroText !important",
    fontSize: "16px !important",
    color: `${santander} !important`,
    textDecoration: "underline !important",
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  inputContainer: {
    padding: "32px 32px 0px 32px",
  },
  searchInput: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
  linkBtn: {
    fontFamily: "SantanderMicroText !important",
    color: `${santander} !important`,
    textTransform: "none !important",
    textDecoration: "underline !important",
    fontSize: "16px !important",
  },
  msg: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "340px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
}));

const getAlertIcon = (type) => {
  switch (type) {
    case 1:
      return <Icons icon="toast-warn" />;
    case 2:
      return <Icons icon="toast-checkmark" />;
    case 3:
      return <Icons icon="toast-info" />;
    case 4:
      return <Icons icon="toast-error" />;
    default:
      break;
  }
};

const List = ({
  onGetGAInfo,
  onGetSupervisorInfo,
  onGetUsers,
  onGetUniorgList,
  onChangeGASelection,
  onChangeSupervisorSelection,
  onGetAlertsFullList,
  onDeleteAlert,
  alertEditMethod = () => {},
  showCheckbox,
  userList,
  uniorgList,
  userInfo,
  alertList,
  type,
  id,
  onGetAgencyInfo,
  onRegisterAgency,
  onDeleteAgency,
  agencyInfo,
  agenciesLength,
  operacoes,
}) => {
  const classes = useStyles();
  const [selection, setSelection] = useState([]);
  const [cols, setCols] = useState([]);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [alertId, setAlertId] = useState("");
  const [textNoRows, setTextNoRows] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [searchedUniorg, setSearchedUniorg] = useState("");

  const profileListColumns = [
    {
      field: "id",
      headerName: "Matrícula",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 400,
      headerClassName: "header",
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 130,
      headerClassName: "header",
    },
    {
      field: "edit",
      headerName: "",
      sortable: false,
      width: 60,
      headerClassName: "header",
      renderCell: (params) => (
        <strong>
          <Button
            className={classes.editBtn}
            onClick={() => (window.location.href = `/perfil/${params.row.id}`)}
          >
            <Icons icon="edit" />
          </Button>
        </strong>
      ),
    },
  ];

  const profileListLinks = [
    {
      field: "id",
      headerName: "Matrícula",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 250,
      headerClassName: "header",
    },
    {
      field: "type",
      headerName: "",
      width: 1,
      headerClassName: "header",
      renderCell: (params) => <></>,
    },
    {
      field: "uniorgSelectionLink",
      headerName: "Carterização",
      sortable: false,
      width: 150,
      headerClassName: "header",
      renderCell: (params) => (
        <strong>
          <Button
            onClick={() =>
              (window.location.href = `/carterizacao/agencia/${params.row.id}`)
            }
            className={classes.linkBtn}
          >
            Agências
          </Button>
        </strong>
      ),
    },
    {
      field: "userSelectionLink",
      headerName: "",
      sortable: false,
      width: 100,
      headerClassName: "header",
      renderCell: (params) => (
        <>
          {params.row.type === "Avançado" ||
          params.row.type === "Administrador" ? (
            <strong>
              <Button
                onClick={() =>
                  (window.location.href = `/carterizacao/usuarios/${params.row.id}`)
                }
                className={classes.linkBtn}
              >
                Usuários
              </Button>
            </strong>
          ) : null}
        </>
      ),
    },
  ];

  const selectUserListCols = [
    {
      field: "id",
      headerName: "Matrícula",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Nome",
      width: 400,
      headerClassName: "header",
    },
  ];

  const selectUniorgCols = [
    {
      field: "id",
      headerName: "Uniorg",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Agência",
      width: 250,
      headerClassName: "header",
    },
    {
      field: "regional",
      headerName: "Regional",
      width: 130,
      headerClassName: "header",
    },
    {
      field: "user",
      headerName: "Usuário",
      width: 130,
      headerClassName: "header",
      valueGetter: (item) => {
        let newValue;
        if (typeof item.value === "string") {
          newValue = parseInt(item.value);
          return newValue;
        }
        return item.value;
      },
      renderCell: (params) => (
        <Tooltip placement="right" title={params.row.userName || ""}>
          <Typography className={classes.userLink}>
            {params.row.user !== 0 ? params.row.user : ""}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "userName",
      headerName: "",
      width: 1,
      headerClassName: "header",
      renderCell: (params) => <></>,
    },
  ];

  const selectAgenciesCols = [
    {
      field: "id",
      headerName: "Uniorg",
      width: 150,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Agência",
      width: 250,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.name || ""}>
          <span>{params.row.name}</span>
        </Tooltip>
      ),
    },
    {
      field: "regional",
      headerName: "Regional",
      width: 130,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.regional || ""}>
          <span>{params.row.regional}</span>
        </Tooltip>
      ),
    },
    {
      field: "ds_rede",
      headerName: "Rede",
      width: 130,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.ds_rede || ""}>
          <span>{params.row.ds_rede}</span>
        </Tooltip>
      ),
    },
  ];

  const editAlertFields = [
    {
      field: "id",
      headerName: "",
      width: 1,
      headerClassName: "header",
      renderCell: (params) => <></>,
    },
    {
      field: "typeIcon",
      headerName: "Tipo",
      width: 80,
      headerClassName: "header",
      renderCell: (params) => <>{getAlertIcon(params.row.type)}</>,
    },
    {
      field: "dt_Reg",
      headerName: "Data",
      width: 90,
      headerClassName: "header",
    },
    {
      field: "msg",
      headerName: "Mensagem",
      width: 360,
      headerClassName: "header",
      renderCell: (params) => (
        <Tooltip
          placement="top"
          title={params.row.msg.length > 30 ? params.row.msg : ""}
        >
          <div
            className={classes.msg}
            dangerouslySetInnerHTML={{ __html: params.row.msg }}
          ></div>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      width: 30,
      headerClassName: "header",
      renderCell: (params) => (
        <strong>
          <Button
            className={classes.editBtn}
            onClick={() => {
              setAlertId(params.row.id);
              setAlertModalOpen(true);
            }}
          >
            <Icons icon="trash-bin" />
          </Button>
        </strong>
      ),
    },
  ];

  useEffect(() => {
    getList();
  }, [id, onGetGAInfo, onGetSupervisorInfo]);

  useEffect(() => {
    requestSearch("");
  }, [onGetAlertsFullList, alertList]);

  useEffect(() => {
    getDefaultSelection();
  }, [userInfo, agencyInfo]);

  useEffect(() => {
    switch (type) {
      case "userlist":
        setCols(profileListColumns);
        onGetUsers();
        break;
      case "selectionList":
        setCols(profileListLinks);
        onGetUsers();
        onGetUniorgList();
        break;
      case "gAs":
        setCols(selectUserListCols);
        onGetUsers();
        break;
      case "uniorgs":
        setCols(selectUniorgCols);
        onGetUniorgList();
        break;
      case "agencies":
        setCols(selectAgenciesCols);
        break;
      case "alerts":
        setCols(editAlertFields);
        let currentUser = getUser();
        onGetAlertsFullList(currentUser);
        break;
      default:
        break;
    }
  }, [onGetUsers, onGetUniorgList, onGetAgencyInfo]);

  useEffect(() => {
    if (searchText === "") {
      updateList();
    }
  }, [userList, uniorgList, agencyInfo, alertList]);

  useEffect(() => {
    if (type === "agencies") {
      setTextNoRows("Digite a uniorg no campo acima e pressione Enter");
    } else {
      setTextNoRows("");
    }
  }, []);

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);
      updateList();
    } else {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
      setRows(filteredRows);
      if (filteredRows.length > 0) {
        let orderedRows = filteredRows;
        for (let index = 0; index < selection.length; index++) {
          orderedRows = reorderArray(
            orderedRows.findIndex((i) => i.id === selection[index]),
            index,
            orderedRows
          );
        }
        setRows(orderedRows);
      }
    }
  };

  const getList = () => {
    if (type === "gAs") onGetSupervisorInfo(id);
    if (type === "uniorgs") onGetGAInfo(id);
  };

  const updateList = () => {
    let list = [];
    if (type === "userlist" || type === "gAs" || type === "selectionList") {
      userList.map((i) => {
        list = [
          ...list,
          {
            id: i.cd_Matricula,
            name: i.nm_Func,
            type: i.nm_Perfil,
          },
        ];
      });
      let orderedRows = list;
      for (let index = 0; index < selection.length; index++) {
        orderedRows = reorderArray(
          orderedRows.findIndex((i) => i.id === selection[index]),
          index,
          orderedRows
        );
      }
      setRows(orderedRows);
      setBackupRows(orderedRows);
    }
    if (type === "uniorgs") {
      uniorgList.map((i) => {
        list = [
          ...list,
          {
            id: i.cd_Uniorg,
            name: i.nm_Agencia,
            regional: i.ds_Regional,
            user:
              i.cd_ga_interino === 0
                ? i.cd_ga_principal
                : `${i.cd_ga_interino}*`,
            userName: i.nm_ga_interino ? i.nm_ga_interino : i.nm_ga_principal,
            cd_ga_interino: i.cd_ga_interino || 0,
            cd_ga_principal: i.cd_ga_principal,
            nm_ga_interino: i.nm_ga_interino || 0,
            nm_ga_principal: i.nm_ga_principal || 0,
            dt_inc_int: i.dt_inc_int || 0,
            dt_fnl_int: i.dt_fnl_int || 0,
          },
        ];
      });
      let orderedRows = list;
      for (let index = 0; index < selection.length; index++) {
        orderedRows = reorderArray(
          orderedRows.findIndex((i) => i.id === selection[index]),
          index,
          orderedRows
        );
      }
      setRows(orderedRows);
      setBackupRows(orderedRows);
    }
    if (type === "agencies" && !agencyInfo.includes(undefined)) {
      agencyInfo.map((i) => {
        list = [
          ...list,
          {
            id: i.cd_Uniorg,
            name: i.nm_Agencia,
            regional: i.ds_Regional,
            ds_rede: i.ds_Rede,
            ativo: i.ativo,
          },
        ];
      });
      let orderedRows = list;
      for (let index = 0; index < selection.length; index++) {
        orderedRows = reorderArray(
          orderedRows.findIndex((i) => (i ? i.id === selection[index] : null)),
          index,
          orderedRows
        );
      }
      if (isSearched && agenciesLength == 0) {
        setTextNoRows("Não encontramos agências com a uniorg informado.");
      }
      setRows(orderedRows);
      setBackupRows(orderedRows);
    }
    if (type === "alerts") {
      alertList.map((i) => {
        list = [
          ...list,
          {
            id: i.id,
            typeIcon: i.tp_Info,
            msg: i.texto,
            type: i.tp_Info,
            title: i.titulo,
            dt_Reg: i.dt_Reg,
            dt_Vgc_Inc: i.dt_Alerta_Inc,
            dt_Vgc_Fnl: i.dt_Alerta_Fim,
            ga: i.cd_GA,
            rede: i.cd_Rede,
            regional: i.cd_Regional,
            agencia: i.cd_Uniorg,
            broadcast: i.broadcast,
          },
        ];
      });
    }
    setRows(list);
    setBackupRows(list);
  };

  const reorderArray = (from, to, arr) => {
    const newArr = [...arr];

    const item = newArr.splice(from, 1)[0];
    newArr.splice(to, 0, item);

    return newArr;
  };

  const formatDate = (date) => {
    let month = date.substring(0, 2);
    let year = date.substring(6, 10);
    let day = date.substring(3, 5);

    return `${year}/${month}/${day}`;
  };

  const getDefaultSelection = () => {
    if (userInfo.uniorgs && type === "uniorgs") {
      let defaultSelection = userInfo.uniorgs.map((i) => {
        return i.cd_Uniorg;
      });
      let defaultObjects = userInfo.uniorgs.map((i) => {
        let dtInicial = i.dt_inc_int
          ? formatDate(i.dt_inc_int.split(" ")[0])
          : "";
        let dtFinal = i.dt_fnl_int
          ? formatDate(i.dt_fnl_int.split(" ")[0])
          : "";
        return {
          cd_Matricula: id,
          cd_Uniorg: i.cd_Uniorg,
          cd_Temporario: i.ga_Interino,
          dt_Inicial: dtInicial,
          dt_Final: dtFinal,
        };
      });
      defaultSelection = defaultSelection.sort((a, b) => (a > b ? 1 : -1));
      setSelection(defaultSelection);
      setSelectedObjects(defaultObjects);
      if (rows.length > 0) {
        let orderedRows = rows;
        for (let index = 0; index < defaultSelection.length; index++) {
          orderedRows = reorderArray(
            orderedRows.findIndex((i) => i.id === defaultSelection[index]),
            index,
            orderedRows
          );
        }
        setRows(orderedRows);
      }
    }
    if (userInfo.users && type === "gAs") {
      let defaultSelection = userInfo.users.map((i) => {
        return i.cd_Matricula_GA;
      });
      defaultSelection = defaultSelection.sort((a, b) => (a > b ? 1 : -1));
      setSelection(defaultSelection);
      if (rows.length > 0) {
        let orderedRows = rows;
        for (let index = 0; index < defaultSelection.length; index++) {
          orderedRows = reorderArray(
            orderedRows.findIndex((i) => i.id === defaultSelection[index]),
            index,
            orderedRows
          );
        }
        setRows(orderedRows);
      }
    }
    if (type === "agencies") {
      let defaultSelection = agencyInfo.map((i) => {
        if (i && i.ativo) {
          return i.cd_Uniorg;
        }
      });
      let defaultObjects = userInfo.uniorgs.map((i) => {
        return {
          cd_Uniorg: i.cd_Uniorg,
        };
      });
      defaultSelection = defaultSelection.sort((a, b) => (a > b ? 1 : -1));
      setSelection(defaultSelection);
      setSelectedObjects(defaultObjects);
      if (rows.length > 0) {
        let orderedRows = rows;
        for (let index = 0; index < defaultSelection.length; index++) {
          orderedRows = reorderArray(
            orderedRows.findIndex((i) => i.id === defaultSelection[index]),
            index,
            orderedRows
          );
        }
        setRows(orderedRows);
      }
    }
  };

  const changeSelection = (model) => {
    if (type === "gAs" || type === "uniorgs" || type === "agencies") {
      let difference = [
        ...model.filter((d) => !selection.includes(d)),
        ...selection.filter((d) => !model.includes(d)),
      ];
      let selectedObject = rows.find((i) => i.id === difference[0]);
      if (selectedObject !== undefined) {
        if (selection.includes(difference[0]) && type === "uniorgs") {
          setModalDetails({
            modalType: "remove",
            newUserId: id,
            newUserName: userInfo.info.nm_Func || "",
            uniorg: selectedObject.id,
            uniorgName: selectedObject.name,
            userId: "",
            userName: "",
            cd_ga_interino: selectedObject.cd_ga_interino,
            cd_ga_principal: selectedObject.cd_ga_principal,
            nm_ga_interino: selectedObject.nm_ga_interino,
            nm_ga_principal: selectedObject.nm_ga_principal,
            dt_inc_int: selectedObject.dt_inc_int,
            dt_fnl_int: selectedObject.dt_fnl_int,
          });
          setOpen(true);
        } else if (selection.includes(difference[0]) && type === "gAs") {
          setModalDetails({
            modalType: "remove-ga",
            newUserId: id,
            newUserName: userInfo.info.nm_Func || "",
            uniorg: selectedObject.id,
            id: selectedObject.id,
            userName: selectedObject.name,
          });
          setOpen(true);
        } else if (type === "gAs") {
          setModalDetails({
            modalType: "add-common-ga",
            uniorg: selectedObject.id,
            newUserId: id,
            newUserName: userInfo.info.nm_Func || "",
            id: selectedObject.id,
            userName: selectedObject.name,
          });
          setOpen(true);
        } else if (selectedObject.user === 0 && type === "uniorgs") {
          setModalDetails({
            modalType: "add-common",
            newUserId: id,
            newUserName: userInfo.info.nm_Func || "",
            uniorg: selectedObject.id,
            uniorgName: selectedObject.name,
            userId: "",
            userName: "",
            cd_ga_interino: selectedObject.cd_ga_interino,
            cd_ga_principal: selectedObject.cd_ga_principal,
            nm_ga_interino: selectedObject.nm_ga_interino,
            nm_ga_principal: selectedObject.nm_ga_principal,
            dt_inc_int: selectedObject.dt_inc_int,
            dt_fnl_int: selectedObject.dt_fnl_int,
          });
          setOpen(true);
        } else if (selectedObject.user !== 0 && type === "uniorgs") {
          setModalDetails({
            modalType: "add-temporary",
            newUserId: id,
            newUserName: userInfo.info.nm_Func || "",
            uniorg: selectedObject.id,
            uniorgName: selectedObject.name,
            userId: selectedObject.user,
            userName: selectedObject.userName,
            cd_ga_interino: selectedObject.cd_ga_interino,
            cd_ga_principal: selectedObject.cd_ga_principal,
            nm_ga_interino: selectedObject.nm_ga_interino,
            nm_ga_principal: selectedObject.nm_ga_principal,
            dt_inc_int: selectedObject.dt_inc_int,
            dt_fnl_int: selectedObject.dt_fnl_int,
          });
          setOpen(true);
        } else if (!selectedObject.ativo && type === "agencies") {
          setModalDetails({
            modalType: "add-agency",
            newUserId: id,
            uniorg: selectedObject.id,
            uniorgName: selectedObject.name,
            userId: "",
            operacoes,
          });
          setOpen(true);
        } else if (selectedObject.ativo && type === "agencies") {
          setModalDetails({
            modalType: "remove-agency",
            newUserId: id,
            uniorg: selectedObject.id,
            id: selectedObject.id,
            uniorgName: selectedObject.name,
          });
          setOpen(true);
        }
      }
    }
  };

  const changeSelectionFromModal = (
    uniorg,
    temporary,
    dates,
    modalType,
    operation
  ) => {
    let dt_Inicial = "";
    let dt_Final = "";
    if (
      dates[0] !== "" ||
      dates[0] !== "NaN-NaN-NaN" ||
      dates[1] !== "" ||
      dates[1] !== "NaN-NaN-NaN" ||
      dates[0] === null ||
      dates[1] === null
    ) {
      dt_Inicial = dates[0];
      let day = dt_Inicial.substring(0, 2);
      let year = dt_Inicial.substring(6, 10);
      let month = dt_Inicial.substring(3, 5);

      dt_Inicial = `${year}/${month}/${day}`;

      dt_Final = dates[1];
      let finalDay = dt_Final.substring(0, 2);
      let finalYear = dt_Final.substring(6, 10);
      let finalMonth = dt_Final.substring(3, 5);

      dt_Final = `${finalYear}/${finalMonth}/${finalDay}`;

      if (dt_Inicial === "//" || dt_Final === "//") {
        dt_Inicial = "";
        dt_Final = "";
      }
    }
    if (
      modalType === "add-temporary" ||
      modalType === "add-common" ||
      modalType === "add-common-ga"
    ) {
      if (type === "gAs") {
        onChangeSupervisorSelection({
          cd_Matricula_Gestor: id,
          cd_Matriculas_GAs: [...selection, parseInt(uniorg)],
        });
      } else if (type === "uniorgs") {
        onChangeGASelection({
          matricula: parseInt(id),
          vinculos: [
            ...selectedObjects,
            {
              cd_Matricula: id,
              cd_Uniorg: uniorg,
              cd_Temporario: temporary ? 1 : 0,
              dt_Inicial: temporary ? dt_Inicial : "",
              dt_Final: temporary ? dt_Final : "",
            },
          ],
        });
      }
    } else if (modalType === "remove" || modalType === "remove-ga") {
      if (type === "gAs") {
        let selectionToSend = selection.filter((i) => i !== uniorg);
        onChangeSupervisorSelection({
          cd_Matricula_Gestor: id,
          cd_Matriculas_GAs: selectionToSend,
        });
      } else if (type === "uniorgs") {
        let selectionToSend = selectedObjects.filter(
          (i) => i.cd_Uniorg !== uniorg
        );
        onChangeGASelection({
          matricula: parseInt(id),
          vinculos: selectionToSend,
        });
      }
    } else if (type === "agencies") {
      let agencyUniorg = agencyInfo.filter((item) => item.cd_Uniorg === uniorg);
      let payload = {
        uniorg: agencyUniorg[0].cd_Uniorg,
        searchedUniorg,
        operacao: operation,
      };
      if (modalType === "add-agency") {
        onRegisterAgency(payload);
      } else if (modalType === "remove-agency") {
        onDeleteAgency(payload);
      }
    }
    setOpen(false);
  };

  const getPlaceholderClass = () => {
    if (searchText === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (searchText === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (searchText !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  const onSearchUniorg = (uniorg, searchedUniorg) => {
    setIsSearched(true);
    setSearchedUniorg(searchedUniorg);
    onGetAgencyInfo(uniorg);
  };

  const closeAlertModal = () => {
    setAlertId("");
    setAlertModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <ChangeUniorgLinkModal
        open={open}
        onClose={() => setOpen(!!!open)}
        details={modalDetails}
        onChangeMethod={changeSelectionFromModal}
      />
      {type === "agencies" ? (
        <SearchAgency onSearchMethod={onSearchUniorg} />
      ) : (
        <div className={classes.inputContainer}>
          <div className={classes.searchInput}>
            <input
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              type="text"
              value={searchText}
              onChange={(event) => requestSearch(event.target.value)}
              className={`${classes.input} dss-form-field__input`}
            />
            <span className={getPlaceholderClass()}>Buscar...</span>
            <div>
              {searchText !== "" ? (
                <div
                  onClick={() => {
                    setSearchText("");
                    requestSearch("");
                  }}
                >
                  <Icons icon="error" clickable isInputIcon />
                </div>
              ) : (
                <Icons icon="search" isInputIcon />
              )}
            </div>
          </div>
        </div>
      )}

      <DeleteAlertModal
        open={alertModalOpen}
        id={alertId}
        onClose={closeAlertModal}
      />

      <DataGrid
        sx={{
          fontFamily: "SantanderMicroText",
          border: "none",
          "& .MuiCheckbox-root svg path": {
            color: boston,
          },
          "& .header": {
            backgroundColor: lighterGrey,
            color: darkGrey,
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "SantanderMicroText",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-selectedRowCount": {
            opacity: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            display: "none",
          },

          "& .MuiDataGrid-row ": {
            background: `${white} !important`,
          },
          "& .MuiDataGrid-row.Mui-selected": {
            background: `${listSelectedBg} !important`,
          },
          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
        }}
        rows={rows}
        columns={cols}
        pageSize={10}
        checkboxSelection={showCheckbox}
        autoHeight
        disableColumnMenu
        onSelectionModelChange={(newSelectionModel) =>
          changeSelection(newSelectionModel)
        }
        selectionModel={selection}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        componentsProps={{ noRowsOverlay: { text: textNoRows } }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsers: () => dispatch(getUsers(0)),
    onGetUniorgList: () => dispatch(getUniorgList(0)),
    onGetGAInfo: (id) => dispatch(getGAInfo(id)),
    onGetSupervisorInfo: (id) => dispatch(getSupervisorInfo(id)),
    onChangeGASelection: (payload) => dispatch(changeGASelection(payload)),
    onChangeSupervisorSelection: (payload) =>
      dispatch(changeSupervisorSelection(payload)),
    onGetAgencyInfo: (uniorg) => dispatch(getAgencyInfo(uniorg)),
    onRegisterAgency: (payload) => dispatch(registerAgency(payload)),
    onDeleteAgency: (payload) => dispatch(deleteAgency(payload)),
    onGetAlertsFullList: (id) => dispatch(getAlertsFullList(id)),
    onDeleteAlert: (id) => dispatch(deleteAlert(id)),
  };
};

const mapStateToProps = (state) => {
  const {
    loading,
    userFormDetails,
    formOptions,
    userList,
    uniorgList,
    userInfo,
  } = state.profile;

  const { agencyInfo, agenciesLength, operacoes } = state.agency;
  const { alertList } = state.alerts;
  return {
    loading,
    userFormDetails,
    formOptions,
    userList,
    uniorgList,
    userInfo,
    agencyInfo,
    alertList,
    agenciesLength,
    operacoes,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(List);
