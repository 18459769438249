import React from "react";
import { makeStyles } from "@material-ui/styles";
import Lottie from "lottie-react";
import flameAnimation from "../assets/lottieAnimations/flame-loader.json";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "80px",
    height: "80px",
    bottom: "50%",
    right: "50%",
    // transform: "translate(-50%, -50%)",
    zIndex: "999999999999999",
  },
}));

const Loading = () => {
  const classes = useStyles();

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: flameAnimation,
  // };

  return (
    <div className={classes.root}>
      <Lottie animationData={flameAnimation} loop={true} autoPlay={true} />
    </div>
  );
};

export default Loading;