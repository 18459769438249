// @flow
import * as fromTypes from "../types/auth";

type State = {
  loading: boolean,
  isAuthenticated: boolean,
  profile: {},
};

export const initialState = {
  loading: false,
  isAuthenticated: false,
};

const updateUser = (state, { payload }) => ({
  ...state,
  ...payload,
  loading: false,
});


const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.AuthAction) => {
  switch (action.type) {
    case fromTypes.SIGN_IN:
    case fromTypes.SEND_PASS_REQUEST:
    case fromTypes.REGISTER_NEW_PASS:
      return setLoading(state, true);
    // Success
    case fromTypes.SIGN_IN_SUCCESS:
      return updateUser(state, action);
    case fromTypes.REGISTER_NEW_PASS_SUCCESS:
    case fromTypes.SEND_PASS_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.SIGN_IN_ERROR:
    case fromTypes.REGISTER_NEW_PASS_ERROR:
    case fromTypes.SEND_PASS_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
