import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { alertToast } from "../components/Toast";

import { Icons } from "./";

import { santander, darkGrey, white, black, inputSky } from "../theme/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  formContainer: {
    marginTop: "32px",
  },
  placeholder: {
    position: "absolute",
    pointerEvents: "none",
    left: "20px",
    top: "13px",
    background: white,
    color: darkGrey,
    opacity: "0.9",
  },
  focusAnimation: {
    animation: `$labelFocus 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  blurAnimation: {
    animation: `$labelBlur 200ms ${theme.transitions.easing.easeInOut} forwards`,
  },
  "@keyframes labelFocus": {
    to: {
      top: "-12px",
      fontSize: "0.9em",
      opacity: "1",
    },
  },
  "@keyframes labelBlur": {
    to: {
      left: "20px",
      top: "13px",
      opacity: "0.8",
    },
  },
  inputContainer: {
    padding: "0 32px 0px 32px",
  },
  searchInput: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  provisionalPlaceholder: {
    pointerEvents: "none",
    position: "absolute",
    left: "20px",
    background: white,
    color: darkGrey,
    top: "-12px",
  },
  input: {
    fontFamily: "SantanderMicroText",
    boxSizing: "border-box",
    width: "100%",
    height: "3.6em",
    border: `0.06em solid ${black}`,
    borderRadius: "0.250em",
    padding: "0em 1em",
    "&::placeholder": {
      color: black,
    },
    "&:hover": {
      borderColor: `${darkGrey} !important`,
    },
    "&:focusVisible": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&:focus": {
      borderColor: `${inputSky} !important`,
      outline: "0",
    },
    "&::-ms-reveal": {
      display: "none",
    },
    "&::-ms-clear": {
      display: "none",
    },
  },
}));

const SearchAgency = ({ onSearchMethod }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const getPlaceholderClass = () => {
    if (searchText === "" && isFocused)
      return `${classes.placeholder} ${classes.focusAnimation}`;
    else if (searchText === "" && !isFocused)
      return `${classes.provisionalPlaceholder} ${classes.blurAnimation}`;
    else if (searchText !== "")
      return `${classes.placeholder} ${classes.focusAnimation}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let uniorg = event.target.uniorgField.value;

    uniorg.length < 4
      ? alertToast("error", "A uniorg deve ter no mínimo 4 caracteres")
      : onSearchMethod(parseInt(uniorg), uniorg);
  };

  return (
    <div className={classes.root}>
      <form className={classes.formContainer} onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.searchInput}>
            <input
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              type="text"
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
              className={`${classes.input} dss-form-field__input`}
              name="uniorgField"
            />
            <span className={getPlaceholderClass()}>Digite a Uniorg...</span>
            <div>
              {searchText !== "" ? (
                <div
                  onClick={() => {
                    setSearchText("");
                  }}
                >
                  <Icons icon="error" clickable isInputIcon />
                </div>
              ) : (
                <Icons icon="search" isInputIcon />
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchAgency;
