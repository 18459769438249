import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import { Loading, MainMenu, List, UserInfo } from "../components";
import {
  lighterGrey,
  darkGrey,
  santander,
  santander20,
  white,
  boston,
} from "../theme/colors";
import { getUserLvl } from "../store/services/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
    },
  },
  flame: {
    display: "block",
    margin: "auto",
    padding: "32px 16px",
  },
  registerPanel: {
    background: lighterGrey,
    padding: "40px 0px",
    minHeight: "100vh",
  },
  titleContainer: {
    padding: "0px 40px",
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "28px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "14px !important",
    color: `${darkGrey} !important`,
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
    padding: "40px 32px 0px 128px",
  },
}));

const GAList = ({ loading, match }) => {
  const classes = useStyles();

  const getType = () => {
    return match.params.type === "agencia" ? "uniorgs" : "gAs";
  };

  const getCurrentDateToFile = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    today = dd + "_" + mm + "_" + yyyy;
    return today;
  };

  useEffect(() => {
    if (getUserLvl() === "1") window.location.href = "/home";
  }, []);

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      <Grid container>
        <Grid item md={1}>
          <div className={classes.flame}>
            <MainMenu />
          </div>
        </Grid>
        <Grid item md={7} className={classes.registerPanel}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Carterização</Typography>
          </div>

          <List showCheckbox={true} type={getType()} id={match?.params.id} />
        </Grid>
        <Grid item md={4}>
          <UserInfo id={match?.params?.id} />
          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              onClick={() => window.history.back()}
              sx={{
                height: "48px",
                fontFamily: "SantanderMicroText ",
                background: white,
                textTransform: "none ",
                color: `${santander} `,
                border: `1px solid ${santander} `,
                display: "block",
                fontSize: "16px ",
                marginRight: "16px",
                "&:hover": {
                  background: santander20,
                  color: `${santander} `,
                },
              }}
            >
              Voltar
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading } = state.auth;
  return { loading, match: ownProps?.match };
};

export default withRouter(compose(connect(mapStateToProps))(GAList));
