import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { makeStyles } from "@material-ui/styles";
import { Typography, Tooltip, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { addExoAction, addExoComment } from "../../store/actions/exo";
import { getUser } from "../../store/services/auth";
import { getLastSearchedUser } from "../../store/services/search";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { decrypt } from "../../store/services/crypto";

import {
  ChartContainer,
  Header,
  IndicatorList,
  Loading,
  ChartATMSkeleton,
  Icons,
  Checked,
  Unchecked,
  CommentDrawer,
  WarningExportModal,
} from "../../components";

import {
  darkGrey,
  santander10,
  lighterGrey,
  turquoise,
  santander,
  white,
  bulletinGrey,
  green,
  blue10,
  santander20,
  santander70,
  black,
  lightGrey,
  darkerGrey2,
  mediumGrey,
  yellow,
  mustard,
  turquoise20,
  beige,
} from "../../theme/colors";
import { setIndicatorType } from "../../utils/indicators";

import interno from "../../assets/img/confidencial_restrito.svg";
import { addIndicatorComment } from "../../store/actions/bulletin";

const mockDataConcMes = [15, 30, 35, 20, 20, 15];
const mockDataConcLeg = [20, 50, 40, 45, 50, 56];
const mockDataMes = [30, 26, 34, 35, 30, 63];
const mockDataLegado = [90, 100, 90, 85, 90, 94];
const mockDataTotal = [90, 100, 90, 85, 90, 100];
const mockDataLineLabel = [
  "Nov/21",
  "Dez/21",
  "Jan/22",
  "Fev/22",
  "Mar/22",
  "Abr/22",
];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    position: "relative",
    backgroundColor: white,
    [theme.breakpoints.up("md")]: {
      maxWidth: "1280px",
      display: "block",
      margin: "auto",
      "& header": {
        backgroundColor: white,
        padding: "2.188em 1em 0em 1em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: "2.500em",
        [theme.breakpoints.up("lg")]: {
          flexDirection: "row",
        },
      },
    },
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 40px",
    [theme.breakpoints.up("xl")]: {
      margin: "8px 0",
    },
  },
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  uptime: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "18px !important",
    color: `${blue10} !important`,
  },
  chartsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    height: "300px",
    padding: "16px 24px",
    marginBottom: "36px",
    [theme.breakpoints.up("xl")]: {
      padding: "16px 24px",
    },
  },
  lineChartsContainer: {
    width: "820px",
    background: white,
    borderRadius: "4px",
    boxShadow: "0px 3px 6px #00000029",
  },
  lineChartContainer: {
    padding: "0 16px 0 16px",
  },
  pieChartContainer: {
    display: "flex",
    borderRadius: "4px",
    background: white,
    boxShadow: "0px 3px 6px #00000029",
  },
  overviewDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "0 0 0 18px",
    width: "180px",
  },
  overviewDataChart: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${bulletinGrey} !important`,
  },
  bar: {
    width: "4px",
    height: "100%",
    borderRadius: "4px 0px 0px 4px",
  },
  uncheckBall: {
    width: "20px",
    height: "20px",
  },
  checkBall: {
    width: "20px",
    height: "20px",
  },
  editBtn: {
    textAlign: "center !important",
    minWidth: "0px !important",
  },
  inputContainer: {
    position: "relative",
    fontFamily: "SantanderMicroText",
  },
  pernumpInput: {
    fontFamily: "SantanderMicroText",
    height: "40px",
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  doughnutChart: {
    paddingBottom: "5px",
  },
  legendContainer: {
    width: "100%",
    height: "50px",
  },
  legendLine: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  legend: {
    fontFamily: "SantanderHeadline !important",
    fontSize: "12px !important",
    color: `${darkGrey} !important`,
  },
  colorLegendSquare: {
    width: "8px",
    height: "8px",
    borderRadius: "2.5px 0",
  },
  pathContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  fixedBottom: {
    cursor: "pointer",
    background: white,
    position: "fixed",
    width: "100%",
    bottom: "0%",
    fontFamily: "SantanderMicroText",
    color: bulletinGrey,
    fontWeight: "bold !important",
    fontSize: "0.875em",
    display: "flex",
    flexDirection: "column",
    padding: "1em",
    "& img": {
      marginRight: "1em",
    },
  },
  docForExport: {
    height: "54px",
  },
  docText: {
    display: "block",
    color: black,
    textAlign: "left",
    padding: "0.2em",
    width: "65%",
    fontWeight: "400 !important",
  },
  confidentialWarn: {
    fontFamily: "SantanderMicroText !important",
    color: black,
    textAlign: "center !important",
  },

  listIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px !important",
  },
  placeholderIcon: {
    direction: "ltr",
    fontFamily: "SantanderIcons !important",
    fontSize: "24px",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontStyle: "normal",
    fontWeight: "400 !important",
    height: "24px",
    letterSpacing: "normal",
    lineHeight: "24px",
    textAlign: "center",
    textTransform: "none",
    whiteSpace: "nowrap",
    width: "24px !important",
    marginLeft: "10px",
    position: "absolute",
    right: "2%",
    top: "30%",
    background: white,
    zIndex: "99",
  },
}));

const convertToDate = (dateString) => {
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
};

const EXO = ({
  match,
  line,
  loading,
  onAddExoAction,
  onAddExoComment,
  table,
  gaInfo,
  doughnut,
}) => {
  const classes = useStyles();
  const [concluidoMesData, setConcluidoMesData] = useState([]);
  const [concluidoLegadoData, setConcluidoLegadoData] = useState([]);
  const [mesData, setMesData] = useState([]);
  const [legadoData, setLegadoData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [concluidoTotalData, setConcluidoTotalData] = useState([]);
  const [periodoData, setPeriodoData] = useState([]);
  const [isResolve, setIsResolve] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [backupRows, setBackupRows] = useState([]);
  const [docInputValue, setDocInputValue] = useState("");
  const [contAGInputValue, setContAGInputValue] = useState("");
  const [propInputValue, setPropInputValue] = useState("");
  const [pernumperInputValue, setPernumperInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [issueDetails, setIssueDetails] = useState({
    equipment: "",
    uniorg: "",
    event: "",
  });
  const [open, setOpen] = useState(false);
  const [showDocText, setShowDocText] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [exoSortModel, setExoSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const [hideState, setHideState] = useState(true);
  const [rowsCopy, setRowsCopy] = useState([]);

  useEffect(() => {
    let list = [];
    table.map((i, index) => {
      list = [
        ...list,
        {
          id: index,
          cdIndicador: i.cd_indicador,
          uniorg: i.cd_Uniorg,
          urgStatus: i.st_Pendencia,
          action: i.bl_Atuacao,
          finalProduct: i.ds_Pendencia,
          date: convertToDate(i.dt_Pendencia),
          documento: i.nu_Doc_Mascara,
          contAgConta: i.nu_contrato_ag_conta_mascara,
          proposta: i.nu_proposta_mascara,
          docCripto: i.nu_Doc_Cripto,
          agCripto: i.nu_contrato_ag_conta_cripto,
          matCripto: i.nu_proposta_cripto,
          legado: i.bl_Legado,
          tpPerson: i.tp_Cliente,
          docTool: "",
          agTool: "",
          matTool: "",
          id_exo: i.id_Exo,
          pernumper: i.nu_Penumper || "",
        },
      ];
    });

    setRows(list);
    setRowsCopy(list);
    setBackupRows(list);
  }, [table, gaInfo]);

  useEffect(() => {
    setHasLoaded(false);
    if (doughnut) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    } else {
      setHasLoaded(false);
    }
  }, [doughnut, line.length]);

  useEffect(() => {
    let concluidoMesData = [];
    let concluidoLegadoData = [];
    let mesData = [];
    let legadoData = [];
    let totalData = [];
    let concluidoTotalData = [];
    let periodoData = [];

    line &&
      line.map((i) => {
        concluidoMesData.push(i.qtde_Mes_Concluido);
        concluidoLegadoData.push(i.qtde_Leg_Concluido);
        mesData.push(i.qtde_Mes_Aberto);
        legadoData.push(i.qtde_Leg_Aberto);
        totalData.push(i.qtde_Total);
        concluidoTotalData.push(i.qtde_Concluido);
        periodoData.push(i.dt_Abrv);
      });

    setConcluidoMesData(concluidoMesData);
    setConcluidoLegadoData(concluidoMesData);
    setMesData(mesData);
    setLegadoData(legadoData);
    setTotalData(totalData);
    setConcluidoTotalData(concluidoTotalData);
    setPeriodoData(periodoData);
  }, [line]);

  useEffect(() => {
    doughnut.perc_Meta < 100 ? setIsResolve(false) : setIsResolve(true);
  }, [doughnut]);

  const doughnutLabels = [
    `Legado (${doughnut.perc_Legado}%)`,
    `Mês (${doughnut.perc_Mes}%)`,
    `Trabalhados Legado (${doughnut.perc_Trab_Legado}%)`,
    `Trabalhados Mês (${doughnut.perc_Trab_Mes}%)`,
  ];

  const doughnutChartConfig = [
    {
      data: [doughnut.qtde_Legado, doughnut.qtde_Mes],
      backgroundColor: [santander, yellow],
      cutout: 80,
    },
  ];

  const multiChartTypeConfig = [
    {
      type: "line",
      label: `Concluido Mês`,
      data: concluidoMesData,
      borderColor: lightGrey,
      backgroundColor: lightGrey,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Concluido Legado`,
      data: concluidoLegadoData,
      borderColor: darkerGrey2,
      backgroundColor: darkerGrey2,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Mês`,
      data: mesData,
      borderColor: mustard,
      backgroundColor: mustard,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },
    {
      type: "line",
      label: `Legado`,
      data: legadoData,
      borderColor: santander,
      backgroundColor: santander,
      tension: 0.5,
      borderWidth: 1.5,
      pointBackgroundColor: white,
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      radius: 5,
      hoverRadius: 5,
    },

    {
      type: "bar",
      label: `Total`,
      data: totalData,
      borderColor: santander20,
      backgroundColor: santander20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
    {
      type: "bar",
      label: `Concluido`,
      data: concluidoTotalData,
      borderColor: turquoise20,
      backgroundColor: turquoise20,
      borderWidth: 0.5,
      maxBarThickness: 40,
      borderRadius: {
        topLeft: 8,
        topRight: 0,
        bottomLeft: 0,
        bottomRight: 8,
      },
      borderSkipped: false,
    },
  ];

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const requestSearch = (searchValue, inputField) => {
    if (searchValue === "") {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "contAgConta":
          setContAGInputValue(searchValue);
          break;
        case "proposta":
          setPropInputValue(searchValue);
          break;
        case "pernumper":
          setPernumperInputValue(searchValue);
          break;
      }
      setRows(backupRows);
    } else {
      switch (inputField) {
        case "documento":
          setDocInputValue(searchValue);
          break;
        case "contAgConta":
          setContAGInputValue(searchValue);
          break;
        case "proposta":
          setPropInputValue(searchValue);
          break;
        case "pernumper":
          setPernumperInputValue(searchValue);
          break;
      }

      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = backupRows.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[inputField]?.toString());
        });
      });

      setRows(filteredRows);
    }
  };

  const openDrawer = (selectedIssue) => {
    setIssueDetails(selectedIssue);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setIssueDetails({
      equipment: "",
      uniorg: "",
    });
  };

  const getUrgencyColor = (legado, action) => {
    if (action) return lightGrey;
    else if (legado) return santander20;
    else return beige;
  };

  const convertDateToBR = (data) => {
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString();
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const letterRegExp = /[a-zA-Z]/g;

  const cols = [
    {
      field: "uniorg",
      headerName: "UNIORG",
      width: 90,
      headerClassName: "header",
    },
    {
      field: "date",
      headerName: "Data",
      width: 90,
      headerClassName: "header",
      renderCell: (params) => <>{convertDateToBR(params.row.date)}</>,
    },
    {
      field: "documento",
      headerName: "CPF/CNPJ",
      width: 180,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={docInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(e.target.value.replace(/[^\d]/, ""), "documento")
            }
            placeholder="CPF/CNPJ"
            className={classes.pernumpInput}
          />
          <Icons
            icon="search"
            // isListInputIcon
            customClass={classes.placeholderIcon}
          />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.docTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o CPF/CNPJ</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.docTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.docCripto);
                showValue(params.id, "docTool", "docCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "docTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "docTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "pernumper",
      headerName: "Pernumper",
      width: 160,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={pernumperInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => requestSearch(e.target.value.replace(/[^\d]/, ""), "pernumper")}
            placeholder="Pernumper"
            className={classes.pernumpInput}
          />
          <Icons icon="search" customClass={classes.placeholderIcon} />
        </div>
      ),
    },
    {
      field: "contAgConta",
      headerName: "Contrato/AG_Conta",
      width: 160,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={contAGInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(e.target.value.replace(/[^\d]/, ""), "contAgConta")
            }
            placeholder="Contrato/AG_Conta"
            className={classes.pernumpInput}
          />
          <Icons
            icon="search"
            // isListInputIcon
            customClass={classes.placeholderIcon}
          />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.agTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar o Contrato/AG_Conta</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.agTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.agCripto);
                showValue(params.id, "agTool", "agCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "agTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "agTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      width: 160,
      headerClassName: "header",
      sortable: false,
      renderHeader: (params) => (
        <div className={classes.inputContainer}>
          <input
            type="text"
            value={propInputValue}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) =>
              requestSearch(e.target.value.replace(/[^\d]/, ""), "proposta")
            }
            placeholder="Matricula/Sigla"
            className={classes.pernumpInput}
          />
          <Icons
            icon="search"
            style={{ background: "red" }}
            // isListInputIcon
            customClass={classes.placeholderIcon}
          />
        </div>
      ),
      renderCell: (params) => (
        <>
          <LightTooltip
            title={
              params.row.matTool == "" ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <span>Clique e segure para copiar a Matricula/Sigla</span>
                  <Icons icon={"copy"} customClass={classes.listIcon} />
                </div>
              ) : (
                <>
                  <span>
                    {params.row.matTool} <br></br>
                  </span>
                  <span>(item copiado)</span>
                </>
              )
            }
          >
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onMouseDown={() => {
                copyValue(params.row.matCripto);
                showValue(params.id, "matTool", "matCripto");
              }}
              onMouseUp={() => {
                hideValue(params.id, "matTool");
              }}
              onMouseLeave={() => {
                hideValue(params.id, "matTool");
              }}
            >
              {params.value}
            </span>
          </LightTooltip>
        </>
      ),
    },
    {
      field: "finalProduct",
      headerName: "Produto Final",
      width: 160,
      headerClassName: "header",
    },
    {
      field: "urgStatus",
      headerName: "Status",
      width: 170,
      headerClassName: "header",
      renderCell: (params) => (
        <div
          style={{
            background: getUrgencyColor(params.row.legado, params.row.action),
            display: "block",
            margin: "auto",
            width: "100%",
            padding: "14px 0px 14px 0px",
            textAlign: "center",
            fontSize: params.row.urgStatus.length > 16 ? "12px" : "14px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {params.row.urgStatus}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Atuação",
      width: 110,
      headerClassName: "header",
      renderCell: (params) => (
        <div className={classes.actionBtns}>
          <Button
            className={classes.editBtn}
            onClick={() =>
              openDrawer({
                aux: params.row.id_exo,
                ind: params.row.cdIndicador,
                uniorg: params.row.uniorg,
                atuacao: params.row.action ? 1 : 0,
              })
            }
          >
            <Icons icon="sign-up" />
          </Button>
          <Button
            className={classes.editBtn}
            onClick={() => {
              onAddExoAction({
                aux: params.row.id_exo,
                ind: params.row.cdIndicador,
                uniorg: params.row.uniorg,
                atuacao: !params.row.action ? 1 : 0,
              });
            }}
          >
            {!params.row.action ? (
              <div className={classes.uncheckBall}>
                <Unchecked />
              </div>
            ) : (
              <div className={classes.checkBall}>
                <Checked />
              </div>
            )}
          </Button>
        </div>
      ),
    },
  ];
  const copyValue = async (value) => {
    navigator.clipboard.writeText(await decrypt(value));
  };

  const showValue = async (id, type, cryptoType) => {
    let newDocObj = {};
    newDocObj[type] = await decrypt(rows.find((x) => x.id === id)[cryptoType]);

    setRows((prevRows) =>
      prevRows.map((row) => {
        return row.id === id ? { ...row, ...newDocObj } : row;
      })
    );

    setHideState(false);
  };

  const hideValue = (id, type) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [type]: "" } : row))
    );

    setHideState(true);
  };

  const getPreviousLink = () => {
    if (document.referrer.includes("boletim")) {
      return { name: "Boletim Diário", url: "/boletim" };
    } else if (document.referrer.includes("checklist")) {
      return { name: "Checklist", url: "/checklist" };
    } else if (document.referrer.includes("home")) {
      return { name: "Home", url: "/home" };
    }
  };

  const customPieConfigs = {
    chartConfig: doughnutChartConfig,
    chartType: "doughnut",
    indicator: true,
    doughnutCenterLabel: doughnut.qtde_Legado + doughnut.qtde_Mes,
    chartLabels: doughnutLabels,
    withLegend: false,
    withLegend: false,
    withLines: false,
    withStacked: false,
    indicatorGoal: 100,
    isEXO: true,
    customHeight: 1.69,
    customPaddingBottom: "30px",
  };

  const customLineConfigs = {
    title: "Resultado mensal",
    chartConfig: multiChartTypeConfig,
    chartType: "line",
    indicator: true,
    chartLabels: periodoData,
    isMulti: true,
    withLines: true,
    withStacked: false,
    withPercentage: false,
    reverseLegend: false,
  };

  return (
    <div className={classes.root}>
      <WarningExportModal
        open={openModal}
        onClose={() => setOpenModal(!!!openModal)}
        data={table}
        type={"EXO"}
        modalType="confidencial"
      />
      <Header isEXO={true} url={match} accordionIsExpanded={isExpanded} />
      <Accordion
        onChange={(e, expanded) => {
          expanded ? setIsExpanded(true) : setIsExpanded(false);
        }}
        sx={{
          border: "none",
          marginBottom: "36px",
          boxShadow: "none",
          position: "static",
        }}
      >
        <div className={classes.headerContainer}>
          <div className={classes.pathContainer}>
            {document.referrer && getPreviousLink() ? (
              <>
                <Typography
                  className={classes.title}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    setIndicatorType({
                      ...match.params,
                    });
                    window.location.href = getPreviousLink().url;
                  }}
                >
                  {getPreviousLink().name}
                </Typography>
                <Icons icon="chevron-right-1px" />
              </>
            ) : null}

            <Typography className={classes.title}>Exo (Legados/Mês)</Typography>
          </div>
          <AccordionSummary
            sx={{
              width: "268px",
              height: "30px",
              border: `1px solid ${mediumGrey}`,
              borderRadius: "4px",
              minHeight: "30px",
              "&.Mui-expanded": {
                minHeight: "30px",
              },
            }}
          >
            {isExpanded ? (
              <>
                <Icons icon="visibility-off" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                    whiteSpace: "nowrap",
                  }}
                >
                  Clique para esconder os gráficos
                </Typography>
              </>
            ) : (
              <>
                <Icons icon="visibility-on" />
                <Typography
                  sx={{
                    fontFamily: "SantanderHeadline !important",
                    fontSize: "16px !important",
                    color: `${mediumGrey} !important`,
                    marginLeft: "15px",
                    textDecoration: "underline",
                  }}
                >
                  Clique para ver os gráficos
                </Typography>
              </>
            )}
          </AccordionSummary>
          <div style={{ display: "flex", alignItems: "center" }}>
            {table.length > 0 ? (
              <>
                <Typography className={classes.uptime}>
                  Dados referentes a: {table[0].dt_Processamento}
                </Typography>
                <Tooltip placement="bottom" title={"Exportar"}>
                  <Button
                    onClick={() => setOpenModal(true)}
                    sx={{
                      "&:hover": {
                        background: "none",
                        color: "none",
                      },
                    }}
                  >
                    <Icons icon="export-xls" />
                  </Button>
                </Tooltip>
              </>
            ) : null}
          </div>
        </div>

        {hasLoaded && !loading ? (
          <AccordionDetails>
            <div
              className={classes.chartsContainer}
              style={{ backgroundColor: isResolve ? lighterGrey : santander10 }}
            >
              <div className={classes.pieChartContainer}>
                <div
                  className={classes.bar}
                  style={{ backgroundColor: isResolve ? green : santander }}
                ></div>
                <div className={classes.overviewDataContainer}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: "SantanderHeadline !important",
                        fontWeight: "bold !important",
                        fontSize: "24px !important",
                        color: `${darkGrey} !important`,
                      }}
                    >
                      Situação atual
                    </Typography>
                    <Typography className={classes.overviewDataChart}>
                      Analista: {doughnut.qtde_Analista}
                    </Typography>
                  </div>
                  <div className={classes.legendContainer}>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: santander }}
                      ></div>
                      <Typography className={classes.legend}>
                        Legado: {doughnut.qtde_Legado} ({doughnut.perc_Legado}%)
                      </Typography>
                    </div>
                    <div className={classes.legendLine}>
                      <div
                        className={classes.colorLegendSquare}
                        style={{ backgroundColor: yellow }}
                      ></div>
                      <Typography className={classes.legend}>
                        Mês: {doughnut.qtde_Mes} ({doughnut.perc_Mes}%)
                      </Typography>
                    </div>
                  </div>
                </div>

                <ChartContainer customConfigs={customPieConfigs} />
              </div>
              <div className={classes.lineChartsContainer}>
                <div className={classes.lineChartContainer}>
                  <ChartContainer customConfigs={customLineConfigs} />
                </div>
              </div>
            </div>
          </AccordionDetails>
        ) : (
          <>
            <ChartATMSkeleton />
            <Loading />
          </>
        )}
      </Accordion>
      <CommentDrawer
        type="exo"
        shouldOpen={open}
        onCloseMethod={closeDrawer}
        issue={issueDetails}
      />
      {table[0] !== "forbidden" ? (
        <>
          {loading ? <Loading /> : null}
          <IndicatorList
            rows={rows}
            cols={cols}
            getStatusBGMethod={getUrgencyColor}
            sortModel={exoSortModel}
            changeSortModel={setExoSortModel}
            type="exo"
          />
        </>
      ) : (
        <Typography className={classes.confidentialWarn}>
          A tabela com dados analíticos do EXO possui dados sensíveis que são
          mostrados apenas à quem tem a(s) agência(s) na carteira.
        </Typography>
      )}
      <div className={classes.docForExport}></div>
      <div
        className={classes.fixedBottom}
        onClick={() => setShowDocText(!!!showDocText)}
      >
        <div>
          <img src={interno} alt="" />
          Documento Confidencial
        </div>
        {showDocText ? (
          <p className={classes.docText}>
            Qualquer dado classificado como regulamentos externos de proceção de
            dados, como dados pessoais, dados de RH, dados não financeiros de
            Clientes, atendimento, interações com Clientes e etc.
          </p>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddExoAction: (payload) => dispatch(addIndicatorComment(payload)),
    onAddExoComment: (payload) => dispatch(addExoComment(payload)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { gaInfo } = state.bulletin;
  const { table, doughnut, loading, line } = state.exo;

  return {
    match: ownProps?.match,
    line,
    loading,
    table,
    gaInfo,
    doughnut,
  };
};

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(EXO)
);
