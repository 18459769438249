import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@mui/material";
import { santander, darkGrey, mediumGrey, lightGrey } from "../theme/colors";
import { Loading } from "../components";
import Icons from "./Icons";
import {
  getGAInfo,
  getSupervisorInfo,
  getUserInfo,
} from "../store/actions/profile";
import { getUser } from "../store/services/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 0px 0px 128px",
    marginBottom: "32px",
  },
  title: {
    fontFamily: "SantanderMicroText !important",
    color: `${santander} !important`,
    fontWeight: "bold !important",
    fontSize: "18px !important",
  },
  hr: {
    border: `1px solid ${santander}`,
    marginBottom: "20px",
    marginRight: "16px",
  },
  subtitle: {
    fontFamily: "SantanderMicroText !important",
    color: `${darkGrey} !important`,
    fontWeight: "bold !important",
    fontSize: "16px !important",
    "& span": {
      fontWeight: "400 !important",
    },
  },
  expand: {
    fontFamily: "SantanderMicroText",
    color: lightGrey,
    fontSize: "14px",
    padding: "5px 0px",
    margin: "10px 15px 8px 0px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: `2px solid ${mediumGrey}`,
    "& span": {
      marginRight: "4px",
    },
  },
  agenciesContainer: {
    marginBottom: "20px",
  },
}));

const UserInfo = ({
  onGetSupervisorInfo,
  onGetGAInfo,
  onGetUserInfo,
  userInfo,
  loading,
  id,
}) => {
  const classes = useStyles();
  const [showMoreUsers, setShowMoreUsers] = useState(false);
  const [showMoreAgencies, setShowMoreAgencies] = useState(false);

  useEffect(() => {
    if (!id) {
      onGetGAInfo(getUser());
      onGetSupervisorInfo(getUser());
      onGetUserInfo(getUser());
    } else {
      onGetGAInfo(id);
      onGetSupervisorInfo(id);
      onGetUserInfo(id);
    }
  }, [onGetSupervisorInfo, onGetGAInfo]);

  return (
    <div className={classes.root}>
      {loading ? <Loading /> : null}
      {userInfo?.info?.nm_Func ? (
        <>
          <Typography className={classes.title}>
            {userInfo.info.nm_Func}
          </Typography>
          <hr className={classes.hr} />
          <Typography className={classes.subtitle}>
            {userInfo.info.nm_Cargo}
          </Typography>
          <br />

          {userInfo.uniorgs.length > 0 ? (
            <>
              <Typography className={classes.subtitle}>
                Agências ({userInfo.uniorgs.length}):
              </Typography>
              {userInfo.uniorgs[0].cd_Uniorg === 0 ||
              userInfo.uniorgs.length === 0 ? (
                <>
                  <Typography className={classes.subtitle}>
                    <span>
                      Não há agências. Faça uma busca por palavra-chave,
                      selecione e clique em atualizar para que seja associada.
                    </span>
                  </Typography>
                </>
              ) : (
                <div className={classes.agenciesContainer}>
                  {userInfo.uniorgs.map((i, index) => {
                    return (
                      <>
                        {index < 5 ? (
                          <Typography className={classes.subtitle} key={index}>
                            <span>
                              {i.cd_Uniorg} - {i.nm_Agencia}
                            </span>
                          </Typography>
                        ) : (
                          <>
                            {showMoreAgencies ? (
                              <Typography
                                className={classes.subtitle}
                                key={index}
                              >
                                <span>
                                  {i.cd_Uniorg} - {i.nm_Agencia}
                                </span>
                              </Typography>
                            ) : null}
                          </>
                        )}
                      </>
                    );
                  })}
                  {userInfo.uniorgs.length > 5 ? (
                    <div
                      className={classes.expand}
                      onClick={() => setShowMoreAgencies(!!!showMoreAgencies)}
                    >
                      {showMoreAgencies ? (
                        <>Ver menos</>
                      ) : (
                        <>
                          <Icons
                            icon="add-in-a-circle"
                            customColor={lightGrey}
                          />
                          Ver mais {userInfo.uniorgs.length - 5} agências
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </>
          ) : null}
          {userInfo.users.length > 0 ? (
            <>
              <Typography className={classes.subtitle}>
                Usuários ({userInfo.users.length}):
              </Typography>
              {userInfo.users.length === 0 ? (
                <>
                  <Typography className={classes.subtitle}>
                    <span>
                      Não existem usuários associados. Faça uma busca por
                      palavra-chave, selecione e clique em atualizar para que
                      seja associado.
                    </span>
                  </Typography>
                </>
              ) : (
                <>
                  {userInfo.users.map((i, index) => {
                    return (
                      <>
                        {index < 5 ? (
                          <Typography className={classes.subtitle} key={index}>
                            <span>
                              {i.cd_Matricula_GA} - {i.nm_GA}
                            </span>
                          </Typography>
                        ) : (
                          <>
                            {showMoreUsers ? (
                              <Typography
                                className={classes.subtitle}
                                key={index}
                              >
                                <span>
                                  {i.cd_Matricula_GA} - {i.nm_GA}
                                </span>
                              </Typography>
                            ) : null}
                          </>
                        )}
                      </>
                    );
                  })}
                  {userInfo.users.length > 5 ? (
                    <div
                      className={classes.expand}
                      onClick={() => setShowMoreUsers(!!!showMoreUsers)}
                    >
                      {showMoreUsers ? (
                        <>Ver menos</>
                      ) : (
                        <>
                          <Icons
                            icon="add-in-a-circle"
                            customColor={lightGrey}
                          />
                          Ver mais {userInfo.users.length - 5} usuários
                        </>
                      )}
                    </div>
                  ) : null}
                </>
              )}
            </>
          ) : null}
          <br />
          <Typography className={classes.subtitle}>
            Gestor: <span>{userInfo?.info?.nm_Gestor}</span>
          </Typography>
        </>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onGetGAInfo: (id) => dispatch(getGAInfo(id)),
    onGetSupervisorInfo: (id) => dispatch(getSupervisorInfo(id)),
  };
};

const mapStateToProps = (state) => {
  const { loading, userInfo } = state.profile;
  return { loading, userInfo };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(UserInfo);
