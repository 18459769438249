import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import { routerMiddleware } from "react-router-redux";
import { logger } from "redux-logger";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(logger, ...middleware));
  }
  return applyMiddleware(...middleware);
};

const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware, routerMiddleware(history)])
  );
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

export { configureStore, history };
