import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Typography, Button, Box } from "@mui/material";
import {
  santander,
  white,
  santander20,
  boston,
  darkGrey,
  black,
  toastError,
} from "../theme/colors";
import Icons from "./Icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "SantanderHeadline !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    color: `${darkGrey} !important`,
  },
  subtitle: {
    fontFamily: "SantanderMicrotext !important",
    fontSize: "16px !important",
    color: `${darkGrey} !important`,

    "& span": {
      fontWeight: "bold !important",
      color: black,
    },
  },
  btnContainer: {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const UrgentAlertModal = ({ open, title, date, msg, onClose }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: white,
          p: 4,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "start",
        }}
      >
        <div>
          <Icons icon="toast-error" customColor={toastError} />
        </div>
        <div>
          <Typography className={classes.title}>
            {title} - {date}
          </Typography>
          <Typography className={classes.subtitle}>{msg}</Typography>
          <div className={classes.btnContainer}>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                height: "48px",
                fontFamily: "SantanderMicroText ",
                background: white,
                textTransform: "none ",
                color: `${santander} `,
                border: `1px solid ${santander} `,
                display: "block",
                fontSize: "16px ",
                "&:hover": {
                  background: santander20,
                  color: `${santander} `,
                },
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UrgentAlertModal;
