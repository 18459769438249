// @flow
import * as fromTypes from "../types/bulletin";

type State = {
  loading: boolean,
  bulletin: Object,
  filters: Object,
  gaInfo: Array,
  exportChecked: Object,
  actionCategories: Array,
  indicatorComments: Array,
};

export const initialState = {
  loading: false,
  bulletin: {},
  filters: {},
  gaInfo: [],
  exportChecked: {
    reft: true,
  },
  actionCategories: [],
  indicatorComments: [],
  status: "",
};

const updateFilter = (state, { payload }) => ({
  ...state,
  filters: payload,
});

const updateBulletin = (state, { payload }) => ({
  ...state,
  bulletin: payload,
  loading: false,
});

const updateGaInfo = (state, { payload }) => ({
  ...state,
  gaInfo: payload,
});

const setLoading = (state, loading, action) => ({
  ...state,
  loading,
  status: action,
});

const setChecked = (state, { payload }) => ({
  ...state,
  exportChecked: payload,
});

const setComments = (state, { payload }) => ({
  ...state,
  indicatorComments: payload,
  loading: false,
});

const setCategories = (state, { payload }) => ({
  ...state,
  actionCategories: payload,
  loading: false,
});

const reducer = (
  state: State = initialState,
  action: fromTypes.BulletinAction
) => {
  switch (action.type) {
    case fromTypes.GET_BULLETIN:
    case fromTypes.GET_FILTERS:
    case fromTypes.GET_BULLETIN_URGENCY:
    case fromTypes.GET_BULLETIN_SEARCH:
    case fromTypes.GET_ACTION_CATEGORIES:
    case fromTypes.GET_INDICATOR_COMMENTS:
    case fromTypes.ADD_INDICATOR_COMMENT:
      return setLoading(state, true, action.type);
    // Success
    case fromTypes.GET_BULLETIN_SUCCESS:
    case fromTypes.GET_BULLETIN_URGENCY_SUCCESS:
    case fromTypes.GET_BULLETIN_SEARCH_SUCCESS:
      return updateBulletin(state, action);
    case fromTypes.GET_FILTERS_SUCCESS:
      return updateFilter(state, action);
    case fromTypes.GET_PERSON_INFO_SUCCESS:
      return updateGaInfo(state, action);
    case fromTypes.GET_ACTION_CATEGORIES_SUCCESS:
      return setCategories(state, action);
    case fromTypes.GET_INDICATOR_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.ADD_INDICATOR_COMMENT_SUCCESS:
      return setLoading(state, false, action.type);
    // Error
    case fromTypes.GET_BULLETIN_ERROR:
    case fromTypes.GET_FILTERS_ERROR:
    case fromTypes.GET_BULLETIN_URGENCY_ERROR:
    case fromTypes.GET_BULLETIN_SEARCH_ERROR:
    case fromTypes.GET_ACTION_CATEGORIES_ERROR:
    case fromTypes.GET_INDICATOR_COMMENTS_ERROR:
    case fromTypes.ADD_INDICATOR_COMMENT_ERROR:
      return setLoading(state, false, action.type);
    // Misc
    case fromTypes.SET_EXPORT_CHECKED:
      return setChecked(state, action);
    default:
      return state;
  }
};

export default reducer;
