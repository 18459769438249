// @flow

import * as fromTypes from "../types/storeAvailability";

type State = {
  loading: boolean,
  stores: array,
  storeComments: array,
  doughnut: array,
  calendar: array,
};

export const initialState = {
  loading: false,
  stores: [],
  storeComments: [],
  doughnut: {},
  calendar: {},
};

const setStores = (state, { payload }) => ({
  ...state,
  stores: payload,
  loading: false,
});

const setComments = (state, { payload }) => ({
  ...state,
  storeComments: payload,
  loading: false,
});

const setDoungnut = (state, { payload }) => ({
  ...state,
  doughnut: payload,
  loading: false,
});

const setCalendar = (state, { payload }) => ({
  ...state,
  calendar: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (
  state: State = initialState,
  action: fromTypes.StoreAction
) => {
  switch (action.type) {
    case fromTypes.GET_STORE_AVAILABILITY:
    case fromTypes.GET_STORE_DOUNGNUT_CHART:
    case fromTypes.GET_STORE_CALENDAR_CHART:
    case fromTypes.GET_STORE_COMMENTS:
    case fromTypes.SEND_STORE_COMMENTS:
      return setLoading(state, true);
    // Success
    case fromTypes.GET_STORE_AVAILABILITY_SUCCESS:
      return setStores(state, action);
    case fromTypes.GET_STORE_COMMENTS_SUCCESS:
      return setComments(state, action);
    case fromTypes.GET_STORE_DOUNGNUT_CHART_SUCCESS:
      return setDoungnut(state, action);
    case fromTypes.GET_STORE_CALENDAR_CHART_SUCCESS:
      return setCalendar(state, action);
    case fromTypes.SEND_STORE_COMMENTS_SUCCESS:
      return setLoading(state, false);
    // Error
    case fromTypes.GET_STORE_AVAILABILITY_ERROR:
    case fromTypes.GET_STORE_COMMENTS_ERROR:
    case fromTypes.SEND_STORE_COMMENTS_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
