export const required = (value) => (value ? undefined : "Campo obrigatório");

export const onlyNumbers = (value) =>
  value && isNaN(Number(value)) ? "Apenas números" : undefined;

export const minEight = (value) =>
  value && value.length < 8 ? "Digite um número de telefone válido" : undefined;

export const minTwo = (value) =>
  value && value.length < 2 ? "Digite um DDD válido" : undefined;

export const newPassValidators = {
  isValidLength: (pass) => pass.length >= 8 && pass.length <= 14,
  hasLowerCase: (pass) => pass.match(/[a-z]/g),
  hasUpperCase: (pass) => pass.match(/[A-Z]/g),
  hasNumbers: (pass) => /\d/.test(pass),
  hasSpecialCharacters: (pass) =>
    /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(pass),
};

export const isEmail = (value) =>
  value
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    ? undefined
    : "Digite um endereço de e-mail.";

export const onlyLetters = (value) =>
  /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(value)
    ? undefined
    : "Digite apenas letras.";

export const onlyLettersNumbersAndSpecCharacters = (value) =>
  /^[-A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9ºª ]+$/.test(value)
    ? undefined
    : "Caractere inválido. Digite apenas letras e números.";
