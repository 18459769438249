// @flow

export const GET_LAST_TEN_REPORTS = "[ALERTS] GET_LAST_TEN_REPORTS";
export const GET_LAST_TEN_REPORTS_SUCCESS =
  "[ALERTS] GET_LAST_TEN_REPORTS SUCCESS";
export const GET_LAST_TEN_REPORTS_ERROR = "[ALERTS] GET_LAST_TEN_REPORTS ERROR";

export const GET_ALL_REPORTS = "[ALERTS] GET_ALL_REPORTS";
export const GET_ALL_REPORTS_SUCCESS = "[ALERTS] GET_ALL_REPORTS SUCCESS";
export const GET_ALL_REPORTS_ERROR = "[ALERTS] GET_ALL_REPORTS ERROR";

export const GET_ALERTS = "[ALERTS] GET_ALERTS";
export const GET_ALERTS_SUCCESS = "[ALERTS] GET_ALERTS SUCCESS";
export const GET_ALERTS_ERROR = "[ALERTS] GET_ALERTS ERROR";

export const SEND_ALERT = "[ALERTS] SEND_ALERT";
export const SEND_ALERT_SUCCESS = "[ALERTS] SEND_ALERT SUCCESS";
export const SEND_ALERT_ERROR = "[ALERTS] SEND_ALERT ERROR";

export const EDIT_ALERT = "[ALERTS] EDIT_ALERT";
export const EDIT_ALERT_SUCCESS = "[ALERTS] EDIT_ALERT SUCCESS";
export const EDIT_ALERT_ERROR = "[ALERTS] EDIT_ALERT ERROR";

export const DELETE_ALERT = "[ALERTS] DELETE_ALERT";
export const DELETE_ALERT_SUCCESS = "[ALERTS] DELETE_ALERT SUCCESS";
export const DELETE_ALERT_ERROR = "[ALERTS] DELETE_ALERT ERROR";

export const REAL_TIME_ALERTS = "[ALERTS] REAL_TIME_ALERTS";
export const REAL_TIME_ALERTS_SUCCESS = "[ALERTS] REAL_TIME_ALERTS SUCCESS";
export const REAL_TIME_ALERTS_ERROR = "[ALERTS] REAL_TIME_ALERTS ERROR";

export const GET_ALERTS_FULL_LIST = "[ALERTS] GET_ALERTS_FULL_LIST";
export const GET_ALERTS_FULL_LIST_SUCCESS =
  "[ALERTS] GET_ALERTS_FULL_LIST_SUCCESS";
export const GET_ALERTS_FULL_LIST_ERROR = "[ALERTS] GET_ALERTS_FULL_LIST_ERROR";

export const SET_ALERT_READ = "[ALERTS] SET_ALERT_READ";
export const SET_ALERT_READ_SUCCESS = "[ALERTS] SET_ALERT_READ_SUCCESS";
export const SET_ALERT_READ_ERROR = "[ALERTS] SET_ALERT_READ_ERROR";

export const GET_ALERTS_HISTORY = "[ALERTS] GET_ALERTS_HISTORY ";
export const GET_ALERTS_HISTORY_SUCCESS = "[ALERTS] GET_ALERTS_HISTORY_SUCCESS";
export const GET_ALERTS_HISTORY_ERROR = "[ALERTS] GET_ALERTS_HISTORY_ERROR";

type GetLastTenReports = {
  type: typeof GET_LAST_TEN_REPORTS,
};
type GetLastTenReportsSuccess = {
  type: typeof GET_LAST_TEN_REPORTS_SUCCESS,
  payload: Array,
};
type GetLastTenReportsError = {
  type: typeof GET_LAST_TEN_REPORTS_ERROR,
  payload: any,
};

type GetAllReports = {
  type: typeof GET_ALL_REPORTS,
};
type GetAllReportsSuccess = {
  type: typeof GET_ALL_REPORTS_SUCCESS,
  payload: Array,
};
type GetAllReportsError = {
  type: typeof GET_ALL_REPORTS_ERROR,
  payload: any,
};

type GetAlerts = {
  type: typeof GET_ALERTS,
  id: Int,
};
type GetAlertsSuccess = {
  type: typeof GET_ALERTS_SUCCESS,
  payload: Array,
};
type GetAlertsError = {
  type: typeof GET_ALERTS_ERROR,
  payload: any,
};

type SendAlert = {
  type: typeof SEND_ALERT,
  payload: Object,
};
type SendAlertSuccess = {
  type: typeof SEND_ALERT_SUCCESS,
  payload: any,
};
type SendAlertError = {
  type: typeof SEND_ALERT_ERROR,
  payload: any,
};

type EditAlert = {
  type: typeof EDIT_ALERT,
  payload: Object,
};
type EditAlertSuccess = {
  type: typeof EDIT_ALERT_SUCCESS,
  payload: any,
};
type EditAlertError = {
  type: typeof EDIT_ALERT_ERROR,
  payload: any,
};

type DeleteAlert = {
  type: typeof DELETE_ALERT,
  id: Int,
};
type DeleteAlertSuccess = {
  type: typeof DELETE_ALERT_SUCCESS,
  payload: any,
};
type DeleteAlertError = {
  type: typeof DELETE_ALERT_ERROR,
  payload: any,
};

type RealTimeAlerts = {
  type: typeof REAL_TIME_ALERTS,
  id: Int,
};
type RealTimeAlertsSuccess = {
  type: typeof REAL_TIME_ALERTS_SUCCESS,
  payload: any,
};
type RealTimeAlertsError = {
  type: typeof REAL_TIME_ALERTS_ERROR,
  payload: any,
};

type GetAlertsFullList = {
  type: typeof GET_ALERTS_FULL_LIST,
  id: Int,
};
type GetAlertsFullListSuccess = {
  type: typeof GET_ALERTS_FULL_LIST_SUCCESS,
  payload: array,
};
type GetAlertsFullListError = {
  type: typeof GET_ALERTS_FULL_LIST_ERROR,
  payload: any,
};

type SetAlertRead = {
  type: typeof SET_ALERT_READ,
  payload: object,
};
type SetAlertReadSuccess = {
  type: typeof SET_ALERT_READ_SUCCESS,
  payload: array,
};
type SetAlertReadError = {
  type: typeof SET_ALERT_READ_ERROR,
  payload: any,
};

type GetAlertsHistory = {
  type: typeof GET_ALERTS_HISTORY,
  id: object,
};
type GetAlertsHistorySuccess = {
  type: typeof GET_ALERTS_HISTORY_SUCCESS,
  payload: array,
};
type GetAlertsHistoryError = {
  type: typeof GET_ALERTS_HISTORY_ERROR,
  payload: any,
};

export type AlertAction =
  | GetLastTenReports
  | GetLastTenReportsSuccess
  | GetLastTenReportsError
  | GetAllReports
  | GetAllReportsSuccess
  | GetAllReportsError
  | GetAlerts
  | GetAlertsSuccess
  | GetAlertsError
  | SendAlert
  | SendAlertSuccess
  | SendAlertError
  | EditAlert
  | EditAlertSuccess
  | EditAlertError
  | DeleteAlert
  | DeleteAlertSuccess
  | DeleteAlertError
  | RealTimeAlerts
  | RealTimeAlertsSuccess
  | RealTimeAlertsError
  | GetAlertsFullList
  | GetAlertsFullListSuccess
  | GetAlertsFullListError
  | SetAlertRead
  | SetAlertReadSuccess
  | SetAlertReadError
  | GetAlertsHistory
  | GetAlertsHistorySuccess
  | GetAlertsHistoryError;
